/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { SuperiorModel } from 'api/types';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: SuperiorModel | undefined | null;
  justify?: 'toLeft' | 'center' | 'toRight';
  noObject?: boolean;
}

const ShowSuperior = (props: Props): ReactElement => {
  const { data, justify, noObject } = props;
  const { t } = useTranslation('trainings');

  return (
    <>{data && data !== null ? `${data?.lastName} ${data?.firstName}` : ''}</>
  );
};

export default ShowSuperior;
