import React, { ReactElement } from 'react';
import { CustomContainer } from 'components';
import PlansTrainingsEditForm from './EmployeeAppraisalEditForm';

const PlansTrainingsEdit = (): ReactElement => {
  return (
    <>
      <CustomContainer>
        <>
          <PlansTrainingsEditForm />
        </>
      </CustomContainer>
    </>
  );
};

export default PlansTrainingsEdit;
