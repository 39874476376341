import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { IDPRepository } from 'api/repository';
import {
  IDPEmployeeModel,
  IDPLeaderProfileForm,
  IDPLeaderProfilePostAndPut,
} from 'api/types';
import IDPEmployeeLeaderProfile from '../../Form/IDPEmployeeLeaderProfile';

interface Props {
  idEmployee: number;
  IDPEmployee: IDPEmployeeModel;
  fetchData?: () => void;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { IDPEmployee, idEmployee, fetchData, changeModalEdit } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [LeaderProfile] = IDPRepository.useGetIDPLeaderProfileAdmin({
    id: idEmployee,
    idpId: IDPEmployee.id,
  });

  const handleEdit = IDPRepository.usePutIDPLeaderProfileAdmin(
    idEmployee,
    IDPEmployee.id,
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null && fetchData) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPLeaderProfileForm) => {
    if (!submitForm) {
      setSubmitForm(true);
      const dataPost: IDPLeaderProfilePostAndPut = {
        leaderProfileValues: {
          EFFECTIVE_LEADERSHIP: { value: data.effectiveLeadership },
          FLEXIBILITY_PERSEVERANCE_COMPOSURE: {
            value: data.flexibilityPerseveranceComposure,
          },
          INNOVATION_AND_CREATIVITY: { value: data.innovationAndCreativity },
          RESULT_ORIENTATION: { value: data.resultOrientation },
          STRATEGIC_THINKING: { value: data.strategicThinking },
        },
      };
      handleEdit(dataPost);
    }
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <>
      <IDPEmployeeLeaderProfile
        id={idEmployee}
        onSave={handleSubmit}
        submitForm={submitForm}
        data={LeaderProfile}
        cancel={handleCancel}
        edit
      />
    </>
  );
};

export default TrainingsProviderAddPage;
