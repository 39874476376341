import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import { EmployeeAppraisalPostAndPutGoal } from 'api/types';
import { Modal } from 'components';
import EmployeeAppraisalGoalForm from '../../../Form/EmployeeAppraisalGoalForm';

interface CompetencesProps {
  idEmployee: number;
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
}
const EmployeeAppraisalGoalAdd = (props: CompetencesProps): ReactElement => {
  const {
    idEmployee,
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateData,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = EmployeeAppraisalRepository.usePostGoalForEmployee(
    idEmployee,
    async (data) => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
      if (changeModalId) {
        changeModalId(data.id);
      }
      if (changeModalEdit) {
        changeModalEdit(true);
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeArea = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    postDataSave(dataPost);
  };

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePostGoalForEmployee(
    idEmployee,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (
    dataPost: EmployeeAppraisalPostAndPutGoal
  ) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd} widthBox="high">
      <div>
        <EmployeeAppraisalGoalForm
          onSave={handleAddIDPEmployeeArea}
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalAdd;
