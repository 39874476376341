import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import useBreadcrumbs from 'use-react-router-breadcrumbs';

const LogoutComp = (): ReactElement => {
  const breadcrumbs = useBreadcrumbs();

  const breadcrumbsLength = breadcrumbs.length;

  return (
    <div className="topBreadcrumb">
      <div className="topBreadcrumbBlock">
        <p className="topBreadcrumbElement">
          Jesteś tutaj:{' '}
          {breadcrumbs.map(({ match, breadcrumb }, i) => {
            if (breadcrumbsLength === i + 1) {
              return (
                <span key={match.url} className="topBreadcrumbElementActive">
                  {breadcrumb}
                </span>
              );
            }
            return (
              <span key={match.url}>
                <NavLink to={match.url}>{breadcrumb}</NavLink> \{' '}
              </span>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default LogoutComp;
