/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState } from 'react';
import { RenderSvg } from 'components';
import './Box.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  name: string;
  svgIcon?: string;
  exportTable?: boolean;
  children?: ReactElement;
  lightHeader?: boolean;
  allowHideContent?: boolean;
  noMarginBottom?: boolean;
  disabledForm?: boolean;
  noRadius?: boolean;
  darkHeader?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  noContentStyle?: boolean;
  paddingBottom?: boolean;
  allowGrow?: boolean;
  noBorderBottom?: boolean;
}

const Box = (props: Props): ReactElement => {
  const {
    id,
    name,
    svgIcon,
    exportTable,
    children,
    lightHeader,
    allowHideContent,
    noMarginBottom,
    disabledForm,
    noPadding,
    noRadius,
    darkHeader,
    noShadow,
    noContentStyle,
    paddingBottom,
    allowGrow,
    noBorderBottom,
  } = props;

  const [showContent, setShowContent] = useState<boolean>(true);

  const iconElement = () => {
    if (typeof { svgIcon } !== undefined) {
      return <RenderSvg nameSvg={svgIcon} />;
    }
    return null;
  };

  const handleChangeHiddenContent = () => {
    setShowContent(!showContent);
  };

  const hideContentElement = () => {
    if (allowHideContent) {
      if (showContent) {
        return (
          <div
            className="hiddenContent"
            role="button"
            tabIndex={0}
            onClick={() => handleChangeHiddenContent()}
            onKeyDown={() => handleChangeHiddenContent()}
          >
            <RenderSvg nameSvg="chevron-up" />
          </div>
        );
      }
      return (
        <div
          role="button"
          className="hiddenContent"
          tabIndex={0}
          onClick={() => handleChangeHiddenContent()}
          onKeyDown={() => handleChangeHiddenContent()}
        >
          <RenderSvg nameSvg="chevron-down" />
        </div>
      );
    }
    return '';
  };

  return (
    <div
      className={`box ${noMarginBottom ? 'noMarginBottom' : ''} ${
        noPadding ? 'noPadding' : ''
      } ${noRadius ? 'noRadius' : ''} ${noShadow ? 'noShadow' : ''} ${
        noContentStyle ? 'noContentStyle' : ''
      } ${paddingBottom ? 'paddingBottom' : ''} ${
        allowGrow ? 'allowGrow' : ''
      } ${noBorderBottom ? 'noBorderBottom' : ''}`}
      id={id}
    >
      <div
        className={`boxHeader ${lightHeader ? 'light' : ''} ${
          darkHeader ? 'dark' : ''
        }`}
        id={`boxHeader-${id}`}
      >
        <div className="leftBlock" id={`boxHeaderLeft-${id}`}>
          {iconElement()}
          {name}
        </div>
        <div className="rightBlock" id={`boxHeaderRight${id}`}>
          {exportTable}
          {hideContentElement()}
        </div>
      </div>
      {showContent ? (
        <div
          className={`boxContent ${disabledForm ? 'disabledForm' : ''}`}
          id={`boxContent-${id}`}
        >
          {children}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Box;
