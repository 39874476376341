import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  LoaderTable,
  ShowTextarea,
  Tabs,
} from 'components';
import {
  EmployeeAppraisalRepository,
  EmployeeRepository,
} from 'api/repository';

import EmployeeAppraisalEmployeeGoal from './EmployeeAppraisalEmployeeGoal';
import EmployeeAppraisalFeedback from './EmployeeAppraisalFeedback';
import EmployeeAppraisalEmployeeGoalRating from './EmployeeAppraisalEmployeeGoalRating';

import './employeeAppraisalDetail.scss';

const EmployeeAppraisalDetail = (): ReactElement => {
  const { action } = useParams<{
    id: string;
    action: string;
  }>();

  const [
    dataSettings,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});

  const [employeeData] = EmployeeRepository.useGetUser({});

  const [
    getGoalUser,
    updateGoalUser,
  ] = EmployeeAppraisalRepository.useGetGoalUser();

  const [getGoalManager] = EmployeeAppraisalRepository.useGetGoalUserManager();

  const nameBox = () => {
    let textName = 'EDYCJA OCENY PRACOWNIKA ';
    if (getGoalUser && getGoalUser.interviewType === 'FIRST_ANNUAL') {
      textName = 'EDYCJA OCENY PRACOWNIKA - WPROWADZANIE DANYCH';
    }
    if (getGoalUser && getGoalUser.interviewType === 'SIX_MONTH') {
      textName = 'EDYCJA OCENY PRACOWNIKA - ROZMOWA PÓŁROCZNA';
    }
    if (getGoalUser && getGoalUser.interviewType === 'LAST_ANNUAL') {
      textName = 'EDYCJA OCENY PRACOWNIKA - ROZMOWA ROCZNA';
    }
    return textName;
  };

  return (
    <>
      {dataSettings !== null ? (
        <CustomContainer>
          <LoaderTable active={getGoalUser === undefined}>
            <Box
              id="EmployeeAppraisalBox"
              name={getGoalUser === null ? 'DODAWANIE IDP' : nameBox()}
              svgIcon="addEdit"
              noPadding
            >
              <>
                <div id="basicInformation">
                  <div id="firstRowBasicInformation">
                    <div id="firstColumn">
                      <div id="labelEmployee">Fromularz wprowadzania dla:</div>
                      <div id="dataEmployee">
                        {employeeData?.lastName}, {employeeData?.firstName}
                      </div>
                    </div>
                  </div>

                  {getGoalUser?.specificGoal !== undefined &&
                  getGoalUser?.specificGoal !== '' &&
                  getGoalUser?.specificGoal !== null ? (
                    <div id="goal">
                      <div id="goalLabel">
                        Cel szczegółowy przypisany do pracownika:
                      </div>
                      <div id="goalData">
                        <ShowTextarea
                          id="goalText"
                          data={getGoalUser?.specificGoal}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {getGoalManager?.overallGoal !== undefined &&
                      getGoalManager?.overallGoal !== null &&
                      getGoalManager?.overallGoal !== '' ? (
                        <div id="goal">
                          <div id="goalLabel">
                            Cel ogólny przypisany wszystkim pracownikom:
                          </div>
                          <div id="goalData">
                            <ShowTextarea
                              id="goalText"
                              data={getGoalManager?.overallGoal}
                            />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
                {getGoalUser === null ? (
                  <div id="EmployeeAppraisalContentBoxEmpty">
                    Nie ma Oceny pracownika
                  </div>
                ) : (
                  <>
                    <Tabs
                      id="EmployeeAppraisalTabs"
                      items={[
                        {
                          id: 'cele-roczne',
                          label: 'Cele roczne',
                          link: `/panel/ocena-pracownika/cele-roczne`,
                          svgName: 'target',
                        },
                        {
                          id: 'ocena-celow',
                          label: 'Ocena celów',
                          link: `/panel/ocena-pracownika/ocena-celow`,
                          svgName: 'appraisalGoal',
                          disabled:
                            getGoalUser?.interviewType === 'LAST_ANNUAL'
                              ? undefined
                              : true,
                        },
                        {
                          id: 'feedback-180',
                          label: 'Feedback 180',
                          link: `/panel/ocena-pracownika/feedback-180`,
                          svgName: 'feedback180',
                          disabled:
                            getGoalUser?.interviewType === 'LAST_ANNUAL'
                              ? undefined
                              : true,
                        },
                      ]}
                    />
                    <div id="EmployeeAppraisalContentBox">
                      {action === 'cele-roczne' ? (
                        <EmployeeAppraisalEmployeeGoal
                          employeeAppraisalStatus={getGoalUser}
                          updateGoalUser={updateGoalUser}
                        />
                      ) : (
                        ''
                      )}
                      {action === 'ocena-celow' ? (
                        <EmployeeAppraisalEmployeeGoalRating
                          goalUser={getGoalUser}
                          employee={employeeData}
                          updateGoalUser={updateGoalUser}
                        />
                      ) : (
                        ''
                      )}
                      {action === 'feedback-180' ? (
                        <EmployeeAppraisalFeedback
                          employeeAppraisalStatus={getGoalUser}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )}
              </>
            </Box>
          </LoaderTable>
        </CustomContainer>
      ) : (
        <CustomContainer allowGrow>
          <Box
            id="managerEmployeeAppraisalEmployeeBoxNoActivePlans"
            name="OCENA PRACOWNICZA"
            svgIcon="employeeAppraisalList"
            noContentStyle
          >
            <div id="managerEmployeeAppraisalEmployeeNoActivePlans">
              <div className="noActivePlan">
                <div>Aktualnie nie ma aktywnego planu ocen pracownika</div>
                <div>Powiadomimy Cię drogą mailową o jego rozpocząciu</div>
              </div>
            </div>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default EmployeeAppraisalDetail;
