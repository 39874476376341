import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  Box,
  ContentPlansTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
} from 'components';
import { PlansTrainingsRepository } from 'api/repository';
import CatalogsPlans from './CatalogsPlans';
import PlansTrainingsShow from './PlansTrainingsShow';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [getData, data] = PlansTrainingsRepository.useGetAllPlansTrainings();

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const handleOpenAddForm = () => {
    history.push(`/ustawienia/szkolenia/plan-szkolen/formularz`);
  };

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleEditClick = (idClick: number) => {
    history.push(
      `/ustawienia/szkolenia/plan-szkolen/formularz/${idClick}/dane-podstawowe`
    );
  };

  const DeleteRecord = PlansTrainingsRepository.useDeletePlansTrainings(
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const HandleDeleteClick = (idClick: number) => {
    setModalId(idClick);
    DeleteRecord(idClick);
  };

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <Box
            id="plansTrainingsBox"
            name={t('planBox.nameBox')}
            svgIcon="planTrainings"
            noContentStyle
          >
            <ContentPlansTrainings>
              <>
                <ActionBeforeCatalog id="actionBeforeCatalogsTrainings">
                  <Button className="green" onClick={() => handleOpenAddForm()}>
                    {t('planBox.addPlan')}
                  </Button>
                </ActionBeforeCatalog>
                <CatalogsPlans
                  id="CatalogsTrainingsItems"
                  data={data}
                  showClick={handleShowClick}
                  editClick={handleEditClick}
                  archiveClick={HandleDeleteClick}
                />
              </>
            </ContentPlansTrainings>
          </Box>
        </LoaderTable>
      </CustomContainer>
      {modalShow ? (
        <PlansTrainingsShow dataId={modalId} changeShow={setModalShow} />
      ) : (
        ''
      )}
    </>
  );
};

export default Trainigns;
