import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsCompanyContactRepository } from 'api/repository';
import { TrainingsCompanyContactAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsCompanyContact from '../Form/FormTrainingsCompanyContact';

interface TrainigsCompanyProps {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
  saveAndExitOnly?: boolean;
}
const TrainingsCompanyShow = (props: TrainigsCompanyProps): ReactElement => {
  const {
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
    saveAndExitOnly,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = TrainingsCompanyContactRepository.useAddTrainingsCompanyContact(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
      if (changeModalId) changeModalId(data.id);
      if (changeModalEdit) changeModalEdit(true);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsCompanyContact = (
    dataPost: TrainingsCompanyContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSave(data);
  };

  const postDataSaveAndExit = TrainingsCompanyContactRepository.useAddTrainingsCompanyContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsCompanyContactAndExit = (dataPost: any) => {
    setSubmitForm(true);
    const data = dataPost;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd}>
      <div>
        <FormTrainingsCompanyContact
          onSave={
            saveAndExitOnly === undefined
              ? handleAddTrainingsCompanyContact
              : undefined
          }
          onSaveAndExit={handleAddTrainingsCompanyContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsCompanyShow;
