import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { TrainingsRepository } from 'api/repository';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ShowBox,
  ShowShortName,
  ShowName,
  ContentBox,
  LoaderForm,
  ShowTextarea,
  ShowInput,
  ShowUl,
} from 'components';

interface Props {
  id: number;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsShow = (props: Props): ReactElement => {
  const { id, changeModalShow } = props;
  const { t } = useTranslation('trainings');

  const [trainings] = TrainingsRepository.useGetTrainings({
    id: Number(id),
  });

  return (
    <Modal
      open
      changeModalShow={changeModalShow}
      widthBox="medium"
      maxLevel="lvl2"
    >
      <LoaderForm active={trainings === undefined} type="getData">
        <ShowBox
          name={t('showTrainings.previewTrainings')}
          id="previewTrainings"
        >
          <>
            <ContentBox id="previewTrainingsDataBox" bottomBorder>
              <>
                <ShowName
                  id="nameTrainings"
                  label={t('showTrainings.name')}
                  data={trainings?.name}
                />
              </>
            </ContentBox>
            <ContentBox id="previewTrainingsPurposeBox" bottomBorder>
              <>
                <ShowTextarea
                  id="purposeTrainings"
                  label={t('showTrainings.purpose')}
                  data={trainings?.purpose}
                />
              </>
            </ContentBox>
            <ContentBox id="previewTrainingsProgramBox" bottomBorder>
              <>
                <ShowTextarea
                  id="programTrainings"
                  label={t('showTrainings.program')}
                  data={trainings?.program}
                />
              </>
            </ContentBox>
            <ContentBox id="previewTrainingsProvidersTrainingsBox" bottomBorder>
              <>
                <ShowInput
                  id="providersTrainings"
                  label={t('showTrainings.providerTrainings')}
                  data={trainings?.provider?.name}
                  fullWidth
                />
              </>
            </ContentBox>
            <ContentBox id="previewTypeTrainingsBox" bottomBorder>
              <>
                <ShowInput
                  id="typeTrainings"
                  label={t('showTrainings.typeTrainings')}
                  data={
                    trainings?.type
                      ? t(`trainingsTypeEnum.${trainings?.type}`)
                      : ''
                  }
                  fullWidth
                />
              </>
            </ContentBox>
            <ContentBox id="previewTrainingsCompetencesBox" bottomBorder>
              <>
                <ShowUl
                  id="competencesTrainings"
                  label={t('showTrainings.competencesTrainings')}
                  data={trainings?.competences}
                  inLine
                />
              </>
            </ContentBox>
            <ContentBox id="previewTrainingsSltTrainingsBox">
              <div
                style={{
                  marginTop: '1.0625rem',
                  marginBottom: '1.4375rem',
                  color: '#074B32',
                }}
              >
                {t('showTrainings.requiredSLT')}{' '}
                {trainings?.sltAcceptanceRequired ? (
                  <span style={{ color: '#00965E', marginLeft: '5px' }}>
                    {t('showTrainings.YES')}
                  </span>
                ) : (
                  <span style={{ color: '#BF3E3E', marginLeft: '5px' }}>
                    {t('showTrainings.NO')}
                  </span>
                )}
              </div>
            </ContentBox>
          </>
        </ShowBox>
      </LoaderForm>
    </Modal>
  );
};

export default TrainingsShow;
