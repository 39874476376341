import React, { ReactElement } from 'react';
import './customHelp.scss';
import { CustomInfoBlock, RenderSvg } from 'components';

interface Props {
  customSvgBlock?: string;
  customTitleBlock?: string;
  customTextBlock?: string;
  widthBox?: 'low400' | 'low' | 'medium' | 'high';
}

const CustomHelp = (props: Props): ReactElement => {
  const { customSvgBlock, customTitleBlock, customTextBlock, widthBox } = props;
  return (
    <div className="customHelpBlock">
      <CustomInfoBlock
        customSvg={customSvgBlock}
        customTitle={customTitleBlock}
        customText={customTextBlock}
        widthBox={widthBox}
      >
        <>
          <RenderSvg nameSvg="questionCircle" />
        </>
      </CustomInfoBlock>
    </div>
  );
};

export default CustomHelp;
