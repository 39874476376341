import React, { ReactElement } from 'react';
import { useStoreState } from 'store/userSettings';
import LeftMenu from './LeftMenu';
import Block from './Block';

import './index.scss';

interface Props {
  children?: ReactElement;
}

export default (props: Props): ReactElement => {
  const { children } = props;

  const isDarkTheme = useStoreState((state) => state.userSettings.isDarkTheme);
  const isHighContrast = useStoreState(
    (state) => state.userSettings.isHighContrast
  );

  const fontSize = useStoreState((state) => state.userSettings.fontSize);

  const dataTheme = () => {
    let theme = 'light';
    if (isDarkTheme) {
      theme = 'dark';
    }
    return theme;
  };

  const blockClassName = () => {
    let classes = 'blockApps';
    if (isHighContrast) {
      classes += ' highContrast';
    }
    if (fontSize === 1) {
      classes += ' fontSize1';
    }
    if (fontSize === 2) {
      classes += ' fontSize2';
    }
    if (fontSize === 3) {
      classes += ' fontSize3';
    }

    return classes;
  };
  // highContrast
  return (
    <div className={blockClassName()} data-theme={dataTheme()}>
      <LeftMenu />
      <Block>{children}</Block>
    </div>
  );
};
