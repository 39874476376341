import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
  ShowAppraisalGoal,
} from 'components';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal } from 'api/types';
import EmployeeAppraisalGoalAdd from './EmployeeAppraisalGoalAdd';
import EmployeeAppraisalGoalEdit from './EmployeeAppraisalGoalEdit';
import EmployeeAppraisalGoalList from './EmployeeAppraisalGoalList';

interface Props {
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
  updateGoalUser?: (data: EmployeeAppraisalGoal) => void;
  idEmployee: number;
  idEvaluation: number;
  goalUser?: EmployeeAppraisalGoal | null | undefined;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const {
    employeeAppraisalStatus,
    updateGoalUser,
    idEmployee,
    idEvaluation,
    goalUser,
  } = props;

  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForEmployeeManagerN2({
    idEmployee,
    idEvaluation,
  });

  useEffect(() => {
    setLoaderTable(true);

    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="target60" />
                </div>
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfo">
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoLabel">
                    Cele roczne
                  </div>
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące celów pracownika
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="EmployeeAppraisalGoalRatingBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalRatingBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowFirstColumnBox">
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxIcon">
                    <RenderSvg nameSvg="ratingGoal60" />
                  </div>
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfo">
                    <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoLabel">
                      Ocena celów
                    </div>
                  </div>
                </div>
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnBox">
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingEmployeeBox"
                    className="ratingGoal"
                  >
                    <div id="RatingEmployeeLabel" className="label">
                      Ogólna ocena pracy:
                    </div>
                    <div id="RatingEmployeeData" className="data">
                      {goalUser?.selfAppraisal === null ? (
                        <>Brak samooceny</>
                      ) : (
                        <div id="RatingEmployeeDataEdit" className="dataEdit">
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRACOWNIKA"
                            appraisal={goalUser?.selfAppraisal}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerBox"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerLabel" className="label">
                      Ogólna ocena pracy N+1:
                    </div>
                    <div id="RatingManagerData" className="data">
                      {goalUser?.superiorAppraisal === null ? (
                        <>Brak oceny n+1</>
                      ) : (
                        <div id="RatingManagerDataEdit" className="dataEdit">
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRZEZ MANAGERA"
                            appraisal={goalUser?.superiorAppraisal}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerN2Box"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerN2Label" className="label">
                      Finalna ocena pracy:
                    </div>
                    <div id="RatingManagerN2Data" className="data">
                      {goalUser?.finalAppraisal === null ? (
                        <>Brak oceny n+2</>
                      ) : (
                        <ShowAppraisalGoal
                          id="employee"
                          title="KOMENTARZ DO OCENY FINALEJ"
                          appraisal={goalUser?.finalAppraisal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="EmployeeAppraisalGoalBoxSecondRow">
            <>
              <div id="EmployeeAppraisalGoalBoxSecondRowSecondColumn">
                <div id="countGoal">
                  <div id="countGoalLabel">Dodano celów:</div>
                  <div id="countGoalData">
                    {dataGoal?.content.length}
                    <span>/</span> 5
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content &&
              dataGoal?.content.length > 0 &&
              employeeAppraisalStatus ? (
                <>
                  <EmployeeAppraisalGoalList
                    dataGoal={dataGoal.content}
                    setEditFormShow={setEditFormShow}
                    setModalId={setModalId}
                  />
                </>
              ) : (
                <div id="noGoalInAppraisal">Brak przypisanych celów</div>
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {editFormShow ? (
        <EmployeeAppraisalGoalEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
          idEvaluation={idEvaluation}
          modalId={modalId}
          employeeAppraisalStatus={employeeAppraisalStatus}
          onlyShow
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
