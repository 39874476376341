import { TrainingsGroupRepository } from 'api/repository';
import {
  TableRecordAction,
  CustomHeaderFormatter,
  TableCellTypeTrainings,
  TableCellListToCell,
  CustomFilterData,
  CustomSortData,
  LoaderTable,
} from 'components';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import { convertStringDatetoShortDate } from 'utils';
import TrainingGroupShow from '../../../Administration/TrainingsGroup/TrainingsGroupShow';

import './trainingsList.scss';

interface Props {
  id: string;
  name: string;
}

const ElementItem = (props: Props): ReactElement => {
  const { id, name } = props;

  const history = useHistory();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [page, setPage] = React.useState(1);

  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    'training.name': 'name',
    'training.competences': 'competences',
    'training.type': 'type',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'training.name': 'TRAINING_NAME',
    'training.type': 'TYPE',
  };
  // end variable order

  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetAllUserTrainingsGroup();

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: {
    [key: string]: string | number[];
  }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.training.id}
            rowNumber={rowIndex}
            page={1}
            rowsPerPage={100}
            showClick={
              row.trainingGroup !== null
                ? () => {
                    setModalId(row.training.id);
                    setModalShow(true);
                  }
                : undefined
            }
            editClick={() => {
              history.push(
                `/panel/szkolenia/termin-szkolenia/${row.training.id}`
              );
            }}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      text: 'Nazwa szkolenia',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'training.type',
      text: 'Rodzaj szkolenia',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.training.type} />;
      },
    },
    {
      dataField: 'training.competences',
      text: 'Kompetencje',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell data={row?.training.competences} />;
      },
    },
    {
      dataField: 'trainingGroup',
      text: 'Data szkolenia',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            {row.trainingGroup === null ? (
              <Button
                className="green"
                onClick={() =>
                  history.push(
                    `/panel/szkolenia/termin-szkolenia/${row.training.id}`
                  )
                }
              >
                Wybierz datę
              </Button>
            ) : (
              <>
                {row.trainingGroup.singleDate !== null ? (
                  convertStringDatetoShortDate(row?.trainingGroup?.singleDate)
                ) : (
                  <>
                    {convertStringDatetoShortDate(
                      row?.trainingGroup?.startDate
                    )}{' '}
                    -{' '}
                    {convertStringDatetoShortDate(row?.trainingGroup?.endDate)}
                  </>
                )}
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div id={`desktopItem-${id}`} className="desktopItemTrainingsList">
      <div id={`desktopItemAccent-${id}`} className="accent" />
      <div id={`desktopItemName-${id}`} className="name">
        {name}
      </div>
      <div id={`desktopItemContent-${id}`} className="content">
        <LoaderTable active={loaderTable} noRadiusTop>
          <>
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication="Brak szkoleń"
              remote={{
                filter: true,
                sort: true,
              }}
              onTableChange={onTableChange}
              filter={filterFactory()}
              defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            />
          </>
        </LoaderTable>
      </div>
      {modalShow ? (
        <TrainingGroupShow id={modalId} changeModalShow={setModalShow} />
      ) : (
        ''
      )}
    </div>
  );
};

export default ElementItem;
