import React, { ReactElement } from 'react';
import { IDPEmployeeModel } from 'api/types';
import './IDPEmployeeLeaderProfile.scss';
import {
  Box,
  ContentBox,
  LoaderForm,
  RenderSvg,
  ShowTextarea,
} from 'components';
import { IDPRepository } from 'api/repository';

interface Props {
  idIdp: number;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { idIdp } = props;
  const [
    LeaderProfile,
    LeaderProfileUpdate,
  ] = IDPRepository.useGetIDPLeaderProfileUserFromIdp({ idIdp });

  return (
    <div id="showIDPEmployeeLeaderProfile">
      <LoaderForm active={LeaderProfile === undefined} type="getData">
        <>
          <ContentBox id="showIDPEmployeeLeaderProfileFirstRow" bottomBorder>
            <div id="showIDPEmployeeLeaderProfileFirstRowBox">
              <div id="showIDPEmployeeLeaderProfileFirstRowBoxIcon">
                <RenderSvg nameSvg="employeeWithStar60" />
              </div>
              <div id="showIDPEmployeeLeaderProfileFirstRowBoxInfo">
                <div id="showIDPEmployeeLeaderProfileFirstRowBoxInfoLabel">
                  Profil lidera
                </div>
                <div id="showIDPEmployeeLeaderProfileFirstRowBoxInfoText">
                  Przedstawiamy Wam Profil Lidera, który stworzyliśmy w oparciu
                  o najbardziej kluczowe kompetencje, niezbędne do rozwoju
                  zawodowego naszych pracowników. <br />
                  Model kompetencji jest uniwersalny dla wszystkich Liderów i
                  obowiązuje w całej firmie. Wpisuje się również w naszą
                  strategię oraz zapewnia spójność kultury organizacyjnej.
                </div>
              </div>
            </div>
          </ContentBox>
          <Box
            lightHeader
            name="Kompetencje lidera:"
            id="IDPLeaderProfileBox"
            noRadius
          >
            <>
              <div id="resultOrientationBox" className="leaderProfileBoxItem">
                <div id="resultOrientationBoxAccent" className="boxAccent" />
                <div id="resultOrientationBoxTitleBox" className="boxTitleBox">
                  <div id="resultOrientationBoxTitle" className="boxTitle">
                    Orientacja na wyniki
                  </div>
                </div>
                <div
                  id="resultOrientationBoxAdditionalInfoBox"
                  className="boxAdditionalInfoBox"
                >
                  <div
                    id="resultOrientationBoxAdditionalInfoBoxItem01"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemNumber01"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      01
                    </div>
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemText01"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Jest zorientowany na działanie.
                    </div>
                  </div>
                  <div
                    id="resultOrientationBoxAdditionalInfoBoxItem02"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemNumber02"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      02
                    </div>
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemText02"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Można liczyć na to, że z powodzeniem przekroczy cele.
                    </div>
                  </div>
                  <div
                    id="resultOrientationBoxAdditionalInfoBoxItem03"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemNumber03"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      03
                    </div>
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemText03"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Kwestionuje status quo i stale dąży do jak najlepszych
                      wyników.
                    </div>
                  </div>
                  <div
                    id="resultOrientationBoxAdditionalInfoBoxItem04"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemNumber04"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      04
                    </div>
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemText04"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Wytrwale motywuje siebie i innych do osiągania wyników.
                    </div>
                  </div>
                  <div
                    id="resultOrientationBoxAdditionalInfoBoxItem05"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemNumber05"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      05
                    </div>
                    <div
                      id="resultOrientationBoxAdditionalInfoBoxItemText05"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Wytrwale motywuje siebie i innych do osiągania wyników.
                    </div>
                  </div>
                </div>
                <div
                  id="resultOrientationBoxshowConsistentBehaviour"
                  className="boxshowConsistentBehaviour"
                >
                  <ShowTextarea
                    id="resultOrientation"
                    data={
                      LeaderProfile?.leaderProfileValues.RESULT_ORIENTATION
                        .value
                    }
                    label="Działania i czynności rozwojowe:"
                  />
                </div>
              </div>

              <div id="effectiveLeadershipBox" className="leaderProfileBoxItem">
                <div id="effectiveLeadershipBoxAccent" className="boxAccent" />
                <div
                  id="effectiveLeadershipBoxTitleBox"
                  className="boxTitleBox"
                >
                  <div id="effectiveLeadershipBoxTitle" className="boxTitle">
                    Skuteczne przywództwo
                  </div>
                </div>
                <div
                  id="effectiveLeadershipBoxAdditionalInfoBox"
                  className="boxAdditionalInfoBox"
                >
                  <div
                    id="effectiveLeadershipBoxAdditionalInfoBoxItem01"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemNumber01"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      01
                    </div>
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemText01"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Jest liderem angażującym i motywującym zespól.
                    </div>
                  </div>
                  <div
                    id="effectiveLeadershipBoxAdditionalInfoBoxItem02"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemNumber02"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      02
                    </div>
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemText02"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Prowadzi inspirującą i przejrzystą komunikację.
                    </div>
                  </div>
                  <div
                    id="effectiveLeadershipBoxAdditionalInfoBoxItem03"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemNumber03"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      03
                    </div>
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemText03"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Bierze odpowiedzialność za realizację strategii oraz
                      mobilizację zespołu do osiągania założonych celów.
                    </div>
                  </div>
                  <div
                    id="effectiveLeadershipBoxAdditionalInfoBoxItem04"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemNumber04"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      04
                    </div>
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemText04"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Wyznacza jasny kierunek.
                    </div>
                  </div>
                  <div
                    id="effectiveLeadershipBoxAdditionalInfoBoxItem05"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemNumber05"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      05
                    </div>
                    <div
                      id="effectiveLeadershipBoxAdditionalInfoBoxItemText05"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Rozwija ludzi, promuje zaangażowanie i współpracę w
                      zespole.
                    </div>
                  </div>
                </div>
                <div
                  id="effectiveLeadershipBoxshowConsistentBehaviour"
                  className="boxshowConsistentBehaviour"
                >
                  <ShowTextarea
                    id="effectiveLeadership"
                    data={
                      LeaderProfile?.leaderProfileValues.EFFECTIVE_LEADERSHIP
                        .value
                    }
                    label="Działania i czynności rozwojowe:"
                  />
                </div>
              </div>

              <div
                id="innovationAndCreativityBox"
                className="leaderProfileBoxItem"
              >
                <div
                  id="innovationAndCreativityBoxAccent"
                  className="boxAccent"
                />
                <div
                  id="innovationAndCreativityBoxTitleBox"
                  className="boxTitleBox"
                >
                  <div
                    id="innovationAndCreativityBoxTitle"
                    className="boxTitle"
                  >
                    Innowacja i kreatywność
                  </div>
                </div>
                <div
                  id="innovationAndCreativityBoxAdditionalInfoBox"
                  className="boxAdditionalInfoBox"
                >
                  <div
                    id="innovationAndCreativityBoxAdditionalInfoBoxItem01"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemNumber01"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      01
                    </div>
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemText01"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Proponuje nowe, wartościowe rozwiązania, które
                      poprawiają/przekształcają zastaną sytuację.
                    </div>
                  </div>
                  <div
                    id="innovationAndCreativityBoxAdditionalInfoBoxItem02"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemNumber02"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      02
                    </div>
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemText02"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Jest zorientowany na klienta, rozumie jego potrzeby i
                      profil jego działalności.
                    </div>
                  </div>
                  <div
                    id="innovationAndCreativityBoxAdditionalInfoBoxItem03"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemNumber03"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      03
                    </div>
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemText03"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Umiejętnie tworzy wartość dodaną dla klienta i dla
                      interesariuszy.
                    </div>
                  </div>
                  <div
                    id="innovationAndCreativityBoxAdditionalInfoBoxItem04"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemNumber04"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      04
                    </div>
                    <div
                      id="innovationAndCreativityBoxAdditionalInfoBoxItemText04"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Doskonali procesy, wydajność i skuteczność.
                    </div>
                  </div>
                </div>
                <div
                  id="innovationAndCreativityBoxshowConsistentBehaviour"
                  className="boxshowConsistentBehaviour"
                >
                  <ShowTextarea
                    id="innovationAndCreativity"
                    data={
                      LeaderProfile?.leaderProfileValues
                        .INNOVATION_AND_CREATIVITY.value
                    }
                    label="Działania i czynności rozwojowe:"
                  />
                </div>
              </div>

              <div id="strategicThinkingBox" className="leaderProfileBoxItem">
                <div id="strategicThinkingBoxAccent" className="boxAccent" />
                <div id="strategicThinkingBoxTitleBox" className="boxTitleBox">
                  <div id="strategicThinkingBoxTitle" className="boxTitle">
                    Myślenie strategiczne
                  </div>
                </div>
                <div
                  id="strategicThinkingBoxAdditionalInfoBox"
                  className="boxAdditionalInfoBox"
                >
                  <div
                    id="strategicThinkingBoxAdditionalInfoBoxItem01"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemNumber01"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      01
                    </div>
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemText01"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Potrafi zrównoważyć czas i swoje podejście do codziennych
                      tematów operacyjnych i strategicznych.
                    </div>
                  </div>
                  <div
                    id="strategicThinkingBoxAdditionalInfoBoxItem02"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemNumber02"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      02
                    </div>
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemText02"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Posiada szeroką wiedzę i perspektywę, wyciąga
                      wnioski/spostrzeżenia z wcześniejszych doświadczeń i z
                      otoczenia.
                    </div>
                  </div>
                  <div
                    id="strategicThinkingBoxAdditionalInfoBoxItem03"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemNumber03"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      03
                    </div>
                    <div
                      id="strategicThinkingBoxAdditionalInfoBoxItemText03"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Jest zorientowany na przyszłość, rozwija myślenie i
                      planowanie długoterminowe.
                    </div>
                  </div>
                </div>
                <div
                  id="strategicThinkingBoxshowConsistentBehaviour"
                  className="boxshowConsistentBehaviour"
                >
                  <ShowTextarea
                    id="strategicThinking"
                    data={
                      LeaderProfile?.leaderProfileValues?.STRATEGIC_THINKING
                        .value
                    }
                    label="Działania i czynności rozwojowe:"
                  />
                </div>
              </div>
              <div
                id="flexibilityPerseveranceComposureBox"
                className="leaderProfileBoxItem"
              >
                <div
                  id="flexibilityPerseveranceComposureBoxAccent"
                  className="boxAccent"
                />
                <div
                  id="flexibilityPerseveranceComposureBoxTitleBox"
                  className="boxTitleBox"
                >
                  <div
                    id="flexibilityPerseveranceComposureBoxTitle"
                    className="boxTitle"
                  >
                    Elastyczność, wytrwałość, opanowanie
                  </div>
                </div>
                <div
                  id="flexibilityPerseveranceComposureBoxAdditionalInfoBox"
                  className="boxAdditionalInfoBox"
                >
                  <div
                    id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItem01"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemNumber01"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      01
                    </div>
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemText01"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Zachowuje spokój w obliczu presji i stresu.
                    </div>
                  </div>
                  <div
                    id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItem02"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemNumber02"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      02
                    </div>
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemText02"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Pracuje z zaangażowaniem, energią i z ukierunkowaniem na
                      realizację celu.
                    </div>
                  </div>
                  <div
                    id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItem03"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemNumber03"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      03
                    </div>
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemText03"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Stale wykazuje się pozytywnym podejściem, dojrzałością,
                      jak również - zależnie od sytuacji - postępuje
                      dyplomatycznie bądź stanowczo.
                    </div>
                  </div>
                  <div
                    id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItem04"
                    className="boxAdditionalInfoBoxItem"
                  >
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemNumber04"
                      className="boxAdditionalInfoBoxItemNumber"
                    >
                      04
                    </div>
                    <div
                      id="flexibilityPerseveranceComposureBoxAdditionalInfoBoxItemText04"
                      className="boxAdditionalInfoBoxItemText"
                    >
                      Efektywnie zarządza kryzysem i szybko dostosowuje się do
                      zmian.
                    </div>
                  </div>
                </div>
                <div
                  id="flexibilityPerseveranceComposureBoxshowConsistentBehaviour"
                  className="boxshowConsistentBehaviour"
                >
                  <ShowTextarea
                    id="flexibilityPerseveranceComposure"
                    data={
                      LeaderProfile?.leaderProfileValues
                        ?.FLEXIBILITY_PERSEVERANCE_COMPOSURE.value
                    }
                    label="Działania i czynności rozwojowe:"
                  />
                </div>
              </div>
            </>
          </Box>
        </>
      </LoaderForm>
    </div>
  );
};

export default TrainingsProviderAddPage;
