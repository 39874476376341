import React, { ReactElement } from 'react';
import Form from 'react-bootstrap/Form';
import './CustomCheckbox.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface CheckboxProps {
  name: string;
  label?: string;
  register?: () => void;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  error?: string | undefined;
  value?: boolean | undefined;
  defaultValue?: boolean | undefined;
  placeholder?: string;
  info?: string;
}

const CustomCheckbox = (props: CheckboxProps): ReactElement => {
  const {
    name,
    label,
    register,
    required,
    id,
    disabled,
    error,
    value,
    defaultValue,
    placeholder,
    info,
  } = props;

  const idElement = () => {
    let tId = 'id=';
    if (typeof { id } === 'string') {
      tId += { id };
    } else {
      tId += { name };
    }
    return tId;
  };

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let tclass = 'blockCheckbox';
    if (required) {
      tclass += ' required';
    }
    if (typeof error !== 'undefined') {
      tclass += ' error';
    }
    if (typeof info !== 'undefined' && !disabled) {
      tclass += ' withInfo';
    }
    if (disabled) {
      tclass += ' disabled';
    }

    return tclass;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  return (
    <>
      <div className={additionalClass()}>
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
        <input
          type="checkbox"
          id={name}
          name={name}
          defaultChecked={defaultValue}
          ref={register}
          disabled={disabled}
          className="form-check-input"
        />

        {infoElement()}
      </div>
      <div className="additionalInfo">{errorElement()}</div>
    </>
  );
};

export default CustomCheckbox;
