import React, { ReactElement, useState } from 'react';
import './Action.scss';
import { RenderSvg, ModalYesNo } from 'components';
import { useTranslation } from 'react-i18next';

/*
TODO
Toggle TableRecordSwitch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  archiveClick: () => void;
}

const ArchiveAction = (props: Props): ReactElement => {
  const { archiveClick } = props;
  const { t } = useTranslation('componentTableRecordAction');

  const [modalShow, setModalShow] = useState<boolean>(false);

  return (
    <>
      <div
        className="buttonAction"
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => setModalShow(true)}
      >
        <RenderSvg nameSvg="archiveAction" />
      </div>
      {modalShow ? (
        <ModalYesNo changeModalShow={setModalShow} yesAction={archiveClick} />
      ) : (
        ''
      )}
    </>
  );
};

export default ArchiveAction;
