import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, CustomContainer, Tabs } from 'components';

import TrainingsGroupEdit from './TrainingsGroupEdit';

import EmployeeRegisterTraining from './EmployeeRegisterTraining';
import EmployeeEndTraining from './EmployeeEndTraining';
import TrainingsGroupSettlement from './TrainingsGroupSettlement';
import Questionnaire from './Questionnaire';

import './trainingsGroupDetail.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation('trainingsGroup');
  const { id, idRecord, action } = useParams<{
    id: string;
    idRecord: string;
    action: string;
  }>();

  return (
    <>
      <CustomContainer>
        <Box
          id="trainingsGroupEditBox"
          name="EDYCJA GRUPY SZKOLENIOWEJ"
          svgIcon="addEdit"
          noPadding
        >
          <>
            <Tabs
              id="TrainingsGruopTabs"
              items={[
                {
                  id: 'dane-podstawowe',
                  label: 'Dane dostawcy',
                  link: `/administracja/szkolenia/grupy-szkoleniowe/${id}/${idRecord}/dane-podstawowe`,
                  svgName: 'trainingsGroupBasicData',
                },
                {
                  id: 'osoby-zapisane',
                  label: 'Osoby zapisane',
                  link: `/administracja/szkolenia/grupy-szkoleniowe/${id}/${idRecord}/osoby-zapisane`,
                  svgName: 'tabPeople',
                },
                {
                  id: 'ukonczenie-szkolenia',
                  label: 'Ukończenie szkolenia',
                  link: `/administracja/szkolenia/grupy-szkoleniowe/${id}/${idRecord}/ukonczenie-szkolenia`,
                  svgName: 'trainingsGroupEndTraining',
                },
                {
                  id: 'rozliczenie-szkolenia',
                  label: 'Rozliczenie szkolenia',
                  link: `/administracja/szkolenia/grupy-szkoleniowe/${id}/${idRecord}/rozliczenie-szkolenia`,
                  svgName: 'trainingsGroupPayment',
                },
                {
                  id: 'formularz-ankiety',
                  label: 'Formularz ankiety',
                  link: `/administracja/szkolenia/grupy-szkoleniowe/${id}/${idRecord}/formularz-ankiety`,
                  svgName: 'trainingsGroupQuestionnaire',
                },
              ]}
            />
            <div id="trainingsGroupEditContentBox">
              {action === 'dane-podstawowe' ? <TrainingsGroupEdit /> : ''}
              {action === 'osoby-zapisane' ? <EmployeeRegisterTraining /> : ''}
              {action === 'ukonczenie-szkolenia' ? <EmployeeEndTraining /> : ''}
              {action === 'rozliczenie-szkolenia' ? (
                <TrainingsGroupSettlement />
              ) : (
                ''
              )}
              {action === 'formularz-ankiety' ? <Questionnaire /> : ''}
            </div>
          </>
        </Box>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
