/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import RenderSvg from 'components/RenderSvg';
import React, { ReactElement } from 'react';
import { toast } from 'react-toastify';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  typeAlert: 'success' | 'error' | 'info' | 'warning';
  customMsg?: string;
}

const CustomAlert = (props: Props): ReactElement => {
  const { typeAlert, customMsg } = props;

  return (
    <>
      {typeAlert === 'info'
        ? toast.info(
            <div className="alertMsgBox">
              <div className="alertTitle">INFORMACJA</div>
              <div className="alertIconAndMsg">
                <div className="alertIcon">
                  <RenderSvg nameSvg="alertInfoIcon" />
                </div>
                <div className="alertMsg">{customMsg}</div>
              </div>
            </div>,
            {
              autoClose: false,
            }
          )
        : ''}
      {typeAlert === 'warning'
        ? toast.warning(
            <div className="alertMsgBox">
              <div className="alertTitle">OSTRZEŻENIE</div>
              <div className="alertIconAndMsg">
                <div className="alertIcon">
                  <RenderSvg nameSvg="alertWarningIcon" />
                </div>
                <div className="alertMsg">{customMsg}</div>
              </div>
            </div>,
            {
              autoClose: false,
            }
          )
        : ''}

      {typeAlert === 'success'
        ? toast.success(
            <div className="alertMsgBox">
              <div className="alertTitle">POWODZENIE</div>
              <div className="alertIconAndMsg">
                <div className="alertIcon">
                  <RenderSvg nameSvg="alertSuccessIcon" />
                </div>
                <div className="alertMsg">{customMsg}</div>
              </div>
            </div>
          )
        : ''}
      {typeAlert === 'error'
        ? toast.error(
            <div className="alertMsgBox">
              <div className="alertTitle">WYSTĄPIŁ BŁĄD</div>
              <div className="alertIconAndMsg">
                <div className="alertIcon">
                  <RenderSvg nameSvg="alertErrorIcon" />
                </div>
                <div className="alertMsg">{customMsg}</div>
              </div>
            </div>,
            {
              autoClose: false,
            }
          )
        : ''}
    </>
  );
};

export default CustomAlert;
