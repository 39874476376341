import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorLoadData } from 'components/CustomForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EmployeeAppraisalRatingModel,
  EmployeeAppraisalRatingPut,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  CustomSelect,
  EditorMceShort,
} from 'components';

import './finalGoalRatingForm.scss';
import { triggerValidRequired } from 'utils';
import { GroupEmployeeAppraisalEmployeeModel } from 'api/types/EmployeeAppraisal';
import { Button } from 'react-bootstrap';
import AppraisalModalSelect from '../../AppraisalModalSelect';

type FormModel = EmployeeAppraisalRatingPut;

interface Props {
  onSaveAndExit?: (dataPost: EmployeeAppraisalRatingModel) => void;
  cancel?: () => void;
  data?: GroupEmployeeAppraisalEmployeeModel | null;
  edit?: boolean;
  onlyView?: boolean;
  submitForm?: boolean;
  lastEdit?: boolean;
  titleBox: string;
}

const GoalRatingForm = (props: Props): ReactElement => {
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    edit,
    data,
    onlyView,
    onSaveAndExit,
    submitForm,
    cancel,
    lastEdit,
    titleBox,
  } = props;

  const { t } = useTranslation(['enum']);
  const [modalShow, setModalShow] = useState<boolean>(false);

  const formSchema: yup.SchemaOf<FormModel> = yup.object().shape({
    justification: yup.string().required('Pole wymagane'),
    rating: yup.string().required('Pole wymagane'),
  });

  const handleSaveAndExit = (dataSubmit: FormModel) => {
    setChangeForm(false);
    if (onSaveAndExit !== undefined) {
      onSaveAndExit(dataSubmit);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    trigger,
  } = useForm<FormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              id="formGoalRatingFinal"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formGoalRatingFinalBox"
                    name={titleBox}
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <div id="formGoalRatingFinalEmployee">
                        {data?.lastName} {data?.firstName}
                      </div>
                      <div id="formGoalRatingFinalSelfAppraisal">
                        Samoocena pracownika:{' '}
                        <span>
                          {data?.selfAppraisal?.rating !== undefined
                            ? t(`appraisalGoal.${data?.selfAppraisal?.rating}`)
                            : 'Brak samooceny pracownika'}
                        </span>
                        {data?.selfAppraisal?.justification !== undefined ? (
                          <div id="formGoalRatingFinalSelfAppraisalComment">
                            Komentarz do samooceny:{' '}
                            <div // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={
                                data?.selfAppraisal?.justification
                                  ? {
                                      __html:
                                        data?.selfAppraisal?.justification,
                                    }
                                  : undefined
                              }
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <div id="formGoalRatingFinalSuperiorAppraisal">
                        Propozycja oceny N+1:{' '}
                        <span>
                          {data?.superiorAppraisal?.rating !== undefined
                            ? t(
                                `appraisalGoal.${data?.superiorAppraisal?.rating}`
                              )
                            : 'Brak propozycji oceny przez kierownika'}
                        </span>
                        {data?.superiorAppraisal?.justification !==
                        undefined ? (
                          <div id="formGoalRatingFinalSelfAppraisalComment">
                            Komentarz do propozycji N+1:{' '}
                            <div // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={
                                data?.superiorAppraisal?.justification
                                  ? {
                                      __html:
                                        data?.superiorAppraisal?.justification,
                                    }
                                  : undefined
                              }
                            />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div id="formGoalRatingFinalSuperiorAppraisal">
                        <Button
                          className="green"
                          onClick={() => setModalShow(true)}
                        >
                          Podgląd celów historycznych
                        </Button>
                      </div>
                      <div id="formGoalRatingFinalAppraisal">
                        {data?.superiorAppraisal?.rating !== undefined ? (
                          <>
                            <CustomSelect
                              label="Ocena końcowa:"
                              options={[
                                {
                                  value: 'EXCEEDS_EXPECTATIONS',
                                  label: t(
                                    'appraisalGoal.EXCEEDS_EXPECTATIONS'
                                  ),
                                },
                                {
                                  value: 'PARTIALLY_EXCEEDS_EXPECTATIONS',
                                  label: t(
                                    'appraisalGoal.PARTIALLY_EXCEEDS_EXPECTATIONS'
                                  ),
                                },
                                {
                                  value: 'FULLY_MEETS_EXPECTATIONS',
                                  label: t(
                                    'appraisalGoal.FULLY_MEETS_EXPECTATIONS'
                                  ),
                                },
                                {
                                  value: 'PARTIALLY_MEETS_EXPECTATIONS',
                                  label: t(
                                    'appraisalGoal.PARTIALLY_MEETS_EXPECTATIONS'
                                  ),
                                },

                                {
                                  value: 'DOES_NOT_MEET_EXPECTATIONS',
                                  label: t(
                                    'appraisalGoal.DOES_NOT_MEET_EXPECTATIONS'
                                  ),
                                },
                              ]}
                              name="rating"
                              required
                              placeholder="Wybierz adekwatną ocenę"
                              setValue={setValue}
                              error={errors?.rating?.message}
                              validRequiredSelect={() => {
                                triggerValidRequired('rating', trigger);
                              }}
                              valueByKey={data?.finalAppraisal?.rating}
                            />

                            <EditorMceShort
                              id="justification"
                              name="justification"
                              setHeight={120}
                              placeholder="Wpisz swój komentarz"
                              register={register}
                              label="Komentarz:"
                              contentGet={data?.finalAppraisal?.justification}
                              error={errors?.justification?.message}
                              required
                            />
                          </>
                        ) : (
                          'Nie można wystawić oceny końcowej kiedy N+1 nie określił swojej propozycji oceny'
                        )}
                      </div>
                    </>
                  </Box>
                </>
              </LoaderForm>
              {data?.superiorAppraisal?.rating !== undefined ? (
                <CustomSubmit
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
            {modalShow && data && data.id ? (
              <AppraisalModalSelect
                changeModalEdit={setModalShow}
                employeeId={data.id}
                employeeName={`${data?.lastName} ${data?.firstName}`}
              />
            ) : (
              ''
            )}
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default GoalRatingForm;
