import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import {
  Box,
  CustomHeaderFormatter,
  TableCellAppraisalGoal,
  TableCellAppraisalStatus,
  TableCellNumberPrecent,
  TableCellProgressBar,
  TableCellTypeGoal,
  TableRecordAction,
} from 'components';
import './employeeAppraisalGoalRatingList.scss';
import { EmployeeAppraisalGoalModel, EmployeeModel } from 'api/types';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';

interface Props {
  dataGoal: EmployeeAppraisalGoalModel[];
  setEditFormShow: Dispatch<SetStateAction<boolean>>;
  setModalId: Dispatch<SetStateAction<number>>;
  deleteAction?: (idClick: number) => void;
  onlyView?: boolean;
}

const EmployeeAppraisalGoalRatingList = (props: Props): ReactElement => {
  const {
    dataGoal,
    setEditFormShow,
    setModalId,
    deleteAction,
    onlyView,
  } = props;

  const [page, setPage] = React.useState(1);

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            editClick={() => {
              setModalId(row.id);
              setEditFormShow(true);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={100}
          />
        );
      },
    },
    {
      dataField: 'type',
      text: 'Rodzaj celu',
      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeGoal id={row.id} type={row.type} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: 'Nazwa celu',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'weight',
      text: 'Waga %',
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellNumberPrecent number={row.weight} />;
      },
      headerStyle: () => {
        return { width: '110px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      align: 'center',

      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellAppraisalStatus id={row.id} status={row.status} />;
      },
      headerStyle: () => {
        return { width: '140px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'progress',
      text: 'Postęp realizacji',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (_cell: unknown, row: any) => {
        return <TableCellProgressBar id={row.id} valueEnd={row.progress} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'appraisal',
      text: 'Samoocena',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellAppraisalGoal
            title="Komentarz do celu"
            id={row.id}
            appraisal={row.appraisal}
          />
        );
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
  ];

  return (
    <>
      <Box id="IDPEmployeeAreaBox" lightHeader name="Samoocena celów:" noRadius>
        <>
          <BootstrapTable
            bootstrap4
            hover
            bordered={false}
            keyField="id"
            data={dataGoal || []}
            columns={columns}
            noDataIndication="Brak danych"
            remote={{
              filter: true,
              sort: true,
            }}
            filter={filterFactory()}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
          />
        </>
      </Box>
    </>
  );
};

export default EmployeeAppraisalGoalRatingList;
