import { AuthResources, Box, CustomContainer } from 'components';
import React, { ReactElement } from 'react';

import './panel.scss';
import UserItem from './UserItem';

const Panel = (): ReactElement => {
  return (
    <CustomContainer allowGrow>
      <>
        <Box
          id="userDesktop"
          name="PULPIT UŻYTKOWNIKA"
          svgIcon="desktop"
          noContentStyle
        >
          <div id="userDesktopBox">
            <AuthResources requiredRoles={['user']}>
              <UserItem />
            </AuthResources>
          </div>
        </Box>
      </>
    </CustomContainer>
  );
};

export default Panel;
