/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { CustomSelect, RenderSvg } from 'components';
import AutosizeInput from 'react-input-autosize';

import './TablePagination.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  rowInPage?: number;
  changeRowInPage?: (rowInPage: number) => void;
  children?: ReactElement;
  page?: number;
  changePage?: (page: number) => void;
  data?: any;
}

const TablePagination = (props: Props): ReactElement => {
  const {
    rowInPage,
    changeRowInPage,
    children,
    page,
    changePage,
    data,
  } = props;

  const [numberPage, setNumberPage] = useState(`${page}`);
  const [maxPage, setMaxPage] = useState<number>(
    data?.totalPages > 0 ? data.totalPages : 1
  );

  useEffect(() => {
    if (Number(numberPage) !== page) {
      setNumberPage(`${page}`);
    }
  }, [page]);

  useEffect(() => {
    if (data) {
      if (data?.totalPages !== maxPage) {
        if (data.totalPages === 0) {
          setMaxPage(1);
        } else {
          setMaxPage(data.totalPages);
        }
      }
    }
  }, [data?.totalPages]);

  const handleChangePage = () => {
    if (Number(numberPage) >= 1 && Number(numberPage) <= maxPage) {
      if (changePage) changePage(Number(numberPage));
    } else {
      setNumberPage(`${page}`);
    }
  };

  const pageUp = () => {
    if (page)
      if (page < data.totalPages) {
        if (changePage) changePage(page + 1);
      }
  };

  const pageDown = () => {
    if (page)
      if (page > 1) {
        if (changePage) changePage(page - 1);
      }
  };

  const options = [
    { value: '3', label: '3' },
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ];

  const onChangeSelect = (e: any) => {
    if (changeRowInPage) {
      if (changePage) changePage(1);
      changeRowInPage(Number(e.value));
    }
  };

  const value = () => {
    return options.filter((option: any) => option.value === `${rowInPage}`);
  };

  return (
    <div className="tablePagination">
      <div className="tablePaginationLeft">{children}</div>
      <div className="tablePaginationRight">
        <div className="rowInPage">
          {changeRowInPage && rowInPage !== null ? (
            <>
              Liczba wierszy:{' '}
              <CustomSelect
                options={options}
                onChange={onChangeSelect}
                value={value}
                className="selectPagination"
              />
            </>
          ) : (
            ''
          )}
        </div>
        {page ? (
          <div className="paginationBlock">
            <div
              role="button"
              tabIndex={0}
              onClick={pageDown}
              onKeyDown={pageDown}
              className={`pagination ${page <= 1 ? 'first' : ''}`}
            >
              <RenderSvg nameSvg="paginationLeft" />
            </div>
            <AutosizeInput
              className="inputPaginationNumberPage"
              value={numberPage}
              onChange={(e) => {
                if (!Number.isNaN(Number(e.target.value))) {
                  if (e.target.value === '') {
                    setNumberPage('');
                  } else if (
                    Number(e.target.value) <= maxPage &&
                    Number(e.target.value) >= 1
                  ) {
                    setNumberPage(e.target.value);
                  }
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp') {
                  if (numberPage !== '') {
                    if (Number(numberPage) < maxPage) {
                      setNumberPage(`${Number(numberPage) + 1}`);
                    }
                  } else {
                    setNumberPage(`${page}`);
                  }
                }
                if (e.key === 'ArrowDown') {
                  if (numberPage !== '') {
                    if (Number(numberPage) > 1) {
                      setNumberPage(`${Number(numberPage) - 1}`);
                    }
                  } else {
                    setNumberPage(`${page}`);
                  }
                }
                if (e.key === 'Enter') {
                  handleChangePage();
                }
              }}
            />
            / {maxPage}
            <div
              role="button"
              tabIndex={0}
              onClick={pageUp}
              onKeyDown={() => {}}
              className={`pagination ${
                maxPage === page || maxPage < 1 ? 'last' : ''
              }`}
            >
              <RenderSvg nameSvg="paginationRight" />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default TablePagination;
