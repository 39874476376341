/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState } from 'react';
import { SummaryOnly } from 'api/types';

import './questionRadioSummary.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: SummaryOnly;
}

const QuestionRadioSummary = (props: Props): ReactElement => {
  const { data } = props;

  return (
    <>
      <div
        id={`questionRadioSummaryBox-${data.id}`}
        className="questionRadioSummaryBox"
      >
        <div
          id={`questionRadioSummaryNumber-${data.id}`}
          className="questionRadioSummaryNumber"
        >
          {data.questionOrder < 10
            ? `0${data.questionOrder}.`
            : `${data.questionOrder}.`}
        </div>

        <div
          id={`questionRadioSummaryName-${data.id}`}
          className="questionRadioSummaryName"
        >
          {data.name}
        </div>
        <div
          id={`questionRadioSummaryAnswerBox-${data.id}`}
          className="questionRadioSummaryAnswerBox"
        >
          {data?.averageAnswer}
        </div>
      </div>
    </>
  );
};

export default QuestionRadioSummary;
