import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { useForm } from 'react-hook-form';
import { IDPEmployeeAreaForm, IDPEmployeeAreaModel } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  RenderSvg,
  CustomDate,
  CustomSliderBar,
  EditorMceShort,
  AnimatedProgressBar,
} from 'components';
import './IDPEmployeeArea.scss';

type FormValue = IDPEmployeeAreaForm;

interface CompetencesProps {
  onSave?: (dataPost: IDPEmployeeAreaForm) => void;
  onSaveAndExit?: (dataPost: IDPEmployeeAreaForm) => void;
  cancel?: () => void;
  data?: IDPEmployeeAreaModel | null;
  edit?: boolean;
  onlyView?: boolean;
  submitForm?: boolean;
  lastEdit?: boolean;
}

const FormCompetences = (props: CompetencesProps): ReactElement => {
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    submitForm,
    cancel,
    lastEdit,
  } = props;

  const formSchema: yup.SchemaOf<FormValue> = yup.object().shape({
    name: yup.string().required('Pole wymagane'),
    name70: yup.string().required('Pole wymagane'),
    term70: yup.string().required('Pole wymagane'),
    progress70: yup.number(),
    measure70: yup.string().required('Pole wymagane'),
    comment70: yup.string(),
    name20: yup.string().required('Pole wymagane'),
    term20: yup.string().required('Pole wymagane'),
    progress20: yup.number(),
    measure20: yup.string().required('Pole wymagane'),
    comment20: yup.string(),
    name10: yup.string().required('Pole wymagane'),
    term10: yup.string().required('Pole wymagane'),
    progress10: yup.number(),
    measure10: yup.string().required('Pole wymagane'),
    comment10: yup.string(),
  });

  const handleSave = (dataSubmit: FormValue) => {
    setChangeForm(false);
    if (onSave !== undefined) {
      onSave(dataSubmit);
    }
  };

  const handleSaveAndExit = (dataSubmit: FormValue) => {
    setChangeForm(false);
    if (onSaveAndExit !== undefined) {
      onSaveAndExit(dataSubmit);
    }
  };

  const { handleSubmit, register, setValue, errors } = useForm<FormValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              id="formIDPEmployeeArea"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formIDPEmployeeAreaBox"
                    name={
                      edit
                        ? 'EDYCJA OBSZARU ROZWOJU'
                        : 'DODAWANIE OBSZARU ROZWOJU'
                    }
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <div id="formIDPEmployeeAreaFirstRow">
                        <div id="formIDPEmployeeAreaFirstRowIcon">
                          <RenderSvg nameSvg="searchAndGraph60" />
                        </div>
                        <div id="formIDPEmployeeAreaFirstRowData">
                          <CustomInput
                            name="name"
                            label="Nazwa obszaru do rozwoju:"
                            register={register}
                            defaultValue={data?.name}
                            error={errors?.name?.message}
                            required
                            disabled={lastEdit}
                            fullWidth
                            setValueWhenDisable={setValue}
                          />
                        </div>
                      </div>
                      <div id="formIDPEmployeeAreaBlock">
                        <div id="formIDPEmployeeAreaBlockLabel">
                          Działania i czynności rozwojowe:
                        </div>
                        <div
                          id="formIDPEmployeeAreaBlock70"
                          className="formIDPEmployeeAreaBlockContent"
                        >
                          <div
                            id="formIDPEmployeeAreaBlock70Percent"
                            className="formIDPEmployeeAreaBlockPerecent"
                          >
                            <AnimatedProgressBar
                              valueEnd={70}
                              maxValue={70}
                              duration={1000}
                              precent
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock70Name"
                            className="formIDPEmployeeAreaBlockName"
                          >
                            <EditorMceShort
                              id="name70"
                              name="name70"
                              setHeight={120}
                              placeholder="Wpisz, czym się będzie zajmował pracownik przez 70% czasu przeznaczonego na rozwój"
                              register={register}
                              contentGet={data?.activities?.[70].name}
                              error={errors?.name70?.message}
                              onlyView={lastEdit}
                              required
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock70Additional"
                            className="formIDPEmployeeAreaBlockAdditional"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock70AdditionalDateAndMeasure"
                              className="formIDPEmployeeAreaBlockAdditionalDateAndMeasure"
                            >
                              <div id="formIDPEmployeeAreaBlock70AdditionalDate">
                                <CustomDate
                                  name="term70"
                                  label="Termin:"
                                  fullWidth
                                  required
                                  setValue={setValue}
                                  defaultValue={data?.activities?.[70].term}
                                  error={errors?.term70?.message}
                                  disabled={lastEdit}
                                />
                              </div>
                              <div id="formIDPEmployeeAreaBlock70AdditionalMeasure">
                                <CustomInput
                                  name="measure70"
                                  label="Miernik:"
                                  register={register}
                                  defaultValue={data?.activities?.[70].measure}
                                  error={errors?.measure70?.message}
                                  required
                                  disabled={lastEdit}
                                  fullWidth
                                  placeholder="Określ poziom rozwoju"
                                  setValueWhenDisable={setValue}
                                />
                              </div>
                            </div>
                            <div
                              id="formIDPEmployeeAreaBlock70AdditionalProgress"
                              className="formIDPEmployeeAreaBlockAdditionalProgress"
                            >
                              <CustomSliderBar
                                name="progress70"
                                label="Określ procent realizacji:"
                                setValue={setValue}
                                defaultValue={data?.activities?.[70].progress}
                              />
                            </div>
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock70Comment"
                            className="formIDPEmployeeAreaBlockComment"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock70CommentLabel"
                              className="formIDPEmployeeAreaBlockCommentLabel"
                            >
                              Komentarz:
                            </div>
                            <EditorMceShort
                              id="comment70"
                              name="comment70"
                              setHeight={100}
                              register={register}
                              contentGet={data?.activities?.[70].comment}
                            />
                          </div>
                        </div>
                        <div
                          id="formIDPEmployeeAreaBlock20"
                          className="formIDPEmployeeAreaBlockContent"
                        >
                          <div
                            id="formIDPEmployeeAreaBlock20Percent"
                            className="formIDPEmployeeAreaBlockPerecent"
                          >
                            <AnimatedProgressBar
                              valueEnd={20}
                              maxValue={20}
                              duration={1000}
                              precent
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock20Name"
                            className="formIDPEmployeeAreaBlockName"
                          >
                            <EditorMceShort
                              id="name20"
                              name="name20"
                              setHeight={120}
                              placeholder="Wpisz, czym się będzie zajmował pracownik przez 70% czasu przeznaczonego na rozwój"
                              register={register}
                              contentGet={data?.activities?.[20].name}
                              error={errors?.name20?.message}
                              onlyView={lastEdit}
                              required
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock20Additional"
                            className="formIDPEmployeeAreaBlockAdditional"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock20AdditionalDateAndMeasure"
                              className="formIDPEmployeeAreaBlockAdditionalDateAndMeasure"
                            >
                              <div id="formIDPEmployeeAreaBlock20AdditionalDate">
                                <CustomDate
                                  name="term20"
                                  label="Termin:"
                                  fullWidth
                                  required
                                  setValue={setValue}
                                  defaultValue={data?.activities?.[20].term}
                                  error={errors?.term20?.message}
                                  disabled={lastEdit}
                                />
                              </div>
                              <div id="formIDPEmployeeAreaBlock20AdditionalMeasure">
                                <CustomInput
                                  name="measure20"
                                  label="Miernik:"
                                  register={register}
                                  defaultValue={data?.activities?.[20].measure}
                                  error={errors?.measure20?.message}
                                  required
                                  disabled={lastEdit}
                                  fullWidth
                                  placeholder="Określ poziom rozwoju"
                                  setValueWhenDisable={setValue}
                                />
                              </div>
                            </div>
                            <div
                              id="formIDPEmployeeAreaBlock20AdditionalProgress"
                              className="formIDPEmployeeAreaBlockAdditionalProgress"
                            >
                              <CustomSliderBar
                                name="progress20"
                                label="Określ procent realizacji:"
                                setValue={setValue}
                                defaultValue={data?.activities?.[20].progress}
                              />
                            </div>
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock20Comment"
                            className="formIDPEmployeeAreaBlockComment"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock20CommentLabel"
                              className="formIDPEmployeeAreaBlockCommentLabel"
                            >
                              Komentarz:
                            </div>
                            <EditorMceShort
                              id="comment20"
                              name="comment20"
                              setHeight={100}
                              register={register}
                              contentGet={data?.activities?.[20].comment}
                            />
                          </div>
                        </div>
                        <div
                          id="formIDPEmployeeAreaBlock10"
                          className="formIDPEmployeeAreaBlockContent noMarginBottom"
                        >
                          <div
                            id="formIDPEmployeeAreaBlock10Percent"
                            className="formIDPEmployeeAreaBlockPerecent"
                          >
                            <AnimatedProgressBar
                              valueEnd={10}
                              maxValue={10}
                              duration={1000}
                              precent
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock10Name"
                            className="formIDPEmployeeAreaBlockName"
                          >
                            <EditorMceShort
                              id="name10"
                              name="name10"
                              setHeight={120}
                              placeholder="Wpisz, czym się będzie zajmował pracownik przez 10% czasu przeznaczonego na rozwój"
                              register={register}
                              contentGet={data?.activities?.[10].name}
                              error={errors?.name10?.message}
                              onlyView={lastEdit}
                              required
                            />
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock10Additional"
                            className="formIDPEmployeeAreaBlockAdditional"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock10AdditionalDateAndMeasure"
                              className="formIDPEmployeeAreaBlockAdditionalDateAndMeasure"
                            >
                              <div id="formIDPEmployeeAreaBlock10AdditionalDate">
                                <CustomDate
                                  name="term10"
                                  label="Termin:"
                                  fullWidth
                                  required
                                  setValue={setValue}
                                  defaultValue={data?.activities?.[10].term}
                                  error={errors?.term10?.message}
                                  disabled={lastEdit}
                                />
                              </div>
                              <div id="formIDPEmployeeAreaBlock10AdditionalMeasure">
                                <CustomInput
                                  name="measure10"
                                  label="Miernik:"
                                  register={register}
                                  defaultValue={data?.activities?.[10].measure}
                                  error={errors?.measure10?.message}
                                  required
                                  disabled={lastEdit}
                                  fullWidth
                                  placeholder="Określ poziom rozwoju"
                                  setValueWhenDisable={setValue}
                                />
                              </div>
                            </div>
                            <div
                              id="formIDPEmployeeAreaBlock10AdditionalProgress"
                              className="formIDPEmployeeAreaBlockAdditionalProgress"
                            >
                              <CustomSliderBar
                                name="progress10"
                                label="Określ procent realizacji:"
                                setValue={setValue}
                                defaultValue={data?.activities?.[10].progress}
                              />
                            </div>
                          </div>
                          <div
                            id="formIDPEmployeeAreaBlock10Comment"
                            className="formIDPEmployeeAreaBlockComment"
                          >
                            <div
                              id="formIDPEmployeeAreaBlock10CommentLabel"
                              className="formIDPEmployeeAreaBlockCommentLabel"
                            >
                              Komentarz:
                            </div>
                            <EditorMceShort
                              id="comment10"
                              name="comment10"
                              setHeight={100}
                              register={register}
                              contentGet={data?.activities?.[10].comment}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  save={handleSubmit(handleSave)}
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormCompetences;
