import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  EmployeeAppraisalFeedback180Form,
  EmployeeAppraisalFeedback180Model,
  EmployeeAppraisalGoal,
} from 'api/types';
import Feedback180 from '../../Form/Feedback180';

interface Props {
  idEvaluation: number;
  idEmployee: number;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { idEvaluation, idEmployee } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    Feedback,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalFeedbackAdmin({
    idEmployee,
    idEvaluation,
  });

  const handleEditTrainingsProvider = EmployeeAppraisalRepository.usePutEmployeeAppraisalFeedbackAdmin(
    idEmployee,
    idEvaluation,
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: EmployeeAppraisalFeedback180Form) => {
    if (!submitForm) {
      const dataToPost: EmployeeAppraisalFeedback180Model = {
        feedbackValues: {
          CORRECT: { description: data.CORRECT },
          CONTINUE: { description: data.CONTINUE },
          STOP: { description: data.STOP },
          INTRODUCE: { description: data.INTRODUCE },
        },
      };
      setSubmitForm(true);

      handleEditTrainingsProvider(dataToPost);
    }
  };

  return (
    <Feedback180
      id={idEvaluation}
      onTrainingsProvider={handleSubmit}
      submitForm={submitForm}
      data={Feedback}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
