import {
  TrainingsProviderContactRequest,
  Error,
  TrainingsProviderContact,
  TrainingsProviderContactAddPut,
  PagedData,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  id: number;
  onCompleted?: (
    data: TrainingsProviderContactAddPut | TrainingsProviderContact | number
  ) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  idRecord: number;
}

const useGetAllTrainingsProviderContact = (
  props: Props
): [
  (params: TrainingsProviderContactRequest) => void,
  PagedData<TrainingsProviderContact> | null
] => {
  const axios = useAxios();
  const { id, onError } = props;
  const [
    trainingsProviderContact,
    setTrainingsProviderContact,
  ] = useState<PagedData<TrainingsProviderContact> | null>(null);

  const doGet = useCallback(
    async (params: TrainingsProviderContactRequest) => {
      try {
        const rData = await axios.get<PagedData<TrainingsProviderContact>>(
          `${apiEndpoints.trainingsProvider}/providers/${id}/contacts`,
          {
            params,
          }
        );
        setTrainingsProviderContact(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onError]
  );

  return [doGet, trainingsProviderContact];
};

const useGetTrainingsProviderContact = (
  props: PropsRecord
): [
  TrainingsProviderContact | null | undefined,
  (trainingsProviderContact: TrainingsProviderContact) => void,
  (idRecord: number) => void
] => {
  const { id, onError, idRecord } = props;
  const axios = useAxios();
  const [trainingsProviderContact, setTrainingsProviderContact] = useState<
    TrainingsProviderContact | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsProviderContact>(
        `${apiEndpoints.trainingsProvider}/providers/${id}/contacts/${idRecord}`
      );
      setTrainingsProviderContact(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setTrainingsProviderContact(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsProviderContact) => {
    setTrainingsProviderContact(data);
  }, []);

  return [trainingsProviderContact, updateData, fetchData];
};

const useAddTrainingsProviderContact = (
  onCompletedPost?: (data: TrainingsProviderContact) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsProviderContactAddPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postTrainingsProviderContact = useCallback(
    async (
      trainingsProviderContact: Omit<TrainingsProviderContactAddPut, 'id'>
    ) => {
      try {
        const rData = await axios.post<TrainingsProviderContact>(
          `${apiEndpoints.trainingsProvider}/providers/${trainingsProviderContact.providerId}/contacts`,
          trainingsProviderContact
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postTrainingsProviderContact;
};

const usePutTrainingsProviderContact = (
  onCompletedPut?: (data: TrainingsProviderContactAddPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsProviderContactAddPut) => Promise<void>) => {
  const axios = useAxios();

  const putTrainingsProviderContact = useCallback(
    async (trainingsProviderContact: TrainingsProviderContactAddPut) => {
      try {
        const rData = await axios.put<TrainingsProviderContactAddPut>(
          `${apiEndpoints.trainingsProvider}/providers/${trainingsProviderContact.providerId}/contacts/${trainingsProviderContact.id}`,
          trainingsProviderContact
        );
        AutoAlertStatus({ status: rData.status });

        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putTrainingsProviderContact;
};

const useDeleteTrainingsProviderContact = (
  id: number,
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteTrainingsProviderContact = useCallback(
    async (idDelete: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.trainingsProvider}/providers/${id}/contacts/${idDelete}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(idDelete);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteTrainingsProviderContact;
};

export default {
  useGetAllTrainingsProviderContact,
  useGetTrainingsProviderContact,
  useAddTrainingsProviderContact,
  usePutTrainingsProviderContact,
  useDeleteTrainingsProviderContact,
};
