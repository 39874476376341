/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  number?: string;
}

const TableCellNumberPrecent = (props: Props): ReactElement => {
  const { number } = props;
  return <>{number ? `${number} %` : ''}</>;
};

export default TableCellNumberPrecent;
