import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  EmployeeAppraisalFeedback180Form,
  EmployeeAppraisalFeedback180Model,
  EmployeeAppraisalGoal,
  IDPEmployeeModel,
  IDPLeaderProfileForm,
  IDPLeaderProfilePostAndPut,
} from 'api/types';
import IDPEmployeeLeaderProfile from '../../Form/IDPEmployeeLeaderProfile';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { idEmployee } = useParams<{ idEmployee: string }>();

  const { IDPEmployee, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    LeaderProfile,
    LeaderProfileUpdate,
  ] = IDPRepository.useGetIDPLeaderProfile({ id: Number(idEmployee) });

  const handleEdit = IDPRepository.usePutIDPLeaderProfile(
    Number(idEmployee),
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPLeaderProfileForm) => {
    if (!submitForm) {
      setSubmitForm(true);
      const dataPost: IDPLeaderProfilePostAndPut = {
        leaderProfileValues: {
          EFFECTIVE_LEADERSHIP: { value: data.effectiveLeadership },
          FLEXIBILITY_PERSEVERANCE_COMPOSURE: {
            value: data.flexibilityPerseveranceComposure,
          },
          INNOVATION_AND_CREATIVITY: { value: data.innovationAndCreativity },
          RESULT_ORIENTATION: { value: data.resultOrientation },
          STRATEGIC_THINKING: { value: data.strategicThinking },
        },
      };
      handleEdit(dataPost);
    }
  };

  const handleCancel = () => {
    history.push(`/panel/tablica`);
  };

  return (
    <>
      <IDPEmployeeLeaderProfile
        id={Number(idEmployee)}
        onSave={handleSubmit}
        submitForm={submitForm}
        data={LeaderProfile}
        cancel={handleCancel}
        edit
      />
    </>
  );
};

export default TrainingsProviderAddPage;
