import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  IDPEmployeeModel,
  IDPLeaderProfileForm,
  IDPLeaderProfilePostAndPut,
} from 'api/types';
import IDPEmployeeLeaderProfile from '../../Form/IDPEmployeeLeaderProfile';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string; idEmployee: string }>();

  const { IDPEmployee, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    LeaderProfile,
    LeaderProfileUpdate,
  ] = IDPRepository.useGetIDPLeaderProfileUser({});

  const handleEdit = IDPRepository.usePutIDPLeaderProfileUser(
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPLeaderProfileForm) => {
    if (!submitForm) {
      setSubmitForm(true);

      const dataPost: IDPLeaderProfilePostAndPut = {
        leaderProfileValues: {
          EFFECTIVE_LEADERSHIP: { value: data.effectiveLeadership },
          FLEXIBILITY_PERSEVERANCE_COMPOSURE: {
            value: data.flexibilityPerseveranceComposure,
          },
          INNOVATION_AND_CREATIVITY: { value: data.innovationAndCreativity },
          RESULT_ORIENTATION: { value: data.resultOrientation },
          STRATEGIC_THINKING: { value: data.strategicThinking },
        },
      };

      handleEdit(dataPost);
    }
  };

  const handleCancel = () => {
    history.push(`/panel/tablica`);
  };

  return (
    <IDPEmployeeLeaderProfile
      id={Number(id)}
      onSave={handleSubmit}
      submitForm={submitForm}
      data={LeaderProfile}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
