/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import CustomHelp from 'components/CustomHelp';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './TableCellYesNo.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: boolean;
  justify?: 'toLeft' | 'center' | 'toRight';
  additionalInfo?: string;
  additionalInfoSvg?: string;
  additionalInfoTitle?: string;
  widthBox?: 'low400' | 'low' | 'medium' | 'high';
}

const TableCellAddress = (props: Props): ReactElement => {
  const {
    data,
    justify,
    additionalInfo,
    additionalInfoSvg,
    additionalInfoTitle,
    widthBox,
  } = props;
  const { t } = useTranslation('global');

  return (
    <div className={`tableCellYesNoContent ${justify || ''}`}>
      {data !== undefined ? (
        <>
          <div
            className={`tableCellYesNo ${
              data === true ? 'yesValue' : 'noValue'
            }`}
          >
            {data === true ? t('yesNo.yes') : t('yesNo.no')}
          </div>
          {additionalInfo ? (
            <CustomHelp
              customTextBlock={additionalInfo}
              customSvgBlock={additionalInfoSvg}
              customTitleBlock={additionalInfoTitle}
              widthBox={widthBox}
            />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default TableCellAddress;
