import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorLoadData } from 'components/CustomForm';
import { useForm } from 'react-hook-form';
import {
  EmployeeAppraisalGoalModel,
  EmployeeAppraisalGoalRatingPut,
  EmployeeModel,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  RenderSvg,
  CustomSliderBar,
  CustomSelect,
  ShowTextarea,
  EditorMceShort,
} from 'components';
import './employeeAppraisalGoalRatingForm.scss';
import { triggerValidRequired } from 'utils';
import { useTranslation } from 'react-i18next';

type FormModel = EmployeeAppraisalGoalRatingPut;

interface Props {
  onSaveAndExit?: (dataPost: EmployeeAppraisalGoalRatingPut) => void;
  cancel?: () => void;
  data?: EmployeeAppraisalGoalModel | null;
  onlyView?: boolean;
  submitForm?: boolean;
  lastEdit?: boolean;
  employee?: null | EmployeeModel;
}

const EmployeeAppraisalGoalRatingForm = (props: Props): ReactElement => {
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    data,
    onlyView,
    onSaveAndExit,
    submitForm,
    cancel,
    lastEdit,
    employee,
  } = props;

  const { t } = useTranslation(['enum']);

  const formSchema: yup.SchemaOf<FormModel> = yup.object().shape({
    status: yup.string().required('Pole wymagane'),
    progress: yup.number().required('Pole wymagane'),
    justification: yup.string().required('Pole wymagane'),
    rating: yup.string().required('Pole wymagane'),
  });

  const handleSaveAndExit = (dataSubmit: FormModel) => {
    setChangeForm(false);
    if (onSaveAndExit !== undefined) {
      onSaveAndExit(dataSubmit);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    trigger,
  } = useForm<FormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              id="formEmployeeAppraisalRatingGoal"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formEmployeeAppraisalRatingGoalBox"
                    name="EDYCJA OCENY CELU ROCZNEGO PRACOWNIKA"
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <div id="formEmployeeAppraisalRatingGoalFirstRow">
                        <div id="formEmployeeAppraisalRatingGoalFirstRowIcon">
                          <RenderSvg nameSvg="ratingGoal60" />
                        </div>
                        <div id="formEmployeeAppraisalRatingGoalFirstRowData">
                          <div id="formEmployeeAppraisalRatingGoalFirstRowDataLabel">
                            Ocena celu rocznego pracownika:
                          </div>
                          <div id="formEmployeeAppraisalRatingGoalFirstRowDataEmployee">
                            {employee?.lastName}, {employee?.firstName}
                          </div>
                        </div>
                        <div id="formEmployeeAppraisalRatingGoalFirstRowDate">
                          <div id="formEmployeeAppraisalRatingGoalFirstRowDateIcon">
                            <RenderSvg nameSvg="dateCalendar60" />
                          </div>
                          <div id="formEmployeeAppraisalRatingGoalFirstRowDateData">
                            <div id="formEmployeeAppraisalRatingGoalFirstRowDateDataLabel">
                              Data realizacji celi:
                            </div>
                            <div id="formEmployeeAppraisalRatingGoalFirstRowDateDataValue">
                              {data?.realizationDate}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="formEmployeeAppraisalRatingGoalSecondRow">
                        <div id="formEmployeeAppraisalRatingGoalSecondRowLabel">
                          Cel do osiągnięcia:
                        </div>
                        <div id="formEmployeeAppraisalRatingGoalSecondRowName">
                          {data?.name}
                        </div>
                        <div id="formEmployeeAppraisalRatingGoalSecondRowWeight">
                          <span id="formEmployeeAppraisalRatingGoalSecondRowWeightLabel">
                            Waga:
                          </span>
                          {data?.weight}
                        </div>
                      </div>
                      <div id="formEmployeeAppraisalRatingGoalThirtRow">
                        <div id="measureLabel">KPI/Mierniki:</div>
                        <div id="measureData">{data?.measure}</div>
                      </div>
                      <div id="formEmployeeAppraisalRatingGoalFourthRow">
                        <div id="commentLabel">Komentarz:</div>
                        <ShowTextarea id="comment" data={data?.comment} />
                      </div>
                      <div id="formEmployeeAppraisalRatingGoalFifthRow">
                        <CustomSelect
                          label="Status realizacji"
                          options={[
                            { value: 'NOT_STARTED', label: 'Niezaczęte' },
                            { value: 'IN_PROGRESS', label: 'W toku' },
                            { value: 'COMPLETED', label: 'Ukończone' },
                            { value: 'OUT_OF_DATE', label: 'Nieaktualne' },
                          ]}
                          name="status"
                          required
                          placeholder="Wybierz status"
                          setValue={setValue}
                          error={errors?.status?.message}
                          validRequiredSelect={() => {
                            triggerValidRequired('status', trigger);
                          }}
                          valueByKey={data?.status}
                        />
                        <CustomSliderBar
                          name="progress"
                          label="Określ procent realizacji:"
                          setValue={setValue}
                          defaultValue={data?.progress}
                        />
                      </div>
                      <div id="formEmployeeAppraisalRatingGoalSixthRow">
                        <CustomSelect
                          label="Samoocena:"
                          options={[
                            {
                              value: 'EXCEEDS_EXPECTATIONS',
                              label: t('appraisalGoal.EXCEEDS_EXPECTATIONS'),
                            },
                            {
                              value: 'PARTIALLY_EXCEEDS_EXPECTATIONS',
                              label: t(
                                'appraisalGoal.PARTIALLY_EXCEEDS_EXPECTATIONS'
                              ),
                            },
                            {
                              value: 'FULLY_MEETS_EXPECTATIONS',
                              label: t(
                                'appraisalGoal.FULLY_MEETS_EXPECTATIONS'
                              ),
                            },
                            {
                              value: 'PARTIALLY_MEETS_EXPECTATIONS',
                              label: t(
                                'appraisalGoal.PARTIALLY_MEETS_EXPECTATIONS'
                              ),
                            },

                            {
                              value: 'DOES_NOT_MEET_EXPECTATIONS',
                              label: t(
                                'appraisalGoal.DOES_NOT_MEET_EXPECTATIONS'
                              ),
                            },
                          ]}
                          name="rating"
                          required
                          placeholder="Wybierz adekwatną ocenę"
                          setValue={setValue}
                          error={errors?.rating?.message}
                          validRequiredSelect={() => {
                            triggerValidRequired('rating', trigger);
                          }}
                          valueByKey={data?.appraisal?.rating}
                        />
                        <EditorMceShort
                          id="justification"
                          name="justification"
                          setHeight={120}
                          placeholder="Wpisz uzsadnienie dla swojej decyzji"
                          register={register}
                          label="Uzasadnienie:"
                          contentGet={data?.appraisal?.justification}
                          error={errors?.justification?.message}
                          required
                        />
                      </div>
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default EmployeeAppraisalGoalRatingForm;
