/*
export default {
  profile: '',
  trainingsProvider: '',
  competences: '',
  trainings: '',
  catalogsTrainings: '',
  plansTrainings: '',
  userPlansTrainings: '',
  employee: '',
  currentPlan: '',
  trainingsCompany: '',
  trainingsGroup: '',
  questionnaire: '',
};
*/

export default {
  profile: '/pr',
  trainingsProvider: '/tr',
  competences: '/tr',
  trainings: '/tr',
  catalogsTrainings: '/tr',
  plansTrainings: '/tr',
  userPlansTrainings: '/tr',
  employee: '/em',
  currentPlan: '/tr',
  trainingsCompany: '/tr',
  trainingsGroup: '/tr',
  questionnaire: '/qu',
  loyalty: '/tr',
  idp: '/idp',
  employeeAppraisal: '/ap',
};
