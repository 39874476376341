/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  type?: string;
}

const TableCellTypeGoal = (props: Props): ReactElement => {
  const { id, type } = props;
  const { t } = useTranslation('enum');

  return (
    <>
      {id ? (
        <div
          id={`tableCellBlockTypeGoal-${id}`}
          className="tableCellBlockTypeGoal"
        >
          {t(`appraisalGoalType.${type}`) || ''}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellTypeGoal;
