import React, { ReactElement } from 'react';
import Footer from '../Footer';

interface Props {
  children?: ReactElement;
}

export default (props: Props): ReactElement => {
  const { children } = props;

  return (
    <>
      <div id="app_content" className="contentFull">
        <div className="contentBlock">{children}</div>
        <Footer />
      </div>
    </>
  );
};
