import React, { ReactElement, useState } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import { EmployeeAppraisalAddAndPut } from 'api/types';
import FormPlansTrainings from '../../Form';

const EmployeeAppraisalEdit = (): ReactElement => {
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [getData] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings(
    {}
  );

  const postDataSave = EmployeeAppraisalRepository.usePutEmployeeAppraisalSettings(
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: EmployeeAppraisalAddAndPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    postDataSave(data);
  };

  return (
    <>
      <FormPlansTrainings
        onSave={handleAddTrainingsProviderContact}
        submitForm={submitForm}
        data={getData}
        edit
      />
    </>
  );
};

export default EmployeeAppraisalEdit;
