/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './Switch.scss';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  id: string;
  checked?: boolean | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  small?: boolean;
  disabled?: boolean;
  register?: () => void;
}

const Switch = (props: SwitchProps): ReactElement => {
  const { checked, id, onChange, name, small, disabled, register } = props;
  const { t } = useTranslation('componentSwitch');

  let evt: KeyboardEvent;
  /* function handleKeyPress(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.keyCode !== 32) return;

    e.preventDefault();
    onChange(!checked);
  } */

  const smallData = () => {
    let v = 'toggle-switch';
    if (small) {
      v += ' small-switch';
    }
    return v;
  };

  return (
    <div className={smallData()}>
      <input
        type="checkbox"
        name={name}
        className="toggle-switch-checkbox"
        id={id}
        checked={checked || false}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}
        disabled={disabled}
        ref={register}
      />
      {id ? (
        <label
          className={
            disabled
              ? 'toggle-switch-label toggle-switch-disabled'
              : 'toggle-switch-label'
          }
          htmlFor={id}
          tabIndex={disabled ? -1 : 1}
        >
          <span
            className={
              disabled
                ? 'toggle-switch-inner toggle-switch-disabled'
                : 'toggle-switch-inner'
            }
            data-yes={t('switch.yes')}
            data-no={t('switch.no')}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? 'toggle-switch-switch toggle-switch-disabled'
                : 'toggle-switch-switch'
            }
            tabIndex={-1}
          >
            {' '}
            <svg
              width="24"
              height="16"
              viewBox="0 0 24 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="16" rx="8" fill="#99D6BF" />
            </svg>
          </span>
        </label>
      ) : null}
    </div>
  );
};

export default Switch;
