import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { ErrorLoadData } from 'components/CustomForm';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDPModelAddAndPut, IDPModel } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  ContentBox,
  CustomDate,
} from 'components';

type IDPValue = IDPModelAddAndPut;

interface FormIDPProps {
  onSave?: (dataPost: IDPValue) => void;
  cancel?: () => void;
  data?: IDPModel | null;
  edit?: boolean;
  onlyView?: boolean;
  submitForm?: boolean;
}

const FormPlansTrainings = (props: FormIDPProps): ReactElement => {
  const { t } = useTranslation(['IDP']);

  const [changeForm, setChangeForm] = useState<boolean>(false);
  const { onSave, edit, data, onlyView, submitForm, cancel } = props;

  const formSchema: yup.SchemaOf<IDPValue> = yup.object().shape(
    {
      annualInterviewStart: yup.string().required('Data wymagana'),
      annualInterviewEnd: yup
        .string()
        .required('Data wymagana')
        .test(
          'annualInterviewEndVSannualInterviewStart',
          'Data zakończenia wprowadzania danych nie może być wcześniejsza niż data rozpoczęcia',
          function annualInterviewEndVSannualInterviewStart() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.annualInterviewEnd) <=
              // eslint-disable-next-line
              new Date(this.parent.annualInterviewStart)
            ) {
              return false;
            }
            return true;
          }
        ),

      sixMonthInterviewStart: yup
        .string()
        .required('Data wymagana')
        .test(
          'sixMonthInterviewStartVSannualInterviewEnd',
          'Data rozpoczęcia weryfikacji półrocznej nie może być wcześniejsza niż data zakończenia wprowadzania danych ',
          function sixMonthInterviewStartVSannualInterviewEnd() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.sixMonthInterviewStart) <=
              // eslint-disable-next-line
              new Date(this.parent.annualInterviewEnd)
            ) {
              return false;
            }
            return true;
          }
        ),
      sixMonthInterviewEnd: yup
        .string()
        .required('Data wymagana')
        .test(
          'sixMonthInterviewEndVSsixMonthInterviewStart',
          'Data zakończenia weryfikacji półrocznej nie może być wcześniejsza niż jej data rozpoczęcia',
          function sixMonthInterviewEndVSsixMonthInterviewStart() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.sixMonthInterviewStart) >=
              // eslint-disable-next-line
              new Date(this.parent.sixMonthInterviewEnd)
            ) {
              return false;
            }
            return true;
          }
        ),
      nextAnnualInterviewStart: yup
        .string()
        .required('Data wymagana')
        .test(
          'nextAnnualInterviewStartVSsixMonthInterviewEnd',
          'Data rozpoczęcia weryfikacji rocznej nie może być wcześniejsza niż data zakończenia weryfikacji półrocznej ',
          function nextAnnualInterviewStartVSsixMonthInterviewEnd() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.nextAnnualInterviewStart) <=
              // eslint-disable-next-line
              new Date(this.parent.sixMonthInterviewEnd)
            ) {
              return false;
            }
            return true;
          }
        ),
      nextAnnualInterviewEnd: yup
        .string()
        .required('Data wymagana')
        .test(
          'nextAnnualInterviewEndVSnextAnnualInterviewStart',
          'Data zakończenia weryfikacji rocznej nie może być wcześniejsza niż jej data rozpoczęcia',
          function nextAnnualInterviewEndVSnextAnnualInterviewStart() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.nextAnnualInterviewStart) >=
              // eslint-disable-next-line
              new Date(this.parent.nextAnnualInterviewEnd)
            ) {
              return false;
            }
            return true;
          }
        ),
    },
    [['startDate', 'endDate']]
  );

  const { handleSubmit, setValue, errors } = useForm<IDPValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: IDPValue) => {
    setChangeForm(false);
    if (onSave !== undefined) {
      onSave(dataSubmit);
    }
  };

  return (
    <>
      {data === undefined ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <Box
              id="formIDPSettingsBox"
              name="EDYCJA KONFIGURACJI IDP"
              svgIcon="addEdit"
              noMarginBottom
              noShadow
            >
              <>
                <ExitFromForm changeForm={changeForm} />
                <Form
                  className="formIDPSettings"
                  onChange={() => {
                    setChangeForm(true);
                  }}
                >
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <Row className="justify-content-md-center">
                        <ContentBox
                          id="formIDPSettingsBasicInformation"
                          bottomBorder
                        >
                          <>
                            <Row>
                              <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                <Row>
                                  <Col style={{ fontWeight: 600 }}>
                                    Daty wprowadzania danych:
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ paddingRight: '0' }}>
                                    <CustomDate
                                      id="annualInterviewStart"
                                      name="annualInterviewStart"
                                      label="Od:"
                                      setValue={setValue}
                                      error={
                                        errors?.annualInterviewStart?.message
                                      }
                                      defaultValue={data?.annualInterviewStart}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                  <Col style={{ paddingLeft: '0' }}>
                                    <CustomDate
                                      id="annualInterviewEnd"
                                      name="annualInterviewEnd"
                                      label="Do:"
                                      setValue={setValue}
                                      error={
                                        errors?.annualInterviewEnd?.message
                                      }
                                      defaultValue={data?.annualInterviewEnd}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                <Row>
                                  <Col>Daty weryfikacji półrocznej:</Col>
                                </Row>
                                <Row>
                                  <Col style={{ paddingRight: '0' }}>
                                    <CustomDate
                                      id="sixMonthInterviewStart"
                                      name="sixMonthInterviewStart"
                                      label="Od:"
                                      setValue={setValue}
                                      error={
                                        errors?.sixMonthInterviewStart?.message
                                      }
                                      defaultValue={
                                        data?.sixMonthInterviewStart
                                      }
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                  <Col style={{ paddingLeft: '0' }}>
                                    <CustomDate
                                      id="sixMonthInterviewEnd"
                                      name="sixMonthInterviewEnd"
                                      label="Do:"
                                      setValue={setValue}
                                      error={
                                        errors?.sixMonthInterviewEnd?.message
                                      }
                                      defaultValue={data?.sixMonthInterviewEnd}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" sm="12" md="6" lg="4" xl="4">
                                <Row>
                                  <Col>Daty weryfikacji rocznej:</Col>
                                </Row>
                                <Row>
                                  <Col style={{ paddingRight: '0' }}>
                                    <CustomDate
                                      id="nextAnnualInterviewStart"
                                      name="nextAnnualInterviewStart"
                                      label="Od:"
                                      setValue={setValue}
                                      error={
                                        errors?.nextAnnualInterviewStart
                                          ?.message
                                      }
                                      defaultValue={
                                        data?.nextAnnualInterviewStart
                                      }
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                  <Col style={{ paddingLeft: '0' }}>
                                    <CustomDate
                                      id="nextAnnualInterviewEnd"
                                      name="nextAnnualInterviewEnd"
                                      label="Do:"
                                      setValue={setValue}
                                      error={
                                        errors?.nextAnnualInterviewEnd?.message
                                      }
                                      defaultValue={
                                        data?.nextAnnualInterviewEnd
                                      }
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        </ContentBox>
                      </Row>
                    </>
                  </LoaderForm>
                </Form>
              </>
            </Box>
            {!onlyView ? (
              <CustomSubmit
                save={handleSubmit(handleSave)}
                cancel={cancel}
                submitForm={submitForm}
              />
            ) : (
              ''
            )}
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormPlansTrainings;
