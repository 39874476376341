import React, { ReactElement, useEffect, useState } from 'react';
import './TableRecordAction.scss';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ShowAction,
  EditAction,
  ArchiveAction,
  ImportAction,
  DeleteAction,
} from './Action';

/*
TODO
Toggle TableRecordSwitch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  dataId: number;
  showClick?: (id: number) => void;
  editClick?: (id: number) => void;
  archiveClick?: (id: number) => void;
  deleteClick?: () => void;
  importClick?: () => void;
  page?: number;
  rowsPerPage?: number;
  rowNumber?: number;
}

const TableRecordAction = (props: Props): ReactElement => {
  const {
    dataId,
    showClick,
    editClick,
    archiveClick,
    importClick,
    deleteClick,
    page,
    rowsPerPage,
    rowNumber,
  } = props;
  const { t } = useTranslation('componentTableRecordAction');

  const actionShowClick = (id: number) => {
    if (showClick) {
      return <ShowAction showClick={() => showClick(id)} />;
    }
    return null;
  };

  const actionEditClick = (id: number) => {
    if (editClick) {
      return <EditAction editClick={() => editClick(id)} />;
    }
    return null;
  };

  const actionArchiveClick = (id: number) => {
    if (archiveClick) {
      return <ArchiveAction archiveClick={() => archiveClick(id)} />;
    }
    return null;
  };

  const actionDeleteClick = () => {
    if (deleteClick) {
      return <DeleteAction deleteClick={() => deleteClick()} />;
    }
    return null;
  };

  const actionImportClick = (id: number) => {
    if (importClick) {
      return <ImportAction importClick={() => importClick()} />;
    }
    return null;
  };

  const activeMenu = (id: number) => {
    return (
      <div className="activeMenu">
        {actionShowClick(id)}
        {actionEditClick(id)}
        {actionArchiveClick(id)}
        {actionImportClick(id)}
        {actionDeleteClick()}
      </div>
    );
  };

  const ordinalNumber = () => {
    if (
      rowNumber !== undefined &&
      page !== undefined &&
      rowsPerPage !== undefined
    ) {
      if (rowNumber) {
        if (rowNumber + 1 + (page - 1) * rowsPerPage < 10) {
          return `0${rowNumber + 1 + (page - 1) * rowsPerPage}.`;
        }
        return `${rowNumber + 1 + (page - 1) * rowsPerPage}.`;
      }
      if (1 + (page - 1) * rowsPerPage < 10) {
        return `0${1 + (page - 1) * rowsPerPage}.`;
      }
      return `${1 + (page - 1) * rowsPerPage}.`;
    }
    return '';
  };

  return (
    <>
      <div className="firstCell">
        <div className="firstCellAccent" />
        <div className="sequence">{ordinalNumber()}</div>
        <div className="boxAction">
          <div id={`${dataId}`} className="boxTableRowAction">
            <RenderSvg nameSvg="wrench" />
          </div>
          {activeMenu(dataId)}
        </div>
      </div>
    </>
  );
};

export default TableRecordAction;
