import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBeforeCatalog, ContentBox, LoaderTable } from 'components';
import { Button } from 'react-bootstrap';
import { TrainingsGroupRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupUserTraining } from 'api/types';
import GroupItem from './GroupItem';
import TrainingGroupShow from '../../../Administration/TrainingsGroup/TrainingsGroupShow';

const TableTrainingsBlock = (): ReactElement => {
  const { t } = useTranslation('trainingsGroup');
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetAllUserTrainingsGroup();

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <LoaderTable active={loaderTable} radiusTop allowGrow>
        <ContentBox
          id="trainingGroupsUserListTrainingContent"
          noPadding
          allowGrow
        >
          <>
            {data?.content && data?.content.length > 0 ? (
              <div className="trainingGroupsUserSelectTrainingItemsBox" id={id}>
                <div className="trainingGroupsUserSelectTrainingItems">
                  {data.content.map((item: TrainingsGroupUserTraining) => {
                    return (
                      <GroupItem
                        id={id}
                        data={item}
                        showClick={() => {
                          if (item.trainingGroup.id !== undefined) {
                            setModalId(Number(item.trainingGroup.id));
                            setModalShow(true);
                          }
                        }}
                      />
                    );
                  })}
                  {modalShow ? (
                    <TrainingGroupShow
                      id={modalId}
                      changeModalShow={setModalShow}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <div id="trainingGroupsUserSelectTrainingItemsNoRecord">
                <div className="noRecord">
                  <div>Brak szkoleń dla których należy wybrać termin</div>
                </div>
              </div>
            )}
          </>
        </ContentBox>
      </LoaderTable>
    </>
  );
};

export default TableTrainingsBlock;
