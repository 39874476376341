import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Competences } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomCheckbox,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
} from 'components';

type CompetencesValue = Omit<Competences, 'id'>;

interface CompetencesProps {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: Competences | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormCompetences = (props: CompetencesProps): ReactElement => {
  const { t } = useTranslation('competences');
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const formSchema: yup.SchemaOf<CompetencesValue> = yup.object().shape({
    name: yup.string().required(t('competencesForm.nameMessage')),
  });

  const handleSave = (dataSubmit: CompetencesValue) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: CompetencesValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const { handleSubmit, register, errors } = useForm<CompetencesValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="formCompetences"
              onChange={(e) => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formCompetencesBox"
                    name={
                      edit
                        ? t('competencesForm.boxNameEdit')
                        : t('competencesForm.boxNameAdd')
                    }
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <Row className="justify-content-md-center">
                        <Col>
                          <CustomInput
                            name="name"
                            label={t('competencesForm.name')}
                            register={register}
                            defaultValue={data?.name}
                            error={errors?.name?.message}
                            required
                            disabled={onlyView}
                            fullWidth
                          />
                        </Col>
                      </Row>
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  // save={handleSubmit(handleSave)}
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormCompetences;
