/* eslint-disable no-nested-ternary */
import React, { ReactElement, useState } from 'react';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ShowTextarea,
} from 'components';
import { EmployeeAppraisalRepository } from 'api/repository';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalPutSubordinatesGoal } from 'api/types';
import './employee.scss';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';
import TableEmployeeBlock from './TableEmployeeBlock';
import GoalEditModal from './GoalEditModal';

const Employee = (): ReactElement => {
  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    dataSettings,
    updateSettings,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});

  const [goalModalShow, setGoalModalShow] = useState<boolean>(false);

  const [dataGoal, updateGoal] = EmployeeAppraisalRepository.useGetGoal();
  const [
    dataOverallGoal,
    updateOverallGoal,
  ] = EmployeeAppraisalRepository.useGetDataOverallGoal();

  const putSubordinatesGoal = EmployeeAppraisalRepository.usePutSubordinatesGoal(
    async (data) => {
      setSubmitForm(false);
      updateGoal(data);
      setGoalModalShow(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (
    dataPost: EmployeeAppraisalPutSubordinatesGoal
  ) => {
    setSubmitForm(true);
    putSubordinatesGoal(dataPost);
  };

  return (
    <>
      {dataSettings !== null ? (
        <CustomContainer>
          <>
            <Box
              id="managerEmployeeAppraisalEmployeeBox"
              name="LISTA PRACOWNIKÓW"
              svgIcon="employeeAppraisalList"
              noPadding
            >
              <>
                <ContentBox
                  id="managerEmployeeAppraisalEmployeeBoxBasicInformation"
                  bottomBorder
                >
                  <>
                    {dataOverallGoal?.overallGoal &&
                    dataOverallGoal?.overallGoal !== null ? (
                      <div id="goalFromTheSupervisorBox">
                        <div id="goalFromTheSupervisorLabel">
                          Cel od Twojego przełożonego:
                        </div>
                        <ShowTextarea
                          id="goalFromTheSupervisorData"
                          data={dataOverallGoal?.overallGoal}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {dataGoal?.subordinatesGoal &&
                    dataGoal?.subordinatesGoal !== null ? (
                      <div id="goalForSubordinatesBox">
                        <div id="goalForSubordinatesLabel">
                          Cel ustalony dla wszystkich Twoich podwładnych:
                        </div>
                        <div id="goalForSubordinatesData">
                          {(dataSettings?.interviewType === 'ANNUAL' &&
                            dataGoal?.interviewType !== 'LAST_ANNUAL') ||
                          dataSettings?.interviewType === 'SIX_MONTH' ? (
                            <EditAction
                              editClick={() => {
                                setGoalModalShow(true);
                              }}
                            />
                          ) : (
                            ''
                          )}
                          <ShowTextarea
                            id="goalForSubordinatesText"
                            data={dataGoal?.subordinatesGoal}
                          />
                        </div>
                      </div>
                    ) : (
                      <div id="goalForSubordinatesBox">
                        {dataSettings?.interviewType !== 'NONE' ? (
                          (dataSettings?.interviewType === 'ANNUAL' &&
                            dataGoal?.interviewType !== 'LAST_ANNUAL') ||
                          dataSettings?.interviewType === 'SIX_MONTH' ? (
                            <div id="goalForSubordinatesAdd">
                              Wprowadź cel ogólny dla wszystkich Twoich
                              podwładnych:{' '}
                              <Button
                                onClick={() => setGoalModalShow(true)}
                                className="green"
                              >
                                Wprowadź
                              </Button>
                            </div>
                          ) : (
                            <div id="goalForSubordinatesAdd">
                              Brak celu ogólnego dla wszystkich Twoich
                              podwładnych. Można to zrobić tylko podczas
                              pierwszej rozmowy lub rozmowy półrocznej
                            </div>
                          )
                        ) : (
                          <div id="goalForSubordinatesAdd">
                            Brak celu ogólnego dla wszystkich Twoich
                            podwładnych. Będzie można go wprowadzić podczas
                            najbliższej rozmowy
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </ContentBox>
                <TableEmployeeBlock />
              </>
            </Box>

            {goalModalShow === true ? (
              <GoalEditModal
                changeShowModal={setGoalModalShow}
                data={dataGoal?.subordinatesGoal}
                submitForm={submitForm}
                onSaveAndExit={handleSaveAndExit}
              />
            ) : (
              ''
            )}
          </>
        </CustomContainer>
      ) : (
        <CustomContainer allowGrow>
          <Box
            id="managerEmployeeAppraisalEmployeeBoxNoActivePlans"
            name="OCENA PRACOWNICZA"
            svgIcon="employeeAppraisalList"
            noContentStyle
          >
            <div id="managerEmployeeAppraisalEmployeeNoActivePlans">
              <div className="noActivePlan">
                <div>Aktualnie nie ma aktywnego planu ocen pracownika</div>
                <div>Powiadomimy Cię drogą mailową o jego rozpocząciu</div>
              </div>
            </div>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default Employee;
