import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PlansTrainingsRepository } from 'api/repository';
import {
  TablePagination,
  LoaderTable,
  TableRecordAction,
  CustomSort,
  CustomFilterText,
  CustomHeaderFormatter,
  TableCellTypeTrainings,
  CustomFilterData,
  CustomSortData,
  TableCellSwitchSingle,
  CustomFilterSelectTrainingType,
} from 'components';
import { useParams } from 'react-router-dom';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalTrainingsShow from '../../../TrainingsAndCompetence/Trainings/ModalTrainingsShow';
import ModalImportTraining from './ImportTrainingModal';

const TrableTrainings = (): ReactElement => {
  const { t } = useTranslation(['plansTrainings', 'trainings']);
  const { id } = useParams<{ id: string; action: string }>();

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalImport, setModalImport] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    'training.name': 'name',
    'training.providerName': 'providerName',
    type: 'type',
  };
  // End variable search

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      'training.name': 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'training.name': 'TRAINING_NAME',
    'training.providerName': 'PROVIDER_NAME',
    type: 'TYPE',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getDataTable,
    dataTable,
  ] = PlansTrainingsRepository.useGetRequiredTrainings(Number(id));

  const requiredTraining = PlansTrainingsRepository.useChangeRequiredTraining(
    Number(id)
  );

  const handleChangeSelect = (dataId: number, checked: boolean) => {
    requiredTraining(dataId, checked);
  };

  const deleteTraining = PlansTrainingsRepository.useDeleteTrainingFromPlan(
    Number(id),
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const handleDeleteTraining = (idClick: number) => {
    deleteTraining(idClick);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getDataTable(paramQuery);
  }, [
    getDataTable,
    page,
    rowsPerPage,
    filterReq,
    orderColumn,
    updateDataTable,
  ]);

  useEffect(() => {
    if (dataTable !== null) {
      setLoaderTable(false);
    }
  }, [dataTable]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleOpenAddForm = () => {
    setModalImport(true);
  };

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <TableRecordAction
            dataId={row.training.id}
            showClick={handleShowClick}
            archiveClick={handleDeleteTraining}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      text: t('trainings:trainingsTable.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'training.providerName',
      text: t('trainings:trainingsTable.providerName'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'type',
      text: t('trainings:trainingsTable.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="left"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.type} justify="toLeft" />;
      },
    },
    {
      dataField: '',
      text: t('trainings:trainingsTable.required'),
      headerFormatter: (column: any, index: any) => {
        return <CustomHeaderFormatter column={column} align="right" />;
      },
      formatter: (_cell: any, row: any) => {
        return (
          <TableCellSwitchSingle
            dataId={row.training.id}
            id="selectedRow"
            justify="toRight"
            selectedData={row.required}
            handleChangeSelect={handleChangeSelect}
            edit
          />
        );
      },
    },
  ];

  return (
    <>
      <>
        <LoaderTable active={loaderTable}>
          <>
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={dataTable}
              changePage={setPage}
            >
              <Button className="green" onClick={() => handleOpenAddForm()}>
                {t('trainingsTable.importTrainings')}
              </Button>
            </TablePagination>
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={dataTable?.content || []}
              columns={columns}
              noDataIndication={t('trainings:trainingsTable.noDataTable')}
              remote={{
                filter: true,
                sort: true,
              }}
              onTableChange={onTableChange}
              filter={filterFactory()}
              defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            />
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={dataTable}
              changePage={setPage}
            />
          </>
        </LoaderTable>

        {modalImport ? (
          <ModalImportTraining
            id={Number(id)}
            changeShowModal={setModalImport}
            updateTable={setUpdateDataTable}
          />
        ) : (
          ''
        )}
        {modalShow ? (
          <ModalTrainingsShow id={modalId} changeModalShow={setModalShow} />
        ) : (
          ''
        )}
      </>
    </>
  );
};

export default TrableTrainings;
