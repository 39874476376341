import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ModalYesNo,
  CustomTooltip,
  ShowListToString,
} from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import GroupsBlock from './GroupsBlock';

import './trainingsRegisterGroup.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation(['trainingsGroup', 'trainings']);
  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [data] = TrainingsGroupRepository.useGetCurrentPlanTest({});

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={data === undefined} radiusTop allowGrow>
          <>
            <>
              {data !== undefined &&
              data !== null &&
              data.status !== 'REGISTRATION' ? (
                <Box
                  id="trainingGroupUserListTrainingsBoxA"
                  name="LISTA SZKOLEŃ"
                  svgIcon="TrainingsGroupUserTrainingsList"
                  noPadding
                  noContentStyle
                >
                  <GroupsBlock />
                </Box>
              ) : (
                <Box
                  id="trainingGroupUserListTrainingsBoxA"
                  name="LISTA SZKOLEŃ"
                  svgIcon="TrainingsGroupUserTrainingsList"
                  noPadding
                  noContentStyle
                >
                  <div id="trainingGroupUserListTrainingsNoActive">
                    <div className="noActivePlan">
                      <div>
                        Nie zakończył się jeszcze proces zapisów na szkolenia
                      </div>
                      <div>
                        Poinformujemy cię mailowo kiedy będzie się można
                        zapisywać na terminy szkoleń
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
