/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import './TableCellLoyaltyStatus.scss';
import CustomInfoBlock from 'components/CustomInfoBlock';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: boolean | null;
  id: number;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellLoyaltyStatus = (props: Props): ReactElement => {
  const { id, data, justify } = props;

  return (
    <>
      <div className={`tableCellLoyaltyStatus ${justify || ''}`}>
        <div className="boxStatus">
          {data === true ? (
            <div className="trueStatus circleBox">
              <RenderSvg nameSvg="LoyaltyStatusTrue" />
            </div>
          ) : (
            ''
          )}
          {data === false ? (
            <div className="falseStatus circleBox">
              <RenderSvg nameSvg="LoyaltyStatusFalse" />
            </div>
          ) : (
            ''
          )}
          {data === null ? (
            <div className="noneStatus circleBox">
              <RenderSvg nameSvg="LoyaltyStatusNone" />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default TableCellLoyaltyStatus;
