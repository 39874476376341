import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsProviderContactRepository } from 'api/repository';
import { TrainingsProviderContactAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsProviderContact from '../Form/FormTrainingsProviderContact';

interface TrainigsProviderProps {
  id: number;
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
  saveAndExitOnly?: boolean;
}
const TrainingsProviderShow = (props: TrainigsProviderProps): ReactElement => {
  const {
    id,
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
    saveAndExitOnly,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = TrainingsProviderContactRepository.useAddTrainingsProviderContact(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
      if (changeModalId) changeModalId(data.id);
      if (changeModalEdit) changeModalEdit(true);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: TrainingsProviderContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.providerId = id;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSave(data);
  };

  const postDataSaveAndExit = TrainingsProviderContactRepository.useAddTrainingsProviderContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContactAndExit = (dataPost: any) => {
    setSubmitForm(true);
    const data = dataPost;
    data.providerId = id;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd}>
      <div>
        <FormTrainingsProviderContact
          onSave={
            saveAndExitOnly ? undefined : handleAddTrainingsProviderContact
          }
          onSaveAndExit={handleAddTrainingsProviderContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderShow;
