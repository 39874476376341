/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './catalogsTrainings.scss';
import CatalogsTrainingsItem from './CatalogsTrainingsItem';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data?: any;
  showClick?: (dataId: number) => void;
  editClick?: (dataId: number) => void;
  archiveClick?: (dataId: number) => void;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data, showClick, editClick, archiveClick } = props;

  return (
    <div className="CatalogsTrainingsItems" id={id}>
      {data?.content
        ? data.content.map((item: any) => {
            return (
              <CatalogsTrainingsItem
                id={id}
                data={item}
                showClick={showClick}
                editClick={editClick}
                archiveClick={archiveClick}
              />
            );
          })
        : ''}
    </div>
  );
};

export default ContentBox;
