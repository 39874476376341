/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect } from 'react';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';
import './animatedProgressBar.scss';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  valueStart?: number;
  valueEnd?: number;
  duration?: number;
  maxValue: number;
  precent?: boolean;
}

const AnimatedProgressBar = (props: Props): ReactElement => {
  const { valueStart, valueEnd, duration, maxValue, precent } = props;

  return (
    <div className="CustomAnimatedPrograssBar">
      <Animate
        show
        start={{
          value: valueStart || 0,
        }}
        update={() => ({
          value: [valueEnd || 0],
          timing: {
            duration: duration || 300,
            ease: easeQuadInOut,
          },
        })}
        enter={() => ({
          value: [valueEnd || 0],
          timing: {
            duration: duration || 300,
            ease: easeQuadInOut,
          },
        })}
      >
        {({ value }) => {
          const roundedValue = Math.round(value);
          return (
            <CircularProgressbar
              value={value}
              text={`${roundedValue}${precent ? '%' : ''}`}
              styles={buildStyles({ pathTransition: 'none' })}
              maxValue={maxValue}
            />
          );
        }}
      </Animate>
    </div>
  );
};

export default AnimatedProgressBar;
