import React, {
  ReactElement,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  AdditionalAction,
  AnimatedProgressBarLine,
  Box,
  CustomHeaderFormatter,
  CustomInfoBlock,
  LoaderTable,
  Modal,
  RenderSvg,
  TableCellAppraisalStatus,
  TableCellComment,
  TableCellNumberPrecent,
  TableCellProgressBar,
  TableCellTypeGoal,
  TableRecordAction,
} from 'components';
import { IDPEmployeeAreaModel } from 'api/types';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';

import './employeeAppraisalGoalmport.scss';

interface CompetencesProps {
  idEmployee: number;
  changeModalImport: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
}
const CompetencesAdd = (props: CompetencesProps): ReactElement => {
  const { idEmployee, changeModalImport, changeUpdateData } = props;

  const [loaderTable, setLoaderTable] = useState<boolean>(true);
  const [updateData, setUpdateData] = useState<number>(0);

  const [
    getData,
    data,
  ] = EmployeeAppraisalRepository.useGetAllImportEmployeeAppraisalGoal({
    idEmployee,
  });

  useEffect(() => {
    setLoaderTable(true);

    getData();
  }, [getData, updateData]);

  useEffect(() => {
    if (data !== undefined) {
      setLoaderTable(false);
    }
  }, [data]);

  const importAction = EmployeeAppraisalRepository.usePostEmployeeAppraisalGoalImport(
    idEmployee,
    () => {
      changeUpdateData(Math.random);
      setUpdateData(Math.random);
    }
  );

  const handleImport = (idArea: number) => {
    importAction(idArea);
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            importClick={() => {
              handleImport(row.id);
            }}
            rowNumber={rowIndex}
            page={1}
            rowsPerPage={100}
          />
        );
      },
    },
    {
      dataField: 'type',
      text: 'Rodzaj celu',
      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeGoal id={row.id} type={row.type} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: 'Nazwa celu',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'weight',
      text: 'Waga %',
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellNumberPrecent number={row.weight} />;
      },
      headerStyle: () => {
        return { width: '110px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'measure',
      text: 'KPI/ Mierniki',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'realizationDate',
      text: 'Data realizacji',
      align: 'center',
      headerStyle: () => {
        return { width: '110px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      align: 'center',

      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellAppraisalStatus id={row.id} status={row.status} />;
      },
      headerStyle: () => {
        return { width: '140px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'progress',
      text: 'Postęp realizacji',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellProgressBar id={row.id} valueEnd={row.progress} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'comment',
      text: 'Komentarz',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellComment
            title="Komentarz do celu"
            id={row.id}
            comment={row.comment}
          />
        );
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
  ];

  return (
    <Modal open changeModalShow={changeModalImport} widthBox="high">
      <Box
        id="employeeAppraisalGoalImportBox"
        name="IMPORT CELU ROCZNEGO"
        svgIcon="importAction"
        noShadow
        noMarginBottom
        noPadding
      >
        <LoaderTable active={loaderTable}>
          <>
            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={data?.content || []}
              columns={columns}
              noDataIndication="Brak celów rocznych do importu"
              remote={{
                filter: true,
                sort: true,
              }}
              filter={filterFactory()}
              defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            />
          </>
        </LoaderTable>
      </Box>
    </Modal>
  );
};

export default CompetencesAdd;
