import React, { ReactElement } from 'react';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  active?: boolean;
  children?: ReactElement;
  radiusTop?: boolean;
  allowGrow?: boolean;
  noRadiusTop?: boolean;
}

const LoaderTable = (props: Props): ReactElement => {
  const { active, children, radiusTop, allowGrow, noRadiusTop } = props;

  const { t } = useTranslation('global');

  return (
    <Loader
      active={active}
      className={`${radiusTop ? 'radiusTop' : ''} ${
        allowGrow ? 'allowGrow' : ''
      } ${noRadiusTop ? 'noRadiusTop' : ''}`}
      text={t('loader.getData')}
    >
      {children}
    </Loader>
  );
};

export default LoaderTable;
