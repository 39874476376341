/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { CustomAlert } from 'components';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  status: number;
  statusMsg?: 'post' | 'put' | 'delete' | 'archive';
  customMsg?: string;
}

const AutoAlertStatus = (props: Props): ReactElement => {
  const { status, statusMsg, customMsg } = props;

  const returnStatusMsg = () => {
    let text = '';
    if (statusMsg === 'post') {
      text += 'Dane zostały zapisane poprawnie';
    } else if (statusMsg === 'put') {
      text += 'Dane zostały zaktualizowane poprawnie';
    } else if (statusMsg === 'delete') {
      text += 'Dane zostały usunięte poprawnie';
    } else if (statusMsg === 'archive') {
      text += 'Dane zostały zarchiwizowane poprawnie';
    }
    if (text !== '') {
      return text;
    }
    return undefined;
  };

  const renderAlert = () => {
    if (status === 200) {
      CustomAlert({
        typeAlert: 'success',
        customMsg:
          customMsg || returnStatusMsg() || 'Dane zostały zapisane poprawnie',
      });
    } else if (status === 201) {
      CustomAlert({
        typeAlert: 'success',
        customMsg:
          customMsg ||
          returnStatusMsg() ||
          'Dane zostały zaktualizowane poprawnie',
      });
    } else if (status === 204) {
      CustomAlert({
        typeAlert: 'error',
        customMsg: customMsg || 'Brak takiego zestawu danych',
      });
    } else if (status === 400) {
      CustomAlert({ typeAlert: 'error', customMsg });
    } else if (status === 401) {
      <CustomAlert
        typeAlert="error"
        customMsg={customMsg || 'Brak aktywnej sesji logowania'}
      />;
    } else if (status === 403) {
      <CustomAlert
        typeAlert="warning"
        customMsg={customMsg || 'Brak uprawnień do danych'}
      />;
    } else if (status === 500) {
      CustomAlert({
        typeAlert: 'error',
        customMsg:
          customMsg ||
          'Wystąpił problem serwera, pracujemy nad jego rozwiązaniem',
      });
    } else if (status === 503) {
      CustomAlert({
        typeAlert: 'warning',
        customMsg: customMsg || 'Serwis jest chwilowo niedostępny',
      });
    } else {
      CustomAlert({ typeAlert: 'error', customMsg: customMsg || '' });
    }
  };

  return <>{renderAlert()}</>;
};

export default AutoAlertStatus;
