import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ShowListToString,
  ShowSuperior,
} from 'components';
import { EmployeeRepository, TrainingsGroupRepository } from 'api/repository';
import GroupsBlock from './GroupsBlock';
import TrainingGroupShow from '../../../../Administration/TrainingsGroup/TrainingsGroupShow';

import './trainingsRegisterGroup.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation(['trainingsGroup', 'trainings']);
  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [data] = TrainingsGroupRepository.useGetCurrentPlanTest({});

  const [dataEmployee] = EmployeeRepository.useGetEmployee({ id: Number(id) });

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={data === undefined} radiusTop allowGrow>
          <>
            <>
              {data !== undefined &&
              data !== null &&
              data.status !== 'REGISTRATION' ? (
                <Box
                  id="trainingGroupUserListTrainingsBox"
                  name="LISTA SZKOLEŃ"
                  svgIcon="TrainingsGroupUserTrainingsList"
                  noPadding
                  noContentStyle
                >
                  <>
                    <ContentBox
                      id="userPlansAcceptedTrainingsBasicInformation"
                      bottomBorder
                    >
                      <>
                        <div className="employeeNameBox">
                          <div className="titleName">Wybrany pracownik:</div>
                          <div className="name">
                            <span className="lastName">
                              {dataEmployee?.lastName},{' '}
                            </span>
                            <span className="firstName">
                              {dataEmployee?.firstName}
                            </span>
                          </div>
                          <div className="extension">
                            Nr. wewnętrzny:{' '}
                            <span className="extensionData">
                              {dataEmployee?.id}
                            </span>
                          </div>
                          <div className="superior">
                            Przełożony:{' '}
                            <span className="superiorData">
                              <ShowSuperior
                                data={dataEmployee?.superior}
                                noObject
                              />
                            </span>
                          </div>
                        </div>
                        <div className="additionalInfoBox">
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="location" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Lokalizacja:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  {dataEmployee?.locations}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="workplace" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Stanowiska:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  <ShowListToString
                                    data={dataEmployee?.positions}
                                    noObject
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="departament" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Działy:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  <ShowListToString
                                    data={dataEmployee?.segments}
                                    noObject
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ContentBox>
                    <GroupsBlock
                      setModalShow={setModalShow}
                      setModalId={setModalId}
                    />
                    {modalShow ? (
                      <TrainingGroupShow
                        id={modalId}
                        changeModalShow={setModalShow}
                      />
                    ) : (
                      ''
                    )}
                  </>
                </Box>
              ) : (
                <Box
                  id="trainingGroupUserListTrainingsBox"
                  name="LISTA SZKOLEŃ"
                  svgIcon="TrainingsGroupUserTrainingsList"
                  noPadding
                  noContentStyle
                >
                  <div id="trainingGroupUserListTrainingsNoActive">
                    <div className="noActivePlan">
                      <div>
                        Nie zakończył się jeszcze proces zapisów na szkolenia
                      </div>
                      <div>
                        Poinformujemy cię mailowo kiedy będzie się można
                        zapisywać na szkolenia
                      </div>
                    </div>
                  </div>
                </Box>
              )}
            </>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
