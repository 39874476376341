/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './contentPlansTrainings.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  children?: ReactElement;
}

const ContentPlansTrainings = (props: Props): ReactElement => {
  const { children } = props;

  return (
    <div id="contentPlansTrainings" className="contentPlansTrainings">
      {children}
    </div>
  );
};

export default ContentPlansTrainings;
