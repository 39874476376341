/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { CompetencesRepository } from 'api/repository';
import TrainingsType from 'constants/Trainings';
import React, { ReactElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from '../ComponentFilter/SearchBox';
import SearchMultiSelect from '../ComponentFilter/SearchMultiSelect';
import SearchSelect from '../ComponentFilter/SearchSelect';
import '../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  customFilter: any;
  column: any;
  placeholder?: string;
  alignBox?: 'left' | 'center' | 'right';
}

const CustomFilterSelect = (props: Props): ReactElement => {
  const { t } = useTranslation(['filterTable']);

  const { customFilter, column, placeholder, alignBox } = props;

  const [openSearch, setOpenSearch] = useState(false);
  const [activeSearch, setActiveSearch] = useState(false);
  const [searchValue, setsearchValue] = useState<any>();

  const [
    getAllCompetences,
    dataAllCompetences,
  ] = CompetencesRepository.useGetAllCompetencesSimple();

  useEffect(() => {
    getAllCompetences();
  }, [getAllCompetences]);

  const competencesOptions = () => {
    const arrayCompetenecesOption = [] as any;
    if (dataAllCompetences !== null && dataAllCompetences.content.length > 0) {
      Object.keys(dataAllCompetences.content).forEach((key) => {
        arrayCompetenecesOption.push({
          value: dataAllCompetences.content[key].id,
          label: dataAllCompetences.content[key].name,
        });
      });
    }
    return arrayCompetenecesOption;
  };

  const selectedOptions = (options: any) => {
    const arrayTypeOption = [] as any;
    Object.keys(options).forEach((key) => {
      arrayTypeOption.push(options[key].value);
    });
    return arrayTypeOption;
  };

  const handleSearch = () => {
    if (searchValue === '') {
      setActiveSearch(false);
    } else {
      setActiveSearch(true);
    }
    customFilter({ [column.dataField]: selectedOptions(searchValue) });
    setOpenSearch(false);
  };

  // Reset filter
  const [resetFilter, setResetFilter] = useState(0);
  useEffect(() => {
    if (resetFilter !== 0) {
      handleSearch();
    }
  }, [resetFilter]);

  const handleSearchClear = () => {
    setsearchValue('');
    setResetFilter(Math.random);
  };
  // End reset filter

  const keyAction = (e: any) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return (
    <SearchBox
      handleSearch={handleSearch}
      labelSearch={t('filterAddress.search')}
      openSearch={openSearch}
      activeSearch={activeSearch}
      alignBox={alignBox}
      handleSearchClear={handleSearchClear}
    >
      <>
        <SearchMultiSelect
          options={competencesOptions()}
          name={column.dataField}
          nameLabel={column.text}
          placeholder={placeholder}
          setValue={setsearchValue}
          keyAction={keyAction}
          setOpenSearch={setOpenSearch}
          value={searchValue}
        />
      </>
    </SearchBox>
  );
};

export default CustomFilterSelect;
