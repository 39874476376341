import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  Loader,
  QuestionRadioSummary,
  QuestionYesNoPartlySummary,
  RenderSvg,
} from 'components';
import {
  QuestionaireRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import { SummaryOnly } from 'api/types';

import './questionnaireEdit.scss';

const QuestionnaireEdit = (): ReactElement => {
  const { id, idRecord, action } = useParams<{
    id: string;
    idRecord: string;
    action: string;
  }>();

  const [
    trainingsGroupData,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetGroup({
    id: Number(idRecord),
  });

  const [idQuestionnaire, setIdQuestionnaire] = useState<number | undefined>(
    undefined
  );

  const [getData, data] = QuestionaireRepository.useGetQuestionnaireSummary({});

  useEffect(() => {
    if (idQuestionnaire !== undefined) {
      getData(idQuestionnaire);
    }
  }, [idQuestionnaire, getData]);

  useEffect(() => {
    if (trainingsGroupData !== undefined && trainingsGroupData !== null) {
      setIdQuestionnaire(trainingsGroupData.questionnaireId);
    }
  }, [trainingsGroupData]);

  return (
    <>
      <CustomContainer id="questionnaireSummaryBox">
        <>
          <Loader active={trainingsGroupData === undefined}>
            <>
              <div id="questionnaireHeader">
                <div id="basicInfoBox" className="basicInfoBox">
                  <div id="questionnaireHeaderLabel">Typ ankiety:</div>
                  <div id="questionnaireHeaderValue">
                    Ankieta pracownika po szkoleniu
                  </div>
                </div>
                <div id="additionalInfoBox" className="additionalInfoBox">
                  <div
                    id="frameAdditionalInfo"
                    className="aditionalInfoContent"
                  >
                    <div id="frameAdditionalInfoDetail" className="content">
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Liczba osób na szkoleniu:
                      </div>
                      <div
                        id="frameAdditionalInfoBoxData"
                        className="additionalInfoDetail"
                      >
                        <div id="frameAdditionalInfoIcon" className="icon">
                          <RenderSvg nameSvg="people" />
                        </div>
                        <div
                          id="frameAdditionalInfoBoxDataData"
                          className="data"
                        >
                          {trainingsGroupData?.registrationsCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="frameAdditionalInfo"
                    className="aditionalInfoContent"
                  >
                    <div id="frameAdditionalInfoDetail" className="content">
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Liczba wypełnionych ankiet:
                      </div>
                      <div
                        id="frameAdditionalInfoBoxData"
                        className="additionalInfoDetail"
                      >
                        <div id="frameAdditionalInfoIcon" className="icon">
                          <RenderSvg nameSvg="questionnaires" />
                        </div>
                        <div
                          id="frameAdditionalInfoBoxDataData"
                          className="data"
                        >
                          {data?.submissions}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="frameAdditionalInfo"
                    className="aditionalInfoContent"
                  >
                    <div id="frameAdditionalInfoDetail" className="content">
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Średnia z ogólnej oceny szkolenia:
                      </div>
                      <div
                        id="frameAdditionalInfoBoxData"
                        className="additionalInfoDetail"
                      >
                        <div id="frameAdditionalInfoIcon" className="icon">
                          <RenderSvg nameSvg="charts" />
                        </div>
                        <div
                          id="frameAdditionalInfoBoxDataData"
                          className="data"
                        >
                          {data?.questions[13]?.averageAnswer}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {trainingsGroupData !== undefined ? (
                <>
                  <Loader active={data === undefined}>
                    <div id="questionnaireBody">
                      <div id="questionnaireBodyHead">
                        <div id="questionnaireBodyHeadLeft">
                          Lista pytań zawartych w ankiecie:
                        </div>
                        <div id="questionnaireBodyHeadRight">Średnia:</div>
                      </div>
                      <div id="questionnaireBodyContent">
                        <div
                          id="questionnaireBodyContentGroup-1"
                          className="group"
                        >
                          <div
                            id="questionnaireBodyContentGroupLabel-1"
                            className="groupLabel"
                          >
                            Ocena techniczno – organizacyjnego przygotowania
                            szkolenia
                          </div>
                          {data?.questions && data?.questions.length > 0
                            ? data.questions.map(
                                (item: SummaryOnly, i: number) => {
                                  if (i <= 4) {
                                    if (item.questionType === 'SINGLE_CHOICE') {
                                      return (
                                        <QuestionRadioSummary data={item} />
                                      );
                                    }
                                    return `${item.questionType}, `;
                                  }
                                  return null;
                                }
                              )
                            : ''}
                        </div>
                        <div
                          id="questionnaireBodyContentGroup-2"
                          className="group"
                        >
                          <div
                            id="questionnaireBodyContentGroupLabel-2"
                            className="groupLabel"
                          >
                            Ocena wykładowców
                          </div>
                          {data?.questions && data?.questions.length > 0
                            ? data.questions.map(
                                (item: SummaryOnly, i: number) => {
                                  if (i >= 5 && i <= 8) {
                                    if (item.questionType === 'SINGLE_CHOICE') {
                                      return (
                                        <QuestionRadioSummary data={item} />
                                      );
                                    }
                                    return `${item.questionType}, `;
                                  }
                                  return null;
                                }
                              )
                            : ''}
                        </div>
                        <div
                          id="questionnaireBodyContentGroup-3"
                          className="group"
                        >
                          <div
                            id="questionnaireBodyContentGroupLabel-3"
                            className="groupLabel"
                          >
                            Ocena merytoryczna szkolenia
                          </div>
                          {data?.questions && data?.questions.length > 0
                            ? data.questions.map(
                                (item: SummaryOnly, i: number) => {
                                  if (i >= 9 && i <= 12) {
                                    if (item.questionType === 'SINGLE_CHOICE') {
                                      return (
                                        <QuestionRadioSummary data={item} />
                                      );
                                    }
                                    if (
                                      item.questionType ===
                                      'SINGLE_CHOICE_WITH_TEXT'
                                    ) {
                                      return (
                                        <QuestionYesNoPartlySummary
                                          data={item}
                                        />
                                      );
                                    }
                                    return `${item.questionType}, `;
                                  }
                                  return null;
                                }
                              )
                            : ''}
                        </div>
                        {data &&
                        data !== null &&
                        data?.questions[14] !== undefined ? (
                          <div id="questionnaireBodySummary">
                            {data.questions[14].textList.length > 0 ? (
                              <div id="questionnaireBodySummaryCommentsBox">
                                <div className="questionnaireBodySummaryCommentsLabel">
                                  Lista uwag:
                                </div>
                                {data?.questions[14].textList.map((val) => {
                                  return (
                                    <div
                                      // eslint-disable-next-line react/no-danger
                                      dangerouslySetInnerHTML={
                                        val ? { __html: val } : undefined
                                      }
                                    />
                                  );
                                })}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Loader>
                </>
              ) : (
                ''
              )}
            </>
          </Loader>
        </>
      </CustomContainer>
    </>
  );
};

export default QuestionnaireEdit;
