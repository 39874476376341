import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Box, LoaderTable, Modal, TabsHook } from 'components';
import { IDPRepository } from 'api/repository';

import IDPEmployeeDetail from './IDPEmployeeDetail';

import IDPEmployeeArea from './IDPEmployeeArea';
import IDPEmployeeLeaderProfile from './IDPEmployeeLeaderProfile';
import IDPFeedbackModalShow from './IDPFeedbackModalShow';

import './IDPDetail.scss';

interface Props {
  idpId: number;
  idEmployee: number;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  employeeName?: string;
  selectedYear?: string;
}

const ShowIDP = (props: Props): ReactElement => {
  const { idpId, idEmployee, setShowModal, employeeName, selectedYear } = props;

  const [selectTab, setSelectTab] = useState<string>('detail');

  const [IDPEmployee] = IDPRepository.useGetIDPEmployeeDataAdmin({
    id: idEmployee,
    idpId,
  });

  return (
    <>
      <Modal open changeModalShow={setShowModal} widthBox="high">
        <LoaderTable active={IDPEmployee === undefined}>
          <Box
            id="ShowIDPEmployeeBox"
            name={`PODGLĄD IDP ${selectedYear || ''}  - ${employeeName || ''} `}
            svgIcon="show"
            noPadding
            noMarginBottom
            noShadow
          >
            <>
              {IDPEmployee === null ? (
                <div id="IDPEmployeeContentBoxEmpty">BRAK DANYCH IDP</div>
              ) : (
                <>
                  <TabsHook
                    id="IDPEmployeeTabs"
                    items={[
                      {
                        id: 'detail',
                        label: 'Cele, komentarze, mobilność',
                        svgName: 'target',
                      },
                      {
                        id: 'area',
                        label: 'Obszary rozwoju',
                        svgName: 'searchAndGraph',
                      },
                      {
                        id: 'profil-lidera',
                        label: 'Profil lidera',
                        svgName: 'employeeWithStar',
                      },
                      {
                        id: 'feedback',
                        label: 'Feedback nr wartości',
                        svgName: 'feedback',
                      },
                    ]}
                    activeTab={selectTab}
                    setActiveTab={setSelectTab}
                  />
                  <div id="IDPEmployeeContentBox">
                    {selectTab === 'detail' ? (
                      <IDPEmployeeDetail IDPEmployee={IDPEmployee} />
                    ) : (
                      ''
                    )}
                    {selectTab === 'area' ? (
                      <IDPEmployeeArea idEmployee={idEmployee} idpId={idpId} />
                    ) : (
                      ''
                    )}
                    {selectTab === 'profil-lidera' ? (
                      <IDPEmployeeLeaderProfile
                        idEmployee={idEmployee}
                        idpId={idpId}
                      />
                    ) : (
                      ''
                    )}
                    {selectTab === 'feedback' ? (
                      <IDPFeedbackModalShow
                        employeeId={idEmployee}
                        idpId={idpId}
                        employeeName={employeeName}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </Box>
        </LoaderTable>
      </Modal>
    </>
  );
};

export default ShowIDP;
