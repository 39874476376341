import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBeforeCatalog, ContentBox, LoaderTable } from 'components';
import { Button } from 'react-bootstrap';
import { TrainingsGroupRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupTraining } from 'api/types';
import GroupItem from './GroupItem';

interface Props {
  setModalShow?: Dispatch<SetStateAction<boolean>>;
  setModalId?: Dispatch<SetStateAction<number>>;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { t } = useTranslation('trainingsGroup');
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { setModalShow, setModalId } = props;

  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [
    getDataSingle,
    dataSingle,
  ] = TrainingsGroupRepository.useGetAllUserTrainingsGroupSingle(Number(id));

  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetAllUserTrainingsGroupAvailableGroup(
    Number(id)
  );

  useEffect(() => {
    getDataSingle();
  }, [getDataSingle]);

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const handleAddTrainingsProvider = TrainingsGroupRepository.useTrainingsGroupUserPostSelectGroup(
    async () => {
      setLoaderTable(false);
      history.push(`/panel/szkolenia/termin-szkolenia`);
    },
    async () => {
      setLoaderTable(false);
    }
  );

  const handleChangeTrainingsProvider = TrainingsGroupRepository.useTrainingsGroupUserDeleteSelectGroup(
    async (idGroupChange) => {
      setLoaderTable(true);
      if (idGroupChange) {
        handleAddTrainingsProvider(idGroupChange);
      }
    },
    async () => {
      setLoaderTable(false);
    }
  );

  const changeSelectGroup = (idClick: number) => {
    if (dataSingle?.content[0]?.trainingGroup?.id !== undefined) {
      setLoaderTable(true);
      handleChangeTrainingsProvider(
        dataSingle?.content[0]?.trainingGroup?.id,
        Number(idClick)
      );
    }
  };

  const selectGroup = (idClick: number) => {
    setLoaderTable(true);
    handleAddTrainingsProvider(Number(idClick));
  };

  return (
    <>
      <LoaderTable active={loaderTable} radiusTop>
        <>
          <ContentBox id="trainingGroupsUserSelectTrainingHeaderBox" noPadding>
            <>
              <div className="labelTrainings">Wybrane szkolenie:</div>
              <div className="valueTrainings">
                {dataSingle?.content[0].training.name}
              </div>
            </>
          </ContentBox>
          <ContentBox
            id="trainingGroupsUserSelectTrainingBox"
            noPadding
            title="DATY SZKOLEŃ:"
          >
            <>
              {data?.content && data.content.length > 0 ? (
                <div
                  className="trainingGroupsUserSelectTrainingItemsBox"
                  id={id}
                >
                  {data.content.map((item: TrainingsGroupTraining) => {
                    return (
                      <GroupItem
                        id="itemSelectTrainings"
                        data={item}
                        selectedGroup={dataSingle?.content[0] || null}
                        showClick={() => {
                          if (setModalId && setModalShow) {
                            setModalId(Number(item.id));
                            setModalShow(true);
                          }
                        }}
                        selectClick={() => {
                          selectGroup(item.id);
                        }}
                        changeSelectClick={() => {
                          changeSelectGroup(item.id);
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  className="trainingGroupsUserSelectTrainingItemsBoxNoRecord"
                  id={id}
                >
                  Brak utworzonych grup szkoleniowych do wybranego szkolenia
                </div>
              )}
            </>
          </ContentBox>
        </>
      </LoaderTable>
    </>
  );
};

export default TableTrainingsBlock;
