import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthResources,
  ContentBox,
  CustomFilterAddress,
  CustomFilterContact,
  CustomFilterData,
  CustomFilterSelectTrainingCompetence,
  CustomFilterSelectTrainingType,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  ShowTitleTable,
  TableCellAddress,
  TableCellContact,
  TableCellDeleteRow,
  TableCellListToCell,
  TableCellTrainingsShowStatus,
  TableCellTrainingsStatusChange,
  TableCellTypeTrainings,
  TableCellYesNo,
  TablePagination,
  TableRecordAction,
} from 'components';
import { Button } from 'react-bootstrap';
import { AcceptedTrainingsRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { Link, useParams } from 'react-router-dom';
import { AcceptedDecisionMotivation } from 'api/types';
import DecisionModal from '../DecisionModal';
import ModalTrainingsShow from '../../../../Settings/TrainingsAndCompetence/Trainings/ModalTrainingsShow';

const TableTrainingsBlock = (): ReactElement => {
  const { t } = useTranslation('acceptedTrainings');
  const { id } = useParams<{ id: string }>();

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    'training.name': 'name',
    'training.type': 'type',
    'training.competences': 'competences',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      'training.name': 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'training.name': 'TRAINING_NAME',
    'training.type': 'TYPE',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = AcceptedTrainingsRepository.useGetAllSelectedTrainingsActivePlans(id);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [modalType, setModalType] = useState<'hr' | 'ds' | 'slt' | undefined>(
    undefined
  );

  const [modalDecisionShow, setModalDecisionShow] = useState<boolean>(false);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      left: true,
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const hrStatus = AcceptedTrainingsRepository.useHrStatus(id, async () => {
    setUpdateDataTable(Math.random);
    setModalDecisionShow(false);
  });

  const dsStatus = AcceptedTrainingsRepository.useDsStatus(id, async () => {
    setUpdateDataTable(Math.random);
    setModalDecisionShow(false);
  });

  const sltStatus = AcceptedTrainingsRepository.useSltStatus(id, async () => {
    setUpdateDataTable(Math.random);
    setModalDecisionShow(false);
  });

  const handleAcceptedHr = (idClick: number) => {
    hrStatus({ comment: null, status: 'ACCEPTED' }, idClick);
  };
  const handleRejectedHr = (idClick: number) => {
    setModalType('hr');
    setModalId(idClick);
    setModalDecisionShow(true);
  };
  const handleVerifyHr = (idClick: number) => {
    hrStatus({ comment: null, status: 'VERIFY' }, idClick);
  };
  const handleAcceptedDs = (idClick: number) => {
    dsStatus({ comment: null, status: 'ACCEPTED' }, idClick);
  };
  const handleRejectedDs = (idClick: number) => {
    setModalType('ds');
    setModalId(idClick);
    setModalDecisionShow(true);
  };
  const handleVerifyDs = (idClick: number) => {
    dsStatus({ comment: null, status: 'VERIFY' }, idClick);
  };
  const handleAcceptedSlt = (idClick: number) => {
    sltStatus({ comment: null, status: 'ACCEPTED' }, idClick);
  };
  const handleRejectedSlt = (idClick: number) => {
    setModalType('slt');
    setModalId(idClick);
    setModalDecisionShow(true);
  };
  const handleVerifySlt = (idClick: number) => {
    sltStatus({ comment: null, status: 'VERIFY' }, idClick);
  };

  const handleSaveAndExit = (dataPost: AcceptedDecisionMotivation) => {
    // setSubmitForm(true);
    const dataModel = dataPost;
    dataModel.status = 'REJECTED';
    if (modalType === 'hr') {
      hrStatus(dataModel, modalId);
    } else if (modalType === 'ds') {
      dsStatus(dataModel, modalId);
    } else if (modalType === 'slt') {
      sltStatus(dataModel, modalId);
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.training.id}
            showClick={handleShowClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      sort: true,
      text: t('acceptedTrainingTable.nameTrainings'),
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'training.type',
      text: t('acceptedTrainingTable.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.training.type} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
    },
    {
      dataField: 'training.competences',
      text: t('acceptedTrainingTable.competences'),
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell data={row?.training.competences} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingCompetence
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
    },
    {
      dataField: '',
      text: t('acceptedTrainingTable.hrDepartament'),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            <AuthResources requiredRoles={['hr_bp']}>
              <TableCellTrainingsStatusChange
                id={row.id}
                data={row}
                dataType="hr"
                justify="center"
                acceptedHandle={() => handleAcceptedHr(row.training.id)}
                verifyHandle={() => handleVerifyHr(row.training.id)}
                rejectedHandle={() => handleRejectedHr(row.training.id)}
              />
            </AuthResources>
            <AuthResources requiredRoles={['hr_bp']} notRequiredShow>
              <TableCellTrainingsShowStatus
                id={row.id}
                data={row}
                dataType="hr"
                justify="center"
              />
            </AuthResources>
          </>
        );
      },
    },
    {
      dataField: '',
      text: t('acceptedTrainingTable.trainingsDepartament'),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            <AuthResources requiredRoles={['training_department']}>
              <TableCellTrainingsStatusChange
                id={row.id}
                data={row}
                dataType="ds"
                justify="center"
                acceptedHandle={() => handleAcceptedDs(row.training.id)}
                verifyHandle={() => handleVerifyDs(row.training.id)}
                rejectedHandle={() => handleRejectedDs(row.training.id)}
              />
            </AuthResources>
            <AuthResources
              requiredRoles={['training_department']}
              notRequiredShow
            >
              <TableCellTrainingsShowStatus
                id={row.id}
                data={row}
                dataType="ds"
                justify="center"
              />
            </AuthResources>
          </>
        );
      },
    },
    {
      dataField: '',
      text: t('acceptedTrainingTable.sltDepartament'),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            <AuthResources requiredRoles={['slt_department']}>
              <TableCellTrainingsStatusChange
                id={row.id}
                data={row}
                dataType="slt"
                acceptedHandle={() => handleAcceptedSlt(row.training.id)}
                verifyHandle={() => handleVerifySlt(row.training.id)}
                rejectedHandle={() => handleRejectedSlt(row.training.id)}
              />
            </AuthResources>
            <AuthResources requiredRoles={['slt_department']} notRequiredShow>
              <TableCellTrainingsShowStatus
                id={row.id}
                data={row}
                dataType="slt"
                justify="center"
              />
            </AuthResources>
          </>
        );
      },
    },
  ];

  return (
    <>
      {modalShow ? (
        <ModalTrainingsShow changeModalShow={setModalShow} id={modalId} />
      ) : (
        ''
      )}

      {modalDecisionShow ? (
        <DecisionModal
          changeShowModal={setModalDecisionShow}
          onSaveAndExit={handleSaveAndExit}
        />
      ) : (
        ''
      )}

      <ContentBox id="userPlansTrainingsTableSelectedTrainings" noPadding>
        <>
          <LoaderTable active={loaderTable} noRadiusTop>
            <>
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              >
                <ShowTitleTable
                  id="titleTableTrainings"
                  label="Lista szkoleń pracownika:"
                />
              </TablePagination>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={data?.content || []}
                columns={columns}
                noDataIndication={t(
                  'acceptedTrainingTable.noDataTableSelected'
                )}
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </>
      </ContentBox>
    </>
  );
};

export default TableTrainingsBlock;
