import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import { IDPEmployeeModel, IDPModelAddAndPut } from 'api/types';
import IDPEmployeeBasicInformation from '../../Form/IDPEmployeeBasicInformation';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string; idRecord: string }>();

  const { IDPEmployee, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const handleEditTrainingsProvider = IDPRepository.usePutIDPEmployeeUser(
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPModelAddAndPut) => {
    if (!submitForm) {
      const postData = data;
      setSubmitForm(true);

      handleEditTrainingsProvider(postData);
    }
  };

  const handleCancel = () => {
    history.push(`/panel/tablica`);
  };

  return (
    <IDPEmployeeBasicInformation
      id={Number(id)}
      onTrainingsProvider={handleSubmit}
      submitForm={submitForm}
      data={IDPEmployee}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
