import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  TrainingsCompanyContactRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import {
  IDPEmployeeAddAndPut,
  IDPEmployeeModel,
  TrainingsGroupTraining,
  TrainingsGroupTrainingAddPut,
} from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  EditorMce,
  LoaderForm,
  ExitFromForm,
  CustomSelect,
  CustomContainer,
  CustomDate,
  CustomNumber,
  ContentBox,
  RenderSvg,
  CustomSwitch,
} from 'components';

import './IDPEmployeeBasicInformation.scss';

type DataValues = IDPEmployeeAddAndPut;

interface TrainingsGroupTrainingProps {
  id?: number;
  onTrainingsProvider?: any;
  onTrainingsProviderAndExit?: any;
  data?: IDPEmployeeModel | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormTrainingsProvider = (
  props: TrainingsGroupTrainingProps
): ReactElement => {
  const {
    id,
    onTrainingsProvider,
    edit,
    data,
    onlyView,
    onTrainingsProviderAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsGroup');

  const formSchema: yup.SchemaOf<DataValues> = yup.object().shape({
    shortTermGoal: yup.string().required('Pole wymagane'),
    longTermGoal: yup.string().required('Pole wymagane'),
    superiorComment: yup.string(),
    employeeComment: yup.string(),
    internationalMobility: yup.bool(),
    mobilityWithinCountry: yup.bool(),
  });

  const { handleSubmit, register, errors } = useForm<DataValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProvider(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProviderAndExit(dataSubmit);
  };

  return (
    <CustomContainer id="formIDPEmployeeBasicInformationBox">
      <>
        {data === undefined ? (
          <ErrorLoadData />
        ) : (
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="form"
              id="formIDPEmployeeBasicInformation"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={data === undefined && edit} type="getData">
                <>
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <ContentBox
                        id="formIDPEmployeeBasicInformationFirstRow"
                        bottomBorder
                      >
                        <div id="formIDPEmployeeBasicInformationFirstRowBox">
                          <div id="formIDPEmployeeBasicInformationFirstRowBoxIcon">
                            <RenderSvg nameSvg="peopleProgress" />
                          </div>
                          <div id="formIDPEmployeeBasicInformationFirstRowBoxInfo">
                            <div id="formIDPEmployeeBasicInformationFirstRowBoxInfoLabel">
                              Rozwój zawodowy pracownika
                            </div>
                            <div id="formIDPEmployeeBasicInformationFirstRowBoxInfoText">
                              W poniższych polach okreś oczekiwania krótko i
                              średnio/długookresowe w zakresie rozwoju
                              zawodowego.
                              <br />
                              Zostaną one rozważone w trakcie Przeglądu
                              Organizacji i Zasobów Ludzkich.
                            </div>
                          </div>
                        </div>
                      </ContentBox>
                      <ContentBox id="formIDPEmployeeBasicInformationSecondRow">
                        <>
                          <EditorMce
                            id="shortTermGoal"
                            name="shortTermGoal"
                            register={register}
                            contentGet={data?.shortTermGoal}
                            setChangeForm={setChangeForm}
                            label="Wpisz cele krótkookresowe:"
                            error={errors?.shortTermGoal?.message}
                            onlyView={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                          <EditorMce
                            id="longTermGoal"
                            name="longTermGoal"
                            register={register}
                            contentGet={data?.longTermGoal}
                            setChangeForm={setChangeForm}
                            label="Wpisz cele średnio i długookresowe:"
                            error={errors?.longTermGoal?.message}
                            onlyView={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                        </>
                      </ContentBox>
                      <Box
                        lightHeader
                        allowHideContent
                        name="Komentarze"
                        id="commentSuperiorAndEmployee"
                        svgIcon="comment"
                        noRadius
                      >
                        <>
                          <EditorMce
                            id="superiorComment"
                            name="superiorComment"
                            register={register}
                            contentGet={data?.superiorComment}
                            setChangeForm={setChangeForm}
                            label="Komentarz przełożonego (N+1):"
                            onlyView={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                          <EditorMce
                            id="employeeComment"
                            name="employeeComment"
                            register={register}
                            contentGet={data?.employeeComment}
                            setChangeForm={setChangeForm}
                            label="Komentarz pracownika:"
                            onlyView={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                        </>
                      </Box>
                      <Box
                        lightHeader
                        allowHideContent
                        name="Mobilności"
                        id="mobility"
                        svgIcon="mobility"
                        noRadius
                      >
                        <>
                          <CustomSwitch
                            name="mobilityWithinCountry"
                            id="mobilityWithinCountry"
                            register={register}
                            defaultValue={data?.mobilityWithinCountry}
                            label="Mobilność na terenie kraju:"
                            disabled={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                          <CustomSwitch
                            name="internationalMobility"
                            id="internationalMobility"
                            register={register}
                            defaultValue={data?.internationalMobility}
                            label="Mobilność międzynarodowa:"
                            disabled={
                              data?.interviewType === 'LAST_ANNUAL'
                                ? true
                                : undefined
                            }
                          />
                        </>
                      </Box>
                    </>
                  </LoaderForm>
                  {!onlyView && data?.interviewType !== 'LAST_ANNUAL' ? (
                    <CustomSubmit
                      save={
                        onTrainingsProvider
                          ? handleSubmit(handleSave)
                          : undefined
                      }
                      saveAndExit={
                        onTrainingsProviderAndExit
                          ? handleSubmit(handleSaveAndExit)
                          : undefined
                      }
                      submitForm={submitForm}
                      cancel={cancel}
                    />
                  ) : (
                    ''
                  )}
                </>
              </LoaderForm>
            </Form>
          </>
        )}
      </>
    </CustomContainer>
  );
};

export default FormTrainingsProvider;
