import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { CatalogsTrainingsRepository } from 'api/repository';
import { CatalogsTrainingsAddAndPut } from 'api/types';
import { Modal } from 'components';
import FormCatalogsTrainings from '../FormCatalogsTrainings';

interface CompetencesProps {
  id: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const CompetencesAdd = (props: CompetencesProps): ReactElement => {
  const { id, changeModalEdit, changeUpdateTableData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [catalog] = CatalogsTrainingsRepository.useGetCatalogTrainings({
    id: Number(id),
  });

  const postDataSave = CatalogsTrainingsRepository.usePutCatalogsTrainings(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: CatalogsTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = id;
    postDataSave(data);
  };

  const postDataSaveAndExit = CatalogsTrainingsRepository.usePutCatalogsTrainings(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContactAndExit = (
    dataPost: CatalogsTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = id;
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit} widthBox="medium">
      <div>
        <FormCatalogsTrainings
          onSave={handleAddTrainingsProviderContact}
          onSaveAndExit={handleAddTrainingsProviderContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          data={catalog}
          edit
        />
      </div>
    </Modal>
  );
};

export default CompetencesAdd;
