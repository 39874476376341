import { TrainingsProvider, Error } from 'api/types';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const usePutTrainingsProvider = (
  editId: number,
  onCompleted?: (data: TrainingsProvider) => void,
  onError?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsProvider) => Promise<void>) => {
  const axios = useAxios();

  const putTrainingsProvider = useCallback(
    async (trainingsProvider: TrainingsProvider) => {
      try {
        const rData = await axios.put<TrainingsProvider>(
          `${apiEndpoints.trainingsProvider}/providers/${editId}`,
          trainingsProvider
        );

        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompleted) onCompleted(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompleted, onError]
  );

  return putTrainingsProvider;
};

export default usePutTrainingsProvider;
