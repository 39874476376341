import useAddTrainingsProvider from './useAddTrainingsProvider';
import usePutTrainingsProvider from './usePutTrainingsProvider';
import useDeleteTrainingsProvider from './useDeleteTrainingsProvider';
import useGetAllTrainingsProvider from './useGetAllTrainingsProvider';
import useGetAllTrainingsProviderSimple from './useGetAllTrainingsProviderSimple';
import useGetTrainingsProvider from './useGetTrainingsProvider';

export default {
  useGetTrainingsProvider,
  useGetAllTrainingsProvider,
  useGetAllTrainingsProviderSimple,
  useAddTrainingsProvider,
  usePutTrainingsProvider,
  useDeleteTrainingsProvider,
};
