import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import { EmployeeAppraisalGoalRatingPut, EmployeeModel } from 'api/types';
import { Modal } from 'components';
import EmployeeAppraisalGoalRatingForm from '../../../Form/EmployeeAppraisalGoalRatingForm';

interface CompetencesProps {
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
  modalId: number;
  lastEdit?: boolean;
  employee?: null | EmployeeModel;
}
const EmployeeAppraisalGoalEdit = (props: CompetencesProps): ReactElement => {
  const {
    idEmployee,
    changeModalEdit,
    changeUpdateData,
    modalId,
    lastEdit,
    employee,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    EmployeeGoal,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalEmployeeGoal({
    idEmployee: Number(idEmployee),
    idGoal: modalId,
  });

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutEmployeeAppraisalEmployeeGoalRating(
    idEmployee,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (
    dataPost: EmployeeAppraisalGoalRatingPut
  ) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit} widthBox="high">
      <div>
        <EmployeeAppraisalGoalRatingForm
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          data={EmployeeGoal}
          lastEdit={lastEdit}
          employee={employee}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalEdit;
