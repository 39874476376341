import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
} from 'components';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal } from 'api/types';
import EmployeeAppraisalGoalAdd from './EmployeeAppraisalGoalAdd';
import EmployeeAppraisalGoalEdit from './EmployeeAppraisalGoalEdit';
import EmployeeAppraisalGoalList from './EmployeeAppraisalGoalList';
import EmployeeAppraisalGoalImport from './EmployeeAppraisalGoalmport';

interface Props {
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
  updateGoalUser?: (data: EmployeeAppraisalGoal) => void;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const { id } = useParams<{ id: string; idRecord: string }>();

  const { employeeAppraisalStatus, updateGoalUser } = props;

  const [addFormShow, setAddFormShow] = useState<boolean>(false);
  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [importFormShow, setImportFormShow] = useState<boolean>(false);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForUser({});

  useEffect(() => {
    setLoaderTable(true);

    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  const DeleteRecord = EmployeeAppraisalRepository.useDeleteUserGoal(
    async () => {
      setUpdateDataTable(Math.random);
    }
  );

  const handleDelete = (idClick: number) => {
    DeleteRecord(idClick);
  };

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="target60" />
                </div>
                <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfo">
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoLabel">
                    Cele roczne
                  </div>
                  <div id="EmployeeAppraisalGoalBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące celów pracownika
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="EmployeeAppraisalGoalBoxSecondRow">
            <>
              <div id="EmployeeAppraisalGoalBoxSecondRowFirstColumn">
                {employeeAppraisalStatus?.interviewType !== 'LAST_ANNUAL' &&
                employeeAppraisalStatus?.interviewType !== 'NONE' ? (
                  <>
                    <Button
                      id="EmployeeAppraisalGoalAddArea"
                      className="green "
                      onClick={() => {
                        setAddFormShow(true);
                      }}
                    >
                      Dodaj cel roczny
                    </Button>
                    <Button
                      id="EmployeeAppraisalGoalImportArea"
                      className="green"
                      onClick={() => {
                        setImportFormShow(true);
                      }}
                    >
                      Importuj cel roczny
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div id="EmployeeAppraisalGoalBoxSecondRowSecondColumn">
                <div id="countGoal">
                  <div id="countGoalLabel">Dodano celów:</div>
                  <div id="countGoalData">
                    {dataGoal?.content.length}
                    <span>/</span> 5
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content &&
              dataGoal?.content.length > 0 &&
              employeeAppraisalStatus ? (
                <>
                  <EmployeeAppraisalGoalList
                    dataGoal={dataGoal.content}
                    setEditFormShow={setEditFormShow}
                    setModalId={setModalId}
                    deleteAction={
                      employeeAppraisalStatus !== undefined &&
                      employeeAppraisalStatus?.interviewType !==
                        'LAST_ANNUAL' &&
                      employeeAppraisalStatus?.interviewType !== 'NONE'
                        ? handleDelete
                        : undefined
                    }
                  />
                </>
              ) : (
                <div id="noGoalInAppraisal">Brak przypisanych celów</div>
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {addFormShow ? (
        <EmployeeAppraisalGoalAdd
          changeModalAdd={setAddFormShow}
          changeUpdateData={setUpdateDataTable}
          changeModalEdit={setEditFormShow}
          changeModalId={setModalId}
        />
      ) : (
        ''
      )}
      {editFormShow ? (
        <EmployeeAppraisalGoalEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          modalId={modalId}
          employeeAppraisalStatus={employeeAppraisalStatus}
        />
      ) : (
        ''
      )}
      {importFormShow ? (
        <EmployeeAppraisalGoalImport
          changeModalImport={setImportFormShow}
          changeUpdateData={setUpdateDataTable}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
