/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './showTitleTable.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label: string;
}

const ShowTitleTable = (props: Props): ReactElement => {
  const { id, label } = props;

  return (
    <div id={id} className="showTitleTable">
      {label}
    </div>
  );
};

export default ShowTitleTable;
