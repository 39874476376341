import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
  ShowAppraisalGoal,
} from 'components';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal, EmployeeModel } from 'api/types';
import EmployeeAppraisalGoalRatingEdit from './EmployeeAppraisalGoalRatingEdit';
import EmployeeAppraisalGoalRatingList from './EmployeeAppraisalGoalRatingList';
import EmployeeAppraisalGoalEmployeeRating from './EmployeeAppraisalGoalEmployeeRating';
import EmployeeAppraisalGoalManagerRating from './EmployeeAppraisalGoalManagerRating';

interface Props {
  goalUser?: EmployeeAppraisalGoal;
  fetchData?: any;
  employee?: null | EmployeeModel;
  updateGoalUser: (data: EmployeeAppraisalGoal) => void;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const { idEmployee } = useParams<{ idEmployee: string }>();
  const history = useHistory();

  const { goalUser, fetchData, employee, updateGoalUser } = props;

  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [employeeRatingModal, setEmployeeRatingModal] = useState<boolean>(
    false
  );
  const [
    employeeRatingEditModal,
    setEmployeeRatingEditModal,
  ] = useState<boolean>(false);
  const [managerRatingModal, setManagerRatingModal] = useState<boolean>(false);
  const [managerRatingEditModal, setManagerRatingEditModal] = useState<boolean>(
    false
  );

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForEmployee({
    idEmployee: Number(idEmployee),
  });

  useEffect(() => {
    setLoaderTable(true);

    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  const handleApproveClick = EmployeeAppraisalRepository.usePutEmployeeAppraisalClose(
    Number(idEmployee),
    (dataPost) => {
      updateGoalUser(dataPost);
      history.push(
        `/kierownik/ocena-pracownika/pracownik/${idEmployee}/cele-roczne`
      );
    }
  );

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalRatingBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalRatingBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalRatingBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowFirstColumnBox">
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxIcon">
                    <RenderSvg nameSvg="ratingGoal60" />
                  </div>
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfo">
                    <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoLabel">
                      Ocena celów
                    </div>
                    <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoText">
                      Dokonaj oceny pracownika
                    </div>
                  </div>
                </div>
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnBox">
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingEmployeeBox"
                    className="ratingGoal"
                  >
                    <div id="RatingEmployeeLabel" className="label">
                      Ogólna ocena pracy:
                    </div>
                    <div id="RatingEmployeeData" className="data">
                      {goalUser?.selfAppraisal === null ? (
                        <>
                          <Button
                            onClick={() => {
                              setEmployeeRatingModal(true);
                            }}
                            className="green"
                          >
                            Dodaj ocenę
                          </Button>
                          {employeeRatingModal ? (
                            <EmployeeAppraisalGoalEmployeeRating
                              changeModal={setEmployeeRatingModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(idEmployee)}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <div id="RatingEmployeeDataEdit" className="dataEdit">
                          <EditAction
                            editClick={() => {
                              setEmployeeRatingEditModal(true);
                            }}
                          />
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRACOWNIKA"
                            appraisal={goalUser?.selfAppraisal}
                          />
                          {employeeRatingEditModal ? (
                            <EmployeeAppraisalGoalEmployeeRating
                              changeModal={setEmployeeRatingEditModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(idEmployee)}
                              edit
                              data={goalUser?.selfAppraisal}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerBox"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerLabel" className="label">
                      Ogólna ocena pracy N+1:
                    </div>
                    <div id="RatingManagerData" className="data">
                      {goalUser?.superiorAppraisal === null ? (
                        <>
                          <Button
                            onClick={() => setManagerRatingModal(true)}
                            className="green"
                          >
                            Dodaj ocenę
                          </Button>
                          {managerRatingModal ? (
                            <EmployeeAppraisalGoalManagerRating
                              changeModal={setManagerRatingModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(idEmployee)}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <div id="RatingManagerDataEdit" className="dataEdit">
                          <EditAction
                            editClick={() => {
                              setManagerRatingEditModal(true);
                            }}
                          />
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRZEZ MANAGERA"
                            appraisal={goalUser?.superiorAppraisal}
                          />
                          {managerRatingEditModal ? (
                            <EmployeeAppraisalGoalManagerRating
                              changeModal={setManagerRatingEditModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(idEmployee)}
                              edit
                              data={goalUser?.superiorAppraisal}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerN2Box"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerN2Label" className="label">
                      Finalna ocena pracy:
                    </div>
                    <div id="RatingManagerN2Data" className="data">
                      {goalUser?.finalAppraisal === null ? (
                        <>---</>
                      ) : (
                        <ShowAppraisalGoal
                          id="employee"
                          title="KOMENTARZ DO OCENY FINALEJ"
                          appraisal={goalUser?.finalAppraisal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content && dataGoal?.content.length > 0 ? (
                <EmployeeAppraisalGoalRatingList
                  dataGoal={dataGoal.content}
                  setEditFormShow={setEditFormShow}
                  setModalId={setModalId}
                />
              ) : (
                ''
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {goalUser?.interviewType === 'LAST_ANNUAL' &&
      goalUser.superiorAppraisal !== null ? (
        <ContentBox id="approveIDP">
          <>
            <Button className="green" onClick={() => setShowModalYesNo(true)}>
              ZATWIERDŹ OCENĘ PRACOWNIKA
            </Button>
            {showModalYesNo ? (
              <ModalYesNo
                changeModalShow={setShowModalYesNo}
                yesAction={() => handleApproveClick()}
                noAction={() => setShowModalYesNo(false)}
                title="ZATWIERDŹ OCENĘ PRACOWNIKA"
                additionalText="Czy chcesz zatwierdzić ocenę pracownika? Po zatwierdzeniu przekierujemy Cię do formularza gdzie będziesz mógł wyznaczyć nowe cele"
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {editFormShow ? (
        <EmployeeAppraisalGoalRatingEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(idEmployee)}
          modalId={modalId}
          employee={employee}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
