import React, { ReactElement } from 'react';
import './Action.scss';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';

/*
TODO
Toggle TableRecordSwitch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  showClick: () => void;
}

const ShowAction = (props: Props): ReactElement => {
  const { showClick } = props;
  const { t } = useTranslation('componentTableRecordAction');

  return (
    <>
      <div
        className="buttonAction"
        onClick={() => showClick()}
        role="button"
        tabIndex={0}
        onKeyDown={() => showClick()}
      >
        <RenderSvg nameSvg="showAction" />
      </div>
    </>
  );
};

export default ShowAction;
