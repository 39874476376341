import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import { IDPEmployeeModel, IDPModelAddAndPut } from 'api/types';
import IDPEmployeeBasicInformation from '../../Form/IDPEmployeeBasicInformation';

interface Props {
  IDPEmployee: IDPEmployeeModel;
  fetchData?: any;
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { IDPEmployee, fetchData, idEmployee, changeModalEdit } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const handleEditTrainingsProvider = IDPRepository.usePutIDPEmployeeAdmin(
    idEmployee,
    IDPEmployee.id,
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleEditTrainingsProviderAndExit = IDPRepository.usePutIDPEmployeeAdmin(
    idEmployee,
    IDPEmployee.id,
    async () => {
      setSubmitForm(false);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPModelAddAndPut) => {
    if (!submitForm) {
      const postData = data;
      setSubmitForm(true);

      handleEditTrainingsProvider(postData);
    }
  };

  const handleSubmitSaveAndExit = (data: IDPModelAddAndPut) => {
    if (!submitForm) {
      setSubmitForm(true);
      const postData = data;

      handleEditTrainingsProviderAndExit(postData);
    }
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <IDPEmployeeBasicInformation
      id={idEmployee}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={handleSubmitSaveAndExit}
      submitForm={submitForm}
      data={IDPEmployee}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
