import React, { ReactElement, useEffect, useState } from 'react';

import { IDPRepository } from 'api/repository';
import { CustomContainer, LoaderTable } from 'components';
import './IDPEmployeeArea.scss';

import IDPEmployeeAreaList from './IDPEmployeeAreaList';

interface Props {
  idIdp: number;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { idIdp } = props;
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [updateData, setUpdateData] = useState<number>(0);

  const [getData, data] = IDPRepository.useGetAllIDPEmployeeAreaUserFromIdp({
    idIdp,
  });

  useEffect(() => {
    setLoaderTable(true);

    getData();
  }, [getData, updateData]);

  useEffect(() => {
    if (data !== undefined) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <CustomContainer id="IDPEmployeeAreaBox">
        <>
          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {data?.content && data?.content.length > 0 ? (
                <IDPEmployeeAreaList data={data} />
              ) : (
                <div className="noData">Brak obszarów rozwoju</div>
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
    </>
  );
};

export default TrainingsProviderAddPage;
