import React, { ReactElement } from 'react';
import { RoutedContent } from 'routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from 'layout';
import { useKeycloak } from '@react-keycloak/web';
import { useStoreRehydrated } from 'easy-peasy';
// import { checkSupport, requestNotificationPermission } from 'utils';

import 'react-toastify/dist/ReactToastify.css';
import SplashScreenProvider from 'components/SplashScreen/SplashScreen';

const App = (): ReactElement => {
  const isRehydrated = useStoreRehydrated();

  const { initialized, keycloak } = useKeycloak();

  /* useEffect(() => {
    if (!checkSupport()) return;
    requestNotificationPermission();
  }, []); */

  if (initialized && keycloak.authenticated) {
    keycloak.updateToken(305);
  }

  /* keycloak.onTokenExpired = () => {
    keycloak.updateToken(4);
  }; */

  return (
    <>
      {initialized ? (
        <>
          <ToastContainer />
          <BrowserRouter>
            <Layout>
              <>
                <RoutedContent />
              </>
            </Layout>
          </BrowserRouter>
        </>
      ) : (
        <SplashScreenProvider />
      )}
    </>
  );
};

export default App;
