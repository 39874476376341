import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  LoaderTable,
  ShowTextarea,
  TabsHook,
} from 'components';
import {
  EmployeeAppraisalRepository,
  EmployeeRepository,
} from 'api/repository';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalPutSpecificGoal } from 'api/types';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';

import EmployeeAppraisalEmployeeGoal from './EmployeeAppraisalEmployeeGoal';
import SpecificGoalEditModal from './SpecificGoalEditModal';
import EmployeeAppraisalEmployeeGoalRating from './EmployeeAppraisalEmployeeGoalRating';
import EmployeeAppraisalFeedback from './EmployeeAppraisalFeedback';

import './employeeAppraisalDetail.scss';

interface Props {
  idEvaluation: number;
  idEmployee: number;
  selectedYear?: string;
  employeeName?: string;
}

const EmployeeAppraisalDetail = (props: Props): ReactElement => {
  const { idEvaluation, idEmployee, selectedYear, employeeName } = props;

  const [
    getGoalUser,
    updateGoalUser,
    fetchGoalUser,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalAdmin(
    idEmployee,
    idEvaluation
  );

  const nameBox = () => {
    return `EDYCJA OCENY PRACOWNIKA - ${selectedYear}`;
  };

  const [
    dataSettings,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});

  const [selectTab, setSelectTab] = useState<string>('goals');

  return (
    <>
      <CustomContainer>
        <LoaderTable active={getGoalUser === undefined}>
          <Box
            id="EmployeeAppraisalBox"
            name={getGoalUser === null ? 'DODAWANIE IDP' : nameBox()}
            svgIcon="addEdit"
            noPadding
          >
            <>
              <div id="basicInformation">
                <div id="firstRowBasicInformation">
                  <div id="firstColumn">
                    <div id="labelEmployee">Fromularz wprowadzania dla:</div>
                    <div id="dataEmployee">{employeeName}</div>
                  </div>
                </div>
              </div>
              {getGoalUser === null ? (
                <div id="EmployeeAppraisalContentBoxEmpty">
                  Nie ma Oceny pracownika
                </div>
              ) : (
                <>
                  <TabsHook
                    id="EmployeeAppraisalTabs"
                    activeTab={selectTab}
                    setActiveTab={setSelectTab}
                    items={[
                      {
                        id: 'goals',
                        label: 'Cele roczne',
                        svgName: 'target',
                      },
                      {
                        id: 'ocena-celow',
                        label: 'Ocena celów',
                        svgName: 'appraisalGoal',
                      },
                      {
                        id: 'feedback',
                        label: 'Feedback 180',
                        svgName: 'feedback',
                      },
                    ]}
                  />
                  <div id="EmployeeAppraisalContentBox">
                    {selectTab === 'goals' ? (
                      <EmployeeAppraisalEmployeeGoal
                        employeeAppraisalStatus={getGoalUser}
                        updateGoalUser={updateGoalUser}
                        idEvaluation={idEvaluation}
                        idEmployee={idEmployee}
                        settings={dataSettings}
                      />
                    ) : (
                      ''
                    )}
                    {selectTab === 'ocena-celow' ? (
                      <EmployeeAppraisalEmployeeGoalRating
                        idEvaluation={idEvaluation}
                        idEmployee={idEmployee}
                        goalUser={getGoalUser}
                        employeeName={employeeName}
                        updateGoalUser={updateGoalUser}
                        fetchData={fetchGoalUser}
                      />
                    ) : (
                      ''
                    )}
                    {selectTab === 'feedback' ? (
                      <EmployeeAppraisalFeedback
                        idEvaluation={idEvaluation}
                        idEmployee={idEmployee}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </Box>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EmployeeAppraisalDetail;
