import {
  EmployeeAppraisalRepository,
  IDPRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import { AuthResources, Box, CustomContainer, DesktopItem } from 'components';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import './panel.scss';

const Panel = (): ReactElement => {
  const history = useHistory();

  /// TRAINING START
  const [statusTrainingPlan] = TrainingsGroupRepository.useGetCurrentPlanTest(
    {}
  );

  const checkStatusTraining = () => {
    if (statusTrainingPlan === undefined) {
      return 'loading';
    }
    if (statusTrainingPlan === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const startDate = new Date(statusTrainingPlan.startDate);
    startDate.setHours(0, 0);
    const endDate = new Date(statusTrainingPlan.endDate);
    endDate.setHours(23, 59);
    const registrationStartDate = new Date(
      statusTrainingPlan.registrationStartDate
    );
    registrationStartDate.setHours(0, 0);
    const registrationEndDate = new Date(
      statusTrainingPlan.registrationEndDate
    );
    endDate.setHours(23, 59);
    const verificationDate = new Date(statusTrainingPlan.verificationDate);
    verificationDate.setHours(0, 0);

    if (statusTrainingPlan !== null && (startDate > now || endDate < now)) {
      return 'noActivePlan';
    }
    if (statusTrainingPlan !== null && registrationStartDate > now) {
      return 'waitingForRegistration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationStartDate <= now &&
      registrationEndDate >= now
    ) {
      return 'registration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationEndDate < now &&
      verificationDate >= now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'verification';
    }
    if (
      statusTrainingPlan !== null &&
      verificationDate < now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'waitForTrainingGroup';
    }
    if (
      statusTrainingPlan !== null &&
      statusTrainingPlan.groupNotificationTime !== null
    ) {
      return 'trainingGroup';
    }
    return null;
  };

  const setButtonTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'registration') {
      return {
        text: 'Zarządzaj pracownikami',
        action: () => {
          history.push('/kierownik/szkolenia/plan');
        },
      };
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return {
        text: 'Zarządzaj pracownikami',
        action: () => {
          history.push('/kierownik/szkolenia/grupy-szkoleniowe');
        },
      };
    }
    return undefined;
  };
  const setContentTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie zapisów na szkolenia. O ich rozpoczęciu
            powiadomimy Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'registration') {
      return (
        <>
          <div>
            Zapisy na szkolenia są aktywne, możesz zarządzać szkoleniami swoich
            pracowników.
          </div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.registrationEndDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'verification') {
      return (
        <>
          <div>
            Trwa proces zatwierdzania szkoleń oraz przygotowywania grup
            szkoleniowych.
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return (
        <>
          <div>Oczekiwanie na utworznie grup szkoleniowych</div>
        </>
      );
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return (
        <>
          <div>Możesz zarządzać grupami szkoleniowymi swoich pracowników</div>
          <div>Pamiętaj że decyduje kolejność zgłoszeń</div>
        </>
      );
    }

    return null;
  };

  /// TRAINING END

  /// IDP START
  const [statusIDP] = IDPRepository.useGetIDPSettings({});
  const checkStatusIDP = () => {
    if (statusIDP === undefined) {
      return 'loading';
    }
    if (statusIDP === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const annualInterviewStart = new Date(statusIDP.annualInterviewStart);
    annualInterviewStart.setHours(0, 0);
    const annualInterviewEnd = new Date(statusIDP.annualInterviewEnd);
    annualInterviewEnd.setHours(23, 59);
    const sixMonthInterviewStart = new Date(statusIDP.sixMonthInterviewStart);
    sixMonthInterviewStart.setHours(0, 0);
    const sixMonthInterviewEnd = new Date(statusIDP.sixMonthInterviewEnd);
    sixMonthInterviewEnd.setHours(23, 59);
    const nextAnnualInterviewStart = new Date(
      statusIDP.nextAnnualInterviewStart
    );
    nextAnnualInterviewStart.setHours(0, 0);
    const nextAnnualInterviewEnd = new Date(statusIDP.nextAnnualInterviewEnd);
    nextAnnualInterviewEnd.setHours(23, 59);

    if (statusIDP !== null && annualInterviewStart > now) {
      return 'waitingForRegistration';
    }
    if (
      statusIDP !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now
    ) {
      return 'registration';
    }
    if (
      statusIDP !== null &&
      annualInterviewEnd < now &&
      sixMonthInterviewStart > now
    ) {
      return 'waitingForSixMonth';
    }
    if (
      statusIDP !== null &&
      sixMonthInterviewStart <= now &&
      sixMonthInterviewEnd >= now
    ) {
      return 'sixMonth';
    }
    if (
      statusIDP !== null &&
      sixMonthInterviewEnd < now &&
      now < nextAnnualInterviewStart
    ) {
      return 'waitingForNextAnnual';
    }

    return null;
  };

  const setButtonIDP = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }

    return {
      text: 'Zarządzaj pracownikami',
      action: () => {
        history.push('/kierownik/IDP/pracownicy');
      },
    };

    return undefined;
  };
  const setContentIDP = () => {
    if (checkStatusIDP() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusIDP() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie planu IDP. O jego rozpoczęciu powiadomimy
            Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusIDP() === 'registration') {
      return (
        <>
          <div>
            Aktywny proces rozmów rocznych, możesz zarządzac swoimi pracownikami
          </div>
          <div>masz czas do</div>
          <div className="dataGreen">{statusIDP?.annualInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForSixMonth') {
      return (
        <>
          <div>Rozmowy półroczne zaczną się</div>
          <div className="dataGreen">{statusIDP?.sixMonthInterviewStart}</div>
          <div>
            W każdej chwili możesz podejrzeć plan IDP swoich pracowników
          </div>
        </>
      );
    }
    if (checkStatusIDP() === 'sixMonth') {
      return (
        <>
          <div>
            Trwa proces rozmów półrocznych jego koniec planowany jest na
          </div>
          <div className="dataGreen">{statusIDP?.sixMonthInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusIDP() === 'waitingForNextAnnual') {
      return (
        <>
          <div>Rozmowy roczne zaczną się</div>
          <div className="dataGreen">{statusIDP?.nextAnnualInterviewStart}</div>
          <div>W każdej chwili możesz podejrzeć swój plan IDP</div>
        </>
      );
    }

    return null;
  };

  /// IDP END
  /// Appraisal START
  const [
    statusAppraisal,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});
  const checkStatusAppraisal = () => {
    if (statusAppraisal === undefined) {
      return 'loading';
    }
    if (statusAppraisal === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const annualInterviewStart = new Date(statusAppraisal.annualInterviewStart);
    annualInterviewStart.setHours(0, 0);
    const annualInterviewEnd = new Date(statusAppraisal.annualInterviewEnd);
    annualInterviewEnd.setHours(23, 59);
    const sixMonthInterviewStart = new Date(
      statusAppraisal.sixMonthInterviewStart
    );
    sixMonthInterviewStart.setHours(0, 0);
    const sixMonthInterviewEnd = new Date(statusAppraisal.sixMonthInterviewEnd);
    sixMonthInterviewEnd.setHours(23, 59);
    const nextAnnualInterviewStart = new Date(
      statusAppraisal.nextAnnualInterviewStart
    );
    nextAnnualInterviewStart.setHours(0, 0);
    const nextAnnualInterviewEnd = new Date(
      statusAppraisal.nextAnnualInterviewEnd
    );
    nextAnnualInterviewEnd.setHours(23, 59);

    if (statusAppraisal !== null && annualInterviewStart > now) {
      return 'waitingForRegistration';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewStart <= now &&
      annualInterviewEnd >= now
    ) {
      return 'registration';
    }
    if (
      statusAppraisal !== null &&
      annualInterviewEnd < now &&
      sixMonthInterviewStart > now
    ) {
      return 'waitingForSixMonth';
    }
    if (
      statusAppraisal !== null &&
      sixMonthInterviewStart <= now &&
      sixMonthInterviewEnd >= now
    ) {
      return 'sixMonth';
    }
    if (
      statusAppraisal !== null &&
      sixMonthInterviewEnd < now &&
      now < nextAnnualInterviewStart
    ) {
      return 'waitingForNextAnnual';
    }

    return null;
  };

  const setButtonAppraisal = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }

    return {
      text: 'Zarządzaj pracownikami',
      action: () => {
        history.push('/kierownik/ocena-pracownika/pracownik');
      },
    };

    return undefined;
  };
  const setContentAppraisal = () => {
    if (checkStatusAppraisal() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusAppraisal() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu szkoleń</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie planu IDP. O jego rozpoczęciu powiadomimy
            Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'registration') {
      return (
        <>
          <div>
            Aktywny proces rozmów rocznych, możesz zarządzać swoimi
            pracownikami.
          </div>
          <div>masz czas do</div>
          <div className="dataGreen">{statusAppraisal?.annualInterviewEnd}</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForSixMonth') {
      return (
        <>
          <div>Rozmowy półroczne zaczną się</div>
          <div className="dataGreen">
            {statusAppraisal?.sixMonthInterviewStart}
          </div>
          <div>W każdej chwili możesz podejrzeć plan swoich pracowników</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'sixMonth') {
      return (
        <>
          <div>
            Trwa proces rozmów półrocznych jego koniec planowany jest na
          </div>
          <div className="dataGreen">
            {statusAppraisal?.sixMonthInterviewEnd}
          </div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'waitingForNextAnnual') {
      return (
        <>
          <div>Rozmowy roczne zaczną się</div>
          <div className="dataGreen">
            {statusAppraisal?.nextAnnualInterviewStart}
          </div>
          <div>W każdej chwili możesz podejrzeć plan swoich pracowników</div>
        </>
      );
    }

    return null;
  };

  /// Appraisal END

  return (
    <CustomContainer allowGrow>
      <>
        <Box
          id="managerDesktop"
          name="PULPIT KIEROWNIKA"
          svgIcon="desktop"
          noContentStyle
        >
          <div id="managerDesktopBox">
            <AuthResources requiredRoles={['manager']}>
              <div id="managerDesktopBoxContent">
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA"
                  buttonTitle={setButtonTraining()?.text}
                  buttonAction={setButtonTraining()?.action}
                >
                  <>{setContentTraining()}</>
                </DesktopItem>
                <DesktopItem
                  id="idp"
                  name="IDP"
                  buttonTitle={setButtonIDP()?.text}
                  buttonAction={setButtonIDP()?.action}
                >
                  <>{setContentIDP()}</>
                </DesktopItem>
                <DesktopItem
                  id="appraisal"
                  name="CELE ROCZNE"
                  buttonTitle={setButtonAppraisal()?.text}
                  buttonAction={setButtonAppraisal()?.action}
                >
                  <>{setContentAppraisal()}</>
                </DesktopItem>
              </div>
            </AuthResources>
          </div>
        </Box>
      </>
    </CustomContainer>
  );
};

export default Panel;
