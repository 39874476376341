import React, { ReactElement } from 'react';
import TopMenu from './TopMenu';
import Content from './Content';

import './Block.scss';

interface Props {
  children?: ReactElement;
}

export default (props: Props): ReactElement => {
  const { children } = props;

  return (
    <div className="root">
      <>
        <TopMenu />
        <Content>{children}</Content>
      </>
    </div>
  );
};
