import React, { ReactElement } from 'react';
import { CustomContainer } from 'components';
import Trainings from './Trainings';
import Competence from './Competences';
import './trainingsAndCompetence.scss';

const Trainigns = (): ReactElement => {
  return (
    <CustomContainer id="TrainingsAndCompetenceTables" column>
      <>
        <div id="trainingsBoxWrap">
          <Trainings />
        </div>
        <div id="competencesBoxWrap">
          <Competence />
        </div>
      </>
    </CustomContainer>
  );
};

export default Trainigns;
