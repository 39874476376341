import React, { ReactElement, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './CustomSubmit.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  save?: any;
  saveAndExit?: any;
  cancel?: any;
  submitForm?: boolean;
  saveAndExitWithoutSubmit?: any;
}

const CustomSubmit = (props: SwitchProps): ReactElement => {
  const {
    save,
    saveAndExit,
    cancel,
    submitForm,
    saveAndExitWithoutSubmit,
  } = props;
  const { t } = useTranslation('form');

  return (
    <div className="submitBlock">
      {save !== undefined ? (
        <Button
          className="green"
          type="submit"
          name="save"
          onClick={save}
          disabled={submitForm}
        >
          <>{t('submit.save')}</>
        </Button>
      ) : (
        ''
      )}

      {saveAndExit !== undefined ? (
        <Button
          className="green"
          type="submit"
          name="saveAndExit"
          onClick={saveAndExit}
          disabled={submitForm}
        >
          <>{t('submit.saveAndExit')}</>
        </Button>
      ) : (
        ''
      )}
      {saveAndExitWithoutSubmit !== undefined ? (
        <Button
          className="green"
          name="saveAndExitWithoutSubmit"
          onClick={saveAndExitWithoutSubmit}
          disabled={submitForm}
        >
          <>{t('submit.saveAndExit')}</>
        </Button>
      ) : (
        ''
      )}

      {cancel !== undefined ? (
        <Button
          className="red"
          type="button"
          name="cancel"
          onClick={cancel}
          disabled={submitForm}
        >
          {t('submit.cancel')}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

export default CustomSubmit;
