import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './CustomDate.scss';
import { RenderSvg } from 'components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Portal } from 'react-overlays';
import { useStoreState } from 'store/userSettings';
import pl from 'date-fns/locale/pl';

import './react-datepicker.css';

/*
Date Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  name: string;
  label?: string;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  error?: string | undefined;
  defaultValue?: string | undefined;
  info?: string;
  fullWidth?: boolean;
  setValue?: any;
  inLine?: boolean;
  growInput?: boolean;
  toLeft?: boolean;
  noPaddingBlock?: boolean;
  smallPaddingBlock?: boolean;
  noInfo?: boolean;
  textAlign?: 'toLeft' | 'toRight' | 'center';
  validRequiredDate?: () => void;
}

interface PropsContainer {
  children: ReactElement;
}

const CustomDate = (props: SwitchProps): ReactElement => {
  const {
    name,
    label,
    required,
    id,
    disabled,
    error,
    defaultValue,
    info,
    fullWidth,
    setValue,
    inLine,
    growInput,
    toLeft,
    noPaddingBlock,
    smallPaddingBlock,
    noInfo,
    textAlign,
    validRequiredDate,
  } = props;

  const isDarkTheme = useStoreState((state) => state.userSettings.isDarkTheme);

  registerLocale('pl', pl);

  const CalendarContainer = (propsContainer: PropsContainer) => {
    const { children } = propsContainer;
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{children}</Portal>;
  };

  const getData = () => {
    if (defaultValue) {
      return new Date(defaultValue);
    }
    return null;
  };

  const [dateValue, setDateValue] = useState<any>(getData());

  useEffect(() => {
    if (
      defaultValue !== undefined &&
      defaultValue !== null &&
      dateValue === null
    ) {
      setDateValue(new Date(defaultValue));
    }
  }, [dateValue, defaultValue]);

  function convertDate(date) {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString();
    const dd = date.getDate().toString();

    const mmChars = mm.split('');
    const ddChars = dd.split('');

    return `${yyyy}-${mmChars[1] ? mm : `0${mmChars[0]}`}-${
      ddChars[1] ? dd : `0${ddChars[0]}`
    }`;
  }

  useEffect(() => {
    if (setValue) {
      if (dateValue === null) {
        setValue(name, '');
      } else {
        setValue(name, convertDate(dateValue));
        if (error && required && validRequiredDate) {
          validRequiredDate();
        }
      }
    }
  }, [dateValue, name, setValue]);

  const handleChange = (d: any) => {
    setDateValue(d);
  };

  const idElement = () => {
    let tId = 'id=';
    if (typeof { id } === 'string') {
      tId += { id };
    } else {
      tId += { name };
    }
    return tId;
  };

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let tclass = 'blockDateWithError';
    if (required) {
      tclass += ' required';
    }
    if (typeof error !== 'undefined') {
      tclass += ' error';
    }
    if (typeof info !== 'undefined' && !disabled) {
      tclass += ' withInfo';
    }
    if (disabled) {
      tclass += ' disabled';
    }
    if (fullWidth) {
      tclass += ' fullWidth';
    }
    if (error) {
      tclass += ' error';
    }
    if (inLine) {
      tclass += ' inLine';
    }
    if (growInput) {
      tclass += ' growInput';
    }
    if (toLeft) {
      tclass += ' toLeft';
    }
    if (noPaddingBlock) {
      tclass += ' noPaddingBlock';
    }
    if (smallPaddingBlock) {
      tclass += ' smallPaddingBlock';
    }
    if (noInfo) {
      tclass += ' noInfo';
    }

    if (textAlign) {
      tclass += ` ${textAlign}`;
    }

    return tclass;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  return (
    <>
      <div id={`${name}BlockDate`} className={additionalClass()}>
        <div className="blockDate">
          {label ? <Form.Label id={name}>{label}</Form.Label> : ''}
          <div className="blockDateWithInfo">
            <DatePicker
              id={name}
              disabled={disabled}
              maxLength={10}
              selected={dateValue}
              onChange={(d: string) => handleChange(d)}
              dateFormat="yyyy-MM-dd"
              placeholder="rrrr-mm-dd"
              showMonthDropdown
              showYearDropdown
              locale="pl"
              popperContainer={CalendarContainer}
              popperClassName={isDarkTheme ? 'darkDate' : undefined}
            />
            {infoElement()}
          </div>
        </div>
        <div className="additionalInfo">{errorElement()}</div>
      </div>
    </>
  );
};

export default CustomDate;
