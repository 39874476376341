import React, { ReactElement, SetStateAction, Dispatch, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Box,
  CustomContainer,
  ExitFromForm,
  CustomSubmit,
  EditorMce,
  LoaderForm,
  ShowTextarea,
} from 'components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeAppraisalPutSubordinatesGoal } from 'api/types';
import './goalEditModal.scss';

type FormModel = EmployeeAppraisalPutSubordinatesGoal;

interface Props {
  changeShowModal: Dispatch<SetStateAction<boolean>>;
  onSaveAndExit?: any;
  submitForm?: boolean;
  data?: string | null;
  superiorGoal?: string | null;
}

const GoalEditModal = (props: Props): ReactElement => {
  const {
    changeShowModal,
    onSaveAndExit,
    submitForm,
    data,
    superiorGoal,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const formSchema: yup.SchemaOf<FormModel> = yup.object().shape({
    subordinatesGoal: yup.string(),
  });

  const handleSaveAndExit = (dataSubmit: FormModel) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const { handleSubmit, register, errors } = useForm<FormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <Modal
      open
      changeModalShow={changeShowModal}
      maxLevel="lvl2"
      widthBox="medium"
    >
      <Box
        id="formEmployeeAppraisalSubordinatesGoalBox"
        name={
          data !== null && data !== undefined
            ? 'EDYCJA CELU DLA WSZYSTKICH PODWŁADNYCH'
            : 'DODAWANIE CELU DLA WSZYSTKICH PODWŁADNYCH'
        }
        noMarginBottom
        noShadow
        svgIcon="addEdit"
        noPadding
      >
        <CustomContainer>
          <>
            {superiorGoal !== null && superiorGoal !== undefined ? (
              <div id="formEmployeeAppraisalSubordinatesGoalBasicInfo">
                <div id="goalFromTheSupervisorLabel">
                  Cel od Twojego przełożonego:
                </div>
                <ShowTextarea
                  id="goalFromTheSupervisorData"
                  data={superiorGoal}
                />
              </div>
            ) : (
              ''
            )}
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="formEmployeeAppraisalSubordinatesGoal"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Row className="justify-content-md-center">
                    <Col>
                      <EditorMce
                        id="subordinatesGoal"
                        name="subordinatesGoal"
                        register={register}
                        contentGet={data || ''}
                        error={errors?.subordinatesGoal?.message}
                        setChangeForm={setChangeForm}
                        placeholder="Wpisz cel dla swoich pracowników"
                        label="Cel dla Twoich podwładnych:"
                      />
                    </Col>
                  </Row>
                </>
              </LoaderForm>

              <CustomSubmit
                saveAndExit={handleSubmit(handleSaveAndExit)}
                submitForm={submitForm}
                cancel={() => changeShowModal(false)}
              />
            </Form>
          </>
        </CustomContainer>
      </Box>
    </Modal>
  );
};

export default GoalEditModal;
