import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  TrainingsCompanyContactRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import {
  IDPEmployeeAddAndPut,
  IDPEmployeeModel,
  IDPFeedbackForm,
  IDPFeedbackModel,
  TrainingsGroupTraining,
  TrainingsGroupTrainingAddPut,
} from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  CustomContainer,
  ContentBox,
  RenderSvg,
  EditorMceShort,
} from 'components';

import './IDPEmployeeFeedback.scss';

type DataValues = IDPFeedbackForm;

interface TrainingsGroupTrainingProps {
  id?: number;
  onTrainingsProvider?: any;
  onTrainingsProviderAndExit?: any;
  data?: IDPFeedbackModel | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormTrainingsProvider = (
  props: TrainingsGroupTrainingProps
): ReactElement => {
  const {
    id,
    onTrainingsProvider,
    edit,
    data,
    onlyView,
    onTrainingsProviderAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsGroup');

  const formSchema: yup.SchemaOf<DataValues> = yup.object().shape({
    honestyConsistentBehaviour: yup.string().required('Pole wymagane'),
    honestyInconsistentBehaviour: yup.string().required('Pole wymagane'),
    respectConsistentBehaviour: yup.string().required('Pole wymagane'),
    respectInconsistentBehaviour: yup.string().required('Pole wymagane'),
    responsibilityConsistentBehaviour: yup.string().required('Pole wymagane'),
    responsibilityInconsistentBehaviour: yup.string().required('Pole wymagane'),
    cooperationConsistentBehaviour: yup.string().required('Pole wymagane'),
    cooperationInconsistentBehaviour: yup.string().required('Pole wymagane'),
  });

  const { handleSubmit, register, errors } = useForm<DataValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProvider(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: DataValues) => {
    setChangeForm(false);
    onTrainingsProviderAndExit(dataSubmit);
  };

  return (
    <CustomContainer id="formIDPEmployeeFeedbackBox">
      <>
        {data === undefined ? (
          <ErrorLoadData />
        ) : (
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="form"
              id="formIDPEmployeeFeedback"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={data === undefined && edit} type="getData">
                <>
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <ContentBox
                        id="formIDPEmployeeFeedbackFirstRow"
                        bottomBorder
                      >
                        <div id="formIDPEmployeeFeedbackFirstRowBox">
                          <div id="formIDPEmployeeFeedbackFirstRowBoxIcon">
                            <RenderSvg nameSvg="feedback60" />
                          </div>
                          <div id="formIDPEmployeeFeedbackFirstRowBoxInfo">
                            <div id="formIDPEmployeeFeedbackFirstRowBoxInfoLabel">
                              Feedback
                            </div>
                            <div id="formIDPEmployeeFeedbackFirstRowBoxInfoText">
                              Przekaż nam informacje zwrotne dotyczące zachowań
                              związanych
                              <br />z wartościami organizacji LF.
                            </div>
                          </div>
                        </div>
                      </ContentBox>
                      <Box
                        lightHeader
                        name="Informacja zwrotna:"
                        id="IDPFeedbackBox"
                        noRadius
                      >
                        <>
                          <div id="honestyBox" className="feedbackBoxItem">
                            <div id="honestyBoxAccent" className="boxAccent" />
                            <div
                              id="honestyBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div id="honestyBoxTitle" className="boxTitle">
                                Uczciwość
                              </div>
                              <div
                                id="honestyBoxSubTitle"
                                className="boxSubTitle"
                              >
                                Działa uczciwie i kieruje się etyką
                                <br /> w codziennej pracy.
                              </div>
                            </div>
                            <div
                              id="honestyBoxAdditionalInfoBox"
                              className="boxAdditionalInfoBox"
                            >
                              <div
                                id="honestyBoxAdditionalInfoBoxItem01"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemNumber01"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  01
                                </div>
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemText01"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Pracuje zgodnie z Kodeksem Postępowania
                                  Biznesowego
                                </div>
                              </div>
                              <div
                                id="honestyBoxAdditionalInfoBoxItem02"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemNumber02"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  02
                                </div>
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemText02"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Zachowuje spójność, postępuje w zgodzie z tym
                                  co mówi, przekazuje wszystkim taką samą wersję
                                  informacji.
                                </div>
                              </div>
                              <div
                                id="honestyBoxAdditionalInfoBoxItem03"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemNumber03"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  03
                                </div>
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemText03"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Zachowuje poufność informacji wrażliwych i
                                  nieformalnych
                                </div>
                              </div>
                              <div
                                id="honestyBoxAdditionalInfoBoxItem04"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemNumber04"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  04
                                </div>
                                <div
                                  id="honestyBoxAdditionalInfoBoxItemText04"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Otwarcie mówi czego potrzebuje od
                                  współpracowników i stara się zrozumiec ich
                                  punkt widzenia
                                </div>
                              </div>
                            </div>
                            <div
                              id="honestyBoxFormConsistentBehaviour"
                              className="boxFormConsistentBehaviour"
                            >
                              <EditorMceShort
                                id="honestyConsistentBehaviour"
                                name="honestyConsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań zgodnych"
                                register={register}
                                label="Wartości zgodne:"
                                contentGet={
                                  data?.feedbackValues?.HONESTY
                                    .consistentBehaviour
                                }
                                error={
                                  errors?.honestyConsistentBehaviour?.message
                                }
                                required
                              />
                            </div>
                            <div
                              id="honestyBoxFormInconsistentBehaviour"
                              className="boxFormInconsistentBehaviour"
                            >
                              <EditorMceShort
                                id="honestyInconsistentBehaviour"
                                name="honestyInconsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań niezgodnych"
                                register={register}
                                label="Wartości niezgodne:"
                                contentGet={
                                  data?.feedbackValues?.HONESTY
                                    .inconsistentBehaviour
                                }
                                error={
                                  errors?.honestyInconsistentBehaviour?.message
                                }
                                required
                              />
                            </div>
                          </div>

                          <div id="respectBox" className="feedbackBoxItem">
                            <div id="respectBoxAccent" className="boxAccent" />
                            <div
                              id="respectBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div id="respectBoxTitle" className="boxTitle">
                                Szacunek
                              </div>
                              <div
                                id="respectBoxSubTitle"
                                className="boxSubTitle"
                              >
                                Tworzy relacje oparte na szacunku i zaufaniu.
                              </div>
                            </div>
                            <div
                              id="respectBoxAdditionalInfoBox"
                              className="boxAdditionalInfoBox"
                            >
                              <div
                                id="respectBoxAdditionalInfoBoxItem01"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="respectBoxAdditionalInfoBoxItemNumber01"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  01
                                </div>
                                <div
                                  id="respectBoxAdditionalInfoBoxItemText01"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  W kontakcie z drugą osobą uważnie słucha i
                                  poświęca jej całą uwagę.
                                </div>
                              </div>
                              <div
                                id="respectBoxAdditionalInfoBoxItem02"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="respectBoxAdditionalInfoBoxItemNumber02"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  02
                                </div>
                                <div
                                  id="respectBoxAdditionalInfoBoxItemText02"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Daje innym prawo do posiadania odmiennego
                                  zdania czy perspektywy.
                                </div>
                              </div>
                              <div
                                id="respectBoxAdditionalInfoBoxItem03"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="respectBoxAdditionalInfoBoxItemNumber03"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  03
                                </div>
                                <div
                                  id="respectBoxAdditionalInfoBoxItemText03"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Wywiązuje się z podjętych zobowiązań, jest
                                  punktualny.
                                </div>
                              </div>
                              <div
                                id="respectBoxAdditionalInfoBoxItem04"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="respectBoxAdditionalInfoBoxItemNumber04"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  04
                                </div>
                                <div
                                  id="respectBoxAdditionalInfoBoxItemText04"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Wypowiada się z szacunkiem biorąc pod uwagę
                                  przekonania i wartości innych.
                                </div>
                              </div>
                            </div>
                            <div
                              id="respectBoxFormConsistentBehaviour"
                              className="boxFormConsistentBehaviour"
                            >
                              <EditorMceShort
                                id="respectConsistentBehaviour"
                                name="respectConsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań zgodnych"
                                register={register}
                                label="Wartości zgodne:"
                                contentGet={
                                  data?.feedbackValues?.RESPECT
                                    .consistentBehaviour
                                }
                                error={
                                  errors?.respectConsistentBehaviour?.message
                                }
                                required
                              />
                            </div>
                            <div
                              id="respectBoxFormInconsistentBehaviour"
                              className="boxFormInconsistentBehaviour"
                            >
                              <EditorMceShort
                                id="respectInconsistentBehaviour"
                                name="respectInconsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań niezgodnych"
                                register={register}
                                label="Wartości niezgodne:"
                                contentGet={
                                  data?.feedbackValues?.RESPECT
                                    .inconsistentBehaviour
                                }
                                error={
                                  errors?.respectInconsistentBehaviour?.message
                                }
                                required
                              />
                            </div>
                          </div>

                          <div
                            id="responsibilityBox"
                            className="feedbackBoxItem"
                          >
                            <div
                              id="responsibilityBoxAccent"
                              className="boxAccent"
                            />
                            <div
                              id="responsibilityBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div
                                id="responsibilityBoxTitle"
                                className="boxTitle"
                              >
                                Odpowiedzialność
                              </div>
                              <div
                                id="responsibilityBoxSubTitle"
                                className="boxSubTitle"
                              >
                                Bierze odpowiedzialność za zadania i ich
                                rezultat.
                              </div>
                            </div>
                            <div
                              id="responsibilityBoxAdditionalInfoBox"
                              className="boxAdditionalInfoBox"
                            >
                              <div
                                id="responsibilityBoxAdditionalInfoBoxItem01"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemNumber01"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  01
                                </div>
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemText01"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Określa cele, potrzebne zasoby, realne terminy
                                  i doprowadza zadania do końca.
                                </div>
                              </div>
                              <div
                                id="responsibilityBoxAdditionalInfoBoxItem02"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemNumber02"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  02
                                </div>
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemText02"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Podejmuje decyzje potrzebne do realizacji
                                  założonego celu i przewiduje ich konsekwencje.
                                </div>
                              </div>
                              <div
                                id="responsibilityBoxAdditionalInfoBoxItem03"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemNumber03"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  03
                                </div>
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemText03"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Wyciąga wnioski z popełnionych błędów i
                                  wprowadza usprawnienia.
                                </div>
                              </div>
                              <div
                                id="responsibilityBoxAdditionalInfoBoxItem04"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemNumber04"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  04
                                </div>
                                <div
                                  id="responsibilityBoxAdditionalInfoBoxItemText04"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Dostarcza innym wyczerpujących informacji
                                  mających wpływ na osiągnięcie celu.
                                </div>
                              </div>
                            </div>
                            <div
                              id="responsibilityBoxFormConsistentBehaviour"
                              className="boxFormConsistentBehaviour"
                            >
                              <EditorMceShort
                                id="responsibilityConsistentBehaviour"
                                name="responsibilityConsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań zgodnych"
                                register={register}
                                label="Wartości zgodne:"
                                contentGet={
                                  data?.feedbackValues?.RESPONSIBILITY
                                    .consistentBehaviour
                                }
                                error={
                                  errors?.responsibilityConsistentBehaviour
                                    ?.message
                                }
                                required
                              />
                            </div>
                            <div
                              id="responsibilityBoxFormInconsistentBehaviour"
                              className="boxFormInconsistentBehaviour"
                            >
                              <EditorMceShort
                                id="responsibilityInconsistentBehaviour"
                                name="responsibilityInconsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań niezgodnych"
                                register={register}
                                label="Wartości niezgodne:"
                                contentGet={
                                  data?.feedbackValues?.RESPONSIBILITY
                                    .inconsistentBehaviour
                                }
                                error={
                                  errors?.responsibilityInconsistentBehaviour
                                    ?.message
                                }
                                required
                              />
                            </div>
                          </div>

                          <div id="cooperationBox" className="feedbackBoxItem">
                            <div
                              id="cooperationBoxAccent"
                              className="boxAccent"
                            />
                            <div
                              id="cooperationBoxTitleBox"
                              className="boxTitleBox"
                            >
                              <div
                                id="cooperationBoxTitle"
                                className="boxTitle"
                              >
                                Współpraca
                              </div>
                              <div
                                id="cooperationBoxSubTitle"
                                className="boxSubTitle"
                              >
                                Skutecznie współdziała z innymi na rzecz
                                osiągania wspólnych celów.
                              </div>
                            </div>
                            <div
                              id="cooperationBoxAdditionalInfoBox"
                              className="boxAdditionalInfoBox"
                            >
                              <div
                                id="cooperationBoxAdditionalInfoBoxItem01"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemNumber01"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  01
                                </div>
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemText01"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Aktywnie proponuje najlepsze rozwiązania i
                                  dokłada starań, aby rozwiązać problem ze
                                  współpracownikami.
                                </div>
                              </div>
                              <div
                                id="cooperationBoxAdditionalInfoBoxItem02"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemNumber02"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  02
                                </div>
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemText02"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Udziela konstruktywnej informacji zwrotnej i
                                  sam prosi o nią współpracowników.
                                </div>
                              </div>
                              <div
                                id="cooperationBoxAdditionalInfoBoxItem03"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemNumber03"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  03
                                </div>
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemText03"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Dzieli się dobrymi praktykami i wdraża dobre
                                  praktyki innych.
                                </div>
                              </div>
                              <div
                                id="cooperationBoxAdditionalInfoBoxItem04"
                                className="boxAdditionalInfoBoxItem"
                              >
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemNumber04"
                                  className="boxAdditionalInfoBoxItemNumber"
                                >
                                  04
                                </div>
                                <div
                                  id="cooperationBoxAdditionalInfoBoxItemText04"
                                  className="boxAdditionalInfoBoxItemText"
                                >
                                  Jest gotowy do pomocy współpracownikom, gra do
                                  jednej bramki.
                                </div>
                              </div>
                            </div>
                            <div
                              id="cooperationBoxFormConsistentBehaviour"
                              className="boxFormConsistentBehaviour"
                            >
                              <EditorMceShort
                                id="cooperationConsistentBehaviour"
                                name="cooperationConsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań zgodnych"
                                register={register}
                                label="Wartości zgodne:"
                                contentGet={
                                  data?.feedbackValues?.COOPERATION
                                    .consistentBehaviour
                                }
                                error={
                                  errors?.cooperationConsistentBehaviour
                                    ?.message
                                }
                                required
                              />
                            </div>
                            <div
                              id="cooperationBoxFormInconsistentBehaviour"
                              className="boxFormInconsistentBehaviour"
                            >
                              <EditorMceShort
                                id="cooperationInconsistentBehaviour"
                                name="cooperationInconsistentBehaviour"
                                setHeight={120}
                                placeholder="Wpisz przykłady zachowań niezgodnych"
                                register={register}
                                label="Wartości niezgodne:"
                                contentGet={
                                  data?.feedbackValues?.COOPERATION
                                    .inconsistentBehaviour
                                }
                                error={
                                  errors?.responsibilityInconsistentBehaviour
                                    ?.message
                                }
                                required
                              />
                            </div>
                          </div>
                        </>
                      </Box>
                    </>
                  </LoaderForm>
                  {!onlyView ? (
                    <CustomSubmit
                      save={
                        onTrainingsProvider
                          ? handleSubmit(handleSave)
                          : undefined
                      }
                      saveAndExit={
                        onTrainingsProviderAndExit
                          ? handleSubmit(handleSaveAndExit)
                          : undefined
                      }
                      submitForm={submitForm}
                      cancel={cancel}
                    />
                  ) : (
                    ''
                  )}
                </>
              </LoaderForm>
            </Form>
          </>
        )}
      </>
    </CustomContainer>
  );
};

export default FormTrainingsProvider;
