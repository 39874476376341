import React, { ReactElement } from 'react';
import TopMenuElement from './TopMenuElement';
import User from './User';
import Logout from './Logout';
import Breadcrumb from './Breadcrumb';
import SlideDrawer from '../../SlideDrawer';

const TopMenuComp = (): ReactElement => {
  return (
    <>
      <div className="topMenuFull">
        <SlideDrawer />
        <TopMenuElement />
        <User />
        <Logout />
      </div>
      <Breadcrumb />
    </>
  );
};

export default TopMenuComp;
