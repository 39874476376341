import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LoyaltyRepository } from 'api/repository';
import { Box } from 'components';
import { LoyaltyPostAndPut } from 'api/types';
import LoyaltyForm from '../Form/Loyalty';

const LoyaltyEdit = (): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [dataLoyalty] = LoyaltyRepository.useGetLoyalty({
    id: Number(id),
  });

  const handlePutEmployeeIdNumber = LoyaltyRepository.usePutEmployeeIdNumber();

  const handleSave = LoyaltyRepository.usePutLoyalty(
    Number(id),
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = LoyaltyRepository.usePutLoyalty(
    Number(id),
    async () => {
      setSubmitForm(false);
      history.push(`/administracja/umowy-lojalnosciowe`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: LoyaltyPostAndPut) => {
    if (!submitForm) {
      const postData = data;
      postData.id = Number(id);
      setSubmitForm(true);
      if (
        postData.idNumber !== undefined &&
        dataLoyalty?.employeeId !== undefined
      ) {
        handlePutEmployeeIdNumber(Number(dataLoyalty?.employeeId), {
          idNumber: postData.idNumber,
        });
      }
      handleSave(postData);
    }
  };

  const handleSubmitSaveAndExit = (data: LoyaltyPostAndPut) => {
    if (!submitForm) {
      setSubmitForm(true);
      const postData = data;
      postData.id = Number(id);
      if (
        postData.idNumber !== undefined &&
        dataLoyalty?.employeeId !== undefined
      ) {
        handlePutEmployeeIdNumber(Number(dataLoyalty?.employeeId), {
          idNumber: postData.idNumber,
        });
      }
      handleSaveAndExit(postData);
    }
  };

  const handleCancel = () => {
    history.push(`/administracja/umowy-lojalnosciowe`);
  };

  return (
    <LoyaltyForm
      id={Number(id)}
      onSave={handleSubmit}
      onSaveAndExit={handleSubmitSaveAndExit}
      submitForm={submitForm}
      data={dataLoyalty}
      cancel={handleCancel}
      // edit
    />
  );
};

export default LoyaltyEdit;
