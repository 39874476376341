import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { TrainingsProviderContactRepository } from 'api/repository';
import { Modal } from 'components';
import FormTrainingsProviderContact from '../Form/FormTrainingsProviderContact';

interface TrainigsProviderProps {
  id: number;
  idRecord: number;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsProviderShow = (props: TrainigsProviderProps): ReactElement => {
  const { id, changeModalShow, idRecord } = props;
  const history = useHistory();
  const [saveAndExit, setSaveAndExit] = useState(false);

  const [
    trainingProviderContact,
  ] = TrainingsProviderContactRepository.useGetTrainingsProviderContact({
    id: Number(id),
    idRecord: Number(idRecord),
  });

  return (
    <Modal open changeModalShow={changeModalShow} title="test" maxLevel="lvl2">
      <div>
        <FormTrainingsProviderContact
          data={trainingProviderContact}
          onlyView
          edit
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderShow;
