import {
  EmployeeIdNumber,
  Loyalty,
  LoyaltyPostAndPut,
  LoyaltyRequest,
  LoyaltyRequiredAttention,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const useGetAllLoyalty = (
  onError?: (error: AxiosError<Error>) => void
): [(params: LoyaltyRequest) => void, PagedData<Loyalty> | null] => {
  const axios = useAxios();
  const [loyalty, setLoyalty] = useState<PagedData<Loyalty> | null>(null);

  const doGet = useCallback(
    (params: LoyaltyRequest) => {
      axios
        .get<PagedData<Loyalty>>(`${apiEndpoints.loyalty}/loyalty-agreements`, {
          params,
        })
        .then(({ data }) => {
          setLoyalty(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, loyalty];
};

const usePutLoyalty = (
  idLoyalty: number,
  onCompletedPut?: (data: LoyaltyPostAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: Omit<LoyaltyPostAndPut, 'idNumber'>) => Promise<void>) => {
  const axios = useAxios();

  const putLoyalty = useCallback(
    async (paramPut: Omit<LoyaltyPostAndPut, 'idNumber'>) => {
      try {
        const rData = await axios.put<Omit<LoyaltyPostAndPut, 'idNumber'>>(
          `${apiEndpoints.loyalty}/loyalty-agreements/${idLoyalty}`,
          paramPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putLoyalty;
};

const useGetLoyalty = (props: {
  id: number;
  onCompleted?: (data: Loyalty) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  Loyalty | null | undefined,
  (trainingsGroup: Loyalty) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<Loyalty | null | undefined>(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<Loyalty>(
        `${apiEndpoints.trainingsGroup}/loyalty-agreements/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: Loyalty) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeIdNumber = (
  onCompletedPut?: () => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: number, arg1: EmployeeIdNumber) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeIdNumber = useCallback(
    async (idEmployee: number, paramPut: EmployeeIdNumber) => {
      try {
        await axios.put<EmployeeIdNumber>(
          `${apiEndpoints.employee}/employees/${idEmployee}`,
          paramPut
        );
        if (onCompletedPut) onCompletedPut();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeIdNumber;
};

const useGetLoyaltyRequiredAttention = (props: {
  onCompleted?: (data: LoyaltyRequiredAttention) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  LoyaltyRequiredAttention | null | undefined,
  (trainingsGroup: LoyaltyRequiredAttention) => void,
  () => void
] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    LoyaltyRequiredAttention | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<LoyaltyRequiredAttention>(
        `${apiEndpoints.trainingsGroup}/loyalty-agreements/require-attention`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: LoyaltyRequiredAttention) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

export default {
  useGetAllLoyalty,
  usePutLoyalty,
  useGetLoyalty,
  usePutEmployeeIdNumber,
  useGetLoyaltyRequiredAttention,
};
