import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { Modal } from 'react-bootstrap';
import { useStoreState } from 'store/userSettings';
import CloseButton from './CloseButton';
import './Modal.scss';

interface Props {
  open: boolean;
  children?: React.ReactNode;
  title?: string;
  forbidClose?: boolean;
  changeModalShow?: Dispatch<SetStateAction<boolean>>;
  maxLevel?: 'lvl2' | 'lvl3' | 'lvl4' | 'lvl5';
  widthBox?: 'low400' | 'low' | 'medium' | 'high';
}

const CustomModal = (props: Props): ReactElement => {
  const {
    title,
    open,
    children,
    forbidClose,
    changeModalShow,
    maxLevel,
    widthBox,
  } = props;

  const isDarkTheme = useStoreState((state) => state.userSettings.isDarkTheme);
  const isHighContrast = useStoreState(
    (state) => state.userSettings.isHighContrast
  );

  const fontSize = useStoreState((state) => state.userSettings.fontSize);

  const dataTheme = () => {
    let theme = 'light';
    if (isDarkTheme) {
      theme = 'dark';
    }
    return theme;
  };

  const blockClassName = () => {
    let classes = 'blockApps';
    if (maxLevel) {
      classes += ` ${maxLevel}`;
    }
    if (widthBox) {
      classes += ` ${widthBox}`;
    }
    if (isHighContrast) {
      classes += ' highContrast';
    }
    if (fontSize === 1) {
      classes += ' fontSize1';
    }
    if (fontSize === 2) {
      classes += ' fontSize2';
    }
    if (fontSize === 3) {
      classes += ' fontSize3';
    }

    return classes;
  };

  const onHide = () => {};

  const closeButton = () => {
    if (changeModalShow !== undefined && forbidClose === undefined) {
      return <CloseButton changeModalShow={changeModalShow} />;
    }
    return null;
  };

  return (
    <>
      <Modal
        show={open}
        onHide={onHide}
        backdropClassName={maxLevel}
        animation
        title={title}
        data-theme={dataTheme()}
        className={blockClassName()}
      >
        {closeButton()}
        {children}
      </Modal>
    </>
  );
};

export default CustomModal;
