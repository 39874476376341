import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  EmployeeAppraisalGoal,
  EmployeeAppraisalRatingModel,
  EmployeeAppraisalRatingPut,
} from 'api/types';
import { Modal } from 'components';
import { EmployeeAppraisalRatingWithIdEmployeePutAdmin } from 'api/types/EmployeeAppraisal';
import GoalRatingForm from '../../../Form/GoalRatingForm';

interface Props {
  idEmployee: number;
  idEvaluation: number;
  changeModal: Dispatch<SetStateAction<boolean>>;
  fetchData: () => void;
  edit?: boolean;
  data?: EmployeeAppraisalRatingModel;
}
const EmployeeAppraisalGoalEmployeeRating = (props: Props): ReactElement => {
  const {
    idEmployee,
    idEvaluation,
    changeModal,
    fetchData,
    edit,
    data,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutManagerN2RatingAdmin(
    async (dataPost) => {
      setSubmitForm(false);
      fetchData();
      changeModal(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (dataPost: EmployeeAppraisalRatingPut) => {
    const post: EmployeeAppraisalRatingWithIdEmployeePutAdmin = {
      id: idEmployee,
      idEvaluation,
      justification: dataPost.justification,
      rating: dataPost.rating,
    };
    setSubmitForm(true);
    postDataSaveAndExit(post);
  };

  const handleCancel = () => {
    changeModal(false);
  };

  return (
    <Modal open changeModalShow={changeModal} widthBox="low" maxLevel="lvl4">
      <div>
        <GoalRatingForm
          onSaveAndExit={handleSaveAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          titleBox={
            edit ? 'EDYCJA OCENY PRZEZ N+2' : 'DODAWANIE OCENY PRZEZ N+2'
          }
          data={data}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalEmployeeRating;
