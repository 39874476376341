/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { AnimatedProgressBar, ActionInFolder } from 'components';

import './catalogsTrainingsItem.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data: any;
  showClick?: (dataId: number) => void;
  editClick?: (dataId: number) => void;
  archiveClick?: (dataId: number) => void;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data, showClick, editClick, archiveClick } = props;
  const { t } = useTranslation('catalogsTrainings');

  return (
    <div className="CatalogsTrainingsItem" id={`${id}-${data.id}`}>
      <div className="accent" />
      <div className="name">{data.name}</div>
      <div className="avalibleTrainings">{t('item.avalibleTrainings')}</div>
      <div className="animateNumber">
        <AnimatedProgressBar
          valueEnd={data.trainingsCount}
          maxValue={data.trainingsCount}
          duration={1000}
        />
      </div>
      <div className="action">
        {data && data.id !== undefined ? (
          // @ts-ignore
          <Button className="green" onClick={() => editClick(data.id)}>
            Dodaj szkolenie
          </Button>
        ) : (
          ''
        )}

        <ActionInFolder
          id="actionCatalogsTrainingsItem"
          dataId={data.id}
          showClick={showClick}
          editClick={editClick}
          archiveClick={archiveClick}
        />
      </div>
    </div>
  );
};

export default ContentBox;
