import React, { ReactElement, SetStateAction, Dispatch, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Box,
  CustomContainer,
  ExitFromForm,
  CustomSubmit,
  EditorMce,
  LoaderForm,
} from 'components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PlansTrainingsRepository } from 'api/repository';
import { AcceptedDecisionMotivation } from 'api/types';
import './decisionModal.scss';

type MotivationValue = Omit<AcceptedDecisionMotivation, 'status'>;

interface Props {
  changeShowModal: Dispatch<SetStateAction<boolean>>;
  onSaveAndExit?: any;
  submitForm?: boolean;
  data?: AcceptedDecisionMotivation | null;
}

const MotivationModal = (props: Props): ReactElement => {
  const { t } = useTranslation(['plansTrainings', 'trainings']);

  const { changeShowModal, onSaveAndExit, submitForm, data } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const formSchema: yup.SchemaOf<MotivationValue> = yup.object().shape({
    comment: yup.string().required(t('trainingsForm.nameMessage')),
  });

  const handleSaveAndExit = (dataSubmit: MotivationValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const { handleSubmit, register, errors, setValue } = useForm<MotivationValue>(
    {
      resolver: yupResolver(formSchema),
      shouldFocusError: true,
      shouldUnregister: false,
    }
  );

  return (
    <Modal
      open
      changeModalShow={changeShowModal}
      maxLevel="lvl2"
      widthBox="medium"
    >
      <Box
        id="selectedTrainingsMotivationSlt"
        name="POWÓD BRAKU AKCEPTACJI"
        noMarginBottom
        noShadow
        svgIcon="manager"
      >
        <CustomContainer>
          <>
            <div className="titleBox">
              Zaznaczyłeś brazk zgody na szkolenie.
              <br />
              Poniżej wpisz powód Twojej decyzji:
            </div>

            <ExitFromForm changeForm={changeForm} />
            <Form
              className="formTrainings"
              onChange={(e) => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Row className="justify-content-md-center">
                    <Col>
                      <EditorMce
                        id="comment"
                        name="comment"
                        register={register}
                        contentGet={data?.comment || ''}
                        error={errors?.comment?.message}
                        setChangeForm={setChangeForm}
                        placeholder="Wpisz dlaczego nie wyrażasz zgody na to szkolenie"
                      />
                    </Col>
                  </Row>
                </>
              </LoaderForm>

              <CustomSubmit
                saveAndExit={handleSubmit(handleSaveAndExit)}
                submitForm={submitForm}
              />
            </Form>
          </>
        </CustomContainer>
      </Box>
    </Modal>
  );
};

export default MotivationModal;
