import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Box,
  ContentPlansTrainings,
  ActionBeforeCatalog,
  LoaderTable,
  CustomContainer,
} from 'components';
import { UserPlansTrainingsRepository } from 'api/repository';
import EditPlansTrainings from './EditPlansTrainings';
import './plansTrainings.scss';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [data] = UserPlansTrainingsRepository.useGetActivePlansTrainings();

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <>
            {data !== null && data !== undefined ? (
              <EditPlansTrainings />
            ) : (
              <Box
                id="userPlansTrainingsBox"
                name={t('userPlans.enrollmentForTraining')}
                svgIcon="enrollmentForTrainings"
                noContentStyle
              >
                <div className="userPlansTrainingsNoActivePlans">
                  <div className="noActivePlan">
                    <div>{t('userPlans.noActivePlansPart1')}</div>
                    <div>{t('userPlans.noActivePlansPart2')}</div>
                  </div>
                </div>
              </Box>
            )}
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default Trainigns;
