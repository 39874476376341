import {
  Error,
  IDPModelAddAndPut,
  IDPModel,
  IDPAllEmployeeRequest,
  PagedData,
  EmployeeAllModel,
  IDPEmployeeModel,
  IDPEmployeeAreaModel,
  IDPEmployeeAreaPostAndPut,
  IDPEmployeeAddAndPut,
  IDPFeedbackModel,
  IDPFeedbackPostAndPut,
  IDPLeaderProfileModel,
  IDPLeaderProfilePostAndPut,
  EmployeeModel,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';
import { EmployeeIDPToSelect, FeedbackToSelect } from 'api/types/IDP';
import { EmployeeRequest } from 'api/types/RequestParams';

interface Props {
  onCompleted?: (data: IDPModelAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

const useGetIDPSettings = (
  props: Props
): [IDPModel | null | undefined, (iDPModel: IDPModel) => void, () => void] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPModel>(
        `${apiEndpoints.idp}/settings`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: IDPModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutIDPSettings = (
  onCompletedPut?: (data: IDPModelAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPModelAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPSettings = useCallback(
    async (plansTrainings: IDPModelAddAndPut) => {
      try {
        const rData = await axios.put<IDPModelAddAndPut>(
          `${apiEndpoints.idp}/settings`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPSettings;
};

const useGetIDPAllEmployee = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: IDPAllEmployeeRequest) => void,
  PagedData<EmployeeAllModel> | null
] => {
  const axios = useAxios();
  const [catalogs, setCatalogs] = useState<PagedData<EmployeeAllModel> | null>(
    null
  );

  const doGet = useCallback(
    (params: IDPAllEmployeeRequest) => {
      axios
        .get<PagedData<EmployeeAllModel>>(
          `${apiEndpoints.idp}/individual-development-plans`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetIDPEmployee = (props: {
  id: number;
  onCompleted?: (data: IDPEmployeeModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPEmployeeModel | null | undefined, () => void] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPEmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeModel>(
        `${apiEndpoints.idp}/individual-development-plans/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePutIDPEmployee = (
  id: number,
  onCompletedPut?: (data: IDPModelAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPModelAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPModelAddAndPut) => {
      try {
        const rData = await axios.put<IDPModelAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/${id}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const usePostIDPEmployeeArea = (
  id: number,
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.post<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/development-areas`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useGetAllIDPEmployeeArea = (props: {
  id: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/individual-development-plans/${id}/development-areas`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, id, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const useGetIDPEmployeeArea = (props: {
  idEmployee: number;
  idArea: number;
  onCompleted?: (data: IDPEmployeeAreaModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  IDPEmployeeAreaModel | null | undefined,
  (trainingsGroup: IDPEmployeeAreaModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idArea, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPEmployeeAreaModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeAreaModel>(
        `${apiEndpoints.idp}/individual-development-plans/${idEmployee}/development-areas/${idArea}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: IDPEmployeeAreaModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutIDPEmployeeArea = (
  idEmployee: number,
  idArea: number,
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.put<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/${idEmployee}/development-areas/${idArea}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useDeleteIDPEmployeeArea = (
  idEmployee: number,
  onCompletedDelete?: (data: IDPEmployeeAreaModel) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const DeleteIDP = useCallback(
    async (idArea: number) => {
      try {
        const rData = await axios.delete<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/${idEmployee}/development-areas/${idArea}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return DeleteIDP;
};

const usePostIDPEmployee = (
  id: number,
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async (plansTrainings: IDPEmployeeAddAndPut) => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/development-areas`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

const useGetIDPFeedback = (props: {
  id: number;
  onCompleted?: (data: IDPFeedbackModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPFeedbackModel | null | undefined, () => void] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPFeedbackModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPFeedbackModel>(
        `${apiEndpoints.idp}/individual-development-plans/${id}/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePutIDPFeedback = (
  id: number,
  onCompletedPut?: (data: IDPFeedbackModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPFeedbackPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPFeedbackPostAndPut) => {
      try {
        const rData = await axios.put<IDPFeedbackModel>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/feedback`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const useGetAllImportIDPEmployeeArea = (props: {
  id: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/individual-development-plans/${id}/development-areas/import`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, id, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const useGetCheckIDPFeedback = (
  id: number,
  onCompleted?: (data: IDPFeedbackModel | number) => void,
  onError?: (error: AxiosError<Error>) => void
): (() => void) => {
  const axios = useAxios();

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPFeedbackModel>(
        `${apiEndpoints.idp}/individual-development-plans/${id}/feedback`
      );
      if (onCompleted) onCompleted(data);
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onCompleted, onError]);

  return fetchData;
};

const usePostIDPEmployeeClose = (
  id: number,
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async () => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/close`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

const usePostIDPEmployeeAreaImport = (
  id: number,
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idArea: number) => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async (idArea: number) => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/development-areas/import/${idArea}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

const useGetIDPEmployeeUser = (props: {
  onCompleted?: (data: IDPEmployeeModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPEmployeeModel | null | undefined, () => void] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPEmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeModel>(
        `${apiEndpoints.idp}/individual-development-plans/user`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetAllIDPEmployeeAreaUser = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/individual-development-plans/user/development-areas`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const usePutIDPEmployeeUser = (
  onCompletedPut?: (data: IDPModelAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPModelAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPModelAddAndPut) => {
      try {
        const rData = await axios.put<IDPModelAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/user`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const useDeleteIDPEmployeeAreaUser = (
  onCompletedDelete?: (data: IDPEmployeeAreaModel) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const DeleteIDP = useCallback(
    async (idArea: number) => {
      try {
        const rData = await axios.delete<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/user/development-areas/${idArea}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return DeleteIDP;
};

const usePostIDPEmployeeAreaUser = (
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.post<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/user/development-areas`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useGetIDPEmployeeAreaUser = (props: {
  idArea: number;
  onCompleted?: (data: IDPEmployeeAreaModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  IDPEmployeeAreaModel | null | undefined,
  (trainingsGroup: IDPEmployeeAreaModel) => void,
  (idRecord: number) => void
] => {
  const { idArea, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPEmployeeAreaModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeAreaModel>(
        `${apiEndpoints.idp}/individual-development-plans/user/development-areas/${idArea}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: IDPEmployeeAreaModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutIDPEmployeeAreaUser = (
  idArea: number,
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.put<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/individual-development-plans/user/development-areas/${idArea}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useGetAllImportIDPEmployeeAreaUser = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/individual-development-plans/user/development-areas/import`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const usePostIDPEmployeeAreaImportUser = (
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idArea: number) => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async (idArea: number) => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/individual-development-plans/user/development-areas/import/${idArea}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

const useGetIDPLeaderProfile = (props: {
  id: number;
  onCompleted?: (data: IDPLeaderProfileModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPLeaderProfileModel | null | undefined, () => void] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPLeaderProfileModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPLeaderProfileModel>(
        `${apiEndpoints.idp}/individual-development-plans/${id}/leader-profile`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePutIDPLeaderProfile = (
  id: number,
  onCompletedPut?: (data: IDPLeaderProfileModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPLeaderProfilePostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPLeaderProfilePostAndPut) => {
      try {
        const rData = await axios.put<IDPLeaderProfileModel>(
          `${apiEndpoints.idp}/individual-development-plans/${id}/leader-profile`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const useGetIDPLeaderProfileUser = (props: {
  onCompleted?: (data: IDPLeaderProfileModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPLeaderProfileModel | null | undefined, () => void] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPLeaderProfileModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPLeaderProfileModel>(
        `${apiEndpoints.idp}/individual-development-plans/user/leader-profile`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePutIDPLeaderProfileUser = (
  onCompletedPut?: (data: IDPLeaderProfileModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPLeaderProfilePostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPLeaderProfilePostAndPut) => {
      try {
        const rData = await axios.put<IDPLeaderProfileModel>(
          `${apiEndpoints.idp}/individual-development-plans/user/leader-profile`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const useGetIDPFeedbackManager = (props: {
  id: number;
  employeeId: number;
  onCompleted?: (data: IDPFeedbackModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPFeedbackModel | null | undefined, () => void] => {
  const { id, employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPFeedbackModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPFeedbackModel>(
        `${apiEndpoints.idp}/individual-development-plans/${employeeId}/feedback/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPFeedbackEmployeeManager = (props: {
  employeeId: number;
  onCompleted?: (data: FeedbackToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [FeedbackToSelect[] | null | undefined, () => void] => {
  const { employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<FeedbackToSelect[] | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<FeedbackToSelect[]>(
        `${apiEndpoints.idp}/individual-development-plans/${employeeId}/feedback/list`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPAllEmployeeAdmin = (
  onError?: (error: AxiosError<Error>) => void
): [(params: EmployeeRequest) => void, PagedData<EmployeeModel> | null] => {
  const axios = useAxios();
  const [catalogs, setCatalogs] = useState<PagedData<EmployeeModel> | null>(
    null
  );

  const doGet = useCallback(
    (params: EmployeeRequest) => {
      axios
        .get<PagedData<EmployeeModel>>(
          `${apiEndpoints.idp}/admin/individual-development-plans`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetIDPEmployeeAdmin = (props: {
  employeeId: number;
  onCompleted?: (data: EmployeeIDPToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeIDPToSelect[] | null | undefined, () => void] => {
  const { employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeIDPToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeIDPToSelect[]>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${employeeId}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPEmployeeDataAdmin = (props: {
  id: number;
  idpId: number;
  onCompleted?: (data: IDPEmployeeModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPEmployeeModel | null | undefined, () => void] => {
  const { id, idpId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPEmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeModel>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${id}/${idpId}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetAllIDPEmployeeAreaAdmin = (props: {
  id: number;
  idpId: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { id, idpId, onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${id}/${idpId}/development-areas`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, id, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const useGetIDPLeaderProfileAdmin = (props: {
  id: number;
  idpId: number;
  onCompleted?: (data: IDPLeaderProfileModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPLeaderProfileModel | null | undefined, () => void] => {
  const { id, idpId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPLeaderProfileModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPLeaderProfileModel>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${id}/${idpId}/leader-profile`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPFeedbackAdmin = (props: {
  id: number;
  employeeId: number;
  onCompleted?: (data: IDPFeedbackModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPFeedbackModel | null | undefined, () => void] => {
  const { id, employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPFeedbackModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPFeedbackModel>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${employeeId}/feedback/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePutIDPEmployeeAdmin = (
  employeeId: number,
  idpId: number,
  onCompletedPut?: (data: IDPModelAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPModelAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (idp: IDPModelAddAndPut) => {
      try {
        const rData = await axios.put<IDPModelAddAndPut>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${employeeId}/${idpId}`,
          idp
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const usePostIDPEmployeeAreaAdmin = (
  employeeId: number,
  idpId: number,
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.post<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${employeeId}/${idpId}/development-areas`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useGetIDPEmployeeAreaAdmin = (props: {
  idEmployee: number;
  idIdp: number;
  idArea: number;
  onCompleted?: (data: IDPEmployeeAreaModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  IDPEmployeeAreaModel | null | undefined,
  (trainingsGroup: IDPEmployeeAreaModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idIdp, idArea, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPEmployeeAreaModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeAreaModel>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/${idIdp}/development-areas/${idArea}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: IDPEmployeeAreaModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutIDPEmployeeAreaAdmin = (
  idEmployee: number,
  idIdp: number,
  idArea: number,
  onCompletedPut?: (data: IDPEmployeeAreaModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPEmployeeAreaPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDP = useCallback(
    async (plansTrainings: IDPEmployeeAreaPostAndPut) => {
      try {
        const rData = await axios.put<IDPEmployeeAreaModel>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/${idIdp}/development-areas/${idArea}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDP;
};

const useGetAllImportIDPEmployeeAreaAdmin = (props: {
  idEmployee: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { idEmployee, onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/development-areas/import`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const usePostIDPEmployeeAreaImportAdmin = (
  idEmployee: number,
  idIdp: number,
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idArea: number) => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async (idArea: number) => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/${idIdp}/development-areas/import/${idArea}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

const usePutIDPLeaderProfileAdmin = (
  idEmployee: number,
  idIdp: number,
  onCompletedPut?: (data: IDPLeaderProfileModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPLeaderProfilePostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPLeaderProfilePostAndPut) => {
      try {
        const rData = await axios.put<IDPLeaderProfileModel>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/${idIdp}/leader-profile`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const usePutIDPFeedbackAdmin = (
  idEmployee: number,
  idIdp: number,
  onCompletedPut?: (data: IDPFeedbackModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: IDPFeedbackPostAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putIDPEmployee = useCallback(
    async (plansTrainings: IDPFeedbackPostAndPut) => {
      try {
        const rData = await axios.put<IDPFeedbackModel>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/feedback/${idIdp}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putIDPEmployee;
};

const useGetIDPEmployeeDataUser = (props: {
  idIdp: number;
  onCompleted?: (data: IDPEmployeeModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPEmployeeModel | null | undefined, () => void] => {
  const { idIdp, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPEmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPEmployeeModel>(
        `${apiEndpoints.idp}/individual-development-plans/user/${idIdp}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPEmployeeUserForSelect = (props: {
  onCompleted?: (data: EmployeeIDPToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeIDPToSelect[] | null | undefined, () => void] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeIDPToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeIDPToSelect[]>(
        `${apiEndpoints.idp}/individual-development-plans/user/forSelect`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetAllIDPEmployeeAreaUserFromIdp = (props: {
  idIdp: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<IDPEmployeeAreaModel> | null | undefined] => {
  const { idIdp, onError } = props;
  const axios = useAxios();
  const [
    IDPAllEmployeeAreaData,
    setIDPAllEmployeeAreaData,
  ] = useState<PagedData<IDPEmployeeAreaModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<IDPEmployeeAreaModel>>(
        `${apiEndpoints.idp}/individual-development-plans/user/${idIdp}/development-areas`
      )
      .then(({ data }) => {
        setIDPAllEmployeeAreaData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, IDPAllEmployeeAreaData];
};

const useGetIDPLeaderProfileUserFromIdp = (props: {
  idIdp: number;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPLeaderProfileModel | null | undefined, () => void] => {
  const { idIdp, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    IDPLeaderProfileModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPLeaderProfileModel>(
        `${apiEndpoints.idp}/individual-development-plans/user/${idIdp}/leader-profile`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetIDPFeedbackUser = (props: {
  idIdp: number;
  onError?: (error: AxiosError<Error>) => void;
}): [IDPFeedbackModel | null | undefined, () => void] => {
  const { idIdp, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<IDPFeedbackModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<IDPFeedbackModel>(
        `${apiEndpoints.idp}/user/individual-development-plans/feedback/${idIdp}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const usePostIDPEmployeeUnclose = (
  idEmployee: number,
  idIdp: number,
  onCompletedPut?: (data: IDPEmployeeAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const postIDPEmployee = useCallback(
    async () => {
      try {
        const rData = await axios.post<IDPEmployeeAddAndPut>(
          `${apiEndpoints.idp}/admin/individual-development-plans/${idEmployee}/${idIdp}/unclose`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postIDPEmployee;
};

export default {
  useGetIDPSettings,
  usePutIDPSettings,
  useGetIDPAllEmployee,
  useGetIDPEmployee,
  usePutIDPEmployee,
  usePostIDPEmployeeArea,
  useGetAllIDPEmployeeArea,
  useGetIDPEmployeeArea,
  usePutIDPEmployeeArea,
  useDeleteIDPEmployeeArea,
  usePostIDPEmployee,
  useGetIDPFeedback,
  usePutIDPFeedback,
  useGetAllImportIDPEmployeeArea,
  useGetCheckIDPFeedback,
  usePostIDPEmployeeClose,
  usePostIDPEmployeeAreaImport,
  useGetIDPEmployeeUser,
  useGetAllIDPEmployeeAreaUser,
  usePutIDPEmployeeUser,
  useDeleteIDPEmployeeAreaUser,
  usePostIDPEmployeeAreaUser,
  usePutIDPEmployeeAreaUser,
  useGetIDPEmployeeAreaUser,
  useGetAllImportIDPEmployeeAreaUser,
  usePostIDPEmployeeAreaImportUser,
  useGetIDPLeaderProfile,
  usePutIDPLeaderProfile,
  useGetIDPLeaderProfileUser,
  usePutIDPLeaderProfileUser,
  useGetIDPFeedbackManager,
  useGetIDPFeedbackEmployeeManager,
  useGetIDPAllEmployeeAdmin,
  useGetIDPEmployeeAdmin,
  useGetIDPEmployeeDataAdmin,
  useGetAllIDPEmployeeAreaAdmin,
  useGetIDPLeaderProfileAdmin,
  useGetIDPFeedbackAdmin,
  usePutIDPEmployeeAdmin,
  usePostIDPEmployeeAreaAdmin,
  useGetIDPEmployeeAreaAdmin,
  usePutIDPEmployeeAreaAdmin,
  useGetAllImportIDPEmployeeAreaAdmin,
  usePostIDPEmployeeAreaImportAdmin,
  usePutIDPLeaderProfileAdmin,
  usePutIDPFeedbackAdmin,
  useGetIDPEmployeeDataUser,
  useGetIDPEmployeeUserForSelect,
  useGetAllIDPEmployeeAreaUserFromIdp,
  useGetIDPLeaderProfileUserFromIdp,
  useGetIDPFeedbackUser,
  usePostIDPEmployeeUnclose,
};
