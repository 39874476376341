import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import { IDPModelAddAndPut } from 'api/types';
import FormPlansTrainings from '../../FormIDP';

const CompetencesAdd = (): ReactElement => {
  const history = useHistory();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [idp] = IDPRepository.useGetIDPSettings({});

  const postDataSave = IDPRepository.usePutIDPSettings(
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (dataPost: IDPModelAddAndPut) => {
    setSubmitForm(true);
    const data = dataPost;
    postDataSave(data);
  };

  const postDataSaveAndExit = IDPRepository.usePutIDPSettings(
    async () => {
      setSubmitForm(false);
      history.push('/ustawienia/szkolenia/plan-szkolen');
    },
    async () => {
      setSubmitForm(false);
    }
  );

  return (
    <>
      <FormPlansTrainings
        onSave={handleAddTrainingsProviderContact}
        submitForm={submitForm}
        data={idp}
        edit
      />
    </>
  );
};

export default CompetencesAdd;
