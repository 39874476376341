import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBeforeCatalog, ContentBox, LoaderTable } from 'components';
import { Button } from 'react-bootstrap';
import { TrainingsGroupRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupUserTraining } from 'api/types';
import GroupItem from './GroupItem';

interface Props {
  setModalShow?: Dispatch<SetStateAction<boolean>>;
  setModalId?: Dispatch<SetStateAction<number>>;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { t } = useTranslation('trainingsGroup');
  const { id } = useParams<{ id: string }>();

  const { setModalShow, setModalId } = props;

  const history = useHistory();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useManagerGetAllUserTrainingsGroup();

  useEffect(() => {
    setLoaderTable(true);
    getData(Number(id));
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <LoaderTable active={loaderTable} radiusTop allowGrow>
        <ContentBox
          id="trainingGroupsUserListTrainingContent"
          noPadding
          allowGrow
        >
          <>
            <div className="trainingGroupsUserSelectTrainingItemsBox" id={id}>
              {data?.content && data?.content.length > 0 ? (
                <div className="trainingGroupsUserSelectTrainingItems">
                  {data.content.map((item: TrainingsGroupUserTraining) => {
                    return (
                      <GroupItem
                        id={id}
                        data={item}
                        showClick={() => {
                          if (setModalId && setModalShow) {
                            setModalId(Number(item.trainingGroup.id));
                            setModalShow(true);
                          }
                        }}
                        idEmployee={Number(id)}
                      />
                    );
                  })}
                </div>
              ) : (
                <div id="trainingGroupsUserSelectTrainingItemsNoRecord">
                  <div className="noRecord">
                    <div>Brak szkoleń dla których należy wybrać termin</div>
                  </div>
                </div>
              )}
            </div>
          </>
        </ContentBox>
      </LoaderTable>
    </>
  );
};

export default TableTrainingsBlock;
