import React, { ReactElement, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TrainingsRepository } from 'api/repository';
import {
  ContentBox,
  TablePagination,
  LoaderTable,
  TableRecordAction,
  CustomSort,
  CustomFilterText,
  CustomHeaderFormatter,
  TableCellTypeTrainings,
  CustomFilterData,
  CustomSortData,
  TableCellSwitch,
  CustomFilterSelectTrainingType,
} from 'components';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import ModalTrainingsAdd from '../../../TrainingsAndCompetence/Trainings/ModalTrainingsAdd';
import ModalTrainingsShow from '../../../TrainingsAndCompetence/Trainings/ModalTrainingsShow';

interface TableTrainingsProps {
  edit?: boolean;
  selectedTrainings: any;
  setSelectedTrainings: any;
  setUpdateSelected: any;
  errormsg?: any;
}

const TrableTrainings = (props: TableTrainingsProps): ReactElement => {
  const { t } = useTranslation(['catalogsTrainings', 'trainings']);

  const {
    edit,
    selectedTrainings,
    setSelectedTrainings,
    setUpdateSelected,
    errormsg,
  } = props;

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    name: 'name',
    type: 'type',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    name: 'default',
    type: 'TYPE',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [getDataTable, dataTable] = TrainingsRepository.useGetAllTrainings();

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getDataTable(paramQuery);
  }, [
    getDataTable,
    page,
    rowsPerPage,
    filterReq,
    orderColumn,
    updateDataTable,
  ]);

  useEffect(() => {
    if (dataTable !== null) {
      setLoaderTable(false);
    }
  }, [dataTable]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('trainings:trainingsTable.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'type',
      text: t('trainings:trainingsTable.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="left"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.type} justify="toLeft" />;
      },
    },
    {
      dataField: '',
      text: t('trainings:trainingsTable.selected'),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return <CustomHeaderFormatter column={column} align="right" />;
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellSwitch
            dataId={row.id}
            id="selectedRow"
            justify="toRight"
            selectedData={formatExtraData}
            setSelectedData={setSelectedTrainings}
            setUpdateSelected={setUpdateSelected}
            edit={edit}
          />
        );
      },
      formatExtraData: selectedTrainings,
    },
  ];

  return (
    <>
      <>
        <ContentBox id="FormCatalogsTrainingsTable" noPadding>
          <LoaderTable active={loaderTable}>
            <>
              {errormsg ? (
                <div style={{ color: '#BF3E3E', textAlign: 'center' }}>
                  {errormsg}
                </div>
              ) : (
                ''
              )}
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={dataTable}
                changePage={setPage}
              >
                <Button className="green" onClick={() => handleOpenAddForm()}>
                  {t('trainings:trainingsTable.addTrainings')}
                </Button>
              </TablePagination>

              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={dataTable?.content || []}
                columns={columns}
                noDataIndication={t('trainings:trainingsTable.noDataTable')}
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={dataTable}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </ContentBox>

        {modalAdd ? (
          <ModalTrainingsAdd
            changeModalAdd={setModalAdd}
            changeUpdateTableData={setUpdateDataTable}
            onlySaveAndExit
          />
        ) : (
          ''
        )}
        {modalShow ? (
          <ModalTrainingsShow id={modalId} changeModalShow={setModalShow} />
        ) : (
          ''
        )}
      </>
    </>
  );
};

export default TrableTrainings;
