import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  EmployeeAppraisalFeedback180Form,
  EmployeeAppraisalFeedback180Model,
  EmployeeAppraisalGoal,
} from 'api/types';
import Feedback180 from '../../Form/Feedback180';

interface Props {
  idEmployee: number;
  idEvaluation: number;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { idEmployee, idEvaluation } = props;

  const [
    Feedback,
    FeedbackUpdate,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalFeedbackManagerN2({
    idEmployee,
    idEvaluation,
  });

  return <Feedback180 id={idEmployee} data={Feedback} onlyView />;
};

export default TrainingsProviderAddPage;
