/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { CustomInfoBlock, EditorMce, RenderSvg } from 'components';
import { QuestionnaireQuestion, QuestionnaireQuestionChoices } from 'api/types';

import './questionYesNoPartly.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: QuestionnaireQuestion;
  changeHandle: (choiceId: number | null) => void;
  changeTextHandle?: (answerText: string) => void;
  error?: boolean;
}

const TableCellTrainingsStatusChange = (props: Props): ReactElement => {
  const { data, changeHandle, changeTextHandle, error } = props;

  const [selected, setSelected] = useState<number | null | undefined>(
    undefined
  );

  useEffect(() => {
    if (selected !== undefined) {
      changeHandle(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <div
        id={`questionYesNoPartlyBox-${data.id}`}
        className={`questionYesNoPartlyBox ${error ? 'error' : ''}`}
      >
        <div
          id={`questionYesNoPartlyNumber-${data.id}`}
          className="questionYesNoPartlyNumber"
        >
          {data.questionOrder < 10
            ? `0${data.questionOrder}.`
            : `${data.questionOrder}.`}
        </div>
        <div
          id={`questionYesNoPartlyName-${data.id}`}
          className="questionYesNoPartlyName"
        >
          {data.name}
        </div>
        <div
          id={`questionYesNoPartlyAnswerBox-${data.id}`}
          className="questionYesNoPartlyAnswerBox"
        >
          {data?.choices && data?.choices.length > 0
            ? data.choices.map((item: QuestionnaireQuestionChoices) => {
                return (
                  <div
                    id={`${data.id}-answer-${item.id}`}
                    className={`status ${item.id === selected ? 'active' : ''}`}
                    role="button"
                    onClick={() => {
                      setSelected(item.id);
                    }}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <div
                      id={`${data.id}-answer-${item.id}-labelStatus`}
                      className="labelStatus"
                    >
                      <span className="number">{item.name}</span>
                    </div>
                    <div
                      id={`${data.id}-answer-${item.id}-boxStatus`}
                      className="boxStatus"
                    >
                      <div
                        id={`${data.id}-answer-${item.id}-circleBox`}
                        className={`circleBox ${
                          item.id === selected ? 'active' : ''
                        }`}
                      >
                        <>
                          {item.id === selected ? (
                            <RenderSvg nameSvg="ACCEPTEDStatus" />
                          ) : (
                            ''
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
      {selected !== undefined && selected !== null ? (
        <div
          id={`questionYesNoPartlySubstantiationBox-${data.id}`}
          className="questionYesNoPartlySubstantiationBox"
        >
          <EditorMce
            id={`answerText-${data.id}`}
            name={`answerText-${data.id}`}
            label="Uzasadnienie:"
            noInfoPlace
            onChange={(textMce: string) => {
              if (changeTextHandle) {
                changeTextHandle(textMce);
              }
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellTrainingsStatusChange;
