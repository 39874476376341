import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  AdditionalAction,
  AnimatedProgressBarLine,
  Box,
  CustomFilterData,
  CustomFilterLastFirstName,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomInfoBlock,
  CustomSort,
  CustomSortData,
  LoaderTable,
  RenderSvg,
  ShowTitleTable,
  TableCellAppraisalStatus,
  TableCellComment,
  TableCellLastFirstName,
  TableCellListToCell,
  TableCellLoyaltyStatus,
  TableCellNumberPrecent,
  TableCellProgressBar,
  TableCellTypeGoal,
  TablePagination,
  TableRecordAction,
} from 'components';
import './employeeAppraisalGoalList.scss';
import { EmployeeAppraisalGoalModel, PagedData } from 'api/types';
import {
  ArchiveAction,
  EditAction,
} from 'components/Table/TableRecordAction/Action';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { EmployeeAppraisalRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';

interface Props {
  dataGoal: EmployeeAppraisalGoalModel[];
  setEditFormShow: Dispatch<SetStateAction<boolean>>;
  setModalId: Dispatch<SetStateAction<number>>;
  deleteAction?: (idClick: number) => void;
  onlyView?: boolean;
}

const IDPEmployeeAreaBlock = (props: Props): ReactElement => {
  const {
    dataGoal,
    setEditFormShow,
    setModalId,
    deleteAction,
    onlyView,
  } = props;

  const [page, setPage] = React.useState(1);

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            editClick={() => {
              setModalId(row.id);
              setEditFormShow(true);
            }}
            deleteClick={
              deleteAction
                ? () => {
                    if (deleteAction) {
                      deleteAction(row.id);
                    }
                  }
                : undefined
            }
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={100}
          />
        );
      },
    },
    {
      dataField: 'type',
      text: 'Rodzaj celu',
      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeGoal id={row.id} type={row.type} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: 'Nazwa celu',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'weight',
      text: 'Waga %',
      align: 'center',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellNumberPrecent number={row.weight} />;
      },
      headerStyle: () => {
        return { width: '110px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'measure',
      text: 'KPI/ Mierniki',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'realizationDate',
      text: 'Data realizacji',
      align: 'center',
      headerStyle: () => {
        return { width: '110px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      align: 'center',

      // @ts-ignore
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellAppraisalStatus id={row.id} status={row.status} />;
      },
      headerStyle: () => {
        return { width: '140px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
    {
      dataField: 'progress',
      text: 'Postęp realizacji',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellProgressBar id={row.id} valueEnd={row.progress} />;
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'comment',
      text: 'Komentarz',
      align: 'center',
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellComment
            title="Komentarz do celu"
            id={row.id}
            comment={row.comment}
          />
        );
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
    },
  ];

  return (
    <>
      <Box
        id="IDPEmployeeAreaBox"
        lightHeader
        name="Lista dodanych celów rocznych:"
        noRadius
      >
        <>
          <BootstrapTable
            bootstrap4
            hover
            bordered={false}
            keyField="id"
            data={dataGoal || []}
            columns={columns}
            noDataIndication="Brak danych"
            remote={{
              filter: true,
              sort: true,
            }}
            filter={filterFactory()}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
          />
        </>
      </Box>
    </>
  );
};

export default IDPEmployeeAreaBlock;
