import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  TrainingsCompanyContactRepository,
  TrainingsGroupRepository,
  TrainingsProviderRepository,
} from 'api/repository';
import {
  TrainingsGroupTraining,
  TrainingsGroupTrainingAddPut,
} from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  EditorMce,
  LoaderForm,
  ExitFromForm,
  CustomSelect,
  CustomContainer,
  CustomDate,
  CustomNumber,
  ContentBox,
} from 'components';
import TrainingsGroup from 'constants/TrainingsGroup';
import ModalProviderContact from '../../../../Settings/TrainingsProvider/TrainingsProviderContact/ModalContactAdd';
import ModalCompanyContact from '../../../../Settings/CompanyContact/ModalContactAdd';

import './basicInformation.scss';

type TrainingsGroupTrainingValues = Omit<
  Omit<
    Omit<Omit<TrainingsGroupTrainingAddPut, 'trainingId'>, 'finalCost'>,
    'questionnaireId'
  >,
  'id'
>;

interface TrainingsGroupTrainingProps {
  id?: number;
  onTrainingsProvider?: any;
  onTrainingsProviderAndExit?: any;
  data?: TrainingsGroupTrainingAddPut | TrainingsGroupTraining | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormTrainingsProvider = (
  props: TrainingsGroupTrainingProps
): ReactElement => {
  const {
    id,
    onTrainingsProvider,
    edit,
    data,
    onlyView,
    onTrainingsProviderAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsGroup');

  const [
    modalProvidersContactAdd,
    setModalProvidersContactAdd,
  ] = useState<boolean>(false);
  const [modalCompanyContactAdd, setModalCompanyContactAdd] = useState<boolean>(
    false
  );

  const [dataTrainingsGroup] = TrainingsGroupRepository.useGetTraining({
    id: Number(id),
  });

  const [typeTrainings, setTypeTrainings] = useState<string>('STUDIES');

  useEffect(() => {
    if (dataTrainingsGroup !== undefined && dataTrainingsGroup !== null) {
      setTypeTrainings(dataTrainingsGroup.training.type);
    }
  }, [dataTrainingsGroup]);

  const formSchema: yup.SchemaOf<TrainingsGroupTrainingValues> = yup
    .object()
    .shape({
      providerId: yup.number().required('Dostawca jest wymagany'),
      termType: yup.string().required('Rodzaj terminu jest wymagany'),
      singleDate: yup.string().when('termType', {
        is: 'ONE_DAY',
        then: yup.string().required('Termin szkolenia jest wymagany'),
        otherwise: yup.string().notRequired(),
      }),
      startDate: yup.string().when('termType', {
        is: 'MULTI_DAY',
        then: yup
          .string()
          .required('Termin rozpoczęcia szkolenia jest wymagany'),
        otherwise: yup.string().notRequired(),
      }),
      endDate: yup
        .string()
        .when('termType', {
          is: 'MULTI_DAY',
          then: yup
            .string()
            .required('Termin zakończenia szkolenia jest wymagany'),
          otherwise: yup.string().notRequired(),
        })
        .test(
          'startDate',
          'Data końca szkolenia nie może być wcześniejsza niż data rozpoczęcia',
          function endDateValidStartDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.endDate) <= new Date(this.parent.startDate)
            ) {
              return false;
            }
            return true;
          }
        ),
      trainingForm: yup.string().required('Rodzaj szkolenia jest wymagany'),
      minPeople: yup
        .number()
        .typeError('Minimalna liczba osób jest wymagana')
        .required('Minimalna liczba osób jest wymagana'),
      maxPeople: yup
        .number()
        .typeError('Maksymalna liczba osób jest wymagana')
        .required('Maksymalna liczba osób jest wymagana'),
      companyContactId: yup.number().required('Pole wymagane'),
      providerContactId: yup.number().required('Pole wymagane'),
      settlementType: yup
        .string()
        .required('Rodzaj ceny szkolenia jest wymagany'),
      settlementAmount: yup
        .number()
        .typeError('Kwota jest wymagana')
        .required('Kwota jest wymagana'),
      place: yup.string().required('Miejsce szkolenia jest wymagane'),
      additionalInformation: yup.string().notRequired(),
      suffix: yup
        .string()
        .test('suffixStudies', 'Pole wymagane', function suffixStudies() {
          // eslint-disable-next-line
          if (typeTrainings === 'STUDIES' && this.parent.suffix === '') {
            return false;
          }
          return true;
        }),
    });

  const [updateProviders, setUpdateProviders] = useState<number>(0);

  const [
    getAllProviders,
    dataAllProviders,
  ] = TrainingsGroupRepository.useGetAllTrainingsProviderSimple();

  useEffect(() => {
    getAllProviders();
  }, [getAllProviders, updateProviders]);

  const providersOptions = () => {
    const arrayProvidersOption = [] as any;
    /* arrayProvidersOption.push({
      value: 'new',
      label: t('trainingsForm.addNewProviders'),
    }); */
    if (dataAllProviders !== null && dataAllProviders.content.length > 0) {
      Object.keys(dataAllProviders.content).forEach((key) => {
        arrayProvidersOption.push({
          value: dataAllProviders.content[key].id,
          label: dataAllProviders.content[key].name,
        });
      });
    }
    return arrayProvidersOption;
  };

  const [
    updateDataProviderContact,
    setUpdateDataProviderContact,
  ] = useState<number>(0);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    errors,
  } = useForm<TrainingsGroupTrainingValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const [providerContactIdClear, setProviderContactId] = useState<
    number | undefined
  >(undefined);

  const [
    getDataProviderContact,
    dataProviderContact,
  ] = TrainingsGroupRepository.useGetAllTrainingsProviderContact();

  useEffect(() => {
    if (watch('providerId') !== undefined && watch('providerId') !== null) {
      getDataProviderContact({
        id: watch('providerId'),
      });
      setProviderContactId(Math.random);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDataProviderContact, updateDataProviderContact, watch('providerId')]);

  const contactProviderOptions = () => {
    const arrayTypeOption = [] as any;
    if (watch('providerId') !== undefined) {
      arrayTypeOption.push({
        value: 'new',
        label: 'Dodaj nowy kontakt do dostawcy',
      });
      if (dataProviderContact !== null) {
        Object.keys(dataProviderContact.content).forEach((key) => {
          arrayTypeOption.push({
            value: dataProviderContact.content[key].id,
            label: dataProviderContact.content[key].name,
          });
        });
      }
    }
    return arrayTypeOption;
  };

  const onNewProviderContact = () => {
    setModalProvidersContactAdd(true);
  };

  const [
    updateDataCompanyContact,
    setUpdateDataCompanyContact,
  ] = useState<number>(0);
  const [
    getDataCompanyContact,
    dataCompanyContact,
  ] = TrainingsCompanyContactRepository.useGetAllTrainingsCompanyContact({});

  useEffect(() => {
    getDataCompanyContact();
  }, [getDataCompanyContact, updateDataCompanyContact]);

  const contactCompanyOptions = () => {
    const arrayTypeOption = [] as any;
    arrayTypeOption.push({
      value: 'new',
      label: 'Dodaj nowy kontakt po stronie LF',
    });
    if (dataCompanyContact !== null) {
      Object.keys(dataCompanyContact.content).forEach((key) => {
        arrayTypeOption.push({
          value: dataCompanyContact.content[key].id,
          label: dataCompanyContact.content[key].name,
        });
      });
    }
    return arrayTypeOption;
  };

  const onNewCompanyContact = () => {
    setModalCompanyContactAdd(true);
  };

  const termTypeOptions = () => {
    const arrayTypeOption = [] as any;
    Object.keys(TrainingsGroup.TermType).forEach((key) => {
      arrayTypeOption.push({
        value: TrainingsGroup.TermType[key],
        label: t(`TermTypeEnum.${TrainingsGroup.TermType[key]}`),
      });
    });
    return arrayTypeOption;
  };

  const trainingFormOptions = () => {
    const arrayTypeOption = [] as any;
    Object.keys(TrainingsGroup.TrainingFormType).forEach((key) => {
      arrayTypeOption.push({
        value: TrainingsGroup.TrainingFormType[key],
        label: t(
          `TrainingFormTypeEnum.${TrainingsGroup.TrainingFormType[key]}`
        ),
      });
    });
    return arrayTypeOption;
  };

  const settlementTypeOptions = () => {
    const arrayTypeOption = [] as any;
    Object.keys(TrainingsGroup.SettlementType).forEach((key) => {
      arrayTypeOption.push({
        value: TrainingsGroup.SettlementType[key],
        label: t(`SettlementType.${TrainingsGroup.SettlementType[key]}`),
      });
    });
    return arrayTypeOption;
  };

  const handleSave = (dataSubmit: TrainingsGroupTrainingValues) => {
    setChangeForm(false);
    onTrainingsProvider(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: TrainingsGroupTrainingValues) => {
    setChangeForm(false);
    onTrainingsProviderAndExit(dataSubmit);
  };

  return (
    <CustomContainer id="formTrainingsGroupBasicInformationBox">
      <>
        {data === null ? (
          <ErrorLoadData />
        ) : (
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="form"
              id="formTrainingsGroupBasicInformation"
              onChange={(e) => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={data === undefined && edit} type="getData">
                <>
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      {typeTrainings === 'STUDIES' ? (
                        <ContentBox
                          id="formTrainingsGroupBasicInformationStudiesRow"
                          bottomBorder
                        >
                          <>
                            <div className="studiesBox">
                              <CustomInput
                                name="suffix"
                                label="Nazwa studiów:"
                                register={register}
                                error={errors?.suffix?.message}
                                defaultValue={data?.suffix}
                                disabled={onlyView}
                                toLeft
                                required
                              />
                            </div>
                          </>
                        </ContentBox>
                      ) : (
                        ''
                      )}

                      <ContentBox
                        id="formTrainingsGroupBasicInformationFirstRow"
                        bottomBorder
                      >
                        <>
                          <div className="providerSelect">
                            <CustomSelect
                              options={providersOptions()}
                              setValue={setValue}
                              valueByKey={
                                dataAllProviders !== null &&
                                dataAllProviders !== undefined
                                  ? data?.providerId
                                  : undefined
                              }
                              error={errors?.providerId?.message}
                              name="providerId"
                              label="Dostawca szkolenia:"
                              fullWidth
                              required
                              height33
                              noInfo
                            />
                          </div>
                          <div className="termTypeSelect">
                            <CustomSelect
                              options={termTypeOptions()}
                              name="termType"
                              label="Rodzaj terminu:"
                              setValue={setValue}
                              valueByKey={data?.termType}
                              error={errors?.termType?.message}
                              fullWidth
                              required
                              height33
                              noInfo
                            />
                          </div>

                          {watch('termType') !== 'ONE_DAY' ? (
                            <div className="dateTrainings">
                              <div className="labelBox">Termin szkolenia:</div>
                              <div className="dateBox">
                                <CustomDate
                                  id="startDate"
                                  name="startDate"
                                  label="Od:"
                                  setValue={setValue}
                                  error={errors?.startDate?.message}
                                  defaultValue={
                                    data?.startDate === null ||
                                    data?.startDate === undefined
                                      ? undefined
                                      : data?.startDate.substring(0, 10)
                                  }
                                  required={watch('termType') === 'MULTI_DAY'}
                                  growInput
                                  toLeft
                                  smallPaddingBlock
                                  noInfo
                                  textAlign="toLeft"
                                />
                                <CustomDate
                                  id="endDate"
                                  name="endDate"
                                  label="Do:"
                                  setValue={setValue}
                                  error={errors?.endDate?.message}
                                  defaultValue={
                                    data?.endDate === null ||
                                    data?.endDate === undefined
                                      ? undefined
                                      : data?.endDate.substring(0, 10)
                                  }
                                  required={watch('termType') === 'MULTI_DAY'}
                                  growInput
                                  toLeft
                                  smallPaddingBlock
                                  noInfo
                                  textAlign="toLeft"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="dateSingleTrainings">
                              <div className="labelBox">Termin szkolenia:</div>
                              <div className="dateSingleBox">
                                <CustomDate
                                  id="singleDate"
                                  name="singleDate"
                                  setValue={setValue}
                                  error={errors?.singleDate?.message}
                                  defaultValue={
                                    data?.singleDate === null ||
                                    data?.singleDate === undefined
                                      ? undefined
                                      : data?.singleDate.substring(0, 10)
                                  }
                                  required={watch('termType') === 'ONE_DAY'}
                                  growInput
                                  toLeft
                                  smallPaddingBlock
                                  noInfo
                                  textAlign="toLeft"
                                />
                              </div>
                            </div>
                          )}

                          <div className="trainingFormSelect">
                            <CustomSelect
                              options={trainingFormOptions()}
                              name="trainingForm"
                              label="Rodzaj szkolenia:"
                              setValue={setValue}
                              error={errors?.trainingForm?.message}
                              valueByKey={data?.trainingForm}
                              fullWidth
                              required
                              height33
                              noInfo
                            />
                          </div>
                          <div className="minPeopleBox">
                            <CustomNumber
                              name="minPeople"
                              label="Minimalna liczba osób:"
                              register={register}
                              defaultValue={
                                data?.minPeople
                                  ? `${data?.minPeople}`
                                  : undefined
                              }
                              error={errors?.minPeople?.message}
                              required
                              noInfo
                              toLeft
                            />
                          </div>
                          <div className="maxPeopleBox">
                            <CustomNumber
                              name="maxPeople"
                              label="Maksymalna liczba osób:"
                              register={register}
                              defaultValue={
                                data?.maxPeople
                                  ? `${data?.maxPeople}`
                                  : undefined
                              }
                              error={errors?.maxPeople?.message}
                              required
                              noInfo
                              toLeft
                            />
                          </div>
                        </>
                      </ContentBox>
                      <ContentBox
                        id="formTrainingsGroupBasicInformationSecondRow"
                        bottomBorder
                      >
                        <>
                          <div className="contactCompanySelect">
                            <CustomSelect
                              options={contactCompanyOptions()}
                              name="companyContactId"
                              label="Opiekun szkolenia po stronie LF:"
                              error={errors?.companyContactId?.message}
                              setValue={setValue}
                              valueByKey={
                                dataCompanyContact !== null &&
                                dataCompanyContact !== undefined
                                  ? data?.companyContactId
                                  : undefined
                              }
                              fullWidth
                              required
                              height33
                              noInfo
                              onNewRecord={onNewCompanyContact}
                            />
                          </div>
                          <div className="contactProviderSelect">
                            <CustomSelect
                              options={contactProviderOptions()}
                              name="providerContactId"
                              label="Opiekun szkolenia po stronie dostawcy:"
                              error={errors?.providerContactId?.message}
                              setValue={setValue}
                              valueByKey={
                                dataProviderContact !== null &&
                                dataProviderContact !== undefined
                                  ? data?.providerContactId
                                  : undefined
                              }
                              fullWidth
                              required
                              height33
                              noInfo
                              clearValue={providerContactIdClear}
                              noOptions={
                                watch('providerId') === undefined ||
                                watch('providerId') === null
                                  ? 'Wybierz najpierw dostawcę szkolenia'
                                  : undefined
                              }
                              onNewRecord={onNewProviderContact}
                            />
                          </div>
                          <div className="settlementTypeSelect">
                            <CustomSelect
                              options={settlementTypeOptions()}
                              name="settlementType"
                              label="Określ rodzaj ceny szkolenia:"
                              setValue={setValue}
                              error={errors?.settlementType?.message}
                              valueByKey={data?.settlementType}
                              fullWidth
                              required
                              height33
                              noInfo
                            />
                          </div>

                          <div className="settlementAmountBox">
                            <CustomNumber
                              name="settlementAmount"
                              label="Kwota:"
                              register={register}
                              defaultValue={
                                data?.settlementAmount
                                  ? `${data?.settlementAmount}`
                                  : undefined
                              }
                              error={errors?.settlementAmount?.message}
                              required
                              noInfo
                            />
                            <span>PLN</span>
                          </div>
                          <div className="placeBox">
                            <CustomInput
                              name="place"
                              label="Miejsce szkolenia:"
                              register={register}
                              error={errors?.place?.message}
                              defaultValue={data?.place}
                              disabled={onlyView}
                              toLeft
                              required
                            />
                          </div>
                        </>
                      </ContentBox>
                      <ContentBox id="formTrainingsGroupBasicInformationThirtRow">
                        <EditorMce
                          id="additionalInformation"
                          name="additionalInformation"
                          register={register}
                          contentGet={data?.additionalInformation}
                          setChangeForm={setChangeForm}
                          label="Informacje dodatkowe dla uczestników szkolenia:"
                        />
                      </ContentBox>
                    </>
                  </LoaderForm>
                  {!onlyView ? (
                    <CustomSubmit
                      save={
                        onTrainingsProvider
                          ? handleSubmit(handleSave)
                          : undefined
                      }
                      saveAndExit={
                        onTrainingsProviderAndExit
                          ? handleSubmit(handleSaveAndExit)
                          : undefined
                      }
                      submitForm={submitForm}
                      cancel={cancel}
                    />
                  ) : (
                    ''
                  )}
                </>
              </LoaderForm>
            </Form>
            {modalProvidersContactAdd ? (
              <ModalProviderContact
                id={watch('providerId')}
                changeModalAdd={setModalProvidersContactAdd}
                changeUpdateTableData={setUpdateDataProviderContact}
                saveAndExitOnly
              />
            ) : (
              ''
            )}
            {modalCompanyContactAdd ? (
              <ModalCompanyContact
                changeModalAdd={setModalCompanyContactAdd}
                changeUpdateTableData={setUpdateDataCompanyContact}
                saveAndExitOnly
              />
            ) : (
              ''
            )}
          </>
        )}
      </>
    </CustomContainer>
  );
};

export default FormTrainingsProvider;
