/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './TableCellInput.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  dataId: string;
  id: string;
  justify?: 'toLeft' | 'center' | 'toRight';
  selectedData?: any;
  handleChange: any;
  placeholder?: string;
  defaultValue?: string;
  maxLength?: number;
}

const TableCellInput = (props: Props): ReactElement => {
  const {
    dataId,
    justify,
    id,
    selectedData,
    handleChange,
    placeholder,
    defaultValue,
    maxLength,
  } = props;

  const [value, setValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {dataId ? (
        <div
          className={`tableCellInputBox ${
            justify !== undefined ? justify : 'toLeft'
          }`}
        >
          <Form.Control
            id={`id-${dataId}`}
            placeholder={placeholder}
            maxLength={maxLength || 255}
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              handleChange(dataId, e.target.value);
            }}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellInput;
