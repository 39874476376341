import React, {
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PlansTrainingsRepository } from 'api/repository';
import {
  TablePagination,
  LoaderTable,
  TableRecordAction,
  CustomSort,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomFilterSelect,
  TableCellTypeTrainings,
  CustomFilterData,
  CustomSortData,
  Modal,
  Box,
  TableCellImport,
  CustomFilterSelectTrainingType,
} from 'components';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import TrainingsType from '../../../../../../constants/Trainings';
import ModalTrainingsShow from '../../../../TrainingsAndCompetence/Trainings/ModalTrainingsShow';
import ModalTrainingsAdd from '../../../../TrainingsAndCompetence/Trainings/ModalTrainingsAdd';

interface Props {
  id: number;
  changeShowModal: Dispatch<SetStateAction<boolean>>;
  updateTable: Dispatch<SetStateAction<number>>;
}

const ImportTrainingModal = (props: Props): ReactElement => {
  const { t } = useTranslation(['plansTrainings', 'trainings']);

  const { id, changeShowModal, updateTable } = props;

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalAdd, setModalAdd] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    name: 'name',
    type: 'type',
    providerName: 'providerName',
  };
  // End variable search

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    name: 'default',
    type: 'TYPE',
    providerName: 'PROVIDER_NAME',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getDataTable,
    dataTable,
  ] = PlansTrainingsRepository.useGetAllTrainingsToImport(id);

  const importTraining = PlansTrainingsRepository.useImportTrainingToPlan(
    Number(id),
    async () => {
      setUpdateDataTable(Math.random);
      updateTable(Math.random);
    }
  );

  const handleImportTraining = (idClick: number) => {
    importTraining(idClick);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getDataTable(paramQuery);
  }, [
    getDataTable,
    page,
    rowsPerPage,
    filterReq,
    orderColumn,
    updateDataTable,
  ]);

  useEffect(() => {
    if (dataTable !== null) {
      setLoaderTable(false);
    }
  }, [dataTable]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const handleOpenAddForm = () => {
    setModalAdd(true);
  };

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            importClick={() => {
              handleImportTraining(row.id);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('trainings:trainingsTable.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'providerName',
      text: t('trainings:trainingsTable.providerName'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'type',
      text: t('trainings:trainingsTable.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="left"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.type} justify="toLeft" />;
      },
    },
    {
      dataField: '',
      text: t('importTrainings.import'),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return <CustomHeaderFormatter column={column} align="right" />;
      },
      formatter: (_cell: any, row: any) => {
        return (
          <TableCellImport
            id={row.id}
            handleImport={handleImportTraining}
            justify="toRight"
          />
        );
      },
    },
  ];

  return (
    <Modal open changeModalShow={changeShowModal}>
      <Box
        id="importTrainingToPlan"
        name={t('importTrainings.boxName')}
        noMarginBottom
        noPadding
        noShadow
      >
        <>
          <LoaderTable active={loaderTable}>
            <>
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={dataTable}
                changePage={setPage}
              >
                <Button className="green" onClick={() => handleOpenAddForm()}>
                  {t('trainings:trainingsTable.addTrainings')}
                </Button>
              </TablePagination>

              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={dataTable?.content || []}
                columns={columns}
                noDataIndication={t('importTrainings.noDataTable')}
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={dataTable}
                changePage={setPage}
              />
            </>
          </LoaderTable>
          {modalShow ? (
            <ModalTrainingsShow id={modalId} changeModalShow={setModalShow} />
          ) : (
            ''
          )}

          {modalAdd ? (
            <ModalTrainingsAdd
              changeModalAdd={setModalAdd}
              changeUpdateTableData={setUpdateDataTable}
              onlySaveAndExit
            />
          ) : (
            ''
          )}
        </>
      </Box>
    </Modal>
  );
};

export default ImportTrainingModal;
