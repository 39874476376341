import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsProviderRepository } from 'api/repository';
import { TrainingsProvider } from 'api/types';
import { Modal } from 'components';
import FormTrainingsProvider from '../Form/FormTrainingsProvider';

interface TrainigsProviderProps {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const TrainingsProviderAddModal = (
  props: TrainigsProviderProps
): ReactElement => {
  const { changeModalAdd, changeUpdateTableData } = props;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const handleAddTrainingsProvider = TrainingsProviderRepository.useAddTrainingsProvider(
    async (addData) => {
      setSubmitForm(false);
      changeModalAdd(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAndExit = TrainingsProviderRepository.useAddTrainingsProvider(
    async () => {
      setSubmitForm(false);
      changeModalAdd(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      handleAddTrainingsProvider(data);
    }
  };

  const handleSubmitSaveAndExit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      handleAddTrainingsProviderAndExit(data);
    }
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal
      open
      changeModalShow={changeModalAdd}
      widthBox="medium"
      maxLevel="lvl3"
    >
      <div>
        <FormTrainingsProvider
          onTrainingsProviderAndExit={handleSubmitSaveAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderAddModal;
