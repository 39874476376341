/* eslint-disable no-nested-ternary */
import React, { ReactElement, useState } from 'react';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ShowTextarea,
} from 'components';
import { EmployeeAppraisalRepository } from 'api/repository';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalPutSubordinatesGoal } from 'api/types';
import './employee.scss';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';
import TableEmployeeBlock from './TableEmployeeBlock';

const Employee = (): ReactElement => {
  return (
    <>
      <CustomContainer>
        <>
          <Box
            id="managerEmployeeAppraisalEmployeeFeedbackBox"
            name="LISTA PRACOWNIKÓW"
            svgIcon="employeeAppraisalList"
            noPadding
          >
            <>
              <TableEmployeeBlock />
            </>
          </Box>
        </>
      </CustomContainer>
    </>
  );
};

export default Employee;
