import React, { ReactElement, Dispatch, SetStateAction, useState } from 'react';
import { TrainingsProviderAddressRepository } from 'api/repository';
import { TrainingsProviderAddressAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsProviderAddress from '../Form/FormTrainingsProviderAddress';

interface TrainigsProviderProps {
  id: number;
  idRecord: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const TrainingsProviderEdit = (props: TrainigsProviderProps): ReactElement => {
  const { id, changeModalEdit, idRecord, changeUpdateTableData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    trainingProviderAddress,
  ] = TrainingsProviderAddressRepository.useGetTrainingsProviderAddress({
    id,
    idRecord,
  });

  const putDataSave = TrainingsProviderAddressRepository.usePutTrainingsProviderAddress(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAddress = (
    dataPost: TrainingsProviderAddressAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    data.providerId = id;
    putDataSave(data);
  };

  const postDataSaveAndExit = TrainingsProviderAddressRepository.usePutTrainingsProviderAddress(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAddressAndExit = (
    dataPost: TrainingsProviderAddressAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    data.providerId = id;
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit} title="test">
      <div>
        <FormTrainingsProviderAddress
          data={trainingProviderAddress}
          edit
          onSave={handleAddTrainingsProviderAddress}
          onSaveAndExit={handleAddTrainingsProviderAddressAndExit}
          cancel={handleCancel}
          submitForm={submitForm}
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderEdit;
