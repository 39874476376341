import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsProviderRepository } from 'api/repository';
import { CustomContainer, Tabs } from 'components';
import { TrainingsProvider } from 'api/types';
import FormTrainingsProvider from '../Form/FormTrainingsProvider';
import TableAddress from '../TrainingsProviderAddress';
import TableContact from '../TrainingsProviderContact';

const TrainingsProviderEditPage = (): ReactElement => {
  const history = useHistory();
  const { id, action } = useParams<{ id: string; action: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    trainingProvider,
  ] = TrainingsProviderRepository.useGetTrainingsProvider({ id: Number(id) });

  const updateTrainingProvider = TrainingsProviderRepository.usePutTrainingsProvider(
    Number(id),
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const updateTrainingProviderAndExit = TrainingsProviderRepository.usePutTrainingsProvider(
    Number(id),
    async () => {
      setSubmitForm(false);
      history.push(`/ustawienia/szkolenia/dostawcy-szkolen/`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      updateTrainingProvider(data);
    }
  };

  const handleSubmitSaveAndExit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      updateTrainingProviderAndExit(data);
    }
  };

  const handleCancel = () => {
    history.push(`/ustawienia/szkolenia/dostawcy-szkolen/`);
  };

  return (
    <CustomContainer>
      <>
        <Tabs
          id="PlansTrainingsTabs"
          items={[
            {
              id: 'dane-dostawcy',
              label: 'Dane dostawcy',
              link: `/ustawienia/szkolenia/dostawcy-szkolen/formularz/${id}/dane-dostawcy`,
              svgName: 'tabPeople',
            },
            {
              id: 'dane-kontaktowe',
              label: 'Kontakty dostawcy',
              link: `/ustawienia/szkolenia/dostawcy-szkolen/formularz/${id}/dane-kontaktowe`,
              svgName: 'tabContact',
            },
            {
              id: 'dane-adresowe',
              label: 'Adresy dostawcy',
              link: `/ustawienia/szkolenia/dostawcy-szkolen/formularz/${id}/dane-adresowe`,
              svgName: 'tabAdress',
            },
          ]}
        />
        {action === 'dane-dostawcy' ? (
          <FormTrainingsProvider
            id={Number(id)}
            onTrainingsProvider={handleSubmit}
            onTrainingsProviderAndExit={handleSubmitSaveAndExit}
            data={trainingProvider}
            edit
            submitForm={submitForm}
            cancel={handleCancel}
          />
        ) : (
          ''
        )}
        {action === 'dane-kontaktowe' ? <TableContact id={Number(id)} /> : ''}
        {action === 'dane-adresowe' ? <TableAddress id={Number(id)} /> : ''}
      </>
    </CustomContainer>
  );
};

export default TrainingsProviderEditPage;
