/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { CustomInfoBlock, RenderSvg } from 'components';
import './TableCellTrainingsStatusChange.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  id: number;
  dataType: 'hr' | 'ds' | 'slt';
  justify?: 'toLeft' | 'center' | 'toRight';
  acceptedHandle: () => void;
  rejectedHandle: () => void;
  verifyHandle: () => void;
}

const TableCellTrainingsStatusChange = (props: Props): ReactElement => {
  const {
    id,
    data,
    justify,
    dataType,
    acceptedHandle,
    rejectedHandle,
    verifyHandle,
  } = props;

  return (
    <>
      <div className={`tableCellTrainingsStatusChange ${justify || ''}`}>
        {data[`${dataType}Accepted`] === 'NONE' ? (
          <div className="boxStatus">
            <div className="circleBox">
              <RenderSvg nameSvg="NONEStatus" />
            </div>
          </div>
        ) : (
          <>
            <div
              id={`${dataType}Accepted`}
              className="status"
              role="button"
              onClick={() => {
                if (data[`${dataType}Accepted`] !== 'ACCEPTED') {
                  acceptedHandle();
                }
              }}
              onKeyUp={() => {}}
              tabIndex={0}
            >
              <div className="labelStatus">Akceptacja</div>
              <div className="boxStatus">
                <div
                  className={`accepted circleBox ${
                    data[`${dataType}Accepted`] === 'ACCEPTED' ? 'selected' : ''
                  }`}
                >
                  <RenderSvg nameSvg="ACCEPTEDStatus" />
                </div>
              </div>
            </div>
            <div
              id={`${dataType}Verify`}
              className="status"
              role="button"
              onClick={() => {
                if (data[`${dataType}Accepted`] !== 'VERIFY') {
                  verifyHandle();
                }
              }}
              onKeyUp={() => {}}
              tabIndex={0}
            >
              <div className="labelStatus">Brak decyzji</div>
              <div className="boxStatus">
                <div
                  className={`verify circleBox ${
                    data[`${dataType}Accepted`] === 'VERIFY' ? 'selected' : ''
                  }`}
                >
                  <RenderSvg nameSvg="VERIFYStatus" />
                </div>
              </div>
            </div>
            <div
              id={`${dataType}Rejected`}
              className="status"
              role="button"
              onClick={() => {
                if (data[`${dataType}Accepted`] !== 'REJECTED') {
                  rejectedHandle();
                }
              }}
              onKeyUp={() => {}}
              tabIndex={0}
            >
              <div className="labelStatus">Brak zgody</div>
              <div className="boxStatus">
                {data[`${dataType}Accepted`] === 'REJECTED' &&
                data[`${dataType}Comment`] !== '' ? (
                  <CustomInfoBlock
                    customText={data[`${dataType}Comment`]}
                    customTitle="Decyzja braku akceptacji"
                    customSvg="manager"
                    widthBox="medium"
                  >
                    <div
                      className={`rejected circleBox ${
                        data[`${dataType}Accepted`] === 'REJECTED'
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <RenderSvg nameSvg="REJECTEDStatusWithInfo" />
                    </div>
                  </CustomInfoBlock>
                ) : (
                  <div
                    className={`rejected circleBox ${
                      data[`${dataType}Accepted`] === 'REJECTED'
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <RenderSvg nameSvg="REJECTEDStatus" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TableCellTrainingsStatusChange;
