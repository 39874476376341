import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentBox,
  CustomFilterAddress,
  CustomFilterContact,
  CustomFilterData,
  CustomFilterSelectTrainingCompetence,
  CustomFilterSelectTrainingType,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  ShowTitleTable,
  TableCellAddress,
  TableCellContact,
  TableCellDeleteRow,
  TableCellListToCell,
  TableCellTrainingsStatus,
  TableCellTypeTrainings,
  TableCellYesNo,
  TablePagination,
  TableRecordAction,
} from 'components';
import { Button } from 'react-bootstrap';
import { ManagerUserPlansTrainingsRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { Link, useParams } from 'react-router-dom';
import ModalSelectTrainings from '../ModalSelectTrainings';
import ModalTrainingsShow from '../../../../Settings/TrainingsAndCompetence/Trainings/ModalTrainingsShow';

interface Props {
  maxLimits?: any;
  limitsData?: any;
  setUpdateLimits?: any;
  approve?: boolean;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { t } = useTranslation('plansTrainings');
  const { id } = useParams<{ id: string }>();

  const { maxLimits, limitsData, setUpdateLimits, approve } = props;

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    'training.name': 'name',
    'training.type': 'type',
    'training.competences': 'competences',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'training.name': 'TRAINING_NAME',
    'training.type': 'TYPE',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = ManagerUserPlansTrainingsRepository.useGetAllSelectedTrainingsActivePlans(
    id
  );

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [modalSelect, setModalSelect] = useState<boolean>(false);

  const handleModalSelect = () => {
    setModalSelect(true);
  };

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const DeleteRecord = ManagerUserPlansTrainingsRepository.useDeleteSeletedTrainings(
    id,
    async () => {
      setUpdateDataTable(Math.random);
      setUpdateLimits(Math.random);
    }
  );

  const handeDeleteTrainings = (idClick: number) => {
    DeleteRecord(idClick);
    setUpdateDataTable(Math.random);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      left: true,
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  function arrayRemove(arr, keyDelete) {
    return arr.filter((ele, key) => {
      return key !== keyDelete;
    });
  }

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.training.id}
            showClick={handleShowClick}
            deleteClick={
              approve === false
                ? () => handeDeleteTrainings(row.training.id)
                : undefined
            }
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      sort: true,
      text: t('userPlans.nameTrainings'),
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'training.type',
      text: t('userPlans.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellTypeTrainings data={row?.training.type} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingType
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
    },
    {
      dataField: 'training.competences',
      text: t('userPlans.competences'),
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell data={row?.training.competences} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelectTrainingCompetence
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
    },
    {
      dataField: 'training.sltAcceptanceRequired',
      text: t('userPlans.sltAcceptanceRequired'),
      headerStyle: () => {
        return { width: '120px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellYesNo
            data={row?.training.sltAcceptanceRequired}
            justify="center"
            additionalInfo={row?.sltMotivation}
            additionalInfoTitle="Powód dla którego pracownik odbyć to szkolenie"
            additionalInfoSvg="manager"
            widthBox="medium"
          />
        );
      },
    },
    /* {
      dataField: '',
      text: t('userPlans.delete'),
      headerStyle: () => {
        return { width: '50px' };
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellDeleteRow
            dataId={row?.training.id}
            deleteClick={() => handeDeleteTrainings(row.training.id)}
            justify="toRight"
          />
        );
      },
    }, */
    {
      dataField: '',
      text: approve ? t('userPlans.status') : t('userPlans.delete'),
      headerStyle: () => {
        if (approve) {
          return { width: '140px' };
        }
        return { width: '50px' };
      },
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="center"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <>
            {formatExtraData ? (
              <TableCellTrainingsStatus
                id={row.id}
                data={row}
                justify="toRight"
              />
            ) : (
              <TableCellDeleteRow
                dataId={row?.training.id}
                deleteClick={() => handeDeleteTrainings(row.training.id)}
                justify="toRight"
              />
            )}
          </>
        );
      },
      formatExtraData: approve,
    },
  ];

  return (
    <>
      {approve === false ? (
        <ContentBox id="userPlansTrainingsSelectTrainings">
          <>
            <Button className="green" onClick={() => handleModalSelect()}>
              {t('userPlans.selectTrainings')}
            </Button>
            {modalSelect}
            {modalSelect ? (
              <ModalSelectTrainings
                changeModalShow={setModalSelect}
                maxLimits={maxLimits}
                limitsData={limitsData}
                setUpdateLimits={setUpdateLimits}
                setUpdateDataTableParent={setUpdateDataTable}
                id={id}
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {modalShow ? (
        <ModalTrainingsShow changeModalShow={setModalShow} id={modalId} />
      ) : (
        ''
      )}

      <ContentBox id="userPlansTrainingsTableSelectedTrainings" noPadding>
        <>
          <LoaderTable active={loaderTable} noRadiusTop>
            <>
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              >
                <ShowTitleTable
                  id="titleTableTrainings"
                  label={
                    approve === false
                      ? t('userPlans.listTrainings')
                      : t('userPlans.listTrainingsApprove')
                  }
                />
              </TablePagination>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={data?.content || []}
                columns={columns}
                noDataIndication={t('userPlans.noDataTableSelected')}
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </>
      </ContentBox>
    </>
  );
};

export default TableTrainingsBlock;
