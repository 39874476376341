/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './showInput.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label: string;
  data?: string;
  fullWidth?: boolean;
  toLeft?: boolean;
}

const ShowName = (props: Props): ReactElement => {
  const { id, label, data, fullWidth, toLeft } = props;

  return (
    <div
      id={id}
      className={`showInput ${fullWidth ? 'fullWidth' : ''} ${
        toLeft ? 'toLeft' : ''
      }`}
    >
      <div id={`${id}-label`} className="label">
        {label}
      </div>
      <div id={`${id}-value`} className="data">
        {data || ''}
      </div>
    </div>
  );
};

export default ShowName;
