import React, { ReactElement, useState } from 'react';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
} from 'components';
import { IDPRepository } from 'api/repository';
import './employee.scss';
import TableEmployeeBlock from './TableEmployeeBlock';

const IDPEMployee = (): ReactElement => {
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [idp] = IDPRepository.useGetIDPSettings({});

  return (
    <>
      <CustomContainer allowGrow>
        <>
          <Box
            id="managerIDPEmployeeBox"
            name="IDP - LISTA PRACOWNIKÓW"
            svgIcon="enrollmentForTrainings"
            noPadding
          >
            <>
              <ContentBox
                id="managerIDPEmployeeBoxBasicInformation"
                bottomBorder
              >
                <>
                  <div className="planNameBox">
                    <div className="name">Indywidualny Plan Rozwoju</div>
                  </div>
                  <div className="dateBox">
                    <div id="frameDate" className="dateContent">
                      <div id="frameDateTitle" className="title">
                        Daty rozmów rocznych i wprowadzania danych:
                      </div>
                      <div id="frameDateDetail" className="content">
                        <div id="frameDatesIcon" className="icon">
                          <RenderSvg nameSvg="frameRegistrationDate" />
                        </div>
                        <div id="frameDateBoxDate" className="dateDetail">
                          <div id="frameDateStart">
                            Od:
                            <span className="start">
                              {idp?.annualInterviewStart}
                            </span>
                          </div>
                          <div id="frameDateEnd">
                            Do:
                            <span className="end">
                              {idp?.annualInterviewEnd}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="frameRegistrationDate" className="dateContent">
                      <div id="frameRegistrationDateTitle" className="title">
                        Daty weryfikacji półrocznej:
                      </div>
                      <div id="frameRegistrationDateDetail" className="content">
                        <div id="frameRegistrationDateIcon" className="icon">
                          <RenderSvg nameSvg="frameRegistrationProgressDate" />
                        </div>
                        <div
                          id="frameRegistrationDateBoxDate"
                          className="dateDetail"
                        >
                          <div id="frameRegistrationDateStart">
                            Od:
                            <span className="start">
                              {idp?.sixMonthInterviewStart}
                            </span>
                          </div>
                          <div id="frameRegistrationDateEnd">
                            Do:
                            <span className="end">
                              {idp?.sixMonthInterviewEnd}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="validationDate" className="dateContent">
                      <div id="validationDateTitle" className="title">
                        Daty weryfikacji rocznej:
                      </div>
                      <div id="validationDateDetail" className="content">
                        <div id="validationDateIcon" className="icon">
                          <RenderSvg nameSvg="frameRegistrationValidationDate" />
                        </div>
                        <div
                          id="frameRegistrationDateBoxDate"
                          className="dateDetail"
                        >
                          <div id="frameRegistrationDateStart">
                            Od:
                            <span className="start">
                              {idp?.nextAnnualInterviewStart}
                            </span>
                          </div>
                          <div id="frameRegistrationDateEnd">
                            Do:
                            <span className="end">
                              {idp?.nextAnnualInterviewEnd}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </ContentBox>
              <TableEmployeeBlock settingsIdp={idp} />
            </>
          </Box>
        </>
      </CustomContainer>
    </>
  );
};

export default IDPEMployee;
