import {
  CompetencesRequest,
  Error,
  Competences,
  CompetencesSimple,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: Competences | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number;
}

const useGetAllCompetences = (
  onError?: (error: AxiosError<Error>) => void
): [(params: CompetencesRequest) => void, PagedData<Competences> | null] => {
  const axios = useAxios();
  const [competence, setCompetence] = useState<PagedData<Competences> | null>(
    null
  );

  const doGet = useCallback(
    (params: CompetencesRequest) => {
      axios
        .get<PagedData<Competences>>(
          `${apiEndpoints.competences}/competences`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCompetence(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, competence];
};

const useGetCompetences = (
  props: PropsRecord
): [
  Competences | null | undefined,
  (competences: Competences) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [competences, setCompetences] = useState<
    Competences | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<Competences>(
        `${apiEndpoints.competences}/competences/${id}`
      );
      setCompetences(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setCompetences(null);
    }
    return null;
  }, [axios, id, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: Competences) => {
    setCompetences(data);
  }, []);

  return [competences, updateData, fetchData];
};

const useAddCompetences = (
  onCompletedPost?: (data: Competences) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<Competences, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (competences: Omit<Competences, 'id'>) => {
      try {
        const rData = await axios.post<Competences>(
          `${apiEndpoints.competences}/competences`,
          competences
        );

        AutoAlertStatus({ status: rData.status });

        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const usePutCompetences = (
  onCompletedPut?: (data: Competences) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: Competences) => Promise<void>) => {
  const axios = useAxios();

  const putCompetences = useCallback(
    async (competences: Competences) => {
      try {
        const rData = await axios.put<Competences>(
          `${apiEndpoints.competences}/competences/${competences.id}`,
          competences
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putCompetences;
};

const useDeleteCompetences = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteCompetences = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.competences}/competences/${id}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteCompetences;
};

const useGetAllCompetencesSimple = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, CompetencesSimple | null] => {
  const axios = useAxios();
  const [competence, setCompetence] = useState<CompetencesSimple | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<CompetencesSimple>(`${apiEndpoints.competences}/competences/all`)
      .then(({ data }) => {
        setCompetence(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  }, [axios, onError]);

  return [doGet, competence];
};

export default {
  useGetAllCompetences,
  useGetCompetences,
  useAddCompetences,
  usePutCompetences,
  useDeleteCompetences,
  useGetAllCompetencesSimple,
};
