import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['pl'];
const availableLanguages = ['pl'];

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  // .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    lng: 'pl',
    fallbackLng: 'pl', // fallback language is polish.
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },

    detection: {
      checkWhitelist: true, // options for language detection
    },

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
