import {
  EmployeeAppraisalRepository,
  IDPRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import { AuthResources, Box, CustomContainer, DesktopItem } from 'components';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import './panel.scss';

const Panel = (): ReactElement => {
  const history = useHistory();

  /// TRAINING START
  const [statusTrainingPlan] = TrainingsGroupRepository.useGetCurrentPlanTest(
    {}
  );

  const checkStatusTraining = () => {
    if (statusTrainingPlan === undefined) {
      return 'loading';
    }
    if (statusTrainingPlan === null) {
      return 'noActivePlan';
    }

    return null;
  };

  const setButtonTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return {
        text: 'Utwórz plan szkoleń',
        action: () => history.push('/ustawienia/szkolenia/plan-szkolen'),
      };
    }

    return undefined;
  };

  const setContentTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu szkoleń, utwórz go teraz.</div>
        </>
      );
    }

    if (checkStatusTraining() === null) {
      return (
        <>
          <div>Konfiguracja planu szkoleń jest poprawna.</div>
        </>
      );
    }

    return null;
  };

  /// TRAINING END

  /// IDP START
  const [statusIDP] = IDPRepository.useGetIDPSettings({});

  const checkStatusIDP = () => {
    if (statusIDP === undefined) {
      return 'loading';
    }
    if (statusIDP === null) {
      return 'noActivePlan';
    }

    if (
      statusIDP.annualInterviewStart === null ||
      statusIDP.annualInterviewEnd === null ||
      statusIDP.nextAnnualInterviewStart === null ||
      statusIDP.nextAnnualInterviewEnd === null ||
      statusIDP.sixMonthInterviewStart === null ||
      statusIDP.sixMonthInterviewEnd === null
    ) {
      return 'action';
    }

    return null;
  };

  const setButtonIDP = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusIDP() === 'noActivePlan') {
      return {
        text: 'Utwórz konfigurację',
        action: () => {
          history.push('/ustawienia/IDP/formularz');
        },
      };
    }
    if (checkStatusIDP() === 'action') {
      return {
        text: 'Edytuj konfigurację',
        action: () => {
          history.push('/ustawienia/IDP/formularz');
        },
      };
    }
    return undefined;
  };
  const setContentIDP = () => {
    if (checkStatusIDP() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusIDP() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP, utwórz go teraz.</div>
        </>
      );
    }
    if (checkStatusIDP() === 'action') {
      return (
        <>
          <div>Wprowadź brakujące dane do konfiguracji.</div>
        </>
      );
    }

    if (checkStatusIDP() === null) {
      return (
        <>
          <div>Dane w konfiguracj są poprawne.</div>
        </>
      );
    }

    return null;
  };

  /// IDP END

  /// Appraisal START
  const [
    statusAppraisal,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});
  const checkStatusAppraisal = () => {
    if (statusAppraisal === undefined) {
      return 'loading';
    }
    if (statusAppraisal === null) {
      return 'noActivePlan';
    }

    if (
      statusAppraisal.annualInterviewStart === null ||
      statusAppraisal.annualInterviewEnd === null ||
      statusAppraisal.nextAnnualInterviewStart === null ||
      statusAppraisal.nextAnnualInterviewEnd === null ||
      statusAppraisal.sixMonthInterviewStart === null ||
      statusAppraisal.sixMonthInterviewEnd === null
    ) {
      return 'action';
    }

    return null;
  };

  const setButtonAppraisal = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusAppraisal() === 'noActivePlan') {
      return {
        text: 'Utwórz konfiguracje',
        action: () => {
          history.push('/ustawienia/ocena-pracownika/formularz');
        },
      };
    }
    if (checkStatusAppraisal() === 'action') {
      return {
        text: 'Edytuj konfigurację',
        action: () => {
          history.push('/ustawienia/ocena-pracownika/formularz');
        },
      };
    }
    return undefined;
  };
  const setContentAppraisal = () => {
    if (checkStatusAppraisal() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusAppraisal() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnej konfiguracji, utwórz ją.</div>
        </>
      );
    }
    if (checkStatusAppraisal() === 'action') {
      return (
        <>
          <div>Konfiguracja wymaga uzupełnienia, zrób to teraz.</div>
        </>
      );
    }
    if (checkStatusAppraisal() === null) {
      return (
        <>
          <div>Dane w konfiguracji są poprawne.</div>
        </>
      );
    }

    return null;
  };

  /// Appraisal END

  return (
    <CustomContainer allowGrow>
      <>
        <Box
          id="settingsDesktop"
          name="PULPIT USTAWIEŃ"
          svgIcon="desktop"
          noContentStyle
        >
          <div id="settingsDesktopBox">
            <AuthResources requiredRoles={['training_department']}>
              <div id="settingsDesktopBoxContent">
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA"
                  buttonTitle={setButtonTraining()?.text}
                  buttonAction={setButtonTraining()?.action}
                >
                  <>{setContentTraining()}</>
                </DesktopItem>
                <DesktopItem
                  id="idp"
                  name="IDP"
                  buttonTitle={setButtonIDP()?.text}
                  buttonAction={setButtonIDP()?.action}
                >
                  <>{setContentIDP()}</>
                </DesktopItem>
                <DesktopItem
                  id="appraisal"
                  name="OCENA PRACOWNIKA"
                  buttonTitle={setButtonAppraisal()?.text}
                  buttonAction={setButtonAppraisal()?.action}
                >
                  <>{setContentAppraisal()}</>
                </DesktopItem>
              </div>
            </AuthResources>
          </div>
        </Box>
      </>
    </CustomContainer>
  );
};

export default Panel;
