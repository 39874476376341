import { TrainingsProvider, Error } from 'api/types';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { displayNotification } from 'utils';
import { useTranslation } from 'react-i18next';
import { AutoAlertStatus } from 'components';

const useAddTrainingsProvider = (
  onCompleted?: (data: TrainingsProvider) => void,
  onError?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsProvider, 'id'>) => Promise<void>) => {
  const { t } = useTranslation(['trainingsProvider', 'defaultNotification']);
  const axios = useAxios();

  const postTrainingsProvider = useCallback(
    async (trainingsProvider: Omit<TrainingsProvider, 'id'>) => {
      try {
        const rData = await axios.post<TrainingsProvider>(
          `${apiEndpoints.trainingsProvider}/providers`,
          trainingsProvider
        );
        displayNotification(
          t('repository.trainingsProvider'),
          t('repository.SuccesAddTrainingProvider')
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompleted) onCompleted(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompleted, onError]
  );

  return postTrainingsProvider;
};

export default useAddTrainingsProvider;
