import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  EmployeeRepository,
  TrainingsCompanyContactRepository,
  TrainingsGroupRepository,
  TrainingsProviderRepository,
} from 'api/repository';
import { Loyalty, LoyaltyPostAndPut } from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  EditorMce,
  LoaderForm,
  ExitFromForm,
  CustomSelect,
  CustomContainer,
  CustomDate,
  CustomNumber,
  ContentBox,
} from 'components';

import './loyaltyForm.scss';

type LoyaltyValue = Omit<Omit<LoyaltyPostAndPut, 'id'>, 'employeeId'>;

interface Props {
  id?: number;
  onSave?: any;
  onSaveAndExit?: any;
  data?: Loyalty | LoyaltyPostAndPut | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormLoyalty = (props: Props): ReactElement => {
  const {
    id,
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsGroup');

  const formSchema: yup.SchemaOf<LoyaltyValue> = yup.object().shape({
    conclusionDate: yup.string().notRequired(),
    expirationDate: yup.string().notRequired(),
    financingAmount: yup
      .number()
      .notRequired()
      .typeError('Wartość nie może być pusta'),
    generationDate: yup.string().notRequired(),
    handoverDate: yup.string().notRequired(),
    representingPerson: yup.string().notRequired(),
    returnDate: yup.string().notRequired(),
    idNumber: yup.string().notRequired(),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    errors,
  } = useForm<LoyaltyValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: LoyaltyValue) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: LoyaltyValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const [dataEmployee] = EmployeeRepository.useGetEmployee({
    id: data?.employeeId ? Number(data?.employeeId) : undefined,
  });

  return (
    <CustomContainer id="formLoyaltyContainer">
      <Box
        id="loyaltyTableBox"
        name="EDYCJA UMOWY LOJALNOŚCIOWEJ PRACOWNIKA"
        svgIcon="addEdit"
        noPadding
      >
        <>
          <ContentBox id="formLoyaltyHeaderContent" bottomBorder>
            <>
              <div className="titleEmployee">Umowa dotyczy: </div>
              <div className="valueEmployee">
                {dataEmployee?.lastName} {dataEmployee?.firstName}
              </div>
            </>
          </ContentBox>
          <ExitFromForm changeForm={changeForm} />
          <Form
            className="form"
            id="formLoyalty"
            onChange={(e) => {
              setChangeForm(true);
            }}
          >
            <LoaderForm
              active={(data === undefined && edit) || submitForm}
              type="getData"
            >
              <>
                <ContentBox id="formLoyaltyFirstContent" bottomBorder>
                  <>
                    <div className="loyaltyDateBox">
                      <div className="conclusionDateBox">
                        <CustomDate
                          id="conclusionDate"
                          name="conclusionDate"
                          label="Data zawarcia:"
                          setValue={setValue}
                          error={errors?.conclusionDate?.message}
                          defaultValue={
                            data?.conclusionDate === null ||
                            data?.conclusionDate === undefined
                              ? undefined
                              : data?.conclusionDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                        />
                      </div>
                      <div className="generationDateBox">
                        <CustomDate
                          id="generationDate"
                          name="generationDate"
                          label="Data wygenerowania:"
                          setValue={setValue}
                          error={errors?.generationDate?.message}
                          defaultValue={
                            data?.generationDate === null ||
                            data?.generationDate === undefined
                              ? undefined
                              : data?.generationDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                        />
                      </div>
                      <div className="handoverDateBox">
                        <CustomDate
                          id="handoverDate"
                          name="handoverDate"
                          label="Data przekazania:"
                          setValue={setValue}
                          error={errors?.handoverDate?.message}
                          defaultValue={
                            data?.handoverDate === null ||
                            data?.handoverDate === undefined
                              ? undefined
                              : data?.handoverDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                        />
                      </div>
                      <div className="returnDateBox">
                        <CustomDate
                          id="returnDate"
                          name="returnDate"
                          label="Data zwrotu:"
                          setValue={setValue}
                          error={errors?.returnDate?.message}
                          defaultValue={
                            data?.returnDate === null ||
                            data?.returnDate === undefined
                              ? undefined
                              : data?.returnDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                        />
                      </div>
                      <div className="expirationDateBox">
                        <CustomDate
                          id="expirationDate"
                          name="expirationDate"
                          label="Data zakończenia:"
                          setValue={setValue}
                          error={errors?.expirationDate?.message}
                          defaultValue={
                            data?.expirationDate === null ||
                            data?.expirationDate === undefined
                              ? undefined
                              : data?.expirationDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="loyaltyEmployeeDataBox">
                      <div className="idCardBox">
                        <CustomInput
                          name="idNumber"
                          label="Seria i numer dowodu osobistego:"
                          register={register}
                          // error={errors?.place?.message}
                          defaultValue={dataEmployee?.idNumber}
                          disabled={onlyView}
                          toLeft
                          fullWidth
                          smallRadius
                        />
                      </div>
                      <div className="financingAmountBox">
                        <CustomNumber
                          name="financingAmount"
                          label="Kwota finansowania:"
                          register={register}
                          error={errors?.financingAmount?.message}
                          defaultValue={
                            data?.financingAmount
                              ? `${data?.financingAmount}`
                              : '0'
                          }
                          disabled={onlyView}
                          toLeft
                          fullWidth
                          noInfo
                        />
                        <span>PLN</span>
                      </div>
                    </div>
                  </>
                </ContentBox>

                <ContentBox id="formLoyaltySecondContent">
                  <EditorMce
                    id="representingPerson"
                    name="representingPerson"
                    register={register}
                    contentGet={data?.representingPerson}
                    setChangeForm={setChangeForm}
                    label="Osoba reprezentująca firmę Lafarge:"
                  />
                </ContentBox>
                {!onlyView ? (
                  <CustomSubmit
                    save={onSave ? handleSubmit(handleSave) : undefined}
                    saveAndExit={
                      onSaveAndExit
                        ? handleSubmit(handleSaveAndExit)
                        : undefined
                    }
                    submitForm={submitForm}
                    cancel={cancel}
                  />
                ) : (
                  ''
                )}
              </>
            </LoaderForm>
          </Form>
        </>
      </Box>
    </CustomContainer>
  );
};

export default FormLoyalty;
