/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './shortName.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label?: string;
  data?: string;
  bold?: boolean;
  fontSize?: 'fontSmall' | 'fontMedium' | 'fontBig';
}

const ShowShortName = (props: Props): ReactElement => {
  const { id, label, data, bold, fontSize } = props;

  return (
    <div
      id={id}
      className={`showShortName ${bold ? 'bold' : ''} ${fontSize || ''}`}
    >
      {label ? (
        <div id={`${id}-label`} className="label">
          {label}
        </div>
      ) : (
        ''
      )}

      <div id={`${id}-value`} className="data">
        {data || ''}
      </div>
    </div>
  );
};

export default ShowShortName;
