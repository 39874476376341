import {
  Error,
  EmployeeAppraisalAddAndPut,
  EmployeeAppraisalModel,
  EmployeeAppraisalAllEmployeeRequest,
  PagedData,
  EmployeeAppraisalGoal,
  EmployeeAppraisalEmployeeModel,
  EmployeeAppraisalPutSubordinatesGoal,
  EmployeeAppraisalGoalModel,
  EmployeeAppraisalPostAndPutGoal,
  EmployeeAppraisalRatingModel,
  EmployeeAppraisalRatingPut,
  EmployeeAppraisalFeedback180Model,
  EmployeeAppraisalManagerGoal,
  EmployeeAppraisalPutSpecificGoal,
  EmployeeAppraisalPostAndPutGoalStatus,
  EmployeeAppraisalGoalRatingPut,
  EmployeeAppraisalRatingWithIdEmployeePut,
  EmployeeModel,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';
import { NumberSchema } from 'yup';
import {
  EmployeeForFeedbackRequest,
  EmployeeRequest,
} from 'api/types/RequestParams';
import {
  EmployeeAppraisalRatingWithIdEmployeePutAdmin,
  EmployeeEvaluationToSelect,
} from 'api/types/EmployeeAppraisal';

interface Props {
  onCompleted?: (data: EmployeeAppraisalAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

const useGetEmployeeAppraisalSettings = (
  props: Props
): [
  EmployeeAppraisalModel | null | undefined,
  (employeeAppraisalModel: EmployeeAppraisalModel) => void,
  () => void
] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalModel>(
        `${apiEndpoints.employeeAppraisal}/settings`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeAppraisalSettings = (
  onCompletedPut?: (data: EmployeeAppraisalAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalSettings = useCallback(
    async (plansTrainings: EmployeeAppraisalAddAndPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalAddAndPut>(
          `${apiEndpoints.employeeAppraisal}/settings`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalSettings;
};

const useGetGoal = (): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetDataOverallGoal = (): [
  EmployeeAppraisalManagerGoal | null | undefined,
  (data: EmployeeAppraisalManagerGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalManagerGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalManagerGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/overall-goal`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalManagerGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalAllEmployee = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: EmployeeAppraisalAllEmployeeRequest) => void,
  PagedData<EmployeeAppraisalEmployeeModel> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<EmployeeAppraisalEmployeeModel> | null>(null);

  const doGet = useCallback(
    (params: EmployeeAppraisalAllEmployeeRequest) => {
      axios
        .get<PagedData<EmployeeAppraisalEmployeeModel>>(
          `${apiEndpoints.employeeAppraisal}/evaluations`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const usePutSubordinatesGoal = (
  onCompletedPost?: (data: EmployeeAppraisalGoal) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPutSubordinatesGoal) => Promise<void>) => {
  const axios = useAxios();

  const put = useCallback(
    async (subordinatesGoal: EmployeeAppraisalPutSubordinatesGoal) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/subordinates-goal`,
          subordinatesGoal
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return put;
};

const usePostGoalForEmployee = (
  employeeId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const postGoalForEmployee = useCallback(
    async (postData: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${employeeId}/goals`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postGoalForEmployee;
};

const useGetAllGoalForEmployee = (props: {
  idEmployee: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const useGetEmployeeAppraisalEmployeeGoal = (props: {
  idEmployee: number;
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeAppraisalEmployeeGoal = (
  idEmployee: number,
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoal = useCallback(
    async (dataPut: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/${idGoal}`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoal;
};

const usePutEmployeeAppraisalPostAndPutGoalStatus = (
  idEmployee: number,
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoalStatus) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalPostAndPutGoalStatus = useCallback(
    async (dataPut: EmployeeAppraisalPostAndPutGoalStatus) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/${idGoal}/progress`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalPostAndPutGoalStatus;
};

const usePutEmployeeAppraisalEmployeeGoalRating = (
  idEmployee: number,
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalGoalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoalRating = useCallback(
    async (dataPut: EmployeeAppraisalGoalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/${idGoal}/appraisal`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoalRating;
};

const useGetAllImportEmployeeAppraisalGoal = (props: {
  idEmployee: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, onError } = props;
  const axios = useAxios();
  const [
    AllGoalData,
    setAllGoalData,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/import`
      )
      .then(({ data }) => {
        setAllGoalData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, AllGoalData];
};

const usePostEmployeeAppraisalGoalImport = (
  idEmployee: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idGoal: number) => Promise<void>) => {
  const axios = useAxios();

  const postEmployeeAppraisalGoalImport = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/import/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postEmployeeAppraisalGoalImport;
};

const useGetGoalEmployee = (
  idEmployee: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetGoalManager = (
  idEmployee: number
): [
  EmployeeAppraisalManagerGoal | null | undefined,
  (data: EmployeeAppraisalManagerGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalManagerGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalManagerGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/overall-goal`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalManagerGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeAppraisaSpecificGoal = (
  idEmployee: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPutSpecificGoal) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalOverallGoal = useCallback(
    async (plansTrainings: EmployeeAppraisalPutSpecificGoal) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/specific-goal`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalOverallGoal;
};

const usePutEmployeeRating = (
  employeeId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeRating = useCallback(
    async (postData: EmployeeAppraisalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${employeeId}/self-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeRating;
};

const usePutManagerRating = (
  employeeId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putManagerRating = useCallback(
    async (postData: EmployeeAppraisalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${employeeId}/superior-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putManagerRating;
};

const usePutManagerN2Rating = (
  onCompletedPut?: (data: EmployeeAppraisalRatingModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingWithIdEmployeePut) => Promise<void>) => {
  const axios = useAxios();

  const putManagerN2Rating = useCallback(
    async (postData: EmployeeAppraisalRatingWithIdEmployeePut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalRatingModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${postData.id}/final-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putManagerN2Rating;
};

const usePutEmployeeAppraisalFeedback = (
  onCompletedPut?: (data: EmployeeAppraisalFeedback180Model) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalFeedback180Model) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalFeedback = useCallback(
    async (plansTrainings: EmployeeAppraisalFeedback180Model) => {
      try {
        const rData = await axios.put<EmployeeAppraisalFeedback180Model>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/feedback`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalFeedback;
};

const useGetEmployeeAppraisalFeedback = (): [
  EmployeeAppraisalFeedback180Model | null | undefined,
  (data: EmployeeAppraisalFeedback180Model) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalFeedback180Model | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalFeedback180Model) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeAppraisalAccept = (
  idEmployee: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalAccept = useCallback(
    async () => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/accept`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalAccept;
};

const useGetCheckFeedback180 = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, EmployeeAppraisalFeedback180Model | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<EmployeeAppraisalFeedback180Model | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/feedback`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const usePutEmployeeAppraisalClose = (
  idEmployee: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalClose = useCallback(
    async () => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/close`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalClose;
};

const useDeleteEmployeeGoal = (
  idEmployee: number,
  onCompletedDelete?: () => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const DeleteEmployeeGoal = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/goals/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete();
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return DeleteEmployeeGoal;
};

const useGetGoalUser = (): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetGoalUserManager = (): [
  EmployeeAppraisalManagerGoal | null | undefined,
  (data: EmployeeAppraisalManagerGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalManagerGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalManagerGoal>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/overall-goal`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalManagerGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllGoalForUser = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const usePostGoalForUser = (
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const postGoalForEmployee = useCallback(
    async (postData: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postGoalForEmployee;
};

const useGetEmployeeAppraisalUserGoal = (props: {
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutEmployeeAppraisalUserGoal = (
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoal = useCallback(
    async (dataPut: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/${idGoal}`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoal;
};

const usePutEmployeeAppraisalUserPostAndPutGoalStatus = (
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoalStatus) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalPostAndPutGoalStatus = useCallback(
    async (dataPut: EmployeeAppraisalPostAndPutGoalStatus) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/${idGoal}/progress`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalPostAndPutGoalStatus;
};

const useGetAllImportUserAppraisalGoal = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [
    AllGoalData,
    setAllGoalData,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/import`
      )
      .then(({ data }) => {
        setAllGoalData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, AllGoalData];
};

const usePostEmployeeAppraisalUserGoalImport = (
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idGoal: number) => Promise<void>) => {
  const axios = useAxios();

  const postEmployeeAppraisalGoalImport = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/import/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postEmployeeAppraisalGoalImport;
};

const useDeleteUserGoal = (
  onCompletedDelete?: () => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const DeleteEmployeeGoal = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete();
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return DeleteEmployeeGoal;
};

const usePutEmployeeAppraisalUserGoalRating = (
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalGoalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoalRating = useCallback(
    async (dataPut: EmployeeAppraisalGoalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/goals/${idGoal}/appraisal`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoalRating;
};

const usePutUserRating = (
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putUserRating = useCallback(
    async (postData: EmployeeAppraisalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/evaluations/user/self-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putUserRating;
};

const useGetEmployeeForFeedback = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: EmployeeForFeedbackRequest) => void,
  PagedData<EmployeeModel> | null
] => {
  const axios = useAxios();
  const [d, setD] = useState<PagedData<EmployeeModel> | null>(null);

  const doGet = useCallback(
    (params: EmployeeForFeedbackRequest) => {
      axios
        .get<PagedData<EmployeeModel>>(
          `${apiEndpoints.idp}/individual-development-plans/feedback/employee`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setD(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, d];
};

const useGetApprasialAllEmployeeAdmin = (
  onError?: (error: AxiosError<Error>) => void
): [(params: EmployeeRequest) => void, PagedData<EmployeeModel> | null] => {
  const axios = useAxios();
  const [catalogs, setCatalogs] = useState<PagedData<EmployeeModel> | null>(
    null
  );

  const doGet = useCallback(
    (params: EmployeeRequest) => {
      axios
        .get<PagedData<EmployeeModel>>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetAllGoalForEmployeeAdmin = (props: {
  idEmployee: number;
  idEvaluation: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, idEvaluation, onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const useGetEmployeeAppraisalAdmin = (
  idEmployee: number,
  idEvaluation: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEvaluationEmployeeAdmin = (props: {
  employeeId: number;
  onCompleted?: (data: EmployeeEvaluationToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeEvaluationToSelect[] | null | undefined, () => void] => {
  const { employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeEvaluationToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeEvaluationToSelect[]>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${employeeId}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetEmployeeAppraisalEmployeeGoalAdmin = (props: {
  idEmployee: number;
  idEvaluation: number;
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idEvaluation, idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalFeedbackAdmin = (props: {
  idEmployee: number;
  idEvaluation: number;
}): [
  EmployeeAppraisalFeedback180Model | null | undefined,
  (data: EmployeeAppraisalFeedback180Model) => void,
  () => void
] => {
  const { idEmployee, idEvaluation } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalFeedback180Model | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalFeedback180Model) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useDeleteEmployeeGoalAdmin = (
  idEmployee: number,
  idEvaluation: number,
  onCompletedDelete?: () => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const DeleteEmployeeGoal = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete();
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return DeleteEmployeeGoal;
};

const usePostGoalForEmployeeAdmin = (
  employeeId: number,
  evaluationId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const postGoalForEmployee = useCallback(
    async (postData: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${employeeId}/${evaluationId}/goals`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postGoalForEmployee;
};

const usePutEmployeeAppraisalEmployeeGoalAdmin = (
  idEmployee: number,
  idEvaluation: number,
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalPostAndPutGoal) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoal = useCallback(
    async (dataPut: EmployeeAppraisalPostAndPutGoal) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoal;
};

const useGetAllImportEmployeeAppraisalGoalAdmin = (props: {
  idEmployee: number;
  idEvaluation: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, idEvaluation, onError } = props;
  const axios = useAxios();
  const [
    AllGoalData,
    setAllGoalData,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals/import`
      )
      .then(({ data }) => {
        setAllGoalData(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, AllGoalData];
};

const usePostEmployeeAppraisalGoalImportAdmin = (
  idEmployee: number,
  idEvaluation: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((idGoal: number) => Promise<void>) => {
  const axios = useAxios();

  const postEmployeeAppraisalGoalImport = useCallback(
    async (idGoal: number) => {
      try {
        const rData = await axios.post<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/goals/import/${idGoal}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return postEmployeeAppraisalGoalImport;
};

const usePutEmployeeAppraisalNotAccepted = (
  idEmployee: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalAccept = useCallback(
    async () => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/notAccepted`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalAccept;
};

const usePutEmployeeRatingAdmin = (
  employeeId: number,
  evaluationId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeRating = useCallback(
    async (postData: EmployeeAppraisalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${employeeId}/${evaluationId}/self-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeRating;
};

const usePutManagerRatingAdmin = (
  employeeId: number,
  evaluationId: number,
  onCompletedPut?: (data: EmployeeAppraisalGoal) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putManagerRating = useCallback(
    async (postData: EmployeeAppraisalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoal>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${employeeId}/${evaluationId}/superior-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putManagerRating;
};

const usePutManagerN2RatingAdmin = (
  onCompletedPut?: (data: EmployeeAppraisalRatingModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalRatingWithIdEmployeePutAdmin) => Promise<void>) => {
  const axios = useAxios();

  const putManagerN2Rating = useCallback(
    async (postData: EmployeeAppraisalRatingWithIdEmployeePutAdmin) => {
      try {
        const rData = await axios.put<EmployeeAppraisalRatingModel>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${postData.id}/${postData.idEvaluation}/final-appraisal`,
          postData
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putManagerN2Rating;
};

const usePutEmployeeAppraisalEmployeeGoalRatingAdmin = (
  idEmployee: number,
  idEvaluation: number,
  idGoal: number,
  onCompletedPut?: (data: EmployeeAppraisalGoalModel) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalGoalRatingPut) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalEmployeeGoalRating = useCallback(
    async (dataPut: EmployeeAppraisalGoalRatingPut) => {
      try {
        const rData = await axios.put<EmployeeAppraisalGoalModel>(
          `${apiEndpoints.employeeAppraisal}/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}/appraisal`,
          dataPut
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalEmployeeGoalRating;
};

const usePutEmployeeAppraisalFeedbackAdmin = (
  idEmployee: number,
  idEvaluation: number,
  onCompletedPut?: (data: EmployeeAppraisalFeedback180Model) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: EmployeeAppraisalFeedback180Model) => Promise<void>) => {
  const axios = useAxios();

  const putEmployeeAppraisalFeedback = useCallback(
    async (plansTrainings: EmployeeAppraisalFeedback180Model) => {
      try {
        const rData = await axios.put<EmployeeAppraisalFeedback180Model>(
          `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}/feedback`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putEmployeeAppraisalFeedback;
};

const useGetGoalEmployeeAdmin = (
  idEmployee: number,
  idEvaluation: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/admin/evaluations/${idEmployee}/${idEvaluation}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEvaluationEmployeeManager = (props: {
  employeeId: number;
  onCompleted?: (data: EmployeeEvaluationToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeEvaluationToSelect[] | null | undefined, () => void] => {
  const { employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeEvaluationToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeEvaluationToSelect[]>(
        `${apiEndpoints.employeeAppraisal}/manager/evaluations/${employeeId}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetGoalEmployeeManager = (
  idEmployee: number,
  idEvaluation: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/manager/evaluations/${idEmployee}/${idEvaluation}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllGoalForEmployeeManager = (props: {
  idEmployee: number;
  idEvaluation: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, idEvaluation, onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/manager/evaluations/${idEmployee}/${idEvaluation}/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const useGetEvaluationEmployeeUser = (props: {
  onCompleted?: (data: EmployeeEvaluationToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeEvaluationToSelect[] | null | undefined, () => void] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeEvaluationToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeEvaluationToSelect[]>(
        `${apiEndpoints.employeeAppraisal}/user/evaluations`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetGoalEmployeeUser = (
  idEvaluation: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/user/evaluations/${idEvaluation}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllGoalForEmployeeUser = (props: {
  idEvaluation: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEvaluation, onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/user/evaluations/${idEvaluation}/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, dataAllGoalForEmployee];
};

const useGetEmployeeAppraisalEmployeeGoalManager = (props: {
  idEmployee: number;
  idEvaluation: number;
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idEvaluation, idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/manager/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalEmployeeGoalUser = (props: {
  idEvaluation: number;
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idEvaluation, idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/user/evaluations/${idEvaluation}/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalFeedbackManager = (props: {
  idEmployee: number;
  idEvaluation: number;
}): [
  EmployeeAppraisalFeedback180Model | null | undefined,
  (data: EmployeeAppraisalFeedback180Model) => void,
  () => void
] => {
  const { idEmployee, idEvaluation } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalFeedback180Model | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/manager/evaluations/${idEmployee}/${idEvaluation}/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalFeedback180Model) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalFeedbackUser = (props: {
  idEvaluation: number;
}): [
  EmployeeAppraisalFeedback180Model | null | undefined,
  (data: EmployeeAppraisalFeedback180Model) => void,
  () => void
] => {
  const { idEvaluation } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalFeedback180Model | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/user/evaluations/${idEvaluation}/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalFeedback180Model) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEvaluationEmployeeManagerN2 = (props: {
  employeeId: number;
  onCompleted?: (data: EmployeeEvaluationToSelect[]) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [EmployeeEvaluationToSelect[] | null | undefined, () => void] => {
  const { employeeId, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeEvaluationToSelect[] | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeEvaluationToSelect[]>(
        `${apiEndpoints.employeeAppraisal}/managern2/evaluations/${employeeId}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useGetEmployeeAppraisalFeedbackManagerN2 = (props: {
  idEmployee: number;
  idEvaluation: number;
}): [
  EmployeeAppraisalFeedback180Model | null | undefined,
  (data: EmployeeAppraisalFeedback180Model) => void,
  () => void
] => {
  const { idEmployee, idEvaluation } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalFeedback180Model | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalFeedback180Model>(
        `${apiEndpoints.employeeAppraisal}/managern2/evaluations/${idEmployee}/${idEvaluation}/feedback`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalFeedback180Model) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetEmployeeAppraisalEmployeeGoalManagerN2 = (props: {
  idEmployee: number;
  idEvaluation: number;
  idGoal: number;
  onCompleted?: (data: EmployeeAppraisalGoalModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  EmployeeAppraisalGoalModel | null | undefined,
  (employeeAppraisalGoalModel: EmployeeAppraisalGoalModel) => void,
  (idRecord: number) => void
] => {
  const { idEmployee, idEvaluation, idGoal, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoalModel | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoalModel>(
        `${apiEndpoints.employeeAppraisal}/managern2/evaluations/${idEmployee}/${idEvaluation}/goals/${idGoal}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoalModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetGoalEmployeeManagerN2 = (
  idEmployee: number,
  idEvaluation: number
): [
  EmployeeAppraisalGoal | null | undefined,
  (data: EmployeeAppraisalGoal) => void,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    EmployeeAppraisalGoal | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeAppraisalGoal>(
        `${apiEndpoints.employeeAppraisal}/managern2/evaluations/${idEmployee}/${idEvaluation}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeAppraisalGoal) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllGoalForEmployeeManagerN2 = (props: {
  idEmployee: number;
  idEvaluation: number;
  onError?: (error: AxiosError<Error>) => void;
}): [() => void, PagedData<EmployeeAppraisalGoalModel> | null | undefined] => {
  const { idEmployee, idEvaluation, onError } = props;
  const axios = useAxios();
  const [
    dataAllGoalForEmployee,
    setDataAllGoalForEmployee,
  ] = useState<PagedData<EmployeeAppraisalGoalModel> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<EmployeeAppraisalGoalModel>>(
        `${apiEndpoints.employeeAppraisal}/managern2/evaluations/${idEmployee}/${idEvaluation}/goals`
      )
      .then(({ data }) => {
        setDataAllGoalForEmployee(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, idEmployee, onError]);

  return [doGet, dataAllGoalForEmployee];
};

export default {
  useGetEmployeeAppraisalSettings,
  usePutEmployeeAppraisalSettings,
  useGetGoal,
  useGetDataOverallGoal,
  useGetEmployeeAppraisalAllEmployee,
  usePutSubordinatesGoal,
  usePostGoalForEmployee,
  useGetAllGoalForEmployee,
  useGetEmployeeAppraisalEmployeeGoal,
  usePutEmployeeAppraisalEmployeeGoal,
  usePutEmployeeAppraisalPostAndPutGoalStatus,
  usePutEmployeeAppraisalEmployeeGoalRating,
  useGetAllImportEmployeeAppraisalGoal,
  usePostEmployeeAppraisalGoalImport,
  useGetGoalEmployee,
  usePutEmployeeAppraisaSpecificGoal,
  usePutEmployeeRating,
  usePutManagerRating,
  usePutManagerN2Rating,
  usePutEmployeeAppraisalFeedback,
  useGetEmployeeAppraisalFeedback,
  useGetGoalManager,
  usePutEmployeeAppraisalAccept,
  useGetCheckFeedback180,
  usePutEmployeeAppraisalClose,
  useDeleteEmployeeGoal,
  useGetGoalUser,
  useGetGoalUserManager,
  useGetAllGoalForUser,
  usePostGoalForUser,
  useGetEmployeeAppraisalUserGoal,
  usePutEmployeeAppraisalUserGoal,
  usePutEmployeeAppraisalUserPostAndPutGoalStatus,
  useGetAllImportUserAppraisalGoal,
  usePostEmployeeAppraisalUserGoalImport,
  useDeleteUserGoal,
  usePutEmployeeAppraisalUserGoalRating,
  usePutUserRating,
  useGetEmployeeForFeedback,
  useGetApprasialAllEmployeeAdmin,
  useGetAllGoalForEmployeeAdmin,
  useGetEmployeeAppraisalAdmin,
  useGetEvaluationEmployeeAdmin,
  useGetEmployeeAppraisalEmployeeGoalAdmin,
  useGetEmployeeAppraisalFeedbackAdmin,
  useDeleteEmployeeGoalAdmin,
  usePostGoalForEmployeeAdmin,
  usePutEmployeeAppraisalEmployeeGoalAdmin,
  useGetAllImportEmployeeAppraisalGoalAdmin,
  usePostEmployeeAppraisalGoalImportAdmin,
  usePutEmployeeAppraisalNotAccepted,
  usePutManagerN2RatingAdmin,
  usePutManagerRatingAdmin,
  usePutEmployeeRatingAdmin,
  usePutEmployeeAppraisalEmployeeGoalRatingAdmin,
  usePutEmployeeAppraisalFeedbackAdmin,
  useGetGoalEmployeeAdmin,
  useGetEvaluationEmployeeManager,
  useGetGoalEmployeeManager,
  useGetAllGoalForEmployeeManager,
  useGetEvaluationEmployeeUser,
  useGetGoalEmployeeUser,
  useGetAllGoalForEmployeeUser,
  useGetEmployeeAppraisalEmployeeGoalManager,
  useGetEmployeeAppraisalEmployeeGoalUser,
  useGetEmployeeAppraisalFeedbackManager,
  useGetEmployeeAppraisalFeedbackUser,
  useGetEvaluationEmployeeManagerN2,
  useGetEmployeeAppraisalFeedbackManagerN2,
  useGetEmployeeAppraisalEmployeeGoalManagerN2,
  useGetGoalEmployeeManagerN2,
  useGetAllGoalForEmployeeManagerN2,
};
