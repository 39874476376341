import { TrainingsProvider, Error } from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  id: number;
  onError?: (error: AxiosError<Error>) => void;
}

const useGetTrainingsProvider = (
  props: Props
): [
  TrainingsProvider | null | undefined,
  (trainingsProvider: TrainingsProvider) => void,
  (id: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [trainingsProvider, setTrainingsProvider] = useState<
    TrainingsProvider | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsProvider>(
        `${apiEndpoints.trainingsProvider}/providers/${id}`
      );
      setTrainingsProvider(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setTrainingsProvider(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsProvider) => {
    setTrainingsProvider(data);
  }, []);

  return [trainingsProvider, updateData, fetchData];
};

export default useGetTrainingsProvider;
