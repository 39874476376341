import React, { ReactElement, useState } from 'react';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
} from 'components';
import { IDPRepository } from 'api/repository';
import './employee.scss';
import TableEmployeeBlock from './TableEmployeeBlock';

const EmployeeForAppraisal = (): ReactElement => {
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  return (
    <>
      <CustomContainer allowGrow>
        <>
          <Box
            id="adminEmployeeAppraisalBox"
            name="Ocena pracownika - LISTA PRACOWNIKÓW"
            svgIcon="enrollmentForTrainings"
            noPadding
          >
            <>
              <TableEmployeeBlock />
            </>
          </Box>
        </>
      </CustomContainer>
    </>
  );
};

export default EmployeeForAppraisal;
