/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import '../../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  name: string;
  setValue: (value: string) => void;
  keyAction: (e: any) => void;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  nameLabel?: string;
  value: string;
}

const SearchBox = (props: Props): ReactElement => {
  const {
    name,
    nameLabel,
    placeholder,
    setValue,
    keyAction,
    setOpenSearch,
    value,
  } = props;

  return (
    <>
      {nameLabel !== undefined ? <label htmlFor={name}>{nameLabel}</label> : ''}
      <input
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          keyAction(e);
        }}
        onFocus={() => {
          setOpenSearch(true);
        }}
        onBlur={(e) => {
          setOpenSearch(false);
        }}
        autoComplete="off"
        value={value}
      />
    </>
  );
};

export default SearchBox;
