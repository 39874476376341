import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupRepository } from 'api/repository';
import { Box } from 'components';
import { TrainingsGroupTrainingAddPut } from 'api/types';
import BasicInformation from '../../Form/BasicInformation';

const TrainingsProviderAddPage = (): ReactElement => {
  const history = useHistory();
  const { id, idRecord } = useParams<{ id: string; idRecord: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    trainingsGroupData,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetGroup({
    id: Number(idRecord),
  });

  const handleAddTrainingsProvider = TrainingsGroupRepository.usePutTrainingsGroupPutGroup(
    async (addData) => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAndExit = TrainingsGroupRepository.usePutTrainingsGroupPutGroup(
    async () => {
      setSubmitForm(false);
      history.push(`/administracja/szkolenia/grupy-szkoleniowe/${id}`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: TrainingsGroupTrainingAddPut) => {
    if (!submitForm) {
      const postData = data;
      postData.id = Number(idRecord);
      postData.trainingId = Number(id);
      if (data.suffix === '') {
        postData.suffix = undefined;
      }
      if (data.termType === 'MULTI_DAY') {
        postData.startDate = `${data.startDate}T00:00:00`;
        postData.endDate = `${data.endDate}T00:00:00`;
        postData.singleDate = undefined;
      } else {
        postData.singleDate = `${data.singleDate}T00:00:00`;
        postData.startDate = undefined;
        postData.endDate = undefined;
      }
      setSubmitForm(true);
      handleAddTrainingsProvider(postData);
    }
  };

  const handleSubmitSaveAndExit = (data: TrainingsGroupTrainingAddPut) => {
    if (!submitForm) {
      setSubmitForm(true);
      const postData = data;
      postData.id = Number(idRecord);
      postData.trainingId = Number(id);
      if (data.suffix === '') {
        postData.suffix = undefined;
      }
      if (data.termType === 'MULTI_DAY') {
        postData.startDate = `${data.startDate}T00:00:00`;
        postData.endDate = `${data.endDate}T00:00:00`;
        postData.singleDate = undefined;
      } else {
        postData.singleDate = `${data.singleDate}T00:00:00`;
        postData.startDate = undefined;
        postData.endDate = undefined;
      }
      handleAddTrainingsProviderAndExit(postData);
    }
  };

  const handleCancel = () => {
    history.push(`/administracja/szkolenia/grupy-szkoleniowe/${id}`);
  };

  return (
    <BasicInformation
      id={Number(id)}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={handleSubmitSaveAndExit}
      submitForm={submitForm}
      data={trainingsGroupData}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
