import React, {
  ReactElement,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import Form from 'react-bootstrap/Form';
import './CustomNumber.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  name: string;
  label?: string;
  register?: (Ref, RegisterOptions?) => void;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  error?: string | undefined;
  value?: string | number | undefined;
  defaultValue?: string | undefined;
  placeholder?: string;
  info?: string;
  maxLength?: number;
  fullWidth?: boolean;
  smallInput?: boolean;
  toLeft?: boolean;
  smallPaddingBlock?: boolean;
  noInfo?: boolean;
  setValue?: Dispatch<SetStateAction<number | null>>;
}

const CustomNumber = (props: SwitchProps): ReactElement => {
  const {
    name,
    label,
    register,
    required,
    id,
    disabled,
    error,
    value,
    defaultValue,
    placeholder,
    info,
    maxLength,
    fullWidth,
    smallInput,
    toLeft,
    smallPaddingBlock,
    noInfo,
    setValue,
  } = props;

  const [val, setVal] = useState<number | null | undefined>(undefined);

  useEffect(() => {
    if (defaultValue !== undefined && val === undefined) {
      setVal(Number(defaultValue));
      if (setValue) setValue(Number(defaultValue));
    }
  }, [defaultValue, val]);

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let tclass = 'blockNumberWithError';
    if (required) {
      tclass += ' required';
    }
    if (typeof error !== 'undefined') {
      tclass += ' error';
    }
    if (typeof info !== 'undefined' && !disabled) {
      tclass += ' withInfo';
    }
    if (disabled) {
      tclass += ' disabled';
    }
    if (fullWidth) {
      tclass += ' fullWidth';
    }
    if (error) {
      tclass += ' error';
    }
    if (smallInput) {
      tclass += ' smallInput';
    }
    if (toLeft) {
      tclass += ' toLeft';
    }
    if (smallPaddingBlock) {
      tclass += ' smallPaddingBlock';
    }

    if (noInfo) {
      tclass += ' noInfo';
    }

    return tclass;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  return (
    <>
      <div className={additionalClass()}>
        <div className="blockNumber">
          <Form.Label id={name}>{label}</Form.Label>
          <div className="blockNumberWithInfo">
            <Form.Control
              name={name}
              id={name}
              ref={register}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength || 255}
              value={val !== null && val !== undefined ? `${val}` : ''}
              onChange={(e) => {
                if (!Number.isNaN(Number(e.target.value))) {
                  if (e.target.value === '') {
                    setVal(null);
                    if (setValue) {
                      setValue(Number(e.target.value));
                    }
                  } else {
                    setVal(Number(e.target.value));
                    if (setValue) {
                      setValue(Number(e.target.value));
                    }
                  }
                }
              }}
            />
            {infoElement()}
          </div>
        </div>
        <div className="additionalInfo">{errorElement()}</div>
      </div>
    </>
  );
};

export default CustomNumber;
