/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { Dispatch, SetStateAction } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  filterReq: { [key: string]: string };
  objSearch: { [key: string]: string };
  mapSearch: { [key: string]: string };
  setFilterReq: Dispatch<SetStateAction<{ [key: string]: string }>>;
  setUpdateDataTable: Dispatch<SetStateAction<number>>;
  setPage: Dispatch<SetStateAction<number>>;
}

const CustomFilterData = (props: any) => {
  const {
    name,
    filterReq,
    objSearch,
    mapSearch,
    setFilterReq,
    setUpdateDataTable,
    setPage,
  } = props;

  const actualFilter = filterReq;
  Object.keys(objSearch).forEach((key) => {
    if (mapSearch[key]) {
      if (actualFilter[`${mapSearch[key]}`]) {
        actualFilter[mapSearch[key]] = objSearch[key];
      } else {
        Object.assign(actualFilter, { [mapSearch[key]]: objSearch[key] });
      }
    }
  });
  setFilterReq(actualFilter);
  setPage(1);
  setUpdateDataTable(Math.random);
  return null;
};

export default CustomFilterData;
