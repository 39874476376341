/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { CustomInfoBlock, RenderSvg } from 'components';
import { QuestionnaireQuestion, QuestionnaireQuestionChoices } from 'api/types';

import './questionRadio.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: QuestionnaireQuestion;
  changeHandle: (id: number | null) => void;
  additionalClass?: string;
  noNumber?: boolean;
  error?: boolean;
}

const TableCellTrainingsStatusChange = (props: Props): ReactElement => {
  const { data, changeHandle, additionalClass, noNumber, error } = props;

  const [selected, setSelected] = useState<number | null | undefined>(
    undefined
  );

  useEffect(() => {
    if (selected !== undefined) {
      changeHandle(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <div
        id={`questionRadioBox-${data.id}`}
        className={`questionRadioBox ${additionalClass ? 'additionalClass' : ''}
        ${error ? 'error' : ''}
        `}
      >
        {noNumber === undefined ? (
          <div
            id={`questionRadioNumber-${data.id}`}
            className="questionRadioNumber"
          >
            {data.questionOrder < 10
              ? `0${data.questionOrder}.`
              : `${data.questionOrder}.`}
          </div>
        ) : (
          ''
        )}

        <div id={`questionRadioName-${data.id}`} className="questionRadioName">
          {data.name}
        </div>
        <div
          id={`questionRadioAnswerBox-${data.id}`}
          className="questionRadioAnswerBox"
        >
          {data?.choices && data?.choices.length > 0
            ? data.choices.map((item: QuestionnaireQuestionChoices) => {
                return (
                  <div
                    id={`${data.id}-answer-${item.id}`}
                    className={`status ${item.id === selected ? 'active' : ''}`}
                    role="button"
                    onClick={() => {
                      setSelected(item.id);
                    }}
                    onKeyUp={() => {}}
                    tabIndex={0}
                  >
                    <div
                      id={`${data.id}-answer-${item.id}-labelStatus`}
                      className="labelStatus"
                    >
                      {item.name === '2' ? (
                        <>
                          <span className="number">2</span>
                          <span>=ŹLE</span>
                        </>
                      ) : (
                        ''
                      )}
                      {item.name !== '2' && item.name !== '5' ? (
                        <span className="number">{item.name}</span>
                      ) : (
                        ''
                      )}
                      {item.name === '5' ? (
                        <>
                          <span className="number">5</span>
                          <span>=B.DOBRZE</span>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      id={`${data.id}-answer-${item.id}-boxStatus`}
                      className="boxStatus"
                    >
                      <div
                        id={`${data.id}-answer-${item.id}-circleBox`}
                        className={`circleBox ${
                          item.id === selected ? 'active' : ''
                        }`}
                      >
                        <>
                          {item.id === selected ? (
                            <RenderSvg nameSvg="ACCEPTEDStatus" />
                          ) : (
                            ''
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};

export default TableCellTrainingsStatusChange;
