import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { IDPRepository } from 'api/repository';
import { IDPEmployeeAreaForm, IDPEmployeeAreaPostAndPut } from 'api/types';
import { Modal } from 'components';
import FormIDPEmployeeArea from '../../../Form/IDPEmployeeArea';

interface CompetencesProps {
  idEmployee: number;
  idpId: number;
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
}
const CompetencesAdd = (props: CompetencesProps): ReactElement => {
  const {
    idEmployee,
    idpId,
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateData,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = IDPRepository.usePostIDPEmployeeAreaAdmin(
    idEmployee,
    idpId,
    async (data) => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
      if (changeModalId) {
        changeModalId(data.id);
      }
      if (changeModalEdit) {
        changeModalEdit(true);
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeArea = (dataPost: IDPEmployeeAreaForm) => {
    const dataToPost: IDPEmployeeAreaPostAndPut = {
      name: dataPost.name,
      activities: {
        '70': {
          name: dataPost.name70,
          measure: dataPost.measure70,
          progress: dataPost.progress70,
          term: dataPost.term70,
          comment: dataPost.comment70,
        },
        '20': {
          name: dataPost.name20,
          measure: dataPost.measure20,
          progress: dataPost.progress20,
          term: dataPost.term20,
          comment: dataPost.comment20,
        },
        '10': {
          name: dataPost.name10,
          measure: dataPost.measure10,
          progress: dataPost.progress10,
          term: dataPost.term10,
          comment: dataPost.comment10,
        },
      },
    };
    setSubmitForm(true);
    postDataSave(dataToPost);
  };

  const postDataSaveAndExit = IDPRepository.usePostIDPEmployeeArea(
    idEmployee,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (dataPost: IDPEmployeeAreaForm) => {
    const dataToPost: IDPEmployeeAreaPostAndPut = {
      name: dataPost.name,
      activities: {
        '70': {
          name: dataPost.name70,
          measure: dataPost.measure70,
          progress: dataPost.progress70,
          term: dataPost.term70,
          comment: dataPost.comment70,
        },
        '20': {
          name: dataPost.name20,
          measure: dataPost.measure20,
          progress: dataPost.progress20,
          term: dataPost.term20,
          comment: dataPost.comment20,
        },
        '10': {
          name: dataPost.name10,
          measure: dataPost.measure10,
          progress: dataPost.progress10,
          term: dataPost.term10,
          comment: dataPost.comment10,
        },
      },
    };
    setSubmitForm(true);
    postDataSaveAndExit(dataToPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd} widthBox="high">
      <div>
        <FormIDPEmployeeArea
          onSave={handleAddIDPEmployeeArea}
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default CompetencesAdd;
