import React, {
  ReactElement,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { TrainingsGroupRepository } from 'api/repository';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ShowBox,
  ShowName,
  ContentBox,
  LoaderForm,
  ShowTextarea,
  ShowInput,
  ShowDate,
  Box,
  AuthResources,
} from 'components';
import './trainingsGroupShow.scss';

interface Props {
  id: number;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsShow = (props: Props): ReactElement => {
  const { id, changeModalShow } = props;
  const { t } = useTranslation('trainingsGroup');

  const [
    trainingsGroupData,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetGroup({
    id: Number(id),
  });

  const [
    getDataSingleProviderContact,
    dataProviderContact,
  ] = TrainingsGroupRepository.useGetSingleTrainingsProviderContact();

  const [
    getDataSingleProvider,
    dataSingleProvider,
  ] = TrainingsGroupRepository.useGetSingleTrainingsProvider();

  const [
    getDataSingleCompanyContact,
    dataSingleCompanyContact,
  ] = TrainingsGroupRepository.useGetSingleCompanyContact();

  useEffect(() => {
    if (
      trainingsGroupData?.companyContactId !== undefined &&
      trainingsGroupData?.companyContactId !== null
    ) {
      getDataSingleCompanyContact({
        id: trainingsGroupData?.companyContactId,
      });
    }
    if (
      trainingsGroupData?.providerId !== undefined &&
      trainingsGroupData?.providerId !== null
    ) {
      getDataSingleProvider({
        id: trainingsGroupData?.providerId,
      });
    }
    if (
      trainingsGroupData?.providerId !== undefined &&
      trainingsGroupData?.providerId !== null &&
      trainingsGroupData?.providerContactId !== undefined &&
      trainingsGroupData?.providerContactId !== null
    ) {
      getDataSingleProviderContact({
        id: trainingsGroupData?.providerId,
        idContact: trainingsGroupData?.providerContactId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingsGroupData]);

  return (
    <Modal
      open
      changeModalShow={changeModalShow}
      widthBox="high"
      maxLevel="lvl2"
    >
      <LoaderForm active={trainingsGroupData === undefined} type="getData">
        <ShowBox
          name="PODGLĄD DANYCH PODSTAWOWYCH GRUPY SZKOLENIOWEJ"
          id="previewTrainingGroup"
          noPadding
        >
          <>
            <ContentBox id="previewTrainingGroupNameBox" bottomBorder>
              <>
                <ShowName
                  id="nameTrainings"
                  label="Nazwa szkolenia:"
                  data={trainingsGroupData?.name}
                />
              </>
            </ContentBox>
            <Box
              id="previewTrainingGroupBasicInformationBox"
              name="DANE PODSTAWOWE"
              noRadius
              noPadding
              lightHeader
              svgIcon="basicInformation"
              noShadow
            >
              <>
                <ContentBox
                  id="previewTrainingGroupBasicInformationContentFirst"
                  bottomBorder
                >
                  <>
                    <div id="previewTrainingGroupBasicInformationContentFirstColOne">
                      <ShowInput
                        id="provider"
                        label="Dostawca szkolenia:"
                        data={dataSingleProvider?.fullName}
                        toLeft
                      />
                      <ShowInput
                        id="termType"
                        label="Rodzaj terminu:"
                        data={t(`TermTypeEnum.${trainingsGroupData?.termType}`)}
                        toLeft
                      />
                      <ShowDate
                        id="date"
                        label="Termin szkolenia:"
                        singleDate={trainingsGroupData?.singleDate}
                        dateFrom={trainingsGroupData?.startDate}
                        dateTo={trainingsGroupData?.endDate}
                        toLeft
                      />
                    </div>
                    <div id="previewTrainingGroupBasicInformationContentFirstColTwo">
                      <ShowInput
                        id="settlementType"
                        label="Rodzaj szkolenia:"
                        data={t(
                          `TrainingFormTypeEnum.${trainingsGroupData?.trainingForm}`
                        )}
                        toLeft
                      />
                      <ShowInput
                        id="minPeople"
                        label="Minimalna ilość osób:"
                        data={`${trainingsGroupData?.minPeople}`}
                        toLeft
                      />
                      <ShowInput
                        id="maxPeople"
                        label="Maksymalna ilość osób:"
                        data={`${trainingsGroupData?.maxPeople}`}
                        toLeft
                      />
                    </div>
                    <div id="previewTrainingGroupBasicInformationContentFirstColThree">
                      <AuthResources requiredRoles={['training_department']}>
                        <>
                          <div id="previewTrainingGroupBasicInformationContentFirstColThreeLabel">
                            {trainingsGroupData?.settlementType === 'PER_GROUP'
                              ? 'Cena za grupę:'
                              : 'Cena za osobę:'}
                          </div>
                          <div id="previewTrainingGroupBasicInformationContentFirstColThreeData">
                            <span>{trainingsGroupData?.settlementAmount}</span>{' '}
                            PLN
                          </div>
                        </>
                      </AuthResources>
                    </div>
                  </>
                </ContentBox>
                <ContentBox id="previewTrainingGroupBasicInformationContentSecond">
                  <div id="previewTrainingGroupBasicInformationContentSecondDiv">
                    <ShowInput
                      id="companyContactId"
                      label="Opiekun szkolenia po stronie LF:"
                      data={`${dataSingleCompanyContact?.name}${
                        dataSingleCompanyContact?.phone !== null
                          ? ` - tel: ${dataSingleCompanyContact?.phone}`
                          : ''
                      }${
                        dataSingleCompanyContact?.email !== null
                          ? ` - mail: ${dataSingleCompanyContact?.email}`
                          : ''
                      }`}
                      toLeft
                    />
                    <AuthResources requiredRoles={['training_department']}>
                      <ShowInput
                        id="providerContactId"
                        label="Opiekun szkolenia po stronie dostawcy:"
                        data={`${dataProviderContact?.name}${
                          dataProviderContact?.phone !== null
                            ? ` - tel: ${dataProviderContact?.phone}`
                            : ''
                        }${
                          dataProviderContact?.email !== null
                            ? ` - mail: ${dataProviderContact?.email}`
                            : ''
                        }`}
                        toLeft
                      />
                    </AuthResources>
                    <ShowInput
                      id="place"
                      label="Miejsce szkolenia:"
                      data={`${trainingsGroupData?.place}`}
                      toLeft
                    />
                  </div>
                </ContentBox>
              </>
            </Box>
            <Box
              id="previewTrainingGroupBasicInformationBox"
              name="INFORMACJE DODATKOWE"
              noRadius
              noPadding
              lightHeader
              svgIcon="additionalInfo"
              noShadow
            >
              <ContentBox id="previewTrainingsProgramBox" bottomBorder>
                <>
                  <ShowTextarea
                    id="additionalInformation"
                    data={trainingsGroupData?.additionalInformation}
                  />
                </>
              </ContentBox>
            </Box>
          </>
        </ShowBox>
      </LoaderForm>
    </Modal>
  );
};

export default TrainingsShow;
