/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import Select from 'react-select';
import './TableCellContact.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  withName?: boolean;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellContact = (props: Props): ReactElement => {
  const { data, withName, justify } = props;

  return (
    <>
      {data ? (
        <div
          className={`tableCellBlockContact ${
            justify !== undefined ? justify : 'toLeft'
          }`}
        >
          {withName ? <div className="contactName">{data.name}</div> : ''}
          {data.phone ? <div>Tel: {data.phone}</div> : ''}
          {data.email ? <div>e-mail: {data.email}</div> : ''}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellContact;
