import React, { ReactElement, useEffect, useState } from 'react';
import './AdditionalAction.scss';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';

import {
  ShowAction,
  EditAction,
  ArchiveAction,
  ImportAction,
  DeleteAction,
} from './Action';

/*
TODO
Toggle TableRecordSwitch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  showClick?: () => void;
  editClick?: () => void;
  archiveClick?: () => void;
  deleteClick?: () => void;
  importClick?: () => void;
  page?: number;
  rowsPerPage?: number;
  rowNumber?: number;
}

const TableRecordAction = (props: Props): ReactElement => {
  const {
    id,
    showClick,
    editClick,
    archiveClick,
    importClick,
    deleteClick,
    page,
    rowsPerPage,
    rowNumber,
  } = props;
  const { t } = useTranslation('componentTableRecordAction');

  const actionShowClick = () => {
    if (showClick) {
      return <ShowAction showClick={() => showClick()} />;
    }
    return null;
  };

  const actionEditClick = () => {
    if (editClick) {
      return <EditAction editClick={() => editClick()} />;
    }
    return null;
  };

  const actionArchiveClick = () => {
    if (archiveClick) {
      return <ArchiveAction archiveClick={() => archiveClick()} />;
    }
    return null;
  };

  const actionDeleteClick = () => {
    if (deleteClick) {
      return <DeleteAction deleteClick={() => deleteClick()} />;
    }
    return null;
  };

  const actionImportClick = () => {
    if (importClick) {
      return <ImportAction importClick={() => importClick()} />;
    }
    return null;
  };

  const activeMenu = () => {
    return (
      <div className="activeMenu">
        {actionShowClick()}
        {actionEditClick()}
        {actionArchiveClick()}
        {actionImportClick()}
        {actionDeleteClick()}
      </div>
    );
  };

  return (
    <>
      <div className="boxAction" id={id}>
        <div id={`${id}`} className="boxTableRowAction">
          <RenderSvg nameSvg="wrench" />
        </div>
        {activeMenu()}
      </div>
    </>
  );
};

export default TableRecordAction;
