import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
  ShowAppraisalGoal,
} from 'components';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';
import './employeeAppraisalGoal.scss';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalGoal, EmployeeModel } from 'api/types';
import EmployeeAppraisalGoalRatingEdit from './EmployeeAppraisalGoalRatingEdit';
import EmployeeAppraisalGoalRatingList from './EmployeeAppraisalGoalRatingList';
import EmployeeAppraisalGoalEmployeeRating from './EmployeeAppraisalGoalEmployeeRating';

interface Props {
  goalUser?: EmployeeAppraisalGoal;
  fetchData?: any;
  employee?: null | EmployeeModel;
  updateGoalUser: (data: EmployeeAppraisalGoal) => void;
}

const EmployeeAppraisalGoalE = (props: Props): ReactElement => {
  const { id } = useParams<{ id: string; idRecord: string }>();
  const history = useHistory();

  const { goalUser, fetchData, employee, updateGoalUser } = props;

  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [employeeRatingModal, setEmployeeRatingModal] = useState<boolean>(
    false
  );
  const [
    employeeRatingEditModal,
    setEmployeeRatingEditModal,
  ] = useState<boolean>(false);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);
  const [emptyFeedback, setEmptyFeedback] = useState<boolean>(false);

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);
  const [
    getDataGoal,
    dataGoal,
  ] = EmployeeAppraisalRepository.useGetAllGoalForUser({});

  useEffect(() => {
    setLoaderTable(true);

    getDataGoal();
  }, [getDataGoal, updateDataTable]);

  useEffect(() => {
    if (dataGoal !== undefined) {
      setLoaderTable(false);
    }
  }, [dataGoal]);

  const [
    getFeedback,
    dataFeedback,
  ] = EmployeeAppraisalRepository.useGetCheckFeedback180({});

  useEffect(() => {
    getFeedback();
  }, []);

  return (
    <>
      <CustomContainer id="EmployeeAppraisalGoalRatingBox">
        <>
          <ContentBox id="EmployeeAppraisalGoalRatingBoxFirstRow" bottomBorder>
            <>
              <div id="EmployeeAppraisalGoalRatingBoxFirstRowBox">
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowFirstColumnBox">
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxIcon">
                    <RenderSvg nameSvg="ratingGoal60" />
                  </div>
                  <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfo">
                    <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoLabel">
                      Ocena celów
                    </div>
                    <div id="EmployeeAppraisalGoalRatingBoxFirstRowBoxInfoText">
                      Dokonaj samooceny celów jak i ogólnej oceny pracy
                    </div>
                  </div>
                </div>
                <div id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnBox">
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingEmployeeBox"
                    className="ratingGoal"
                  >
                    <div id="RatingEmployeeLabel" className="label">
                      Ogólna ocena pracy:
                    </div>
                    <div id="RatingEmployeeData" className="data">
                      {goalUser?.selfAppraisal === null ? (
                        <>
                          <Button
                            onClick={() => {
                              setEmployeeRatingModal(true);
                            }}
                            className="green"
                          >
                            Dodaj ocenę
                          </Button>
                          {employeeRatingModal ? (
                            <EmployeeAppraisalGoalEmployeeRating
                              changeModal={setEmployeeRatingModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(id)}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <div id="RatingEmployeeDataEdit" className="dataEdit">
                          <EditAction
                            editClick={() => {
                              setEmployeeRatingEditModal(true);
                            }}
                          />
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRACOWNIKA"
                            appraisal={goalUser?.selfAppraisal}
                          />
                          {employeeRatingEditModal ? (
                            <EmployeeAppraisalGoalEmployeeRating
                              changeModal={setEmployeeRatingEditModal}
                              changeUpdateData={updateGoalUser}
                              idEmployee={Number(id)}
                              edit
                              data={goalUser?.selfAppraisal}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerBox"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerLabel" className="label">
                      Ogólna ocena pracy N+1:
                    </div>
                    <div id="RatingManagerData" className="data">
                      {goalUser?.superiorAppraisal === null ? (
                        <>---</>
                      ) : (
                        <div id="RatingManagerDataEdit" className="dataEdit">
                          <ShowAppraisalGoal
                            id="employee"
                            title="KOMENTARZ OCENY PRZEZ MANAGERA"
                            appraisal={goalUser?.superiorAppraisal}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="EmployeeAppraisalGoalRatingBoxFirstRowSecondColumnRatingManagerN2Box"
                    className="ratingGoal"
                  >
                    <div id="RatingManagerN2Label" className="label">
                      Finalna ocena pracy:
                    </div>
                    <div id="RatingManagerN2Data" className="data">
                      {goalUser?.finalAppraisal === null ? (
                        <>---</>
                      ) : (
                        <ShowAppraisalGoal
                          id="employee"
                          title="KOMENTARZ DO OCENY FINALNEJ"
                          appraisal={goalUser?.finalAppraisal}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {dataGoal?.content && dataGoal?.content.length > 0 ? (
                <EmployeeAppraisalGoalRatingList
                  dataGoal={dataGoal.content}
                  setEditFormShow={setEditFormShow}
                  setModalId={setModalId}
                />
              ) : (
                ''
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {dataFeedback === null ? (
        <ContentBox id="checkFeedback180">
          <>
            <div id="checkFeedback180Title">
              FEEDBACK 180 nie został uzupełniony. Czy chcesz go uzupełnić
              teraz?
            </div>
            <Button
              className="green"
              onClick={() =>
                history.push(`/panel/ocena-pracownika/feedback-180`)
              }
            >
              PRZEJDŹ DO FORMULARZA FEEDBACK 180
            </Button>
          </>
        </ContentBox>
      ) : (
        ''
      )}
      {editFormShow ? (
        <EmployeeAppraisalGoalRatingEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateDataTable}
          idEmployee={Number(id)}
          modalId={modalId}
          employee={employee}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default EmployeeAppraisalGoalE;
