import React, { ReactElement, Dispatch, SetStateAction, useState } from 'react';
import { TrainingsProviderRepository } from 'api/repository';
import {
  Box,
  Modal,
  ShowBox,
  ShowInput,
  ShowTextarea,
  TabsHook,
} from 'components';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import FormTrainingsProvider from '../Form/FormTrainingsProvider';
import TableAddress from '../TrainingsProviderAddress';
import TableContact from '../TrainingsProviderContact';

interface TrainigsProviderProps {
  id: number;
  modalShow: boolean;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsProviderShow = (props: TrainigsProviderProps): ReactElement => {
  const { id, modalShow, changeModalShow } = props;

  const { t } = useTranslation('trainingsProvider');

  const [activeTab, setActiveTab] = useState<string>('dane-dostawcy');

  const [data] = TrainingsProviderRepository.useGetTrainingsProvider({ id });

  return (
    <Modal open={modalShow} changeModalShow={changeModalShow}>
      <ShowBox
        id="showTrainingsProvider"
        name="PODGLĄD DANYCH DOSTAWCY SZKOLEŃ"
        noPadding
      >
        <>
          <TabsHook
            marginTop
            id="providerTrainingsTabShow"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            items={[
              {
                id: 'dane-dostawcy',
                label: 'Dane dostawcy',
                svgName: 'tabPeople',
              },
              {
                id: 'dane-kontaktowe',
                label: 'Kontakty dostawcy',
                svgName: 'tabContact',
              },
              {
                id: 'dane-adresowe',
                label: 'Adresy dostawcy',
                svgName: 'tabAdress',
              },
            ]}
          />
          {activeTab === 'dane-dostawcy' ? (
            <>
              <Box
                id="trainingProviderBox"
                name={t('form.basicInfomation')}
                svgIcon="basicInformation"
                lightHeader
                noRadius
                paddingBottom
                noShadow
              >
                <>
                  <Row className="justify-content-md-center">
                    <Col>
                      <ShowInput
                        id="name"
                        label={t('form.nameLabel')}
                        data={data?.name}
                      />
                      <ShowInput
                        id="fullName"
                        label={t('form.nameFullName')}
                        data={data?.fullName}
                      />
                      <ShowInput
                        id="website"
                        label={t('form.website')}
                        data={data?.website}
                      />
                    </Col>
                    <Col>
                      <ShowInput
                        id="nip"
                        label={t('form.taxNumber')}
                        data={data?.nip}
                      />
                      <ShowInput
                        id="regon"
                        label={t('form.regon')}
                        data={data?.regon}
                      />
                    </Col>
                  </Row>
                </>
              </Box>
              <Box
                id="trainingProviderBox"
                name={t('form.cooperationTerms')}
                svgIcon="hand-cap"
                lightHeader
                noRadius
                noMarginBottom
                noShadow
              >
                <ShowTextarea
                  id="cooperationTermsShow"
                  data={data?.cooperationTerms}
                />
              </Box>
            </>
          ) : (
            ''
          )}
          {activeTab === 'dane-kontaktowe' ? (
            <TableContact id={Number(id)} onlyView />
          ) : (
            ''
          )}
          {activeTab === 'dane-adresowe' ? (
            <TableAddress id={Number(id)} onlyView />
          ) : (
            ''
          )}
        </>
      </ShowBox>
    </Modal>
  );
};

export default TrainingsProviderShow;
