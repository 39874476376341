/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './showTextarea.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label?: string;
  data?: string;
  normalSizeFont?: boolean;
}

const ShowTextarea = (props: Props): ReactElement => {
  const { id, label, data, normalSizeFont } = props;

  return (
    <div
      id={id}
      className={`showTextarea ${normalSizeFont ? 'normalSizeFont' : ''}`}
    >
      {label ? (
        <div id={`${id}-label`} className="label">
          {label}
        </div>
      ) : (
        ''
      )}

      <div
        id={`${id}-value`}
        className="data"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={data ? { __html: data } : undefined}
      />
    </div>
  );
};

export default ShowTextarea;
