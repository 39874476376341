/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import Select from 'react-select';
import './CustomHeaderFormatter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  column: any;
  sortElement?: any;
  filterElement?: any;
  align?: 'left' | 'center' | 'right';
  moveOverAction?: boolean;
}

const CustomHeaderFormatter = (props: Props): ReactElement => {
  const { column, sortElement, filterElement, align, moveOverAction } = props;

  return (
    <div
      className={`columnHeaderCell ${align !== undefined ? align : 'left'} ${
        moveOverAction ? 'moveOverAction' : ''
      }`}
    >
      {sortElement ? <div className="sort">{sortElement}</div> : ''}
      <div className="columnName">{column.text}</div>{' '}
      {filterElement ? <div className="filter">{filterElement}</div> : ''}
    </div>
  );
};

export default CustomHeaderFormatter;
