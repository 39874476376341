/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState } from 'react';
import { RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';
import '../../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  children: ReactElement;
  handleSearch: () => void;
  labelSearch: string;
  openSearch: boolean;
  activeSearch: boolean;
  alignBox?: 'left' | 'center' | 'right';
  handleSearchClear?: () => void;
}

const SearchBox = (props: Props): ReactElement => {
  const {
    children,
    handleSearch,
    handleSearchClear,
    labelSearch,
    openSearch,
    activeSearch,
    alignBox,
  } = props;

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={`searchBox noButtonStyle ${openSearch ? 'active' : ''} ${
          activeSearch === true ? 'activeSearch' : ''
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onKeyUp={(e) => {
          e.stopPropagation();
        }}
        onKeyPress={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="iconBox">
          <RenderSvg nameSvg="search" />
        </div>
        <div
          className={`blockSearch ${
            alignBox !== undefined ? alignBox : 'center'
          }`}
        >
          {children}
          <div className="buttonBox">
            <div
              role="button"
              onClick={handleSearch}
              onKeyUp={() => {}}
              tabIndex={0}
              className="buttonSubmit"
            >
              {labelSearch}
            </div>
            {handleSearchClear ? (
              <div
                role="button"
                onClick={handleSearchClear}
                onKeyUp={() => {}}
                tabIndex={0}
                className="buttonClear"
              >
                Wyczyść filtr
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
