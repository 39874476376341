import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  ContentBox,
  CustomContainer,
  LoaderTable,
  RenderSvg,
  ShowAppraisalGoal,
  ShowTextarea,
  Tabs,
  TabsHook,
} from 'components';
import {
  EmployeeAppraisalRepository,
  EmployeeRepository,
} from 'api/repository';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalPutSpecificGoal } from 'api/types';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';

import EmployeeAppraisalEmployeeGoal from './EmployeeAppraisalEmployeeGoal';
import SpecificGoalEditModal from './SpecificGoalEditModal';
import EmployeeAppraisalEmployeeGoalRating from './EmployeeAppraisalEmployeeGoalRating';
import EmployeeAppraisalFeedback180 from './EmployeeAppraisalFeedback180';

import './employeeAppraisalDetail.scss';

interface Props {
  idEvaluation: number;
  idEmployee: number;
  selectedYear?: string;
  employeeName?: string;
}

const EmployeeAppraisalDetail = (props: Props): ReactElement => {
  const { idEvaluation, idEmployee, selectedYear, employeeName } = props;

  const [
    getGoalUser,
    updateGoalUser,
    refreshGoalUser,
  ] = EmployeeAppraisalRepository.useGetGoalEmployeeManager(
    idEmployee,
    idEvaluation
  );

  const [selectTab, setSelectTab] = useState<string>('goals');

  return (
    <>
      <CustomContainer>
        <LoaderTable active={getGoalUser === undefined}>
          <Box
            id="EmployeeAppraisalBox"
            name="PODGLĄD OCENY PRACOWNIKA"
            svgIcon="addEdit"
            noPadding
          >
            <>
              <TabsHook
                id="EmployeeAppraisalTabs"
                items={[
                  {
                    id: 'goals',
                    label: 'Cele',
                    svgName: 'target',
                  },
                  {
                    id: 'feedback',
                    label: 'Feedback 180',
                    svgName: 'feedback',
                  },
                ]}
                activeTab={selectTab}
                setActiveTab={setSelectTab}
              />
              {selectTab === 'goals' ? (
                <>
                  <div id="basicInformation">
                    <div id="firstRowBasicInformation">
                      <div id="firstColumn">
                        <div id="labelEmployee">
                          Podgląd dla celów rocznych {selectedYear || ''}:
                        </div>
                        <div id="dataEmployee">{employeeName}</div>
                      </div>
                    </div>
                  </div>
                  {getGoalUser === null ? (
                    <div id="EmployeeAppraisalContentBoxEmpty">
                      Nie ma Oceny pracownika
                    </div>
                  ) : (
                    <>
                      <div id="EmployeeAppraisalContentBox">
                        <EmployeeAppraisalEmployeeGoal
                          employeeAppraisalStatus={getGoalUser}
                          updateGoalUser={updateGoalUser}
                          idEmployee={idEmployee}
                          idEvaluation={idEvaluation}
                          goalUser={getGoalUser}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
              {selectTab === 'feedback' ? (
                <>
                  <div id="basicInformation">
                    <div id="firstRowBasicInformation">
                      <div id="firstColumn">
                        <div id="labelEmployee">
                          Podgląd feedback 180 - {selectedYear || ''}:
                        </div>
                        <div id="dataEmployee">{employeeName}</div>
                      </div>
                    </div>
                  </div>
                  {getGoalUser === null ? (
                    <div id="EmployeeAppraisalContentBoxEmpty">
                      Nie ma feedback 180
                    </div>
                  ) : (
                    <>
                      <div id="EmployeeAppraisalContentBox">
                        <EmployeeAppraisalFeedback180
                          idEmployee={idEmployee}
                          idEvaluation={idEvaluation}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          </Box>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EmployeeAppraisalDetail;
