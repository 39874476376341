import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import { IDPFeedbackModel } from 'api/types';
import {
  Box,
  ContentBox,
  LoaderForm,
  Modal,
  RenderSvg,
  ShowTextarea,
} from 'components';
import IDPFeedbackShow from './IDPFeedbackShow';

interface Props {
  employeeId: number;
  idpId: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  selectedYear?: string;
  employeeName?: string;
}

const IDPFeedbackModalShow = (props: Props): ReactElement => {
  const {
    employeeId,
    idpId,
    changeModalEdit,
    selectedYear,
    employeeName,
  } = props;

  const [data] = IDPRepository.useGetIDPFeedbackManager({
    id: idpId,
    employeeId,
  });

  const cancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={cancel} widthBox="high" maxLevel="lvl2">
      <Box
        id="employeeFeedbackShow"
        name={`Feedback z roku ${selectedYear}`}
        noMarginBottom
      >
        <IDPFeedbackShow
          data={data}
          year={selectedYear}
          employeeName={employeeName}
        />
      </Box>
    </Modal>
  );
};

export default IDPFeedbackModalShow;
