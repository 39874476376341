import { TrainingsProviderRequest, Error, TrainingsProvider } from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const useGetAllTrainingsProvider = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: TrainingsProviderRequest) => void,
  PagedData<TrainingsProvider> | null
] => {
  const axios = useAxios();
  const [
    trainingsProvider,
    setTrainingsProvider,
  ] = useState<PagedData<TrainingsProvider> | null>(null);

  const doGet = useCallback(
    (params: TrainingsProviderRequest) => {
      axios
        .get<PagedData<TrainingsProvider>>(
          `${apiEndpoints.trainingsProvider}/providers`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setTrainingsProvider(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, trainingsProvider];
};

export default useGetAllTrainingsProvider;
