/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import './TableCellTrainingsStatus.scss';
import CustomInfoBlock from 'components/CustomInfoBlock';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  id: number;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellTrainingsStatus = (props: Props): ReactElement => {
  const { id, data, justify } = props;

  return (
    <>
      <div className={`tableCellTrainingsStatus ${justify || ''}`}>
        <div id="dhr" className="status">
          <div className="labelStatus">DziałHR</div>
          <div className="boxStatus">
            {data.hrAccepted === 'REJECTED' && data.hrComment !== '' ? (
              <CustomInfoBlock
                customText={data.hrComment}
                customTitle="Decyzja braku akceptacji przez dział HR"
                customSvg="manager"
                widthBox="medium"
              >
                <div className={`${data.hrAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.hrAccepted}StatusWithInfo`} />
                </div>
              </CustomInfoBlock>
            ) : (
              <div className={`${data.hrAccepted} circleBox`}>
                <RenderSvg nameSvg={`${data.hrAccepted}Status`} />
              </div>
            )}
          </div>
        </div>
        <div id="ds" className="status">
          <div className="labelStatus">Szkolenia</div>
          <div className="boxStatus">
            {data.dsAccepted === 'REJECTED' && data.dsComment !== '' ? (
              <CustomInfoBlock
                customText={data.dsComment}
                customTitle="Decyzja braku akceptacji przez dział Szkoleń"
                customSvg="manager"
                widthBox="medium"
              >
                <div className={`${data.dsAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.dsAccepted}StatusWithInfo`} />
                </div>
              </CustomInfoBlock>
            ) : (
              <div className={`${data.dsAccepted} circleBox`}>
                <RenderSvg nameSvg={`${data.dsAccepted}Status`} />
              </div>
            )}
          </div>
        </div>
        <div id="slt" className="status">
          <div className="labelStatus">SLT</div>
          <div className="boxStatus">
            {data.sltAccepted === 'REJECTED' && data.sltComment !== '' ? (
              <CustomInfoBlock
                customText={data.sltComment}
                customTitle="Decyzja braku akceptacji przez SLT"
                customSvg="manager"
                widthBox="medium"
              >
                <div className={`${data.sltAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.sltAccepted}StatusWithInfo`} />
                </div>
              </CustomInfoBlock>
            ) : (
              <div className={`${data.sltAccepted} circleBox`}>
                <RenderSvg nameSvg={`${data.sltAccepted}Status`} />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableCellTrainingsStatus;
