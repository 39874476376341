import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import {
  EmployeeAppraisalFeedback180Form,
  EmployeeAppraisalFeedback180Model,
  EmployeeAppraisalGoal,
} from 'api/types';
import Feedback180 from '../../Form/Feedback180';

interface Props {
  employeeAppraisalStatus?: EmployeeAppraisalGoal;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string; idRecord: string }>();

  const { employeeAppraisalStatus, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    Feedback,
    FeedbackUpdate,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalFeedback();

  const handleEditTrainingsProvider = EmployeeAppraisalRepository.usePutEmployeeAppraisalFeedback(
    async () => {
      setSubmitForm(false);
      if (employeeAppraisalStatus === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: EmployeeAppraisalFeedback180Form) => {
    if (!submitForm) {
      const dataToPost: EmployeeAppraisalFeedback180Model = {
        feedbackValues: {
          CORRECT: { description: data.CORRECT },
          CONTINUE: { description: data.CONTINUE },
          STOP: { description: data.STOP },
          INTRODUCE: { description: data.INTRODUCE },
        },
      };
      setSubmitForm(true);

      handleEditTrainingsProvider(dataToPost);
    }
  };

  return (
    <Feedback180
      id={Number(id)}
      onTrainingsProvider={handleSubmit}
      submitForm={submitForm}
      data={Feedback}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
