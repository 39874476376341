import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './CustomSwitch.scss';
import { Switch, RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface CustomSwitchProps {
  name: string;
  label?: string;
  register?: () => void;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  error?: string | undefined;
  value?: boolean | undefined;
  defaultValue?: boolean | undefined;
  info?: string;
}

const CustomSwitch = (props: CustomSwitchProps): ReactElement => {
  const {
    name,
    label,
    register,
    required,
    id,
    disabled,
    error,
    value,
    defaultValue,
    info,
  } = props;

  const [checkValue, setCheckvalue] = useState<boolean | null>(null);

  useEffect(() => {
    if (checkValue === null) {
      if (defaultValue !== undefined) {
        setCheckvalue(defaultValue);
      }
    }
  }, [defaultValue, checkValue]);

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let tclass = 'blockCustomSwitch';
    if (label) {
      tclass += ' withLabel';
    }
    if (required) {
      tclass += ' required';
    }
    if (typeof error !== 'undefined') {
      tclass += ' error';
    }
    if (typeof info !== 'undefined' && !disabled) {
      tclass += ' withInfo';
    }
    if (disabled) {
      tclass += ' disabled';
    }

    return tclass;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  const onChange = () => {
    setCheckvalue(!checkValue);
  };

  return (
    <>
      <div className={additionalClass()}>
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
        <Switch
          id={id || name}
          name={name}
          register={register}
          onChange={onChange}
          checked={checkValue}
          disabled={disabled}
        />

        {infoElement()}
      </div>
      <div className="additionalInfo">{errorElement()}</div>
    </>
  );
};

export default CustomSwitch;
