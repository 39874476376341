import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Box, LoaderTable, Modal, TabsHook } from 'components';
import { IDPRepository } from 'api/repository';

import IDPEmployeeDetail from './IDPEmployeeDetail';

import IDPEmployeeArea from './IDPEmployeeArea';
import IDPEmployeeLeaderProfile from './IDPEmployeeLeaderProfile';
import IDPFeedbackModalShow from './IDPFeedbackModalShow';

import './IDPDetail.scss';

interface Props {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  idIdp: number;
  selectedYear?: string;
}

const ShowIDP = (props: Props): ReactElement => {
  const { setShowModal, idIdp, selectedYear } = props;

  const [selectTab, setSelectTab] = useState<string>('detail');

  const [IDPEmployee, fetchData] = IDPRepository.useGetIDPEmployeeDataUser({
    idIdp,
  });

  return (
    <>
      <Modal open changeModalShow={setShowModal} widthBox="high">
        <LoaderTable active={IDPEmployee === undefined}>
          <Box
            id="ShowIDPEmployeeBox"
            name={`PODGLĄD IDP - ${selectedYear || ''}`}
            svgIcon="show"
            noPadding
            noMarginBottom
            noShadow
          >
            <>
              {IDPEmployee === null ? (
                <div id="IDPEmployeeContentBoxEmpty">BRAK DANYCH IDP</div>
              ) : (
                <>
                  <TabsHook
                    id="IDPEmployeeTabs"
                    items={[
                      {
                        id: 'detail',
                        label: 'Cele, komentarze, mobilność',
                        svgName: 'target',
                      },
                      {
                        id: 'area',
                        label: 'Obszary rozwoju',
                        svgName: 'searchAndGraph',
                      },
                      {
                        id: 'profil-lidera',
                        label: 'Profil lidera',
                        svgName: 'employeeWithStar',
                      },
                      {
                        id: 'feedback',
                        label: 'Feedback nr wartości',
                        svgName: 'feedback',
                      },
                    ]}
                    activeTab={selectTab}
                    setActiveTab={setSelectTab}
                  />
                  <div id="IDPEmployeeContentBox">
                    {selectTab === 'detail' ? (
                      <IDPEmployeeDetail IDPEmployee={IDPEmployee} />
                    ) : (
                      ''
                    )}
                    {selectTab === 'area' ? (
                      <IDPEmployeeArea idIdp={idIdp} />
                    ) : (
                      ''
                    )}
                    {selectTab === 'profil-lidera' ? (
                      <IDPEmployeeLeaderProfile idIdp={idIdp} />
                    ) : (
                      ''
                    )}
                    {selectTab === 'feedback' ? (
                      <IDPFeedbackModalShow idIdp={idIdp} />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </Box>
        </LoaderTable>
      </Modal>
    </>
  );
};

export default ShowIDP;
