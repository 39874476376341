import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  CustomSubmit,
  EditorMce,
  Loader,
  QuestionRadio,
  QuestionYesNoPartly,
} from 'components';
import { QuestionaireRepository } from 'api/repository';
import {
  QuestionnaireAnswersForm,
  QuestionnaireQuestion,
  QuestionnaireAnswersFormValid,
} from 'api/types';

import './questionnaireEdit.scss';

const QuestionnaireEdit = (): ReactElement => {
  const { idGroup, idQuestionnaire } = useParams<{
    idGroup: string;
    idQuestionnaire: string;
  }>();
  const history = useHistory();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [data] = QuestionaireRepository.useGetQuestionnaire({
    id: Number(idQuestionnaire),
  });

  const [value, setValue] = useState<QuestionnaireAnswersForm | undefined>(
    undefined
  );

  const [validValue, setValidValue] = useState<
    QuestionnaireAnswersFormValid | undefined
  >(undefined);

  useEffect(() => {
    if (data !== undefined && data !== null && value === undefined) {
      const tValue = {};
      const tValidValue = {};
      if (data?.questions && data?.questions.length > 0) {
        data.questions.forEach((val) => {
          tValue[val.questionOrder] = {
            answerText: null,
            answerType: val.questionType,
            choiceId: null,
          };

          if (val.questionType !== 'TEXT') {
            tValidValue[val.questionOrder] = {
              notValid: false,
              notValidText: false,
            };
          }
        });
        setValue(tValue);
        setValidValue(tValidValue);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangeRadio = (idAnswer: number, choiceId: number | null) => {
    if (value !== undefined) {
      const tValue = value;
      tValue[idAnswer].choiceId = choiceId;
      setValue(tValue);
    }
  };

  const handleChangeAnswerText = (idAnswer: number, answerText: string) => {
    if (value !== undefined) {
      const tValue = value;
      tValue[idAnswer].answerText = answerText;
      setValue(tValue);
    }
  };

  const saveQuestionnaire = QuestionaireRepository.usePostQuestionnaire(
    Number(idGroup),
    async () => {
      setSubmitForm(false);
      history.push('/panel/szkolenia/ankiety');
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleValidValue = () => {
    if (validValue !== undefined && value !== undefined) {
      const tValidValue = validValue;
      let valid = true;
      Object.keys(value).forEach((key) => {
        if (
          value[key].answerType === 'SINGLE_CHOICE' &&
          value[key].choiceId === null
        ) {
          valid = false;
          tValidValue[key].notValid = true;
        }
        if (
          value[key].answerType === 'SINGLE_CHOICE_WITH_TEXT' &&
          value[key].choiceId === null
        ) {
          valid = false;
          tValidValue[key].notValid = true;
        }
      });
      setValidValue(tValidValue);

      if (valid === true) {
        setSubmitForm(true);
        saveQuestionnaire({ answers: value });
      }
    }
  };

  const handleSubmit = () => {
    handleValidValue();
  };

  return (
    <>
      <CustomContainer>
        <Box
          id="questionnaireBox"
          name="ANKIETY"
          svgIcon="questionnarieIcon"
          noPadding
        >
          <Loader active={data === undefined || submitForm === true}>
            <>
              <div id="questionnaireHeader">
                <div id="questionnaireHeaderLabel">Ankieta dla szkolenia:</div>
                <div id="questionnaireHeaderValue">{data?.name}</div>
              </div>
              <div id="questionnaireBody">
                <div id="questionnaireBodyHead">
                  <div id="questionnaireBodyHeadLeft">Lista pytań:</div>
                  <div id="questionnaireBodyHeadRight">Odpowiedzi:</div>
                </div>
                <div id="questionnaireBodyContent">
                  <div id="questionnaireBodyContentGroup-1" className="group">
                    <div
                      id="questionnaireBodyContentGroupLabel-1"
                      className="groupLabel"
                    >
                      Ocena techniczno – organizacyjnego przygotowania szkolenia
                    </div>
                    {data?.questions && data?.questions.length > 0
                      ? data.questions.map(
                          (item: QuestionnaireQuestion, i: number) => {
                            if (i <= 4) {
                              if (item.questionType === 'SINGLE_CHOICE') {
                                return (
                                  <QuestionRadio
                                    data={item}
                                    changeHandle={(choiceId: number | null) => {
                                      handleChangeRadio(
                                        item.questionOrder,
                                        choiceId
                                      );
                                    }}
                                    error={
                                      validValue
                                        ? validValue[item.questionOrder]
                                            ?.notValid
                                        : undefined
                                    }
                                  />
                                );
                              }
                              return `${item.questionType}, `;
                            }
                            return null;
                          }
                        )
                      : ''}
                  </div>
                  <div id="questionnaireBodyContentGroup-2" className="group">
                    <div
                      id="questionnaireBodyContentGroupLabel-2"
                      className="groupLabel"
                    >
                      Ocena wykładowców
                    </div>
                    {data?.questions && data?.questions.length > 0
                      ? data.questions.map(
                          (item: QuestionnaireQuestion, i: number) => {
                            if (i >= 5 && i <= 8) {
                              if (item.questionType === 'SINGLE_CHOICE') {
                                return (
                                  <QuestionRadio
                                    data={item}
                                    changeHandle={(choiceId: number | null) => {
                                      handleChangeRadio(
                                        item.questionOrder,
                                        choiceId
                                      );
                                    }}
                                    error={
                                      validValue
                                        ? validValue[item.questionOrder]
                                            ?.notValid
                                        : undefined
                                    }
                                  />
                                );
                              }
                              return `${item.questionType}, `;
                            }
                            return null;
                          }
                        )
                      : ''}
                  </div>
                  <div id="questionnaireBodyContentGroup-3" className="group">
                    <div
                      id="questionnaireBodyContentGroupLabel-3"
                      className="groupLabel"
                    >
                      Ocena merytoryczna szkolenia
                    </div>
                    {data?.questions && data?.questions.length > 0
                      ? data.questions.map(
                          (item: QuestionnaireQuestion, i: number) => {
                            if (i >= 9 && i <= 12) {
                              if (item.questionType === 'SINGLE_CHOICE') {
                                return (
                                  <QuestionRadio
                                    data={item}
                                    changeHandle={(choiceId: number | null) => {
                                      handleChangeRadio(
                                        item.questionOrder,
                                        choiceId
                                      );
                                    }}
                                    error={
                                      validValue
                                        ? validValue[item.questionOrder]
                                            ?.notValid
                                        : undefined
                                    }
                                  />
                                );
                              }
                              if (
                                item.questionType === 'SINGLE_CHOICE_WITH_TEXT'
                              ) {
                                return (
                                  <QuestionYesNoPartly
                                    data={item}
                                    changeHandle={(choiceId: number | null) => {
                                      handleChangeRadio(
                                        item.questionOrder,
                                        choiceId
                                      );
                                    }}
                                    changeTextHandle={(answerText: string) => {
                                      handleChangeAnswerText(
                                        item.questionOrder,
                                        answerText
                                      );
                                    }}
                                    error={
                                      validValue
                                        ? validValue[item.questionOrder]
                                            ?.notValid
                                        : undefined
                                    }
                                  />
                                );
                              }
                              return `${item.questionType}, `;
                            }
                            return null;
                          }
                        )
                      : ''}
                  </div>
                  <div id="questionnaireBodySummary">
                    {data?.questions && data?.questions.length > 0 ? (
                      <QuestionRadio
                        data={data?.questions[13]}
                        changeHandle={(choiceId: number | null) => {
                          handleChangeRadio(
                            data?.questions[13].questionOrder,
                            choiceId
                          );
                        }}
                        additionalClass="summary"
                        noNumber
                      />
                    ) : (
                      ''
                    )}
                    <div id="questionnaireBodySummaryCommentsBox">
                      {data?.questions && data?.questions.length > 0 ? (
                        <EditorMce
                          id="comments"
                          name="comments"
                          label="Uwagi:"
                          noInfoPlace
                          onChange={(textMce: string) => {
                            handleChangeAnswerText(
                              data?.questions[14].questionOrder,
                              textMce
                            );
                          }}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div id="questionnaireBodySubmit">
                    <CustomSubmit
                      saveAndExitWithoutSubmit={() => {
                        handleSubmit();
                      }}
                      cancel={() => {}}
                    />
                  </div>
                </div>
              </div>
            </>
          </Loader>
        </Box>
      </CustomContainer>
    </>
  );
};

export default QuestionnaireEdit;
