import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ModalYesNo,
  CustomTooltip,
  ShowListToString,
  ShowSuperior,
  AuthResources,
} from 'components';
import {
  ManagerUserPlansTrainingsRepository,
  EmployeeRepository,
} from 'api/repository';
import { Button } from 'react-bootstrap';
import TableTrainingsBlock from './TableTrainingsBlock';
import IDPShow from '../../../Manager/IDP/IDPShow';

import './editPlansTrainings.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');
  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [dataEmployee] = EmployeeRepository.useGetEmployee({ id: Number(id) });

  const [approve, setApprove] = useState<boolean>(false);

  const [updateLimitData, setUpdateLimitData] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);

  const history = useHistory();

  return (
    <>
      {dataEmployee !== null ? (
        <CustomContainer>
          <>
            <LoaderTable radiusTop allowGrow>
              <>
                <Box
                  id="userPlansAcceptedTrainingsBox"
                  name="AKCEPTACJA SZKOLEŃ PRACOWNIKA"
                  svgIcon="enrollmentForTrainings"
                  noPadding
                >
                  <>
                    <ContentBox
                      id="userPlansAcceptedTrainingsBasicInformation"
                      bottomBorder
                    >
                      <>
                        <div className="employeeNameBox">
                          <div className="titleName">Wybrany pracownik:</div>
                          <div className="name">
                            <span className="lastName">
                              {dataEmployee?.lastName},{' '}
                            </span>
                            <span className="firstName">
                              {dataEmployee?.firstName}
                            </span>
                          </div>
                          <div className="extension">
                            Nr. wewnętrzny:{' '}
                            <span className="extensionData">
                              {dataEmployee?.id}
                            </span>
                          </div>
                          <div className="superior">
                            Przełożony:{' '}
                            <span className="superiorData">
                              <ShowSuperior
                                data={dataEmployee?.superior}
                                noObject
                              />
                            </span>
                          </div>
                          <AuthResources
                            requiredRoles={['training_department']}
                          >
                            <Button
                              className="green"
                              onClick={() => setModalShow(true)}
                            >
                              Podgląd IDP
                            </Button>
                          </AuthResources>
                        </div>
                        <div className="additionalInfoBox">
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="location" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Lokalizacja:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  {dataEmployee?.locations}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="workplace" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Stanowiska:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  <ShowListToString
                                    data={dataEmployee?.positions}
                                    noObject
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="frameAdditionalInfo"
                            className="aditionalInfoContent"
                          >
                            <div
                              id="frameAdditionalInfoDetail"
                              className="content"
                            >
                              <div
                                id="frameAdditionalInfoIcon"
                                className="icon"
                              >
                                <RenderSvg nameSvg="departament" />
                              </div>
                              <div
                                id="frameAdditionalInfoBoxData"
                                className="additionalInfoDetail"
                              >
                                <div
                                  id="frameAdditionalInfoBoxDataTitle"
                                  className="title"
                                >
                                  Działy:
                                </div>
                                <div
                                  id="frameAdditionalInfoBoxDataData"
                                  className="data"
                                >
                                  <ShowListToString
                                    data={dataEmployee?.segments}
                                    noObject
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </ContentBox>
                    <TableTrainingsBlock />
                  </>
                </Box>
              </>
            </LoaderTable>
            <AuthResources requiredRoles={['training_department']}>
              <>
                {modalShow ? (
                  <IDPShow
                    setShowModal={setModalShow}
                    idEmployee={Number(id)}
                  />
                ) : (
                  ''
                )}
              </>
            </AuthResources>
          </>
        </CustomContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default EditAcceptedTrainings;
