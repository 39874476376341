/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { AnimatedProgressBarLine } from 'components';
import React, { ReactElement } from 'react';
import './tableCellProgressBar.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  valueEnd?: number;
  color?: 'orange' | 'darkGreen';
}

const TableCellProgressBar = (props: Props): ReactElement => {
  const { id, valueEnd, color } = props;

  return (
    <>
      <div
        id={`tableCellProgressBar-${id}`}
        className={`tableCellProgressBar ${color || ''}`}
      >
        {valueEnd || 0}%
        <AnimatedProgressBarLine valueEnd={valueEnd || 0} />
      </div>
    </>
  );
};

export default TableCellProgressBar;
