import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsProviderContactRepository } from 'api/repository';
import { TrainingsProviderContactAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsProviderContact from '../Form/FormTrainingsProviderContact';

interface TrainigsProviderProps {
  id: number;
  idRecord: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const TrainingsProviderEdit = (props: TrainigsProviderProps): ReactElement => {
  const { id, changeModalEdit, idRecord, changeUpdateTableData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    trainingProviderContact,
  ] = TrainingsProviderContactRepository.useGetTrainingsProviderContact({
    id: Number(id),
    idRecord: Number(idRecord),
  });

  const putDataSave = TrainingsProviderContactRepository.usePutTrainingsProviderContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: TrainingsProviderContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    data.providerId = id;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    putDataSave(data);
  };

  const postDataSaveAndExit = TrainingsProviderContactRepository.usePutTrainingsProviderContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContactAndExit = (
    dataPost: TrainingsProviderContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    data.providerId = id;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit}>
      <div>
        <FormTrainingsProviderContact
          data={trainingProviderContact}
          edit
          onSave={handleAddTrainingsProviderContact}
          onSaveAndExit={handleAddTrainingsProviderContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderEdit;
