import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TrainingsProviderRepository } from 'api/repository';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import {
  Box,
  TableRecordAction,
  TablePagination,
  TableCellAddress,
  TableCellContact,
  CustomFilterAddress,
  CustomFilterContact,
  CustomFilterText,
  CustomSort,
  CustomHeaderFormatter,
  CustomFilterData,
  CustomSortData,
  LoaderTable,
  CustomContainer,
} from 'components';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import TrainingProviderShow from './TrainingsProviderShow';

const Trainings = (): ReactElement => {
  const { t } = useTranslation('trainingsProvider');
  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    name: 'providerName',
    addressName: 'addressName',
    addressCity: 'city',
    contactName: 'contactName',
    contactEmail: 'email',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    name: 'default',
    address: 'addressName',
    contact: 'contactName',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = TrainingsProviderRepository.useGetAllTrainingsProvider();
  const deleteTrainingsProvider = TrainingsProviderRepository.useDeleteTrainingsProvider();
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  function handleShowClick(id: number) {
    setModalId(id);
    setModalShow(true);
  }

  const handleEditClick = (id: number) => {
    history.push(
      `/ustawienia/szkolenia/dostawcy-szkolen/formularz/${id}/dane-dostawcy`
    );
  };

  const handleDeleteClick = async (id: number) => {
    const idDelete = await deleteTrainingsProvider(id);
    setUpdateDataTable(Math.random);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={handleShowClick}
            editClick={handleEditClick}
            archiveClick={handleDeleteClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: t('trainingsProvider.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'address',
      text: t('trainingsProvider.defaultAddress'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellAddress data={row?.address} withName />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterAddress
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
    },
    {
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="right"
          />
        );
      },
      dataField: 'contact',
      text: t('trainingsProvider.defaultContact'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellContact data={row?.contact} withName justify="toRight" />
        );
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterContact
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
        />
      ),
    },
  ];

  /* if (data === null)
    return (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    );
*/
  //  if (data === null) return <div>Błąd</div>;

  return (
    <>
      <CustomContainer>
        <Row>
          <Col>
            <Box
              id="trainingProviderBox"
              name={t('trainingsProvider.trainingProvider')}
              svgIcon="hand-cap"
              exportTable
              noPadding
            >
              <LoaderTable active={loaderTable}>
                <>
                  <TablePagination
                    rowInPage={rowsPerPage}
                    page={page}
                    changeRowInPage={setRowsPerPage}
                    data={data}
                    changePage={setPage}
                  >
                    <Link
                      className="btn btn-primary green"
                      to="/ustawienia/szkolenia/dostawcy-szkolen/formularz"
                    >
                      {t('trainingsProvider.addTrainingsProvider')}
                    </Link>
                  </TablePagination>
                  <BootstrapTable
                    bootstrap4
                    hover
                    bordered={false}
                    keyField="id"
                    data={data?.content || []}
                    columns={columns}
                    noDataIndication={t('trainingsProvider.noDataTable')}
                    remote={{
                      filter: true,
                      sort: true,
                    }}
                    onTableChange={onTableChange}
                    filter={filterFactory()}
                    defaultSorted={[{ dataField: 'name', order: 'asc' }]}
                  />
                  <TablePagination
                    rowInPage={rowsPerPage}
                    page={page}
                    changeRowInPage={setRowsPerPage}
                    data={data}
                    changePage={setPage}
                  />
                </>
              </LoaderTable>
            </Box>
          </Col>
        </Row>
      </CustomContainer>
      {modalShow ? (
        <TrainingProviderShow
          id={modalId}
          modalShow={modalShow}
          changeModalShow={setModalShow}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Trainings;
