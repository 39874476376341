import React, { ReactElement, useState } from 'react';
import './Action.scss';
import { ModalYesNo, RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';

/*
TODO
Toggle TableRecordSwitch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  deleteClick: () => void;
}

const DeleteAction = (props: Props): ReactElement => {
  const { deleteClick } = props;
  const { t } = useTranslation('componentTableRecordAction');

  const [modalShow, setModalShow] = useState<boolean>(false);

  return (
    <>
      <div
        className="buttonAction"
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <RenderSvg nameSvg="deleteAction" />
      </div>
      {modalShow ? (
        <ModalYesNo changeModalShow={setModalShow} yesAction={deleteClick} />
      ) : (
        ''
      )}
    </>
  );
};

export default DeleteAction;
