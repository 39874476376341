import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { CompetencesRepository } from 'api/repository';
import { Competences } from 'api/types';
import { Modal } from 'components';
import FormCompetences from '../FormCompetences';

interface CompetencesProps {
  id: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const CompetencesEdit = (props: CompetencesProps): ReactElement => {
  const { id, changeModalEdit, changeUpdateTableData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [competences] = CompetencesRepository.useGetCompetences({
    id: Number(id),
  });

  const putDataSave = CompetencesRepository.usePutCompetences(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSave = (dataPost: Competences) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = id;
    putDataSave(data);
  };

  const postDataSaveAndExit = CompetencesRepository.usePutCompetences(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (dataPost: Competences) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = id;
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit} widthBox="low">
      <div>
        <FormCompetences
          data={competences}
          edit
          onSave={handleSave}
          onSaveAndExit={handleSaveAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default CompetencesEdit;
