import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { IDPRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
} from 'components';
import './IDPEmployeeArea.scss';
import { Button } from 'react-bootstrap';
import { IDPEmployeeModel, IDPModel } from 'api/types';
import IDPEmployeeAreaAdd from './IDPEmployeeAreaAdd';
import IDPEmployeeAreaEdit from './IDPEmployeeAreaEdit';
import IDPEmployeeAreaList from './IDPEmployeeAreaList';
import IDPEmployeeAreaImport from './IDPEmployeeAreaImport';

interface Props {
  IDPEmployee: IDPEmployeeModel;
  fetchData?: () => void;
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  settingsIdp?: IDPModel | null;
}

const IDPEmployeeArea = (props: Props): ReactElement => {
  const {
    IDPEmployee,
    fetchData,
    idEmployee,
    changeModalEdit,
    settingsIdp,
  } = props;

  const [addFormShow, setAddFormShow] = useState<boolean>(false);
  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [importFormShow, setImportFormShow] = useState<boolean>(false);

  const [updateData, setUpdateData] = useState<number>(0);

  const [showModalYesNo, setShowModalYesNo] = useState<boolean>(false);
  const [emptyFeedback, setEmptyFeedback] = useState<boolean>(false);

  const [getData, data] = IDPRepository.useGetAllIDPEmployeeAreaAdmin({
    id: idEmployee,
    idpId: IDPEmployee.id,
  });

  useEffect(() => {
    setLoaderTable(true);

    getData();
  }, [getData, updateData]);

  useEffect(() => {
    if (data !== undefined) {
      setLoaderTable(false);
    }
  }, [data]);

  const DeleteRecord = IDPRepository.useDeleteIDPEmployeeArea(
    Number(idEmployee),
    async () => {
      setUpdateData(Math.random);
    }
  );

  const handleDelete = (idClick: number) => {
    DeleteRecord(idClick);
  };

  const handleUnapproveClick = IDPRepository.usePostIDPEmployeeUnclose(
    Number(idEmployee),
    IDPEmployee.id,
    () => {
      if (fetchData) {
        fetchData();
      }
      changeModalEdit(false);
    }
  );

  return (
    <>
      <CustomContainer id="IDPEmployeeAreaBox">
        <>
          <ContentBox id="IDPEmployeeAreaBoxFirstRow" bottomBorder>
            <>
              <div id="IDPEmployeeAreaBoxFirstRowBox">
                <div id="IDPEmployeeAreaBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="searchAndGraph60" />
                </div>
                <div id="IDPEmployeeAreaBoxFirstRowBoxInfo">
                  <div id="IDPEmployeeAreaBoxFirstRowBoxInfoLabel">
                    Obszary rozwoju pracownika
                  </div>
                  <div id="IDPEmployeeAreaBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące obszarów rozwoju
                    <br />
                    Zostaną one rozważone w trakcie przeglądu organizacji i
                    zasobów ludzkich
                  </div>
                </div>
              </div>
            </>
          </ContentBox>

          <ContentBox id="IDPEmployeeAreaBoxSecondRow">
            <>
              <Button
                id="IDPEmployeeAreaAddArea"
                className="green "
                onClick={() => {
                  setAddFormShow(true);
                }}
              >
                Dodaj obszar do rozwoju
              </Button>
              <Button
                id="IDPEmployeeAreaImportArea"
                className="green"
                onClick={() => {
                  setImportFormShow(true);
                }}
              >
                Importuj obszar do rozwoju
              </Button>
            </>
          </ContentBox>

          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {data?.content && data?.content.length > 0 ? (
                <IDPEmployeeAreaList
                  data={data}
                  setEditFormShow={setEditFormShow}
                  setModalId={setModalId}
                  deleteAction={handleDelete}
                  IDPEmployee={IDPEmployee}
                />
              ) : (
                ''
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {settingsIdp?.previousSettingsId === IDPEmployee.settingId &&
      IDPEmployee.accepted === true ? (
        <ContentBox id="unapproveIDP">
          <>
            <Button className="green" onClick={() => handleUnapproveClick()}>
              ODAKCEPTUJ ROZMOWĘ ROCZNĄ IDP
            </Button>
            {showModalYesNo ? (
              <ModalYesNo
                changeModalShow={setShowModalYesNo}
                yesAction={() => handleUnapproveClick()}
                noAction={() => setShowModalYesNo(false)}
                title="ODAKECPTOWANIE IDP"
                additionalText="Czy chcesz odakceptować ostatnią rozmowę IDP?"
              />
            ) : (
              ''
            )}
          </>
        </ContentBox>
      ) : (
        ''
      )}

      {addFormShow ? (
        <IDPEmployeeAreaAdd
          changeModalAdd={setAddFormShow}
          changeUpdateData={setUpdateData}
          changeModalEdit={setEditFormShow}
          changeModalId={setModalId}
          idEmployee={Number(idEmployee)}
          idpId={IDPEmployee.id}
        />
      ) : (
        ''
      )}
      {editFormShow ? (
        <IDPEmployeeAreaEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateData}
          idEmployee={Number(idEmployee)}
          modalId={modalId}
          idIdp={IDPEmployee.id}
        />
      ) : (
        ''
      )}
      {importFormShow ? (
        <IDPEmployeeAreaImport
          changeModalImport={setImportFormShow}
          changeUpdateData={setUpdateData}
          idEmployee={Number(idEmployee)}
          idIdp={IDPEmployee.id}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default IDPEmployeeArea;
