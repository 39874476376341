import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  EmployeeAppraisalGoalModel,
  EmployeeAppraisalPostAndPutGoal,
  EmployeeAppraisalRatingModel,
  EmployeeAppraisalRatingPut,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  RenderSvg,
  CustomDate,
  CustomSliderBar,
  CustomSelect,
  EditorMce,
  EditorMceShort,
} from 'components';
import './goalRatingForm.scss';
import { triggerValidRequired } from 'utils';

type FormModel = EmployeeAppraisalRatingPut;

interface Props {
  onSaveAndExit?: (dataPost: EmployeeAppraisalRatingModel) => void;
  cancel?: () => void;
  data?: EmployeeAppraisalRatingModel | null;
  edit?: boolean;
  onlyView?: boolean;
  submitForm?: boolean;
  lastEdit?: boolean;
  titleBox: string;
}

const GoalRatingForm = (props: Props): ReactElement => {
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    edit,
    data,
    onlyView,
    onSaveAndExit,
    submitForm,
    cancel,
    lastEdit,
    titleBox,
  } = props;

  const { t } = useTranslation(['enum']);

  const formSchema: yup.SchemaOf<FormModel> = yup.object().shape({
    justification: yup.string().required('Pole wymagane'),
    rating: yup.string().required('Pole wymagane'),
  });

  const handleSaveAndExit = (dataSubmit: FormModel) => {
    setChangeForm(false);
    if (onSaveAndExit !== undefined) {
      onSaveAndExit(dataSubmit);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    trigger,
  } = useForm<FormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              id="formGoalRating"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formGoalRatingBox"
                    name={titleBox}
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <CustomSelect
                        label="Ocena:"
                        options={[
                          {
                            value: 'EXCEEDS_EXPECTATIONS',
                            label: t('appraisalGoal.EXCEEDS_EXPECTATIONS'),
                          },
                          {
                            value: 'PARTIALLY_EXCEEDS_EXPECTATIONS',
                            label: t(
                              'appraisalGoal.PARTIALLY_EXCEEDS_EXPECTATIONS'
                            ),
                          },
                          {
                            value: 'FULLY_MEETS_EXPECTATIONS',
                            label: t('appraisalGoal.FULLY_MEETS_EXPECTATIONS'),
                          },
                          {
                            value: 'PARTIALLY_MEETS_EXPECTATIONS',
                            label: t(
                              'appraisalGoal.PARTIALLY_MEETS_EXPECTATIONS'
                            ),
                          },

                          {
                            value: 'DOES_NOT_MEET_EXPECTATIONS',
                            label: t(
                              'appraisalGoal.DOES_NOT_MEET_EXPECTATIONS'
                            ),
                          },
                        ]}
                        name="rating"
                        required
                        placeholder="Wybierz adekwatną ocenę"
                        setValue={setValue}
                        error={errors?.rating?.message}
                        validRequiredSelect={() => {
                          triggerValidRequired('rating', trigger);
                        }}
                        valueByKey={data?.rating}
                      />

                      <EditorMceShort
                        id="justification"
                        name="justification"
                        setHeight={120}
                        placeholder="Wpisz swój komentarz"
                        register={register}
                        label="Komentarz:"
                        contentGet={data?.justification}
                        error={errors?.justification?.message}
                        required
                      />
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default GoalRatingForm;
