/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import Select from 'react-select';
import './TableCellDefault.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  recordDefault: boolean;
}

const TableCellDefault = (props: Props): ReactElement => {
  const { recordDefault } = props;

  return (
    <>
      {recordDefault ? (
        <div className="recordDefault">
          <RenderSvg nameSvg="ok" />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellDefault;
