/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import './TableCellImport.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  handleImport: any;
  id: number;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellImport = (props: Props): ReactElement => {
  const { id, handleImport, justify } = props;

  const handleClick = () => {
    handleImport(id);
  };

  return (
    <>
      <div className={`tableCellImport ${justify || ''}`}>
        <div
          className="buttonAction"
          onClick={() => handleClick()}
          role="button"
          tabIndex={0}
          onKeyDown={() => handleClick()}
        >
          <RenderSvg nameSvg="importAction" />
        </div>
      </div>
    </>
  );
};

export default TableCellImport;
