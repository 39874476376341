import React, { ReactElement, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingsGroupTraining, TrainingsGroupUserTraining } from 'api/types';
import { ActionInFolder, RenderSvg } from 'components';

import './groupItem.scss';
import { Button } from 'react-bootstrap';

interface Props {
  id: string;
  data: TrainingsGroupTraining;
  selectedGroup?: TrainingsGroupUserTraining | null;
  showClick?: (dataId: number) => void;
  selectClick: () => void;
  changeSelectClick: () => void;
}

const ContentBox = (props: Props): ReactElement => {
  const {
    id,
    data,
    selectedGroup,
    showClick,
    selectClick,
    changeSelectClick,
  } = props;
  const { t } = useTranslation(['plansTrainings', 'trainingsGroup']);

  const actualDate: Date = new Date();
  const startDate: Date | null =
    selectedGroup?.trainingGroup?.startDate !== null &&
    selectedGroup?.trainingGroup?.startDate !== undefined
      ? new Date(selectedGroup?.trainingGroup?.startDate)
      : null;
  const singleDate: Date | null =
    selectedGroup?.trainingGroup?.singleDate !== null &&
    selectedGroup?.trainingGroup?.singleDate !== undefined
      ? new Date(selectedGroup?.trainingGroup?.singleDate)
      : null;

  const button = () => {
    if (selectedGroup?.trainingGroup === null) {
      if (
        data.startDate !== null &&
        new Date(data.startDate).getTime() <= actualDate.getTime()
      ) {
        return (
          <Button className="noActive disabled">
            Nie można wybrać tej daty
          </Button>
        );
      }
      if (
        data.singleDate !== null &&
        new Date(data.singleDate).getTime() <= actualDate.getTime()
      ) {
        return (
          <Button className="noActive disabled">
            Nie można wybrać tej daty
          </Button>
        );
      }
      if (data?.maxPeople - data?.registrationsCount === 0) {
        return (
          <Button className="noActive disable">Brak wolnych miejsc</Button>
        );
      }
      return (
        <Button className="green" onClick={() => selectClick()}>
          Wybierz datę
        </Button>
      );
    }
    if (selectedGroup?.trainingGroup?.id === Number(data.id)) {
      return <Button className="noActive disabled">Wybrana</Button>;
    }
    if (selectedGroup?.trainingGroup?.id !== Number(data.id)) {
      if (
        data.startDate !== null &&
        new Date(data.startDate).getTime() <= actualDate.getTime()
      ) {
        return (
          <Button className="noActive disabled">Nie można zmienić daty</Button>
        );
      }
      if (
        data.singleDate !== null &&
        new Date(data.singleDate).getTime() <= actualDate.getTime()
      ) {
        return (
          <Button className="noActive disabled">Nie można zmienić daty</Button>
        );
      }
      if (startDate !== null) {
        startDate.setDate(startDate.getDate() - 28);
        if (startDate.getTime() <= actualDate.getTime()) {
          return (
            <Button className="noActive disabled">
              Nie można zmienić daty
            </Button>
          );
        }
        if (data?.maxPeople - data?.registrationsCount === 0) {
          return (
            <Button className="noActive disabled">Brak wolnych miejsc</Button>
          );
        }
        if (startDate.getTime() > actualDate.getTime()) {
          return (
            <Button className="green" onClick={() => changeSelectClick()}>
              Zmień datę
            </Button>
          );
        }
      }
      if (singleDate !== null) {
        singleDate.setDate(singleDate.getDate() - 28);
        if (singleDate.getTime() <= actualDate.getTime()) {
          return (
            <Button className="noActive disabled">
              Nie można zmienić daty
            </Button>
          );
        }
        if (data?.maxPeople - data?.registrationsCount === 0) {
          return (
            <Button className="noActive disabled">Brak wolnych miejsc</Button>
          );
        }
        if (singleDate.getTime() > actualDate.getTime()) {
          return (
            <Button className="green" onClick={() => changeSelectClick()}>
              Zmień datę
            </Button>
          );
        }
      }
      return null;
    }

    return null;
  };

  return (
    <div
      className={`trainingGroupsUserSelectTrainingSelectGroupItem ${
        (data.startDate !== null &&
          new Date(data.startDate).getTime() <= actualDate.getTime()) ||
        (data.singleDate !== null &&
          new Date(data.singleDate).getTime() <= actualDate.getTime())
          ? 'noActive'
          : ''
      }`}
      id={`${id}-${data.id}`}
    >
      <div className="accent" />
      <div className="name">{data.name}</div>

      <div className="datesPlan">
        <div className="icon">
          <RenderSvg nameSvg="plansIcon" />
        </div>
        {data.startDate !== null ? (
          <div className="dates">
            <div className="startDateBox">
              Od:{' '}
              <span className="startDate">
                {data.startDate.substring(0, 10)}
              </span>
            </div>
            <div className="endDateBox">
              Do:{' '}
              <span className="endDate">{data.endDate.substring(0, 10)}</span>
            </div>
          </div>
        ) : (
          <div className="singleDate">
            <div className="singleDateBox">
              {data.singleDate.substring(0, 10)}
            </div>
          </div>
        )}
      </div>

      <div className="typeTrainingsBox">
        <div className="typeTrainingsLabel">Forma szkolenia:</div>
        <div className="typeTrainingsValue">
          {data?.trainingForm
            ? t(`trainingsGroup:TrainingFormTypeEnum.${data?.trainingForm}`)
            : ''}
        </div>
      </div>
      <div className="freeSpaceBox">
        <div className="freeSpaceLabel">Wolne miejsca:</div>
        <div className="freeSpaceValue">
          {data?.maxPeople - data?.registrationsCount}
        </div>
      </div>

      <div className="action">
        <div className="buttonBox">{button()}</div>
        <ActionInFolder
          id="actionTrainingsGroupItem"
          dataId={data.id}
          showClick={showClick}
        />
      </div>
    </div>
  );
};

export default ContentBox;
