/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { CustomInfoBlock, RenderSvg } from 'components';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import './showAppraisalGoal.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  appraisal?: null | {
    justification: string;
    rating: string;
  };
  title: string;
}

const ShowAppraisalGoal = (props: Props): ReactElement => {
  const { id, appraisal, title } = props;

  const { t } = useTranslation('enum');

  return (
    <>
      {appraisal !== undefined && appraisal !== null ? (
        <div id={`showAppraisalGoal-${id}`} className="showAppraisalGoal">
          <span
            id={`showAppraisalGoal-${id}-rating`}
            className="showAppraisalGoalRating"
          >
            {t(`appraisalGoal.${appraisal.rating}`)}
          </span>
          {appraisal.justification !== '' &&
          appraisal.justification !== null ? (
            <CustomInfoBlock
              customText={appraisal.justification}
              customTitle={title}
              customSvg="comment"
              widthBox="medium"
              maxLevel="lvl4"
            >
              <div id={`showAppraisalGoal-${id}-icon`} className="circleBox">
                <RenderSvg nameSvg="iconInfo" />
              </div>
            </CustomInfoBlock>
          ) : (
            '---'
          )}
        </div>
      ) : (
        '---'
      )}
    </>
  );
};

export default ShowAppraisalGoal;
