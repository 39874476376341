/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';

import './tableCellAppraisalGoalPrecentBonus.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  appraisal?: null | {
    justification: string;
    rating: string;
  };
}

const TableCellAppraisalGoalPrecentBonus = (props: Props): ReactElement => {
  const { id, appraisal } = props;

  const precentBonus = {
    EXCEEDS_EXPECTATIONS: '120',
    PARTIALLY_EXCEEDS_EXPECTATIONS: '110',
    FULLY_MEETS_EXPECTATIONS: '100',
    PARTIALLY_MEETS_EXPECTATIONS: '90',
    DOES_NOT_MEET_EXPECTATIONS: '0',
  };

  return (
    <>
      {appraisal !== undefined && appraisal !== null ? (
        <div
          id={`tableCellAppraisalGoalPrecentBonus-${id}`}
          className="tableCellAppraisalGoalPrecentBonus"
        >
          <span
            id={`tableCellAppraisalGoalPrecentBonus-${id}-rating`}
            className="tableCellAppraisalGoalRatingPrecentBonus"
          >
            {precentBonus[appraisal.rating]}
          </span>
        </div>
      ) : (
        '---'
      )}
    </>
  );
};

export default TableCellAppraisalGoalPrecentBonus;
