import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  LoaderTable,
  CustomContainer,
  ContentBox,
  RenderSvg,
  ModalYesNo,
  CustomTooltip,
  ShowListToString,
} from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import GroupsBlock from './GroupsBlock';
import TrainingsGroupShow from '../TrainingsGroupShow';

import './trainingsGroupList.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation(['trainingsGroup', 'trainings']);
  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [dataTrainingsGroup] = TrainingsGroupRepository.useGetTraining({
    id: Number(id),
  });

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  return (
    <>
      <CustomContainer>
        <LoaderTable radiusTop allowGrow>
          <>
            <Box
              id="trainingGroupListGroupBox"
              name="EDYCJA SZKOLENIA - GRUPY SZKOLENIOWE"
              svgIcon="addEdit"
              noPadding
            >
              <>
                <ContentBox id="trainingGroupListGroupBasicInformation">
                  <>
                    <div className="trainingNameBox">
                      <div className="titleName">Wybrane szkolenie:</div>
                      <div className="name">
                        {dataTrainingsGroup?.training.name}
                      </div>
                    </div>
                    <div className="additionalInfoBox">
                      <div
                        id="frameAdditionalInfo"
                        className="aditionalInfoContent"
                      >
                        <div id="frameAdditionalInfoDetail" className="content">
                          <div
                            id="frameAdditionalInfoBoxDataTitle"
                            className="title"
                          >
                            Łączna liczba osób:
                          </div>
                          <div
                            id="frameAdditionalInfoBoxData"
                            className="additionalInfoDetail"
                          >
                            <div id="frameAdditionalInfoIcon" className="icon">
                              <RenderSvg nameSvg="employeeRegisterTrainingGroup" />
                            </div>
                            <div
                              id="frameAdditionalInfoBoxDataData"
                              className="dataRegister"
                            >
                              {dataTrainingsGroup?.allRegistered}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="frameAdditionalInfo"
                        className="aditionalInfoContent"
                      >
                        <div id="frameAdditionalInfoDetail" className="content">
                          <div
                            id="frameAdditionalInfoBoxDataTitle"
                            className="title"
                          >
                            Liczba osób niezapisanych:
                          </div>
                          <div
                            id="frameAdditionalInfoBoxData"
                            className="additionalInfoDetail"
                          >
                            <div id="frameAdditionalInfoIcon" className="icon">
                              <RenderSvg nameSvg="employeeNotRegisterTrainingGroup" />
                            </div>
                            <div
                              id="frameAdditionalInfoBoxDataData"
                              className="dataNotRegister"
                            >
                              {dataTrainingsGroup?.allRegistered}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="frameAdditionalInfo"
                        className="aditionalInfoContent"
                      >
                        <div id="frameAdditionalInfoDetail" className="content">
                          <div
                            id="frameAdditionalInfoBoxDataTitle"
                            className="title"
                          >
                            Rodzaj szkolenia:
                          </div>
                          <div
                            id="frameAdditionalInfoBoxData"
                            className="additionalInfoDetail"
                          >
                            <div id="frameAdditionalInfoIcon" className="icon">
                              <RenderSvg nameSvg="trainingGroupTypeTraining" />
                            </div>
                            <div
                              id="frameAdditionalInfoBoxDataData"
                              className="data"
                            >
                              {dataTrainingsGroup?.training.type
                                ? t(
                                    `trainings:trainingsTypeEnum.${dataTrainingsGroup?.training.type}`
                                  )
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </ContentBox>
                <GroupsBlock
                  setModalId={setModalId}
                  changeModalShow={setModalShow}
                />
                {modalShow ? (
                  <TrainingsGroupShow
                    id={modalId}
                    changeModalShow={setModalShow}
                  />
                ) : (
                  ''
                )}
              </>
            </Box>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
