import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsProviderRepository } from 'api/repository';
import { TrainingsProvider } from 'api/types';
import FormTrainingsProvider from '../Form/FormTrainingsProvider';

const TrainingsProviderAddPage = (): ReactElement => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const handleAddTrainingsProvider = TrainingsProviderRepository.useAddTrainingsProvider(
    async (addData) => {
      setSubmitForm(false);
      history.push(
        `/ustawienia/szkolenia/dostawcy-szkolen/formularz/${addData.id}/dane-dostawcy`
      );
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAndExit = TrainingsProviderRepository.useAddTrainingsProvider(
    async () => {
      setSubmitForm(false);
      history.push(`/ustawienia/szkolenia/dostawcy-szkolen/`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      handleAddTrainingsProvider(data);
    }
  };

  const handleSubmitSaveAndExit = (data: TrainingsProvider) => {
    if (!submitForm) {
      setSubmitForm(true);
      handleAddTrainingsProviderAndExit(data);
    }
  };

  const handleCancel = () => {
    history.push(`/ustawienia/szkolenia/dostawcy-szkolen/`);
  };

  return (
    <FormTrainingsProvider
      id={Number(id)}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={handleSubmitSaveAndExit}
      submitForm={submitForm}
      cancel={handleCancel}
    />
  );
};

export default TrainingsProviderAddPage;
