/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useState } from 'react';
import { RenderSvg, Box } from 'components';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  name: string;
  children?: ReactElement;
  noPadding?: boolean;
}

const ShowBox = (props: Props): ReactElement => {
  const { id, name, children, noPadding } = props;

  return (
    <Box
      id={id}
      name={name}
      svgIcon="show"
      noShadow
      noPadding={noPadding}
      noMarginBottom
    >
      <>{children}</>
    </Box>
  );
};

export default ShowBox;
