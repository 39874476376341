import React, { ReactElement } from 'react';
import { IDPEmployeeModel } from 'api/types';
import './IDPEmployeeDetail.scss';
import { Box, ShowTextarea } from 'components';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { IDPEmployee } = props;

  return (
    <div id="ShowIDPDetail">
      <Box
        lightHeader
        name="CELE KRÓTKOOKRESOWE"
        id="shortTerm"
        svgIcon="shortTerm"
        noRadius
        noShadow
        noBorderBottom
      >
        <ShowTextarea
          id="ShowIDPDetailShortTermGoal"
          data={IDPEmployee?.shortTermGoal}
        />
      </Box>
      <Box
        lightHeader
        name="CELE DŁUGOOKRESOWE"
        id="longTerm"
        svgIcon="longTerm"
        noRadius
        noShadow
        noBorderBottom
      >
        <ShowTextarea
          id="ShowIDPDetailShortTermGoal"
          data={IDPEmployee?.longTermGoal}
        />
      </Box>
      <Box
        lightHeader
        name="KOMENTARZE"
        id="comment"
        svgIcon="comment"
        noRadius
        noShadow
        noBorderBottom
      >
        <>
          <ShowTextarea
            id="ShowIDPDetailSuperiorComment"
            data={IDPEmployee?.superiorComment}
            label="Komentarz przełożonego (N+1)"
          />
          <ShowTextarea
            id="ShowIDPDetailEmployeeComment"
            data={IDPEmployee?.employeeComment}
            label="Komentarz pracownika"
          />
        </>
      </Box>
      <Box
        lightHeader
        name="MOBILNOŚĆ"
        id="mobility"
        svgIcon="mobility"
        noRadius
        noShadow
        noBorderBottom
      >
        <>
          <div id="mobilityWithinCountryBox" className="yesNoBox">
            Mobilność na terenie kraju:{' '}
            {IDPEmployee?.mobilityWithinCountry ? (
              <span className="yes">TAK</span>
            ) : (
              <span className="no">NIE</span>
            )}
          </div>
          <div id="internationalMobilityBox" className="yesNoBox">
            Mobilność międzynarodowa:{' '}
            {IDPEmployee?.internationalMobility ? (
              <span className="yes">TAK</span>
            ) : (
              <span className="no">NIE</span>
            )}
          </div>
        </>
      </Box>
    </div>
  );
};

export default TrainingsProviderAddPage;
