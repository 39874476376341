import {
  Error,
  PlansTrainingsList,
  PlansTrainingsAddAndPut,
  PlansTrainings,
  RequiredTrainingsList,
  RequiredTrainingsListRequest,
  ImportTrainingList,
  ImportTrainingsListRequest,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: PlansTrainingsAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number;
}

const useGetAllPlansTrainings = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<PlansTrainingsList> | null] => {
  const axios = useAxios();
  const [plans, setPlans] = useState<PagedData<PlansTrainingsList> | null>(
    null
  );

  const doGet = useCallback(() => {
    axios
      .get<PagedData<PlansTrainingsList>>(
        `${apiEndpoints.plansTrainings}/plans`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useGetPlanTrainings = (
  props: PropsRecord
): [
  PlansTrainings | null | undefined,
  (plansTrainings: PlansTrainings) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<PlansTrainings | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<PlansTrainings>(
        `${apiEndpoints.plansTrainings}/plans/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      AutoAlertStatus({
        status: error.response.status,
        customMsg: error.response.data,
      });
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: PlansTrainings) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useAddPlansTrainings = (
  onCompletedPost?: (data: PlansTrainingsAddAndPut) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<PlansTrainingsAddAndPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (plansTrainings: Omit<PlansTrainingsAddAndPut, 'id'>) => {
      try {
        const rData = await axios.post<PlansTrainingsAddAndPut>(
          `${apiEndpoints.plansTrainings}/plans`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const usePutPlansTrainings = (
  onCompletedPut?: (data: PlansTrainingsAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: PlansTrainingsAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putPlansTrainings = useCallback(
    async (plansTrainings: PlansTrainingsAddAndPut) => {
      try {
        const rData = await axios.put<PlansTrainingsAddAndPut>(
          `${apiEndpoints.trainings}/plans/${plansTrainings.id}`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putPlansTrainings;
};

const useDeletePlansTrainings = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deletePlansTrainings = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.plansTrainings}/plans/${id}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deletePlansTrainings;
};

const useGetRequiredTrainings = (
  id: number,
  onError?: (error: AxiosError<Error>) => void
): [
  (params: RequiredTrainingsListRequest) => void,
  PagedData<RequiredTrainingsList> | null
] => {
  const axios = useAxios();
  const [
    requiredTrainings,
    setRequiredTrainings,
  ] = useState<PagedData<RequiredTrainingsList> | null>(null);

  const doGet = useCallback(
    (params: RequiredTrainingsListRequest) => {
      axios
        .get<PagedData<RequiredTrainingsList>>(
          `${apiEndpoints.plansTrainings}/plans/${id}/trainings`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setRequiredTrainings(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onError]
  );

  return [doGet, requiredTrainings];
};

const useChangeRequiredTraining = (
  id: number,
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((idRecord: number, state: boolean) => Promise<void>) => {
  const axios = useAxios();

  const changeRequiredTrainings = useCallback(
    async (idRecord: number, state: boolean) => {
      try {
        const rData = await axios.put(
          `${apiEndpoints.plansTrainings}/plans/${id}/trainings/${idRecord}`,
          { required: state }
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return changeRequiredTrainings;
};

const useDeleteTrainingFromPlan = (
  id: number,
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((idRecord: number) => Promise<void>) => {
  const axios = useAxios();

  const changeRequiredTrainings = useCallback(
    async (idRecord: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.plansTrainings}/plans/${id}/trainings/${idRecord}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return changeRequiredTrainings;
};

const useGetAllTrainingsToImport = (
  id: number,
  onError?: (error: AxiosError<Error>) => void
): [
  (params: ImportTrainingsListRequest) => void,
  PagedData<ImportTrainingList> | null
] => {
  const axios = useAxios();
  const [
    importTrainings,
    setImportTraining,
  ] = useState<PagedData<ImportTrainingList> | null>(null);

  const doGet = useCallback(
    (params: ImportTrainingsListRequest) => {
      axios
        .get<PagedData<ImportTrainingList>>(
          `${apiEndpoints.plansTrainings}/plans/${id}/available-trainings`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setImportTraining(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onError]
  );

  return [doGet, importTrainings];
};

const useImportTrainingToPlan = (
  id: number,
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((idRecord: number) => Promise<void>) => {
  const axios = useAxios();

  const importTrainingToPlan = useCallback(
    async (idRecord: number) => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.plansTrainings}/plans/${id}/trainings`,
          { trainingId: idRecord }
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return importTrainingToPlan;
};

export default {
  useGetAllPlansTrainings,
  useGetPlanTrainings,
  useAddPlansTrainings,
  usePutPlansTrainings,
  useDeletePlansTrainings,
  useGetRequiredTrainings,
  useChangeRequiredTraining,
  useDeleteTrainingFromPlan,
  useGetAllTrainingsToImport,
  useImportTrainingToPlan,
};
