import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Box, LoaderTable, CustomContainer } from 'components';
import GroupsBlock from './GroupsBlock';
import TrainingGroupShow from '../../../Administration/TrainingsGroup/TrainingsGroupShow';

import './trainingsRegisterGroupSelectGroup.scss';

const EditAcceptedTrainings = (): ReactElement => {
  const { t } = useTranslation(['trainingsGroup', 'trainings']);
  const { id } = useParams<{ id: string }>();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  return (
    <>
      <CustomContainer>
        <LoaderTable radiusTop allowGrow>
          <>
            <Box
              id="trainingGroupUserTrainingsSelectedGroupBox"
              name="WYBÓR DATY SZKOLEŃ"
              svgIcon="TrainingsGroupUserTrainingsSelectGroupList"
              noPadding
            >
              <>
                <GroupsBlock
                  setModalShow={setModalShow}
                  setModalId={setModalId}
                />
                {modalShow ? (
                  <TrainingGroupShow
                    id={modalId}
                    changeModalShow={setModalShow}
                  />
                ) : (
                  ''
                )}
              </>
            </Box>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EditAcceptedTrainings;
