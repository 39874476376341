import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import {
  ContentBox,
  CustomContainer,
  LoaderTable,
  ModalYesNo,
  RenderSvg,
} from 'components';
import './IDPEmployeeArea.scss';
import { Button } from 'react-bootstrap';
import { IDPEmployeeModel } from 'api/types';
import IDPEmployeeAreaAdd from './IDPEmployeeAreaAdd';
import IDPEmployeeAreaEdit from './IDPEmployeeAreaEdit';
import IDPEmployeeAreaList from './IDPEmployeeAreaList';
import IDPEmployeeAreaImport from './IDPEmployeeAreaImport';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();

  const { IDPEmployee, fetchData } = props;

  const [addFormShow, setAddFormShow] = useState<boolean>(false);
  const [editFormShow, setEditFormShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);
  const [loaderTable, setLoaderTable] = useState<boolean>(true);

  const [importFormShow, setImportFormShow] = useState<boolean>(false);

  const [updateData, setUpdateData] = useState<number>(0);

  const [getData, data] = IDPRepository.useGetAllIDPEmployeeAreaUser({});

  useEffect(() => {
    setLoaderTable(true);

    getData();
  }, [getData, updateData]);

  useEffect(() => {
    if (data !== undefined) {
      setLoaderTable(false);
    }
  }, [data]);

  const DeleteRecord = IDPRepository.useDeleteIDPEmployeeAreaUser(async () => {
    setUpdateData(Math.random);
  });

  const handleDelete = (idClick: number) => {
    DeleteRecord(idClick);
  };

  return (
    <>
      <CustomContainer id="IDPEmployeeAreaBox">
        <>
          <ContentBox id="IDPEmployeeAreaBoxFirstRow" bottomBorder>
            <>
              <div id="IDPEmployeeAreaBoxFirstRowBox">
                <div id="IDPEmployeeAreaBoxFirstRowBoxIcon">
                  <RenderSvg nameSvg="searchAndGraph60" />
                </div>
                <div id="IDPEmployeeAreaBoxFirstRowBoxInfo">
                  <div id="IDPEmployeeAreaBoxFirstRowBoxInfoLabel">
                    Obszary rozwoju pracownika
                  </div>
                  <div id="IDPEmployeeAreaBoxFirstRowBoxInfoText">
                    Uzupełnij dane dotyczące obszarów rozwoju
                    <br />
                    Zostaną one rozważone w trakcie przeglądu organizacji i
                    zasobów ludzkich
                  </div>
                </div>
              </div>
            </>
          </ContentBox>
          {IDPEmployee?.interviewType !== 'LAST_ANNUAL' ? (
            <ContentBox id="IDPEmployeeAreaBoxSecondRow">
              <>
                <Button
                  id="IDPEmployeeAreaAddArea"
                  className="green "
                  onClick={() => {
                    setAddFormShow(true);
                  }}
                >
                  Dodaj obszar do rozwoju
                </Button>
                <Button
                  id="IDPEmployeeAreaImportArea"
                  className="green"
                  onClick={() => {
                    setImportFormShow(true);
                  }}
                >
                  Importuj obszar do rozwoju
                </Button>
              </>
            </ContentBox>
          ) : (
            ''
          )}
          <LoaderTable active={loaderTable === true} noRadiusTop>
            <>
              {data?.content && data?.content.length > 0 ? (
                <IDPEmployeeAreaList
                  data={data}
                  setEditFormShow={setEditFormShow}
                  setModalId={setModalId}
                  deleteAction={handleDelete}
                  IDPEmployee={IDPEmployee}
                />
              ) : (
                ''
              )}
            </>
          </LoaderTable>
        </>
      </CustomContainer>
      {addFormShow ? (
        <IDPEmployeeAreaAdd
          changeModalAdd={setAddFormShow}
          changeUpdateData={setUpdateData}
          changeModalEdit={setEditFormShow}
          changeModalId={setModalId}
        />
      ) : (
        ''
      )}
      {editFormShow ? (
        <IDPEmployeeAreaEdit
          changeModalEdit={setEditFormShow}
          changeUpdateData={setUpdateData}
          modalId={modalId}
          lastEdit={IDPEmployee?.interviewType === 'LAST_ANNUAL'}
        />
      ) : (
        ''
      )}
      {importFormShow ? (
        <IDPEmployeeAreaImport
          changeModalImport={setImportFormShow}
          changeUpdateData={setUpdateData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TrainingsProviderAddPage;
