/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './TableCellAddress.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  withName?: boolean;
}

const TableCellAddress = (props: Props): ReactElement => {
  const { data, withName } = props;

  return (
    <>
      {data ? (
        <div className="tableCellBlockAddress">
          {withName ? <div className="addressName">{data.name}</div> : ''}
          <div>
            {data?.street}
            {data.street ? ' ' : ''}
            {data?.buildingNumber}
            {data.street || data.buildingNumber ? ' ' : ''}
            {data?.apartmentNumber}
          </div>
          <div>
            {data?.city}
            {data.city ? ' ' : ''}
            {data?.postalCode}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellAddress;
