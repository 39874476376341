import {
  TrainingsProviderAddressRequest,
  Error,
  TrainingsProviderAddress,
  TrainingsProviderAddressAddPut,
  PagedData,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  id: number;
  onCompleted?: (
    data: TrainingsProviderAddressAddPut | TrainingsProviderAddress | number
  ) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  idRecord: number;
}

const useGetAllTrainingsProviderAddress = (
  props: Props
): [
  (params: TrainingsProviderAddressRequest) => void,
  PagedData<TrainingsProviderAddress> | null
] => {
  const axios = useAxios();
  const { id, onError } = props;
  const [
    trainingsProviderAddress,
    setTrainingsProviderAddress,
  ] = useState<PagedData<TrainingsProviderAddress> | null>(null);

  const doGet = useCallback(
    async (params: TrainingsProviderAddressRequest) => {
      try {
        const rData = await axios.get<PagedData<TrainingsProviderAddress>>(
          `${apiEndpoints.trainingsProvider}/providers/${id}/addresses`,
          {
            params,
          }
        );
        setTrainingsProviderAddress(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onError]
  );

  return [doGet, trainingsProviderAddress];
};

const useGetTrainingsProviderAddress = (
  props: PropsRecord
): [
  TrainingsProviderAddress | null | undefined,
  (trainingsProviderAddress: TrainingsProviderAddress) => void,
  (idRecord: number) => void
] => {
  const { id, onError, idRecord } = props;
  const axios = useAxios();
  const [trainingsProviderAddress, setTrainingsProviderAddress] = useState<
    TrainingsProviderAddress | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsProviderAddress>(
        `${apiEndpoints.trainingsProvider}/providers/${id}/addresses/${idRecord}`
      );
      setTrainingsProviderAddress(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setTrainingsProviderAddress(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsProviderAddress) => {
    setTrainingsProviderAddress(data);
  }, []);

  return [trainingsProviderAddress, updateData, fetchData];
};

const useAddTrainingsProviderAddress = (
  id: number,
  onCompletedPost?: (data: TrainingsProviderAddress) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsProviderAddressAddPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postTrainingsProviderAddress = useCallback(
    async (
      trainingsProviderAddress: Omit<TrainingsProviderAddressAddPut, 'id'>
    ) => {
      try {
        const rData = await axios.post<TrainingsProviderAddress>(
          `${apiEndpoints.trainingsProvider}/providers/${id}/addresses`,
          trainingsProviderAddress
        );
        AutoAlertStatus({ status: rData.status });

        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postTrainingsProviderAddress;
};

const usePutTrainingsProviderAddress = (
  onCompletedPut?: (data: TrainingsProviderAddressAddPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsProviderAddressAddPut) => Promise<void>) => {
  const axios = useAxios();

  const putTrainingsProviderAddress = useCallback(
    async (trainingsProviderAddress: TrainingsProviderAddressAddPut) => {
      try {
        const rData = await axios.put<TrainingsProviderAddressAddPut>(
          `${apiEndpoints.trainingsProvider}/providers/${trainingsProviderAddress.providerId}/addresses/${trainingsProviderAddress.id}`,
          trainingsProviderAddress
        );
        AutoAlertStatus({ status: rData.status });

        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putTrainingsProviderAddress;
};

const useDeleteTrainingsProviderAddress = (
  id: number,
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteTrainingsProviderAddress = useCallback(
    async (idDelete: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.trainingsProvider}/providers/${id}/addresses/${idDelete}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });

        if (onCompletedDelete) onCompletedDelete(idDelete);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteTrainingsProviderAddress;
};

export default {
  useGetAllTrainingsProviderAddress,
  useGetTrainingsProviderAddress,
  useAddTrainingsProviderAddress,
  usePutTrainingsProviderAddress,
  useDeleteTrainingsProviderAddress,
};
