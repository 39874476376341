import {
  EmployeeAppraisalRepository,
  IDPRepository,
  LoyaltyRepository,
  TrainingsGroupRepository,
} from 'api/repository';
import { AuthResources, Box, CustomContainer, DesktopItem } from 'components';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import './panel.scss';

const Panel = (): ReactElement => {
  const history = useHistory();

  /// TRAINING START
  const [statusTrainingPlan] = TrainingsGroupRepository.useGetCurrentPlanTest(
    {}
  );

  const checkStatusTraining = () => {
    if (statusTrainingPlan === undefined) {
      return 'loading';
    }
    if (statusTrainingPlan === null) {
      return 'noActivePlan';
    }
    const now = new Date();
    const startDate = new Date(statusTrainingPlan.startDate);
    startDate.setHours(0, 0);
    const endDate = new Date(statusTrainingPlan.endDate);
    endDate.setHours(23, 59);
    const registrationStartDate = new Date(
      statusTrainingPlan.registrationStartDate
    );
    registrationStartDate.setHours(0, 0);
    const registrationEndDate = new Date(
      statusTrainingPlan.registrationEndDate
    );
    endDate.setHours(23, 59);
    const verificationDate = new Date(statusTrainingPlan.verificationDate);
    verificationDate.setHours(0, 0);

    if (statusTrainingPlan !== null && (startDate > now || endDate < now)) {
      return 'noActivePlan';
    }
    if (statusTrainingPlan !== null && registrationStartDate > now) {
      return 'waitingForRegistration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationStartDate <= now &&
      registrationEndDate >= now
    ) {
      return 'registration';
    }
    if (
      statusTrainingPlan !== null &&
      registrationEndDate < now &&
      verificationDate >= now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'verification';
    }
    if (
      statusTrainingPlan !== null &&
      verificationDate < now &&
      statusTrainingPlan.groupNotificationTime === null
    ) {
      return 'waitForTrainingGroup';
    }
    if (
      statusTrainingPlan !== null &&
      statusTrainingPlan.groupNotificationTime !== null
    ) {
      return 'trainingGroup';
    }
    return null;
  };

  const setButtonTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'registration') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }
    if (checkStatusTraining() === 'verification') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return {
        text: 'Zarządzaj grupami',
        action: () =>
          history.push('/administracja/szkolenia/grupy-szkoleniowe'),
      };
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return {
        text: 'Zarządzaj grupami',
        action: () =>
          history.push('/administracja/szkolenia/grupy-szkoleniowe'),
      };
    }
    return undefined;
  };

  const setContentTraining = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitingForRegistration') {
      return (
        <>
          <div>
            Oczekiwanie na rozpoczęcie zapisów na szkolenia. O ich rozpoczęciu
            powiadomimy Cię mailowo.
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'registration') {
      return (
        <>
          <div>
            Zapisy na szkolenia są aktywne, już teraz możesz akceptować
            zatwierdzone szkolenia
          </div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.verificationDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'verification') {
      return (
        <>
          <div>Trwa proces akceptacji szkoleń</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return (
        <>
          <div>Akceptacja szkoleń została ukończona</div>
          <div>Stwórz grupy szkoleniowe</div>
        </>
      );
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return (
        <>
          <div>Informajca o grupach szkoleniowych została wysłana</div>
          <div>Możesz zarządzać ukończonymi szkoleniami</div>
        </>
      );
    }

    return null;
  };

  const setButtonTrainingHRBP = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'registration') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }
    if (checkStatusTraining() === 'verification') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }

    return undefined;
  };

  const setContentTrainingHRBP = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitingForRegistration') {
      return (
        <>
          <div>Oczekiwanie na rozpoczęcie zapisów na szkolenia.</div>
        </>
      );
    }
    if (checkStatusTraining() === 'registration') {
      return (
        <>
          <div>
            Zapisy na szkolenia są aktywne, już teraz możesz akceptować
            zatwierdzone szkolenia
          </div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.verificationDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'verification') {
      return (
        <>
          <div>Trwa proces akceptacji szkoleń</div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.verificationDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return (
        <>
          <div>Akceptacja szkoleń została ukończona</div>
          <div>Tworzone są teraz grupy szkoleniowe</div>
        </>
      );
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return (
        <>
          <div>Informajca o grupach szkoleniowych została wysłana</div>
          <div>Aktualnie odbywają się szkolenia</div>
        </>
      );
    }

    return null;
  };

  const setButtonTrainingSLT = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusTraining() === 'registration') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }
    if (checkStatusTraining() === 'verification') {
      return {
        text: 'Przejdź do akceptacji',
        action: () =>
          history.push(
            '/administracja/szkolenia/akceptacja-szkolen/pracownicy'
          ),
      };
    }
    return undefined;
  };

  const setContentTrainingSLT = () => {
    if (checkStatusTraining() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusTraining() === 'noActivePlan') {
      return (
        <>
          <div>Brak aktywnego planu IDP</div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitingForRegistration') {
      return (
        <>
          <div>Oczekiwanie na rozpoczęcie zapisów na szkolenia.</div>
        </>
      );
    }
    if (checkStatusTraining() === 'registration') {
      return (
        <>
          <div>
            Zapisy na szkolenia są aktywne, już teraz możesz akceptować
            zatwierdzone szkolenia
          </div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.verificationDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'verification') {
      return (
        <>
          <div>Trwa proces akceptacji szkoleń</div>
          <div>Masz czas do.</div>

          <div className="dataGreen">
            <span>{statusTrainingPlan?.verificationDate}</span>
          </div>
        </>
      );
    }
    if (checkStatusTraining() === 'waitForTrainingGroup') {
      return (
        <>
          <div>Akceptacja szkoleń została ukończona</div>
          <div>Tworzone są teraz grupy szkoleniowe</div>
        </>
      );
    }
    if (checkStatusTraining() === 'trainingGroup') {
      return (
        <>
          <div>Informajca o grupach szkoleniowych została wysłana</div>
          <div>Aktualnie odbywają się szkolenia</div>
        </>
      );
    }

    return null;
  };

  /// TRAINING END

  /// Appraisal START
  const [statusLoyalty] = LoyaltyRepository.useGetLoyaltyRequiredAttention({});
  const checkStatusLoyalty = () => {
    if (statusLoyalty === undefined) {
      return 'loading';
    }
    if (statusLoyalty && statusLoyalty?.requireAttention === 0) {
      return 'noAction';
    }
    if (
      statusLoyalty !== undefined &&
      statusLoyalty?.requireAttention &&
      statusLoyalty?.requireAttention > 0
    ) {
      return 'action';
    }

    return null;
  };

  const setButtonLoyalty = () => {
    if (checkStatusTraining() === 'loading') {
      return { text: undefined, action: undefined };
    }
    if (checkStatusLoyalty() === 'action') {
      return {
        text: 'Zarządzaj umowami',
        action: () => {
          history.push('/administracja/umowy-lojalnosciowe');
        },
      };
    }
    return undefined;
  };
  const setContentLoyalty = () => {
    if (checkStatusLoyalty() === 'loading') {
      return <div>trwa pobieranie danych</div>;
    }
    if (checkStatusLoyalty() === 'noAction') {
      return <div>Brak umów lojalnościowych wymagających Twojej uwagi.</div>;
    }

    if (checkStatusLoyalty() === 'action') {
      return (
        <>
          <div>Liczba umów wymagającej Twojej uwagi:</div>
          <div className="dataGreen">
            <span>{statusLoyalty?.requireAttention}</span>
          </div>
        </>
      );
    }

    return null;
  };

  /// Appraisal END

  return (
    <CustomContainer allowGrow>
      <>
        <Box
          id="administrationDesktop"
          name="PULPIT ADMINISTRACYJNY"
          svgIcon="desktop"
          noContentStyle
        >
          <div id="administrationDesktopBox">
            <div id="administrationDesktopBoxContent">
              <AuthResources requiredRoles={['training_department']}>
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA"
                  buttonTitle={setButtonTraining()?.text}
                  buttonAction={setButtonTraining()?.action}
                >
                  <>{setContentTraining()}</>
                </DesktopItem>
              </AuthResources>
              <AuthResources requiredRoles={['hr_bp']}>
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA HRBP"
                  buttonTitle={setButtonTrainingHRBP()?.text}
                  buttonAction={setButtonTrainingHRBP()?.action}
                >
                  <>{setContentTrainingHRBP()}</>
                </DesktopItem>
              </AuthResources>
              <AuthResources requiredRoles={['slt_department']}>
                <DesktopItem
                  id="trainings"
                  name="SZKOLENIA SLT"
                  buttonTitle={setButtonTrainingSLT()?.text}
                  buttonAction={setButtonTrainingSLT()?.action}
                >
                  <>{setContentTrainingSLT()}</>
                </DesktopItem>
              </AuthResources>
              <AuthResources requiredRoles={['training_department']}>
                <DesktopItem
                  id="loyality"
                  name="UMOWY LOJALNOŚCIOWE"
                  buttonTitle={setButtonLoyalty()?.text}
                  buttonAction={setButtonLoyalty()?.action}
                  smallButtonName
                >
                  <>{setContentLoyalty()}</>
                </DesktopItem>
              </AuthResources>
            </div>
          </div>
        </Box>
      </>
    </CustomContainer>
  );
};

export default Panel;
