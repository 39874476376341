import { Error } from 'api/types';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const useDeleteTrainingsProvider = (
  onError?: (error: AxiosError<Error>) => void,
  onCompleted?: (id: number) => void
): ((id: number) => void) => {
  const axios = useAxios();

  const deleteTrainingsProvider = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.trainingsProvider}/providers/${id}`
        );

        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompleted) onCompleted(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompleted, onError]
  );

  return deleteTrainingsProvider;
};

export default useDeleteTrainingsProvider;
