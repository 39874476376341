import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentBox,
  CustomFilterData,
  CustomFilterLastFirstName,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  ShowTitleTable,
  TableCellAppraisalGoal,
  TableCellAppraisalGoalFinal,
  TableCellAppraisalGoalPrecentBonus,
  TableCellLastFirstNameWithAdditionalInfo,
  TablePagination,
  TableRecordAction,
} from 'components';
import {
  EmployeeAppraisalRepository,
  GroupEmployeeAppraisalRepository,
} from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import {
  EmployeeAppraisalRatingWithIdEmployeePut,
  GroupEmployeeAppraisalEmployeeModel,
} from 'api/types/EmployeeAppraisal';
import EmployeeAppraisalFinalRating from '../EmployeeAppraisalFinalRating';

const TableTrainingsBlock = (): ReactElement => {
  const [modalData, setModalData] = useState<
    GroupEmployeeAppraisalEmployeeModel | undefined
  >(undefined);
  const [
    showModalFinalAppraisal,
    setShowModalFinalAppraisal,
  ] = useState<boolean>(false);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    firstName: 'firstName',
    lastName: 'lastName',
    locations: 'location',
    positions: 'position',
    segments: 'segment',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      'row.lastName': 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'row.lastName': 'lastName',
    id: 'ID',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = GroupEmployeeAppraisalRepository.useGetGroupEmployeeAppraisalAllEmployee();

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutManagerN2Rating(
    async () => {
      // setSubmitForm(false);
      setUpdateDataTable(Math.random);
    },
    async () => {
      // setSubmitForm(false);
    }
  );

  const importAppraisalN1 = (
    idClick: number,
    justification: string,
    rating: string
  ) => {
    const dataToPost: EmployeeAppraisalRatingWithIdEmployeePut = {
      id: idClick,
      justification,
      rating,
    };
    postDataSaveAndExit(dataToPost);
  };

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };
    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableRecordAction
            dataId={row.id}
            editClick={() => {
              setModalData(row);
              setShowModalFinalAppraisal(true);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'row.lastName',
      text: 'Nazwisko i imię',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      sort: true,
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterLastFirstName
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellLastFirstNameWithAdditionalInfo
            id={row.id}
            first={row.firstName}
            last={row.lastName}
            customList={{
              Stanowiska: row.positions,
              Segment: row.segments,
              Przełożony: row.superior,
            }}
          />
        );
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'selfApprisal',
      text: 'Samoocena',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellAppraisalGoal
            id={row.id}
            appraisal={row.selfAppraisal}
            quickView
            title="Komentarz do samooceny"
          />
        );
      },
    },
    {
      dataField: 'superiorAppraisal',
      text: 'Propozycja N+1',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellAppraisalGoal
            id={row.id}
            appraisal={row.superiorAppraisal}
            quickView
            title="Komentarz N+1"
          />
        );
      },
    },
    {
      dataField: 'finalAppraisal',
      text: 'Ocena finalna',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellAppraisalGoalFinal
            id={row.id}
            appraisal={row.finalAppraisal}
            quickView
            title="Ocena finalna"
            allowImport={row.superiorAppraisal || undefined}
            importFunction={() =>
              importAppraisalN1(
                row.id,
                row.superiorAppraisal.justification,
                row.superiorAppraisal.rating
              )
            }
          />
        );
      },
    },
    {
      dataField: 'finalAppraisal',
      text: '% Bonusa',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellAppraisalGoalPrecentBonus
            id={row.id}
            appraisal={row.finalAppraisal}
          />
        );
      },
    },
  ];

  return (
    <>
      <ContentBox id="managerUserPlansTrainingsEmployee" noPadding>
        <>
          <LoaderTable active={loaderTable} noRadiusTop>
            <>
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              >
                <ShowTitleTable
                  id="titleTableEmployee"
                  label="LISTA PRACOWNIKÓW:"
                />
              </TablePagination>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={data?.content || []}
                columns={columns}
                noDataIndication="Brak danych"
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </>
      </ContentBox>
      {showModalFinalAppraisal && modalData ? (
        <EmployeeAppraisalFinalRating
          changeModal={setShowModalFinalAppraisal}
          changeUpdateData={setUpdateDataTable}
          data={modalData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TableTrainingsBlock;
