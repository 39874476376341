/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { CustomInfoBlock, RenderSvg } from 'components';
import './TableCellTrainingsShowStatus.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  id: number;
  dataType: 'hr' | 'ds' | 'slt';
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellTrainingsShowStatus = (props: Props): ReactElement => {
  const { id, data, justify, dataType } = props;

  return (
    <>
      <div className={`tableCellTrainingsStatus ${justify || ''}`}>
        {dataType === 'hr' ? (
          <div id="dhr" className="status">
            {data[`${dataType}Accepted`] === 'ACCEPTED' ? (
              <div className="labelStatus">Akceptacja</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'VERIFY' ? (
              <div className="labelStatus">Brak decyzji</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'REJECTED' ? (
              <div className="labelStatus">Brak zgody</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'NONE' ? (
              <div className="labelStatus" />
            ) : (
              ''
            )}

            <div className="boxStatus">
              {data.hrAccepted === 'REJECTED' && data.hrComment !== '' ? (
                <CustomInfoBlock
                  customText={data.hrComment}
                  customTitle="Decyzja braku akceptacji przez dział HR"
                  customSvg="manager"
                  widthBox="medium"
                >
                  <div className={`${data.hrAccepted} circleBox`}>
                    <RenderSvg nameSvg={`${data.hrAccepted}StatusWithInfo`} />
                  </div>
                </CustomInfoBlock>
              ) : (
                <div className={`${data.hrAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.hrAccepted}Status`} />
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {dataType === 'ds' ? (
          <div id="ds" className="status">
            {data[`${dataType}Accepted`] === 'ACCEPTED' ? (
              <div className="labelStatus">Akceptacja</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'VERIFY' ? (
              <div className="labelStatus">Brak decyzji</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'REJECTED' ? (
              <div className="labelStatus">Brak zgody</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'NONE' ? (
              <div className="labelStatus" />
            ) : (
              ''
            )}
            <div className="boxStatus">
              {data.dsAccepted === 'REJECTED' && data.dsComment !== '' ? (
                <CustomInfoBlock
                  customText={data.dsComment}
                  customTitle="Decyzja braku akceptacji przez dział Szkoleń"
                  customSvg="manager"
                  widthBox="medium"
                >
                  <div className={`${data.dsAccepted} circleBox`}>
                    <RenderSvg nameSvg={`${data.dsAccepted}StatusWithInfo`} />
                  </div>
                </CustomInfoBlock>
              ) : (
                <div className={`${data.dsAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.dsAccepted}Status`} />
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {dataType === 'slt' ? (
          <div id="slt" className="status">
            {data[`${dataType}Accepted`] === 'ACCEPTED' ? (
              <div className="labelStatus">Akceptacja</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'VERIFY' ? (
              <div className="labelStatus">Brak decyzji</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'REJECTED' ? (
              <div className="labelStatus">Brak zgody</div>
            ) : (
              ''
            )}
            {data[`${dataType}Accepted`] === 'NONE' ? (
              <div className="labelStatus" />
            ) : (
              ''
            )}
            <div className="boxStatus">
              {data.sltAccepted === 'REJECTED' && data.sltComment !== '' ? (
                <CustomInfoBlock
                  customText={data.sltComment}
                  customTitle="Decyzja braku akceptacji przez SLT"
                  customSvg="manager"
                  widthBox="medium"
                >
                  <div className={`${data.sltAccepted} circleBox`}>
                    <RenderSvg nameSvg={`${data.sltAccepted}StatusWithInfo`} />
                  </div>
                </CustomInfoBlock>
              ) : (
                <div className={`${data.sltAccepted} circleBox`}>
                  <RenderSvg nameSvg={`${data.sltAccepted}Status`} />
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default TableCellTrainingsShowStatus;
