import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsProviderAddressRepository } from 'api/repository';
import { Modal } from 'components';
import FormTrainingsProviderAddress from '../Form/FormTrainingsProviderAddress';

interface TrainigsProviderProps {
  id: number;
  idRecord: number;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsProviderShow = (props: TrainigsProviderProps): ReactElement => {
  const { id, changeModalShow, idRecord } = props;

  const [
    trainingProviderAddress,
  ] = TrainingsProviderAddressRepository.useGetTrainingsProviderAddress({
    id: Number(id),
    idRecord: Number(idRecord),
  });

  return (
    <Modal open changeModalShow={changeModalShow} maxLevel="lvl2">
      <div>
        <FormTrainingsProviderAddress
          data={trainingProviderAddress}
          onlyView
          edit
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderShow;
