import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { PlansTrainingsRepository } from 'api/repository';
import { PlansTrainingsAddAndPut } from 'api/types';
import { Col, Row } from 'react-bootstrap';
import { CustomContainer } from 'components';
import { useHistory } from 'react-router-dom';
import FormPlansTrainings from '../FormPlansTrainings';

interface CompetencesProps {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const CompetencesAdd = (props: CompetencesProps): ReactElement => {
  const {
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
  } = props;

  const history = useHistory();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = PlansTrainingsRepository.useAddPlansTrainings(
    async (data) => {
      setSubmitForm(false);
      history.push(
        `/ustawienia/szkolenia/plan-szkolen/formularz/${data.id}/dane-podstawowe`
      );
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAdd = (dataPost: PlansTrainingsAddAndPut) => {
    setSubmitForm(true);
    postDataSave(dataPost);
  };

  const postDataSaveAndExit = PlansTrainingsRepository.useAddPlansTrainings(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    }
  );

  const handleAddAndExit = (dataPost: PlansTrainingsAddAndPut) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <CustomContainer>
      <Row>
        <Col>
          <FormPlansTrainings
            onSave={handleAdd}
            onSaveAndExit={handleAddAndExit}
            submitForm={submitForm}
            cancel={handleCancel}
          />
        </Col>
      </Row>
    </CustomContainer>
  );
};

export default CompetencesAdd;
