import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { useForm } from 'react-hook-form';
import {
  EmployeeAppraisalGoalModel,
  EmployeeAppraisalPostAndPutGoal,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  RenderSvg,
  CustomDate,
  CustomSliderBar,
  CustomSelect,
  EditorMce,
  EditorMceShort,
} from 'components';
import { useTranslation } from 'react-i18next';
import './employeeAppraisalGoalForm.scss';
import { triggerValidRequired } from 'utils';

type FormModel = EmployeeAppraisalPostAndPutGoal;

interface CompetencesProps {
  onSave?: (dataPost: EmployeeAppraisalPostAndPutGoal) => void;
  onSaveAndExit?: (dataPost: EmployeeAppraisalPostAndPutGoal) => void;
  cancel?: () => void;
  data?: EmployeeAppraisalGoalModel | null;
  edit?: boolean;
  onlyView?: boolean;
  submitForm?: boolean;
  editOnlyStatus?: boolean;
}

const EmployeeAppraisalGoalForm = (props: CompetencesProps): ReactElement => {
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    submitForm,
    cancel,
    editOnlyStatus,
  } = props;

  const { t } = useTranslation(['enum']);

  const formSchema: yup.SchemaOf<FormModel> = yup.object().shape({
    type: yup.string().required('Pole wymagane'),
    name: yup.string().required('Pole wymagane'),
    weight: yup.number().required('Pole wymagane'),
    measure: yup.string().required('Pole wymagane'),
    realizationDate: yup.string().required('Pole wymagane'),
    status: yup.string().required('Pole wymagane'),
    progress: yup.number().required('Pole wymagane'),
    comment: yup.string(),
  });

  const handleSave = (dataSubmit: FormModel) => {
    setChangeForm(false);
    if (onSave !== undefined) {
      onSave(dataSubmit);
    }
  };

  const handleSaveAndExit = (dataSubmit: FormModel) => {
    setChangeForm(false);
    if (onSaveAndExit !== undefined) {
      onSaveAndExit(dataSubmit);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    errors,
    trigger,
  } = useForm<FormModel>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const nameBox = () => {
    if (onlyView) {
      return 'PODGLĄD CELU ROCZNEGO PRACOWNIKA';
    }
    if (edit) {
      return 'EDYCJA CELU ROCZNEGO PRACOWNIKA';
    }
    return 'DODAWANIE CELU ROCZNEGO PRACOWNIKA';
  };

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              id="formEmployeeAppraisal"
              onChange={() => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formEmployeeAppraisalBox"
                    name={nameBox()}
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                  >
                    <>
                      <div id="formEmployeeAppraisalFirstRow">
                        <div id="formEmployeeAppraisalFirstRowIcon">
                          <RenderSvg nameSvg="target60" />
                        </div>
                        <div id="formEmployeeAppraisalFirstRowData">
                          <CustomSelect
                            label="Rodzaj celu:"
                            options={[
                              { value: 'BIOZ', label: 'BIOZ' },
                              { value: 'FINANCIAL', label: 'Finansowy' },
                              { value: 'PROJECT', label: 'Projektowy' },
                            ]}
                            name="type"
                            required
                            placeholder="Wybierz cel"
                            setValue={setValue}
                            error={errors?.type?.message}
                            validRequiredSelect={() => {
                              triggerValidRequired('type', trigger);
                            }}
                            valueByKey={data?.type}
                            disabled={editOnlyStatus}
                          />

                          <CustomInput
                            name="name"
                            label="Nazwa obszaru do rozwoju:"
                            register={register}
                            defaultValue={data?.name}
                            error={errors?.name?.message}
                            required
                            disabled={editOnlyStatus}
                            fullWidth
                            setValueWhenDisable={setValue}
                            placeholder="Wpisz nazwę celu"
                          />

                          <CustomSelect
                            label="Waga celu w %:"
                            options={[
                              { value: 5, label: '5%' },
                              { value: 10, label: '10%' },
                              { value: 15, label: '15%' },
                              { value: 20, label: '20%' },
                              { value: 25, label: '25%' },
                              { value: 30, label: '30%' },
                              { value: 35, label: '35%' },
                              { value: 40, label: '40%' },
                              { value: 45, label: '45%' },
                              { value: 50, label: '50%' },
                              { value: 55, label: '55%' },
                              { value: 60, label: '60%' },
                              { value: 65, label: '65%' },
                              { value: 70, label: '70%' },
                              { value: 75, label: '75%' },
                              { value: 80, label: '80%' },
                              { value: 85, label: '85%' },
                              { value: 90, label: '90%' },
                              { value: 95, label: '95%' },
                              { value: 100, label: '100%' },
                            ]}
                            name="weight"
                            required
                            placeholder="Wybierz wagę"
                            setValue={setValue}
                            error={errors?.weight?.message}
                            validRequiredSelect={() => {
                              triggerValidRequired('weight', trigger);
                            }}
                            valueByKey={data?.weight}
                            disabled={editOnlyStatus}
                          />
                        </div>
                      </div>
                      <div id="formEmployeeAppraisalSecondRow">
                        <CustomInput
                          name="measure"
                          label="KPI/Mierniki:"
                          register={register}
                          defaultValue={data?.measure}
                          error={errors?.measure?.message}
                          required
                          disabled={editOnlyStatus}
                          fullWidth
                          setValueWhenDisable={setValue}
                          placeholder="Wpisz nazwę celu"
                        />
                        <CustomDate
                          id="realizationDate"
                          name="realizationDate"
                          label="Data realizacji:"
                          setValue={setValue}
                          error={errors?.realizationDate?.message}
                          defaultValue={
                            data?.realizationDate === null ||
                            data?.realizationDate === undefined
                              ? undefined
                              : data?.realizationDate
                          }
                          toLeft
                          smallPaddingBlock
                          noInfo
                          textAlign="toLeft"
                          fullWidth
                          required
                          validRequiredDate={() => {
                            triggerValidRequired('realizationDate', trigger);
                          }}
                          disabled={editOnlyStatus}
                        />
                        <CustomSelect
                          label="Status realizacji"
                          options={[
                            { value: 'NOT_STARTED', label: 'Niezaczęte' },
                            { value: 'IN_PROGRESS', label: 'W toku' },
                            { value: 'COMPLETED', label: 'Ukończone' },
                            { value: 'OUT_OF_DATE', label: 'Nieaktualne' },
                          ]}
                          name="status"
                          required
                          placeholder="Wybierz status"
                          setValue={setValue}
                          error={errors?.status?.message}
                          validRequiredSelect={() => {
                            triggerValidRequired('status', trigger);
                          }}
                          valueByKey={data?.status}
                          onlyView={onlyView}
                        />
                        <CustomSliderBar
                          name="progress"
                          label="Określ procent realizacji:"
                          setValue={setValue}
                          defaultValue={data?.progress}
                          disabled={onlyView}
                        />
                      </div>
                      <div id="formEmployeeAppraisalThirdRow">
                        <EditorMce
                          id="comment"
                          name="comment"
                          register={register}
                          contentGet={data?.comment}
                          setChangeForm={setChangeForm}
                          label="Komentarz:"
                          onlyView={editOnlyStatus || onlyView}
                        />
                      </div>
                      {onlyView && data?.appraisal ? (
                        <div id="formEmployeeAppraisalRatingGoalSixthRow">
                          <CustomSelect
                            label="Samoocena:"
                            options={[
                              {
                                value: 'EXCEEDS_EXPECTATIONS',
                                label: t('appraisalGoal.EXCEEDS_EXPECTATIONS'),
                              },
                              {
                                value: 'PARTIALLY_EXCEEDS_EXPECTATIONS',
                                label: t(
                                  'appraisalGoal.PARTIALLY_EXCEEDS_EXPECTATIONS'
                                ),
                              },
                              {
                                value: 'FULLY_MEETS_EXPECTATIONS',
                                label: t(
                                  'appraisalGoal.FULLY_MEETS_EXPECTATIONS'
                                ),
                              },
                              {
                                value: 'PARTIALLY_MEETS_EXPECTATIONS',
                                label: t(
                                  'appraisalGoal.PARTIALLY_MEETS_EXPECTATIONS'
                                ),
                              },

                              {
                                value: 'DOES_NOT_MEET_EXPECTATIONS',
                                label: t(
                                  'appraisalGoal.DOES_NOT_MEET_EXPECTATIONS'
                                ),
                              },
                            ]}
                            name="rating"
                            placeholder="Wybierz adekwatną ocenę"
                            valueByKey={data?.appraisal?.rating}
                            disabled={onlyView}
                          />
                          <EditorMceShort
                            id="justification"
                            name="justification"
                            setHeight={120}
                            placeholder="Wpisz uzsadnienie dla swojej decyzji"
                            label="Uzasadnienie:"
                            contentGet={data?.appraisal?.justification}
                            onlyView
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  save={handleSubmit(handleSave)}
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default EmployeeAppraisalGoalForm;
