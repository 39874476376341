/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { RenderSvg } from 'components';
import { useHistory } from 'react-router-dom';

import './questionnaireListItem.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data: any;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data } = props;
  const { t } = useTranslation('plansTrainings');
  const history = useHistory();

  return (
    <div className="QuestionnaireListItem" id={`${id}-${data.id}`}>
      <div className="accent" />
      <div className="name">{data.name}</div>
      <div className="datesTraining">
        <div className="icon">
          <RenderSvg nameSvg="plansIcon" />
        </div>
        {data.startDate !== null ? (
          <div className="dates">
            <div className="startDateBox">
              Od:{' '}
              <span className="startDate">
                {data.startDate.substring(0, 10)}
              </span>
            </div>
            <div className="endDateBox">
              Do:{' '}
              <span className="endDate">{data.endDate.substring(0, 10)}</span>
            </div>
          </div>
        ) : (
          <div className="singleDate">
            <div className="singleDateBox">
              {data.singleDate.substring(0, 10)}
            </div>
          </div>
        )}
      </div>

      <div className="action">
        <div className="buttonFill">
          <Button
            className="green"
            onClick={() => {
              history.push(
                `/panel/szkolenia/ankiety/${data.id}/${data.questionnaireId}`
              );
            }}
          >
            Wypełnij ankietę
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContentBox;
