import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionBeforeCatalog,
  ContentBox,
  LoaderTable,
  RenderSvg,
  ShowListToString,
  ShowSuperior,
} from 'components';
import { Button } from 'react-bootstrap';
import { EmployeeRepository, TrainingsGroupRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupTraining } from 'api/types';
import GroupItem from './GroupItem';

interface Props {
  setModalShow?: Dispatch<SetStateAction<boolean>>;
  setModalId?: Dispatch<SetStateAction<number>>;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { t } = useTranslation('trainingsGroup');
  const { id, idTraining } = useParams<{ id: string; idTraining: string }>();

  const history = useHistory();
  const { setModalShow, setModalId } = props;

  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [
    getDataSingle,
    dataSingle,
  ] = TrainingsGroupRepository.useManagerGetAllUserTrainingsGroupSingle(
    Number(id),
    Number(idTraining)
  );

  const [
    getData,
    data,
  ] = TrainingsGroupRepository.useGetAllUserTrainingsGroupAvailableGroup(
    Number(idTraining)
  );

  const [dataEmployee] = EmployeeRepository.useGetEmployee({ id: Number(id) });

  useEffect(() => {
    getDataSingle();
  }, [getDataSingle]);

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const handleAddTrainingsProvider = TrainingsGroupRepository.useManagerTrainingsGroupUserPostSelectGroup(
    async () => {
      setLoaderTable(false);
      history.push(`/kierownik/szkolenia/grupy-szkoleniowe/${id}`);
    },
    async () => {
      setLoaderTable(false);
    }
  );

  const handleChangeTrainingsProvider = TrainingsGroupRepository.useManagerTrainingsGroupUserDeleteSelectGroup(
    async (idGroupChange) => {
      setLoaderTable(true);
      if (idGroupChange) {
        handleAddTrainingsProvider(idGroupChange, Number(id));
      }
    },
    async () => {
      setLoaderTable(false);
    }
  );

  const changeSelectGroup = (idClick: number) => {
    if (dataSingle?.content[0]?.trainingGroup?.id !== undefined) {
      setLoaderTable(true);
      handleChangeTrainingsProvider(
        dataSingle?.content[0]?.trainingGroup?.id,
        Number(id),
        Number(idClick)
      );
    }
  };

  const selectGroup = (idClick: number) => {
    setLoaderTable(true);
    handleAddTrainingsProvider(Number(idClick), Number(id));
  };

  return (
    <>
      <LoaderTable active={loaderTable} radiusTop>
        <>
          <ContentBox
            id="userPlansAcceptedTrainingsBasicInformation"
            bottomBorder
          >
            <>
              <div className="employeeNameBox">
                <div className="titleName">Wybrany pracownik:</div>
                <div className="name">
                  <span className="lastName">{dataEmployee?.lastName}, </span>
                  <span className="firstName">{dataEmployee?.firstName}</span>
                </div>
                <div className="extension">
                  Nr. wewnętrzny:{' '}
                  <span className="extensionData">{dataEmployee?.id}</span>
                </div>
                <div className="superior">
                  Przełożony:{' '}
                  <span className="superiorData">
                    <ShowSuperior data={dataEmployee?.superior} noObject />
                  </span>
                </div>
              </div>
              <div className="additionalInfoBox">
                <div id="frameAdditionalInfo" className="aditionalInfoContent">
                  <div id="frameAdditionalInfoDetail" className="content">
                    <div id="frameAdditionalInfoIcon" className="icon">
                      <RenderSvg nameSvg="location" />
                    </div>
                    <div
                      id="frameAdditionalInfoBoxData"
                      className="additionalInfoDetail"
                    >
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Lokalizacja:
                      </div>
                      <div id="frameAdditionalInfoBoxDataData" className="data">
                        {dataEmployee?.locations}
                      </div>
                    </div>
                  </div>
                </div>
                <div id="frameAdditionalInfo" className="aditionalInfoContent">
                  <div id="frameAdditionalInfoDetail" className="content">
                    <div id="frameAdditionalInfoIcon" className="icon">
                      <RenderSvg nameSvg="workplace" />
                    </div>
                    <div
                      id="frameAdditionalInfoBoxData"
                      className="additionalInfoDetail"
                    >
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Stanowiska:
                      </div>
                      <div id="frameAdditionalInfoBoxDataData" className="data">
                        <ShowListToString
                          data={dataEmployee?.positions}
                          noObject
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="frameAdditionalInfo" className="aditionalInfoContent">
                  <div id="frameAdditionalInfoDetail" className="content">
                    <div id="frameAdditionalInfoIcon" className="icon">
                      <RenderSvg nameSvg="departament" />
                    </div>
                    <div
                      id="frameAdditionalInfoBoxData"
                      className="additionalInfoDetail"
                    >
                      <div
                        id="frameAdditionalInfoBoxDataTitle"
                        className="title"
                      >
                        Działy:
                      </div>
                      <div id="frameAdditionalInfoBoxDataData" className="data">
                        <ShowListToString
                          data={dataEmployee?.segments}
                          noObject
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </ContentBox>
          <ContentBox
            id="trainingGroupsUserSelectTrainingBox"
            noPadding
            title="DATY SZKOLEŃ:"
          >
            <>
              {data?.content && data.content.length > 0 ? (
                <div
                  className="trainingGroupsUserSelectTrainingItemsBox"
                  id={id}
                >
                  {data.content.map((item: TrainingsGroupTraining) => {
                    return (
                      <GroupItem
                        id="itemSelectTrainings"
                        data={item}
                        selectedGroup={dataSingle?.content[0] || null}
                        showClick={() => {
                          if (setModalId && setModalShow) {
                            setModalId(Number(item.id));
                            setModalShow(true);
                          }
                        }}
                        selectClick={() => {
                          selectGroup(item.id);
                        }}
                        changeSelectClick={() => {
                          changeSelectGroup(item.id);
                        }}
                      />
                    );
                  })}
                </div>
              ) : (
                <div
                  className="trainingGroupsUserSelectTrainingItemsBoxNoRecord"
                  id={id}
                >
                  Brak utworzonych grup szkoleniowych do wybranego szkolenia
                </div>
              )}
            </>
          </ContentBox>
        </>
      </LoaderTable>
    </>
  );
};

export default TableTrainingsBlock;
