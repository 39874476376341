import React, { ReactElement } from 'react';
import {
  AnimatedProgressBarLine,
  CustomInfoBlock,
  RenderSvg,
} from 'components';
import './IDPEmployeeAreaList.scss';
import { IDPEmployeeAreaModel } from 'api/types';

interface Props {
  data: any;
}

const IDPEmployeeAreaBlock = (props: Props): ReactElement => {
  const { data } = props;

  return (
    <>
      <div id="IDPEmployeeAreaListShowBox">
        {data?.content
          ? data.content.map((item: IDPEmployeeAreaModel, i: number) => {
              return (
                <div
                  id={`IDPEmployeeAreaBoxItem-${item.id}`}
                  className="IDPEmployeeAreaBoxItem"
                >
                  <div
                    id={`IDPEmployeeAreaBoxItemNumber-${item.id}`}
                    className="IDPEmployeeAreaBoxItemNumber"
                  >
                    {i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
                  </div>
                  <div
                    id={`IDPEmployeeAreaBoxItemBlock-${item.id}`}
                    className="IDPEmployeeAreaBoxItemBlock"
                  >
                    <div
                      id={`IDPEmployeeAreaBoxItemBlockTitle-${item.id}`}
                      className="IDPEmployeeAreaBoxItemBlockTitle"
                    >
                      <div
                        id={`IDPEmployeeAreaBoxItemBlockTitleArea-${item.id}`}
                        className="IDPEmployeeAreaBoxItemBlockTitleArea"
                      >
                        Obszar do rozwoju:
                      </div>
                      <div
                        id={`IDPEmployeeAreaBoxItemBlockTitleAdditional-${item.id}`}
                        className="IDPEmployeeAreaBoxItemBlockTitleAdditional"
                      >
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockTitleActivities-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockTitleActivities"
                        >
                          Działania i czynności rozwojowe:
                        </div>
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockTitleTerm-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockTitleTerm"
                        >
                          Termin:
                        </div>
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockTitleMeasure-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockTitleMeasure"
                        >
                          Mierniki:
                        </div>
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockTitleProgress-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockTitleProgress"
                        >
                          % Realizacji:
                        </div>
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockTitleComment-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockTitleComment"
                        >
                          Komentarz:
                        </div>
                      </div>
                    </div>
                    <div
                      id={`IDPEmployeeAreaBoxItemBlockData-${item.id}`}
                      className="IDPEmployeeAreaBoxItemBlockData"
                    >
                      <div
                        id={`IDPEmployeeAreaBoxItemBlockDataArea-${item.id}`}
                        className="IDPEmployeeAreaBoxItemBlockDataArea"
                      >
                        {item.name}
                      </div>
                      <div
                        id={`IDPEmployeeAreaBoxItemBlockDataAdditionalData-${item.id}`}
                        className="IDPEmployeeAreaBoxItemBlockDataAdditional"
                      >
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockDataAdditionalDataRow70-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockDataAdditionalRow"
                        >
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataActivities-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataActivities"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataActivities70-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataActivitiesItem IDPEmployeeAreaBoxItemBlockDataActivities70"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities70Icon-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemIcon IDPEmployeeAreaBoxItemBlockDataActivities70Icon"
                              >
                                70%
                              </div>
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities70Data-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemData IDPEmployeeAreaBoxItemBlockDataActivities70Data"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={
                                  item.activities?.[70].name
                                    ? { __html: item.activities?.[70].name }
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataTerm70-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataTerm"
                          >
                            {item.activities?.[70].term}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataMeasure70-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataMeasure"
                          >
                            {item.activities?.[70].measure}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataProgress70-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataProgress"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataProgress70Block-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataProgressBlock IDPEmployeeAreaBoxItemBlockDataProgress70Block"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataProgress70Label-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataProgressLabel"
                              >
                                {item.activities?.[70].progress}%
                              </div>
                              <AnimatedProgressBarLine
                                valueEnd={item.activities?.[70].progress || 0}
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataComment70-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataComment"
                          >
                            {item.activities?.[70].comment !== null &&
                            item.activities?.[70].comment !== '' ? (
                              <CustomInfoBlock
                                customText={item.activities?.[70].comment}
                                customTitle="Komentarz do czynności rozwojowej"
                                customSvg="manager"
                                widthBox="medium"
                                maxLevel="lvl2"
                              >
                                <RenderSvg nameSvg="iconInfo" />
                              </CustomInfoBlock>
                            ) : (
                              '---'
                            )}
                          </div>
                        </div>
                        <div
                          id={`IDPEmployeeAreaBoxItemBlockDataAdditionalDataRow20-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockDataAdditionalRow"
                        >
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataActivities-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataActivities"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataActivities20-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataActivitiesItem IDPEmployeeAreaBoxItemBlockDataActivities20"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities20Icon-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemIcon IDPEmployeeAreaBoxItemBlockDataActivities20Icon"
                              >
                                20%
                              </div>
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities20Data-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemData IDPEmployeeAreaBoxItemBlockDataActivities20Data"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={
                                  item.activities?.[20].name
                                    ? { __html: item.activities?.[20].name }
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataTerm20-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataTerm"
                          >
                            {item.activities?.[20].term}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataMeasure20-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataMeasure"
                          >
                            {item.activities?.[20].measure}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataProgress20-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataProgress"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataProgress20Block-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataProgressBlock IDPEmployeeAreaBoxItemBlockDataProgress20Block"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataProgress20Label-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataProgressLabel"
                              >
                                {item.activities?.[20].progress}%
                              </div>
                              <AnimatedProgressBarLine
                                valueEnd={item.activities?.[20].progress || 0}
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataComment20-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataComment"
                          >
                            {item.activities?.[20].comment !== null &&
                            item.activities?.[20].comment !== '' ? (
                              <CustomInfoBlock
                                customText={item.activities?.[20].comment}
                                customTitle="Komentarz do czynności rozwojowej"
                                customSvg="manager"
                                widthBox="medium"
                                maxLevel="lvl2"
                              >
                                <RenderSvg nameSvg="iconInfo" />
                              </CustomInfoBlock>
                            ) : (
                              '---'
                            )}
                          </div>
                        </div>

                        <div
                          id={`IDPEmployeeAreaBoxItemBlockDataAdditionalDataRow10-${item.id}`}
                          className="IDPEmployeeAreaBoxItemBlockDataAdditionalRow noBorder"
                        >
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataActivities-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataActivities"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataActivities10-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataActivitiesItem IDPEmployeeAreaBoxItemBlockDataActivities10"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities10Icon-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemIcon IDPEmployeeAreaBoxItemBlockDataActivities10Icon"
                              >
                                10%
                              </div>
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataActivities10Data-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataActivitiesItemData IDPEmployeeAreaBoxItemBlockDataActivities10Data"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={
                                  item.activities?.[10].name
                                    ? { __html: item.activities?.[10].name }
                                    : undefined
                                }
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataTerm10-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataTerm"
                          >
                            {item.activities?.[10].term}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataMeasure10-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataMeasure"
                          >
                            {item.activities?.[10].measure}
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataProgress10-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataProgress"
                          >
                            <div
                              id={`IDPEmployeeAreaBoxItemBlockDataProgress10Block-${item.id}`}
                              className="IDPEmployeeAreaBoxItemBlockDataProgressBlock IDPEmployeeAreaBoxItemBlockDataProgress10Block"
                            >
                              <div
                                id={`IDPEmployeeAreaBoxItemBlockDataProgress10Label-${item.id}`}
                                className="IDPEmployeeAreaBoxItemBlockDataProgressLabel"
                              >
                                {item.activities?.[10].progress}%
                              </div>
                              <AnimatedProgressBarLine
                                valueEnd={item.activities?.[10].progress || 0}
                              />
                            </div>
                          </div>
                          <div
                            id={`IDPEmployeeAreaBoxItemBlockDataComment10-${item.id}`}
                            className="IDPEmployeeAreaBoxItemBlockDataComment"
                          >
                            {item.activities?.[10].comment !== null &&
                            item.activities?.[10].comment !== '' ? (
                              <CustomInfoBlock
                                customText={item.activities?.[10].comment}
                                customTitle="Komentarz do czynności rozwojowej"
                                customSvg="manager"
                                widthBox="medium"
                                maxLevel="lvl2"
                              >
                                <RenderSvg nameSvg="iconInfo" />
                              </CustomInfoBlock>
                            ) : (
                              '---'
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : ''}
      </div>
    </>
  );
};

export default IDPEmployeeAreaBlock;
