import React, { ReactElement } from 'react';
import { Loader } from 'components';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  active?: boolean;
  children?: ReactElement;
  type: 'getData' | 'saving';
}

const LoaderForm = (props: Props): ReactElement => {
  const { active, children, type } = props;

  const { t } = useTranslation('form');

  return (
    <Loader
      active={active}
      className="radiusTop"
      text={type === 'getData' ? t('loader.getData') : t('loader.saving')}
    >
      {children}
    </Loader>
  );
};

export default LoaderForm;
