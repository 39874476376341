/* eslint-disable import/prefer-default-export */

enum TermType {
  MULTI_DAY = 'MULTI_DAY',
  ONE_DAY = 'ONE_DAY',
}

enum TrainingFormType {
  E_LEARNING = 'E_LEARNING',
  MIXED = 'MIXED',
  REMOTE = 'REMOTE',
  STATIONARY = 'STATIONARY',
}

enum SettlementType {
  PER_GROUP = 'PER_GROUP',
  PER_PERSON = 'PER_PERSON',
}

export default { TermType, TrainingFormType, SettlementType };
