import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { TrainingsCompanyContactRepository } from 'api/repository';
import { Modal } from 'components';
import FormTrainingsCompanyContact from '../Form/FormTrainingsCompanyContact';

interface TrainigsCompanyProps {
  idRecord: number;
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}
const TrainingsCompanyShow = (props: TrainigsCompanyProps): ReactElement => {
  const { changeModalShow, idRecord } = props;
  const history = useHistory();
  const [saveAndExit, setSaveAndExit] = useState(false);

  const [
    trainingCompanyContact,
  ] = TrainingsCompanyContactRepository.useGetTrainingsCompanyContact({
    idRecord: Number(idRecord),
  });

  return (
    <Modal open changeModalShow={changeModalShow} title="test" maxLevel="lvl2">
      <div>
        <FormTrainingsCompanyContact
          data={trainingCompanyContact}
          onlyView
          edit
        />
      </div>
    </Modal>
  );
};

export default TrainingsCompanyShow;
