import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CatalogsTrainingsAddAndPut, CatalogTrainings } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  ContentBox,
  SelectedToArray,
} from 'components';
import TableTrainings from './TableTrainings';
import './formCatalogsTrainings.scss';

type CatalogTrainingsValue = Omit<CatalogsTrainingsAddAndPut, 'id'>;

interface FormCatalogTrainingsProps {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: CatalogTrainings | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormCatalogsTrainings = (
  props: FormCatalogTrainingsProps
): ReactElement => {
  const { t } = useTranslation(['catalogsTrainings', 'trainings']);

  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [selectedTrainings, setSelectedTrainings] = useState<any>(null);
  const [incomingData, setIncomingData] = useState<boolean>(false);
  const [updateSelected, setUpadteSelected] = useState<number>(0);

  const formSchema: yup.SchemaOf<CatalogTrainingsValue> = yup.object().shape({
    name: yup.string().required(t('catalogsForm.nameMessage')),
    trainings: yup.array().min(1, t('catalogsForm.minimumOneTraing')),
  });

  const {
    handleSubmit,
    register,
    setValue,
    errors,
  } = useForm<CatalogTrainingsValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue('trainings', SelectedToArray({ dataObject: selectedTrainings }));
  }, [selectedTrainings, setValue, updateSelected]);

  useEffect(() => {
    if (data && incomingData === false) {
      if (data.trainingIds.length > 0) {
        const obj = {};
        Object.keys(data.trainingIds).forEach((key) => {
          obj[Number(data.trainingIds[key])] = Number(data.trainingIds[key]);
        });
        setSelectedTrainings(JSON.stringify(obj));
      }
      setIncomingData(true);
    }
  }, [data, incomingData]);

  const handleSave = (dataSubmit: CatalogTrainingsValue) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: CatalogTrainingsValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="formCatalogsTrainings"
              onChange={(e) => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formCatalogTrainingsBox"
                    name={
                      edit
                        ? t('catalogsForm.boxNameEdit')
                        : t('catalogsForm.boxNameAdd')
                    }
                    svgIcon="addEdit"
                    noMarginBottom
                    noShadow
                    noPadding
                  >
                    <>
                      <ContentBox
                        id="FormCatalogsTrainingsBasicInformation"
                        noPadding
                      >
                        <>
                          <CustomInput
                            name="name"
                            label={t('catalogsForm.name')}
                            register={register}
                            defaultValue={data?.name}
                            error={errors?.name?.message}
                            required
                            disabled={onlyView}
                            fullWidth
                          />
                          <div className="subTitleTable">
                            {t('catalogsForm.listAllTrainigs')}
                          </div>
                        </>
                      </ContentBox>
                      <TableTrainings
                        selectedTrainings={selectedTrainings}
                        setSelectedTrainings={setSelectedTrainings}
                        setUpdateSelected={setUpadteSelected}
                        errormsg={errors?.trainings?.message}
                        edit={edit}
                      />
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  save={handleSubmit(handleSave)}
                  saveAndExit={handleSubmit(handleSaveAndExit)}
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormCatalogsTrainings;
