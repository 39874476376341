/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './CustomContainer.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  children: ReactElement;
  allowGrow?: boolean;
  id?: string;
  paddingSides?: boolean;
  column?: boolean;
}

const CustomContainer = (props: Props): ReactElement => {
  const { children, allowGrow, id, paddingSides, column } = props;

  return (
    <div
      id={id}
      className={`customContainer ${allowGrow ? 'allowGrow' : ''} ${
        paddingSides ? 'paddingSides' : ''
      } ${column ? 'column' : ''}`}
    >
      {children}
    </div>
  );
};

export default CustomContainer;
