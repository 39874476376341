import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlansTrainingsRepository } from 'api/repository';
import {
  TablePagination,
  LoaderTable,
  TableRecordAction,
  CustomSort,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomFilterSelect,
  TableCellTypeTrainings,
  CustomFilterData,
  CustomSortData,
  TableCellYesNo,
  ShowTitleTable,
} from 'components';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import TrainingsType from '../../../../../constants/Trainings';
import ModalTrainingsShow from '../../../TrainingsAndCompetence/Trainings/ModalTrainingsShow';

interface Props {
  id: number;
}

const TrableTrainings = (props: Props): ReactElement => {
  const { t } = useTranslation(['plansTrainings', 'trainings']);
  const { id } = props;

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    name: 'name',
    type: 'type',
  };
  // End variable search

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      name: 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    name: 'default',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getDataTable,
    dataTable,
  ] = PlansTrainingsRepository.useGetRequiredTrainings(Number(id));

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };

    getDataTable(paramQuery);
  }, [
    getDataTable,
    page,
    rowsPerPage,
    filterReq,
    orderColumn,
    updateDataTable,
  ]);

  useEffect(() => {
    if (dataTable !== null) {
      setLoaderTable(false);
    }
  }, [dataTable]);

  function handleShowClick(idClick: number) {
    setModalId(idClick);
    setModalShow(true);
  }

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const typeOptions = () => {
    const arrayTypeOption = [] as any;
    Object.keys(TrainingsType).forEach((key) => {
      arrayTypeOption.push({
        value: TrainingsType[key],
        label: t(`trainingsTypeEnum.${TrainingsType[key]}`),
      });
    });
    return arrayTypeOption;
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (cell: any, row: any, rowIndex: any) => {
        return (
          <TableRecordAction
            dataId={row.training.id}
            showClick={handleShowClick}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'training.name',
      text: t('trainings:trainingsTable.name'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'training.providerName',
      text: t('trainings:trainingsTable.providerName'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
    },
    {
      dataField: 'training.type',
      text: t('trainings:trainingsTable.type'),
      sort: true,
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterSelect
          customFilter={dataCustomFilterData}
          column={column}
          alignBox="left"
          options={typeOptions()}
        />
      ),
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            align="left"
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellTypeTrainings data={row?.training?.type} justify="toLeft" />
        );
      },
      headerStyle: () => {
        return { width: '120px' };
      },
    },
    {
      dataField: '',
      text: t('trainings:trainingsTable.required'),
      headerFormatter: (column: any, index: any) => {
        return <CustomHeaderFormatter column={column} align="right" />;
      },
      formatter: (_cell: any, row: any) => {
        return <TableCellYesNo data={row.required} justify="toRight" />;
      },
      headerStyle: () => {
        return { width: '100px' };
      },
    },
  ];

  return (
    <>
      <>
        <LoaderTable active={loaderTable}>
          <>
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={dataTable}
              changePage={setPage}
            >
              <ShowTitleTable
                id="tableTitle"
                label="Lista dostępnych szkoleń:"
              />
            </TablePagination>

            <BootstrapTable
              bootstrap4
              hover
              bordered={false}
              keyField="id"
              data={dataTable?.content || []}
              columns={columns}
              noDataIndication={t('trainings:trainingsTable.noDataTable')}
              remote={{
                filter: true,
                sort: true,
              }}
              onTableChange={onTableChange}
              filter={filterFactory()}
              defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            />
            <TablePagination
              rowInPage={rowsPerPage}
              page={page}
              changeRowInPage={setRowsPerPage}
              data={dataTable}
              changePage={setPage}
            />
          </>
        </LoaderTable>

        {modalShow ? (
          <ModalTrainingsShow id={modalId} changeModalShow={setModalShow} />
        ) : (
          ''
        )}
      </>
    </>
  );
};

export default TrableTrainings;
