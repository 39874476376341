import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import { IDPEmployeeModel, IDPModelAddAndPut } from 'api/types';
import IDPEmployeeBasicInformation from '../../Form/IDPEmployeeBasicInformation';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { idEmployee } = useParams<{ idEmployee: string; idRecord: string }>();

  const { IDPEmployee, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const handleEditTrainingsProvider = IDPRepository.usePutIDPEmployee(
    Number(idEmployee),
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleEditTrainingsProviderAndExit = IDPRepository.usePutIDPEmployee(
    Number(idEmployee),
    async () => {
      setSubmitForm(false);
      history.push(`/kierownik/IDP/pracownicy`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPModelAddAndPut) => {
    if (!submitForm) {
      const postData = data;
      setSubmitForm(true);

      handleEditTrainingsProvider(postData);
    }
  };

  const handleSubmitSaveAndExit = (data: IDPModelAddAndPut) => {
    if (!submitForm) {
      setSubmitForm(true);
      const postData = data;

      handleEditTrainingsProviderAndExit(postData);
    }
  };

  const handleCancel = () => {
    history.push(`/kierownik/IDP/pracownicy`);
  };

  return (
    <IDPEmployeeBasicInformation
      id={Number(idEmployee)}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={
        IDPEmployee !== null ? handleSubmitSaveAndExit : undefined
      }
      submitForm={submitForm}
      data={IDPEmployee}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
