import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupRepository } from 'api/repository';
import { Box, ContentBox, CustomContainer, CustomNumber } from 'components';
import { TrainingsGroupTrainingAddPut } from 'api/types';
import { Button } from 'react-bootstrap';
import TableCostMPK from './TableCostMPK';
import './trainingsGroupSettlement.scss';

const TrainingsProviderAddPage = (): ReactElement => {
  const { id, idRecord } = useParams<{ id: string; idRecord: string }>();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [valueSettlement, setValueSettlement] = useState<number | null>(null);

  const [updateTableData, setUpdateTableData] = useState<number>(0);

  const [
    trainingsGroupData,
  ] = TrainingsGroupRepository.useGetTrainingsGroupGetGroup({
    id: Number(idRecord),
  });

  const handleAddTrainingsProvider = TrainingsGroupRepository.usePutTrainingsGroupPutGroup(
    async () => {
      setSubmitForm(false);
      setUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = () => {
    if (!submitForm) {
      if (trainingsGroupData !== undefined && trainingsGroupData !== null) {
        const postData = trainingsGroupData;
        postData.finalCost = valueSettlement;
        setSubmitForm(true);
        handleAddTrainingsProvider(postData);
      }
    }
  };

  return (
    <CustomContainer id="trainingsGroupSettlementContainer">
      <>
        <ContentBox id="trainingsGroupSettlementBox">
          <>
            <div id="inputBox">
              <CustomNumber
                name="test"
                label="Koszty szkolenia:"
                setValue={setValueSettlement}
                defaultValue={
                  trainingsGroupData &&
                  trainingsGroupData !== null &&
                  trainingsGroupData?.finalCost
                    ? `${trainingsGroupData?.finalCost}`
                    : undefined
                }
              />
              <span>PLN</span>
            </div>
            <div id="buttonBox">
              <Button className="green" onClick={() => handleSubmit()}>
                Zatwierdź
              </Button>
            </div>
          </>
        </ContentBox>
        {}
        <TableCostMPK updateTable={updateTableData} />
      </>
    </CustomContainer>
  );
};

export default TrainingsProviderAddPage;
