import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { Box, Modal } from 'components';
import CloseButton from './CloseButton';

import './ModalYesNo.scss';

interface Props {
  children?: React.ReactNode;
  title?: string;
  allowClose?: boolean;
  changeModalShow?: Dispatch<SetStateAction<boolean>>;
  yesAction?: () => void;
  noAction?: () => void;
  additionalText?: string;
  svgIcon?: 'questionCircle';
}

const CustomModalYesNo = (props: Props): ReactElement => {
  const {
    title,
    children,
    allowClose,
    changeModalShow,
    yesAction,
    noAction,
    additionalText,
    svgIcon,
  } = props;

  const onHide = () => {};

  const handleClose = () => {
    if (changeModalShow) {
      changeModalShow(false);
    }
  };

  const handleYesAction = () => {
    if (yesAction) {
      yesAction();
    }
    handleClose();
  };

  const handleNoAction = () => {
    if (noAction) {
      noAction();
    }
    handleClose();
  };

  const closeButton = () => {
    if (changeModalShow) {
      return <CloseButton changeModalShow={changeModalShow} />;
    }
    return null;
  };

  return (
    <>
      <Modal open widthBox="low400" maxLevel="lvl5">
        {allowClose ? closeButton() : ''}
        <Box
          id="QuestionYesNo"
          name={title || 'ARCHIWIZACJA'}
          noShadow
          noMarginBottom
          svgIcon={svgIcon || 'questionCircle'}
        >
          <>
            {additionalText ? (
              <div className="additionalText">{additionalText}</div>
            ) : (
              'Czy na pewno chcesz zarchiwizować?'
            )}
            <div className="answerBox">
              <Button className="yesAnswer" onClick={() => handleYesAction()}>
                Tak
              </Button>
              <Button className="noAnswer" onClick={() => handleNoAction()}>
                Nie
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default CustomModalYesNo;
