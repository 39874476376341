/* eslint-disable import/prefer-default-export */

enum TrainingsType {
  CORPORATE = 'CORPORATE',
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
  LANGUAGE = 'LANGUAGE',
  STUDIES = 'STUDIES',
}

export default TrainingsType;
