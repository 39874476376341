/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './actionBeforeCatalog.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  children?: ReactElement;
}

const ActionBeforeCatalog = (props: Props): ReactElement => {
  const { id, children } = props;

  return (
    <div id={id} className="actionBeforeCatalog">
      {children}
    </div>
  );
};

export default ActionBeforeCatalog;
