import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  TrainingsCompanyContact,
  TrainingsCompanyContactAddPut,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomCheckbox,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
} from 'components';

type TrainingsCompanyContactValues = Omit<TrainingsCompanyContactAddPut, 'id'>;

interface TrainingsCompanyContactProps {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: TrainingsCompanyContact | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormTrainingsCompanyContact = (
  props: TrainingsCompanyContactProps
): ReactElement => {
  const { t } = useTranslation('trainingsCompany');
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const formSchema: yup.SchemaOf<TrainingsCompanyContactValues> = yup
    .object()
    .shape(
      {
        name: yup.string().required(t('contactForm.nameMessage')),
        main: yup.boolean().notRequired(),
        phone: yup.string().when('email', {
          is: '',
          then: yup.string().required(t('contactForm.phoneMessage')),
          otherwise: yup.string().notRequired(),
        }),
        email: yup.string().when('phone', {
          is: '',
          then: yup.string().required(t('contactForm.emailMessage')),
          otherwise: yup.string().notRequired(),
        }),
      },
      [['phone', 'email']]
    );

  const handleSave = (dataSubmit: TrainingsCompanyContactValues) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: TrainingsCompanyContactValues) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const {
    handleSubmit,
    register,
    errors,
  } = useForm<TrainingsCompanyContactValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form className="form">
              <LoaderForm active={submitForm} type="saving">
                <>
                  <Box
                    id="formTrainingCompanyContactBox"
                    name={edit ? 'EDYCJA KONTAKTU' : 'DODAWNIE KONTAKTU'}
                    svgIcon="addEdit"
                    noMarginBottom
                  >
                    <>
                      <Row className="justify-content-md-center">
                        <Col>
                          <CustomInput
                            name="name"
                            label={t('contactForm.name')}
                            register={register}
                            defaultValue={data?.name}
                            error={errors?.name?.message}
                            required
                            disabled={onlyView}
                          />
                        </Col>

                        <Col>
                          <CustomInput
                            name="phone"
                            label={t('contactForm.phone')}
                            register={register}
                            error={errors?.phone?.message}
                            defaultValue={data?.phone}
                            disabled={onlyView}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col />

                        <Col>
                          <CustomInput
                            name="email"
                            label={t('contactForm.email')}
                            register={register}
                            error={errors?.email?.message}
                            defaultValue={data?.email}
                            disabled={onlyView}
                          />
                        </Col>
                      </Row>
                    </>
                  </Box>
                </>
              </LoaderForm>
              {!onlyView ? (
                <CustomSubmit
                  save={onSave ? handleSubmit(handleSave) : undefined}
                  saveAndExit={
                    onSaveAndExit ? handleSubmit(handleSaveAndExit) : undefined
                  }
                  cancel={cancel}
                  submitForm={submitForm}
                />
              ) : (
                ''
              )}
            </Form>
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormTrainingsCompanyContact;
