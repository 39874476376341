import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  TrainingsProviderAddressAddPut,
  TrainingsProviderAddress,
} from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomCheckbox,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
} from 'components';

type TrainingsProviderAddressValues = Omit<
  Omit<TrainingsProviderAddressAddPut, 'id'>,
  'providerId'
>;

interface TrainingsProviderAddressProps {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: TrainingsProviderAddress | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormTrainingsProvider = (
  props: TrainingsProviderAddressProps
): ReactElement => {
  const { t } = useTranslation('trainingsProvider');
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    cancel,
    submitForm,
  } = props;

  const formSchema: yup.SchemaOf<TrainingsProviderAddressValues> = yup
    .object()
    .shape({
      name: yup.string().required(t('addressForm.nameMessage')),
      street: yup.string().required(t('addressForm.streetMessage')),
      main: yup.boolean().notRequired(),
      buildingNumber: yup.string().notRequired(),
      apartmentNumber: yup.string().notRequired(),
      postalCode: yup.string().notRequired(),
      city: yup.string().required(t('addressForm.cityMessage')),
    });

  const handleSave = (dataSubmit: TrainingsProviderAddressValues) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: TrainingsProviderAddressValues) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const {
    handleSubmit,
    register,
    errors,
  } = useForm<TrainingsProviderAddressValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
  });

  return (
    <>
      <LoaderForm active={data === undefined && edit} type="getData">
        <>
          <ExitFromForm changeForm={changeForm} />
          <Form className="form">
            <LoaderForm active={submitForm} type="saving">
              <Box
                id="formTrainingProviderAddressBox"
                name={t('address.ADDRESS')}
                svgIcon="map-marker"
                noMarginBottom
              >
                <>
                  <Row className="justify-content-md-center">
                    <Col>
                      <CustomInput
                        name="name"
                        label={t('addressForm.name')}
                        register={register}
                        defaultValue={data?.address.name}
                        error={errors?.name?.message}
                        required
                        disabled={onlyView}
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        name="street"
                        label={t('addressForm.street')}
                        register={register}
                        error={errors?.street?.message}
                        defaultValue={data?.address.street}
                        disabled={onlyView}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col>
                      <CustomInput
                        name="buildingNumber"
                        label={t('addressForm.buildingNumber')}
                        register={register}
                        defaultValue={data?.address.buildingNumber}
                        error={errors?.buildingNumber?.message}
                        disabled={onlyView}
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        name="apartmentNumber"
                        label={t('addressForm.apartamentNumber')}
                        register={register}
                        defaultValue={data?.address.apartmentNumber}
                        error={errors?.apartmentNumber?.message}
                        disabled={onlyView}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col>
                      <CustomInput
                        name="city"
                        label={t('addressForm.city')}
                        register={register}
                        defaultValue={data?.address?.city}
                        error={errors?.city?.message}
                        disabled={onlyView}
                        required
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        name="postalCode"
                        label={t('addressForm.postalCode')}
                        register={register}
                        defaultValue={data?.address.postalCode}
                        error={errors?.postalCode?.message}
                        disabled={onlyView}
                      />
                    </Col>
                  </Row>
                  <Row className="justify-content-md-center">
                    <Col />

                    <Col>
                      <CustomCheckbox
                        id="main"
                        name="main"
                        label={
                          onlyView
                            ? t('addressForm.defaultAddress')
                            : t('addressForm.setDefaultAddress')
                        }
                        register={register}
                        disabled={onlyView}
                        defaultValue={data?.main}
                      />
                    </Col>
                  </Row>
                </>
              </Box>
            </LoaderForm>
            {!onlyView ? (
              <CustomSubmit
                save={handleSubmit(handleSave)}
                saveAndExit={handleSubmit(handleSaveAndExit)}
                cancel={cancel}
                submitForm={submitForm}
              />
            ) : (
              ''
            )}
          </Form>
        </>
      </LoaderForm>
    </>
  );
};

export default FormTrainingsProvider;
