/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';
import { ProgressBar } from 'react-bootstrap';
import './animatedProgressBarLine.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  valueStart?: number;
  valueEnd?: number;
  duration?: number;
  maxValue?: number;
  noLimits?: boolean;
}

const AnimatedProgressBar = (props: Props): ReactElement => {
  const { valueStart, valueEnd, duration, maxValue, noLimits } = props;

  const returnLimitsBar = () => {
    if (noLimits) {
      return (
        <div className="CustomAnimatedPrograssBarLine noLimits">
          <ProgressBar />
        </div>
      );
    }
    return (
      <div className="CustomAnimatedPrograssBarLine">
        <Animate
          show
          start={{
            value: valueStart || 0,
          }}
          update={() => ({
            value: [valueEnd || 0],
            timing: {
              duration: duration || 300,
              ease: easeQuadInOut,
            },
          })}
          enter={() => ({
            value: [valueEnd || 0],
            timing: {
              duration: duration || 300,
              ease: easeQuadInOut,
            },
          })}
        >
          {({ value }) => {
            return <ProgressBar now={value} />;
          }}
        </Animate>
      </div>
    );
  };

  return returnLimitsBar();
};

export default AnimatedProgressBar;
