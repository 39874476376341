import {
  TrainingsGroupActivePlan,
  TrainingsGroupActivePlansRequest,
  TrainingsGroupTrainings,
  TrainingsGroupTrainingsRequest,
  TrainingsGroupTraining,
  TrainingsProviderSimple,
  TrainingsGroupTrainingAddPut,
  TrainingsProviderContact,
  TrainingsProviderContactRequest,
  TrainingsCompanyContact,
  TrainingsCompanyContactAddPut,
  TrainingsGroupEmployee,
  TrainingsGroupEmployeeRequest,
  TrainingsGroupUserTraining,
  TrainingsGroupEmployeePresenceScore,
  TrainingsProvider,
  LastEmailNotification,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';
import { NumberSchema } from 'yup';

const useGetActivePlansTrainings = (): [
  TrainingsGroupActivePlan | null | undefined,
  (userActivePlansTrainings: TrainingsGroupActivePlan) => void,
  (idRecord: number) => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    TrainingsGroupActivePlan | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const rData = await axios.get<TrainingsGroupActivePlan>(
        `${apiEndpoints.userPlansTrainings}/current-plan/verification`
      );

      setGetData(rData.data);
      return rData.data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsGroupActivePlan) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllTrainings = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: TrainingsGroupTrainingsRequest) => void,
  PagedData<TrainingsGroupTrainings> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<TrainingsGroupTrainings> | null>(null);

  const doGet = useCallback(
    (params: TrainingsGroupTrainingsRequest) => {
      axios
        .get<PagedData<TrainingsGroupTrainings>>(
          `${apiEndpoints.currentPlan}/current-plan/trainings`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetTraining = (props: {
  id: number;
  onError?: (error: AxiosError<Error>) => void;
}): [
  TrainingsGroupTrainings | null | undefined,
  (trainingsGroupTrainings: TrainingsGroupTrainings) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    TrainingsGroupTrainings | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsGroupTrainings>(
        `${apiEndpoints.currentPlan}/current-plan/trainings/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsGroupTrainings) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetAllTrainingsGroup = (
  id: number,
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<TrainingsGroupTraining> | null] => {
  const axios = useAxios();
  const [plans, setPlans] = useState<PagedData<TrainingsGroupTraining> | null>(
    null
  );

  const doGet = useCallback(() => {
    axios
      .get<PagedData<TrainingsGroupTraining>>(
        `${apiEndpoints.plansTrainings}/current-plan/trainings/${id}/groups`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useGetAllTrainingsProviderSimple = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, TrainingsProviderSimple | null] => {
  const axios = useAxios();
  const [
    trainingsProvider,
    setTrainingsProvider,
  ] = useState<TrainingsProviderSimple | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<TrainingsProviderSimple>(
        `${apiEndpoints.trainingsProvider}/providers/all`
      )
      .then(({ data }) => {
        setTrainingsProvider(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, trainingsProvider];
};

const useGetAllTrainingsProviderContact = (): [
  (params: { id: number }) => void,
  PagedData<TrainingsProviderContact> | null
] => {
  const axios = useAxios();

  const [
    trainingsProviderContact,
    setTrainingsProviderContact,
  ] = useState<PagedData<TrainingsProviderContact> | null>(null);

  const doGet = useCallback(
    async (params: { id: number }) => {
      const { id } = params;
      if (id !== undefined) {
        try {
          const rData = await axios.get<PagedData<TrainingsProviderContact>>(
            `${apiEndpoints.trainingsProvider}/providers/${id}/contacts`
          );
          setTrainingsProviderContact(rData.data);
        } catch (error) {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          // if (onError) onError(error);
        }
      } else {
        setTrainingsProviderContact(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios]
  );

  return [doGet, trainingsProviderContact];
};

const useAddTrainingsGroup = (
  onCompletedPost?: (data: TrainingsGroupTrainingAddPut) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsGroupTrainingAddPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (plansTrainings: Omit<TrainingsGroupTrainingAddPut, 'id'>) => {
      try {
        const rData = await axios.post<TrainingsGroupTrainingAddPut>(
          `${apiEndpoints.plansTrainings}/training-groups`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useGetTrainingsGroupGetGroup = (props: {
  id: number;
  onCompleted?: (data: TrainingsGroupTraining | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}): [
  TrainingsGroupTraining | null | undefined,
  (trainingsGroup: TrainingsGroupTraining) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    TrainingsGroupTraining | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsGroupTraining>(
        `${apiEndpoints.trainingsGroup}/training-groups/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsGroupTraining) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePutTrainingsGroupPutGroup = (
  onCompletedPut?: (data: TrainingsGroupTrainingAddPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsGroupTrainingAddPut) => Promise<void>) => {
  const axios = useAxios();

  const putPlansTrainings = useCallback(
    async (trainingsGroup: TrainingsGroupTrainingAddPut) => {
      try {
        const rData = await axios.put<TrainingsGroupTrainingAddPut>(
          `${apiEndpoints.trainings}/training-groups/${trainingsGroup.id}`,
          trainingsGroup
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putPlansTrainings;
};

const useGetTrainingsGroupGetEmployee = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: TrainingsGroupEmployeeRequest) => void,
  PagedData<TrainingsGroupEmployee> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<TrainingsGroupEmployee> | null>(null);

  const doGet = useCallback(
    (params: TrainingsGroupEmployeeRequest) => {
      const { id } = params;
      // eslint-disable-next-line no-param-reassign
      delete params.id;
      axios
        .get<PagedData<TrainingsGroupEmployee>>(
          `${apiEndpoints.currentPlan}/training-groups/${id}/employees`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetAllUserTrainingsGroup = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<TrainingsGroupUserTraining> | null] => {
  const axios = useAxios();
  const [
    plans,
    setPlans,
  ] = useState<PagedData<TrainingsGroupUserTraining> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<TrainingsGroupUserTraining>>(
        `${apiEndpoints.currentPlan}/current-plan/user/trainings`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useGetAllUserTrainingsGroupSingle = (
  id: number,
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<TrainingsGroupUserTraining> | null] => {
  const axios = useAxios();
  const [
    plans,
    setPlans,
  ] = useState<PagedData<TrainingsGroupUserTraining> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<TrainingsGroupUserTraining>>(
        `${apiEndpoints.currentPlan}/current-plan/user/trainings/${id}`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useGetAllUserTrainingsGroupAvailableGroup = (
  id: number,
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<TrainingsGroupTraining> | null] => {
  const axios = useAxios();
  const [plans, setPlans] = useState<PagedData<TrainingsGroupTraining> | null>(
    null
  );

  const doGet = useCallback(() => {
    axios
      .get<PagedData<TrainingsGroupTraining>>(
        `${apiEndpoints.currentPlan}/current-plan/trainings/${id}/groups`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useTrainingsGroupUserPostSelectGroup = (
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (idGroup: number) => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/user`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useTrainingsGroupUserDeleteSelectGroup = (
  onCompletedPost?: (idGroupChange: number | undefined) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: number, arg1: number | undefined) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (idGroup: number, idGroupChange: number | undefined) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/user`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(idGroupChange);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useGetTrainingsGroupGetAvailableEmployee = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: TrainingsGroupEmployeeRequest) => void,
  PagedData<TrainingsGroupEmployee> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<TrainingsGroupEmployee> | null>(null);

  const doGet = useCallback(
    (params: TrainingsGroupEmployeeRequest) => {
      const { id } = params;
      // eslint-disable-next-line no-param-reassign
      delete params.id;
      axios
        .get<PagedData<TrainingsGroupEmployee>>(
          `${apiEndpoints.currentPlan}/training-groups/${id}/available-employees`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useDeleteEmployeeFromGroup = (
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: number, arg1: number) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (idGroup: number, idEmployee: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/employees/${idEmployee}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useRegisterEmployeeToGroup = (
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: number, arg1: number) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (idGroup: number, idEmployee: number) => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/employees/${idEmployee}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const usePutEmployeePresenceAndScore = (
  idGroup: number,
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsGroupEmployeePresenceScore) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (presenceScore: TrainingsGroupEmployeePresenceScore) => {
      try {
        const rData = await axios.put(
          `${apiEndpoints.catalogsTrainings}/training-groups/${idGroup}/employees`,
          presenceScore
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useGetCurrentPlanTest = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [
  TrainingsGroupActivePlan | null | undefined,
  (trainingsGroupTrainings: TrainingsGroupActivePlan) => void,
  (idRecord: number) => void
] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    TrainingsGroupActivePlan | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsGroupActivePlan>(
        `${apiEndpoints.currentPlan}/current-plan/status`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsGroupActivePlan) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useManagerGetAllUserTrainingsGroup = (
  onError?: (error: AxiosError<Error>) => void
): [
  (idEmployee: number) => void,
  PagedData<TrainingsGroupUserTraining> | null
] => {
  const axios = useAxios();
  const [
    plans,
    setPlans,
  ] = useState<PagedData<TrainingsGroupUserTraining> | null>(null);

  const doGet = useCallback(
    (idEmployee: number) => {
      axios
        .get<PagedData<TrainingsGroupUserTraining>>(
          `${apiEndpoints.currentPlan}/current-plan/employees/${idEmployee}/trainings`
        )
        .then(({ data }) => {
          setPlans(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, plans];
};

const useManagerGetAllUserTrainingsGroupSingle = (
  id: number,
  trainingId: number,
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<TrainingsGroupUserTraining> | null] => {
  const axios = useAxios();
  const [
    plans,
    setPlans,
  ] = useState<PagedData<TrainingsGroupUserTraining> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<TrainingsGroupUserTraining>>(
        `${apiEndpoints.currentPlan}/current-plan/employees/${id}/trainings/${trainingId}`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useManagerTrainingsGroupUserPostSelectGroup = (
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: number, arg1: number) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (idGroup: number, idEmployee: number) => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/employees/${idEmployee}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useManagerTrainingsGroupUserDeleteSelectGroup = (
  onCompletedPost?: (idGroupChange: number | undefined) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((
  arg0: number,
  arg1: number,
  arg2: number | undefined
) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (
      idGroup: number,
      idEmployee: number,
      idGroupChange: number | undefined
    ) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.plansTrainings}/training-groups/${idGroup}/employees/${idEmployee}`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(idGroupChange);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useGetSingleTrainingsProviderContact = (): [
  (params: { id: number; idContact: number }) => void,
  TrainingsProviderContact | null
] => {
  const axios = useAxios();

  const [
    trainingsProviderContact,
    setTrainingsProviderContact,
  ] = useState<TrainingsProviderContact | null>(null);

  const doGet = useCallback(
    async (params: { id: number; idContact: number }) => {
      const { id, idContact } = params;
      if (id !== undefined) {
        try {
          const rData = await axios.get<TrainingsProviderContact>(
            `${apiEndpoints.trainingsProvider}/providers/${id}/contacts/${idContact}`
          );
          setTrainingsProviderContact(rData.data);
        } catch (error) {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          // if (onError) onError(error);
        }
      } else {
        setTrainingsProviderContact(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios]
  );

  return [doGet, trainingsProviderContact];
};

const useGetSingleTrainingsProvider = (): [
  (params: { id: number }) => void,
  TrainingsProvider | null
] => {
  const axios = useAxios();

  const [
    trainingsProvider,
    setTrainingsProvider,
  ] = useState<TrainingsProvider | null>(null);

  const doGet = useCallback(
    async (params: { id: number }) => {
      const { id } = params;
      if (id !== undefined) {
        try {
          const rData = await axios.get<TrainingsProvider>(
            `${apiEndpoints.trainingsProvider}/providers/${id}`
          );
          setTrainingsProvider(rData.data);
        } catch (error) {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          // if (onError) onError(error);
        }
      } else {
        setTrainingsProvider(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios]
  );

  return [doGet, trainingsProvider];
};

const useGetSingleCompanyContact = (): [
  (params: { id: number }) => void,
  TrainingsCompanyContact | null
] => {
  const axios = useAxios();

  const [
    companyContact,
    setCompanyContact,
  ] = useState<TrainingsCompanyContact | null>(null);

  const doGet = useCallback(
    async (params: { id: number }) => {
      const { id } = params;
      if (id !== undefined) {
        try {
          const rData = await axios.get<TrainingsCompanyContact>(
            `${apiEndpoints.trainingsProvider}/company-contacts/${id}`
          );
          setCompanyContact(rData.data);
        } catch (error) {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          // if (onError) onError(error);
        }
      } else {
        setCompanyContact(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios]
  );

  return [doGet, companyContact];
};

const useGetLastSendNotification = (): [
  LastEmailNotification | null | undefined,
  () => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    LastEmailNotification | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const rData = await axios.get<LastEmailNotification>(
        `${apiEndpoints.userPlansTrainings}/current-plan/training-groups/notification-date`
      );

      setGetData(rData.data);
      return rData.data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [getData, fetchData];
};

const useSendNotification = (
  onCompletedPost?: () => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): (() => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async () => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.currentPlan}/current-plan/training-groups/send-notification`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost();
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

export default {
  useGetActivePlansTrainings,
  useGetAllTrainings,
  useGetTraining,
  useGetAllTrainingsGroup,
  useGetAllTrainingsProviderSimple,
  useAddTrainingsGroup,
  useGetAllTrainingsProviderContact,
  useGetTrainingsGroupGetGroup,
  usePutTrainingsGroupPutGroup,
  useGetTrainingsGroupGetEmployee,
  useGetAllUserTrainingsGroup,
  useGetAllUserTrainingsGroupSingle,
  useGetAllUserTrainingsGroupAvailableGroup,
  useTrainingsGroupUserPostSelectGroup,
  useTrainingsGroupUserDeleteSelectGroup,
  useGetTrainingsGroupGetAvailableEmployee,
  useDeleteEmployeeFromGroup,
  useRegisterEmployeeToGroup,
  usePutEmployeePresenceAndScore,
  useGetCurrentPlanTest,
  useManagerGetAllUserTrainingsGroup,
  useManagerGetAllUserTrainingsGroupSingle,
  useManagerTrainingsGroupUserPostSelectGroup,
  useManagerTrainingsGroupUserDeleteSelectGroup,
  useGetSingleTrainingsProviderContact,
  useGetSingleTrainingsProvider,
  useGetSingleCompanyContact,
  useGetLastSendNotification,
  useSendNotification,
};
