import React, { ReactElement } from 'react';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import routes from 'routes';
import { useKeycloak } from '@react-keycloak/web';

interface Props {
  children: ReactElement;
  requiredRoles: string[];
  notRequiredShow?: boolean;
}

const AuthResources = ({ children, requiredRoles, notRequiredShow }: Props) => {
  const { keycloak } = useKeycloak();

  const mockRole = [
    'none',
    'user',
    'manager',
    'training_department',
    'admin_phr',
    'hr_bp',
    'slt_department',
    'second-level-manager',
  ];
  const isAuthorized = (roles) => {
    if (keycloak && roles) {
      return roles.some((role) => {
        return keycloak.hasRealmRole(role);
        // return mockRole.includes(role);
      });
    }
    return false;
  };

  return isAuthorized(requiredRoles) ? (
    <>{notRequiredShow === undefined ? children : ''}</>
  ) : (
    <>{notRequiredShow ? children : ''} </>
  );
};

export default AuthResources;
