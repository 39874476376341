/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';
import { Switch } from 'components';
import './TableCellSwitchSingle.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  dataId: string;
  id: string;
  justify?: 'toLeft' | 'center' | 'toRight';
  selectedData?: any;
  handleChangeSelect: any;
  edit?: boolean;
  defaultValueTrue?: boolean;
}

const TableCellSwitchSingle = (props: Props): ReactElement => {
  const {
    dataId,
    justify,
    id,
    selectedData,
    handleChangeSelect,
    edit,
    defaultValueTrue,
  } = props;

  const [checked, setChecked] = useState<boolean>(
    selectedData || defaultValueTrue || false
  );

  const handleChangeSwitch = () => {
    setChecked(!checked);
    handleChangeSelect(dataId, !checked);
  };

  return (
    <>
      {dataId ? (
        <div
          className={`tableCellBlockSwitch ${
            justify !== undefined ? justify : 'toLeft'
          }`}
        >
          <Switch
            id={`${id}-${dataId}`}
            onChange={handleChangeSwitch}
            name={`selected-${dataId}`}
            checked={checked}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellSwitchSingle;
