import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { IDPRepository } from 'api/repository';
import { IDPEmployeeAreaForm, IDPEmployeeAreaPostAndPut } from 'api/types';
import { Modal } from 'components';
import FormIDPEmployeeArea from '../../../Form/IDPEmployeeArea';

interface CompetencesProps {
  idEmployee: number;
  idIdp: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
  modalId: number;
  lastEdit?: boolean;
}
const IDPEmployeeAreaEdit = (props: CompetencesProps): ReactElement => {
  const {
    idEmployee,
    idIdp,
    changeModalEdit,
    changeUpdateData,
    modalId,
    lastEdit,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [IDPEmployeeArea] = IDPRepository.useGetIDPEmployeeAreaAdmin({
    idEmployee: Number(idEmployee),
    idIdp,
    idArea: modalId,
  });

  const postDataSave = IDPRepository.usePutIDPEmployeeAreaAdmin(
    idEmployee,
    idIdp,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeArea = (dataPost: IDPEmployeeAreaForm) => {
    const dataToPost: IDPEmployeeAreaPostAndPut = {
      name: dataPost.name,
      activities: {
        '70': {
          name: dataPost.name70,
          measure: dataPost.measure70,
          progress: dataPost.progress70,
          term: dataPost.term70,
          comment: dataPost.comment70,
        },
        '20': {
          name: dataPost.name20,
          measure: dataPost.measure20,
          progress: dataPost.progress20,
          term: dataPost.term20,
          comment: dataPost.comment20,
        },
        '10': {
          name: dataPost.name10,
          measure: dataPost.measure10,
          progress: dataPost.progress10,
          term: dataPost.term10,
          comment: dataPost.comment10,
        },
      },
    };
    setSubmitForm(true);
    postDataSave(dataToPost);
  };

  const postDataSaveAndExit = IDPRepository.usePutIDPEmployeeAreaAdmin(
    idEmployee,
    idIdp,
    modalId,
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddIDPEmployeeAreaAndExit = (dataPost: IDPEmployeeAreaForm) => {
    const dataToPost: IDPEmployeeAreaPostAndPut = {
      name: dataPost.name,
      activities: {
        '70': {
          name: dataPost.name70,
          measure: dataPost.measure70,
          progress: dataPost.progress70,
          term: dataPost.term70,
          comment: dataPost.comment70,
        },
        '20': {
          name: dataPost.name20,
          measure: dataPost.measure20,
          progress: dataPost.progress20,
          term: dataPost.term20,
          comment: dataPost.comment20,
        },
        '10': {
          name: dataPost.name10,
          measure: dataPost.measure10,
          progress: dataPost.progress10,
          term: dataPost.term10,
          comment: dataPost.comment10,
        },
      },
    };
    setSubmitForm(true);
    postDataSaveAndExit(dataToPost);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal
      open
      changeModalShow={changeModalEdit}
      widthBox="high"
      maxLevel="lvl2"
    >
      <div>
        <FormIDPEmployeeArea
          onSave={handleAddIDPEmployeeArea}
          onSaveAndExit={handleAddIDPEmployeeAreaAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          edit
          data={IDPEmployeeArea}
          lastEdit={lastEdit}
        />
      </div>
    </Modal>
  );
};

export default IDPEmployeeAreaEdit;
