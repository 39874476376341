/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import { IDPFeedbackModel } from 'api/types';
import {
  Box,
  ContentBox,
  LoaderForm,
  Modal,
  RenderSvg,
  ShowTextarea,
} from 'components';
import EmployeeAppraisalShow from '../EmployeeAppraisalShow';
import './appraisalModalSelect.scss';

interface Props {
  employeeId: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  employeeName?: string;
}

const IDPModalSelect = (props: Props): ReactElement => {
  const { employeeId, changeModalEdit, employeeName } = props;

  const [data] = EmployeeAppraisalRepository.useGetEvaluationEmployeeAdmin({
    employeeId,
  });

  const cancel = () => {
    changeModalEdit(false);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectEvaluation, setSelectEvaluation] = useState<number | undefined>(
    undefined
  );
  const [selectYear, setSelectYear] = useState<string | undefined>(undefined);

  return (
    <Modal open changeModalShow={cancel} widthBox="low">
      <>
        <Box
          id="employeeEvaluationSelectYear"
          name="Ocena pracownika z roku"
          noMarginBottom
        >
          <>
            {data && Object.keys(data).length > 0 ? (
              Object.keys(data).map((k) => {
                return (
                  <div
                    id={`yearToSelect-${data?.[k]?.year}`}
                    className="yearToSelect"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setSelectEvaluation(data?.[k]?.id);
                      setSelectYear(data?.[k]?.year);
                      setShowModal(true);
                    }}
                  >
                    {data?.[k]?.year}
                  </div>
                );
              })
            ) : (
              <div className="noEvaluationList">
                Brak wprowadzonych ocen pracownika
              </div>
            )}
          </>
        </Box>
        {showModal && selectEvaluation ? (
          <Modal
            open
            changeModalShow={setShowModal}
            widthBox="high"
            maxLevel="lvl2"
          >
            <EmployeeAppraisalShow
              idEvaluation={selectEvaluation}
              idEmployee={employeeId}
              selectedYear={selectYear}
              employeeName={employeeName}
            />
          </Modal>
        ) : (
          ''
        )}
      </>
    </Modal>
  );
};

export default IDPModalSelect;
