import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';

import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TrainingsAddAndPut, Training } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CompetencesRepository,
  TrainingsProviderRepository,
} from 'api/repository';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  EditorMce,
  CustomSelect,
  CustomMultiSelect,
  CustomSwitch,
} from 'components';
import TrainingsType from '../../../../../constants/Trainings';
import ModalCompetencesAdd from '../../Competences/ModalCompetencesAdd';
import TrainingsProviderAddModal from '../../../TrainingsProvider/TrainingsProviderAddModal';

type TrainingsValue = Omit<TrainingsAddAndPut, 'id'>;

interface Props {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: Training | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormTrainings = (props: Props): ReactElement => {
  const { t } = useTranslation('trainings');
  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [modalCompetencesAdd, setModalCompetencesAdd] = useState<boolean>(
    false
  );
  const [updateCompetences, setUpdateCompetences] = useState<number>(0);

  const [
    getAllCompetences,
    dataAllCompetences,
  ] = CompetencesRepository.useGetAllCompetencesSimple();

  useEffect(() => {
    getAllCompetences();
  }, [getAllCompetences, updateCompetences]);

  const competencesOptions = () => {
    const arrayCompetenecesOption = [] as any;
    arrayCompetenecesOption.push({
      value: 'new',
      label: t('trainingsForm.addNewCompetences'),
    });
    if (dataAllCompetences !== null && dataAllCompetences.content.length > 0) {
      Object.keys(dataAllCompetences.content).forEach((key) => {
        arrayCompetenecesOption.push({
          value: dataAllCompetences.content[key].id,
          label: dataAllCompetences.content[key].name,
        });
      });
    }
    return arrayCompetenecesOption;
  };

  const onNewCompetences = () => {
    setModalCompetencesAdd(true);
  };

  const [modalProvidersAdd, setModalProvidersAdd] = useState<boolean>(false);
  const [updateProviders, setUpdateProviders] = useState<number>(0);

  const [
    getAllProviders,
    dataAllProviders,
  ] = TrainingsProviderRepository.useGetAllTrainingsProviderSimple();

  useEffect(() => {
    getAllProviders();
  }, [getAllProviders, updateProviders]);

  const providersOptions = () => {
    const arrayProvidersOption = [] as any;
    arrayProvidersOption.push({
      value: 'new',
      label: t('trainingsForm.addNewProviders'),
    });
    if (dataAllProviders !== null && dataAllProviders.content.length > 0) {
      Object.keys(dataAllProviders.content).forEach((key) => {
        arrayProvidersOption.push({
          value: dataAllProviders.content[key].id,
          label: dataAllProviders.content[key].name,
        });
      });
    }
    return arrayProvidersOption;
  };

  const onNewTrainignsProviders = () => {
    setModalProvidersAdd(true);
  };

  const typeOptions = () => {
    const arrayTypeOption = [] as any;
    Object.keys(TrainingsType).forEach((key) => {
      arrayTypeOption.push({
        value: TrainingsType[key],
        label: t(`trainingsTypeEnum.${TrainingsType[key]}`),
      });
    });
    return arrayTypeOption;
  };

  const formSchema: yup.SchemaOf<TrainingsValue> = yup.object().shape({
    name: yup.string().required(t('trainingsForm.nameMessage')),
    program: yup.string().notRequired(),
    providerId: yup.number().notRequired(),
    purpose: yup.string().notRequired(),
    type: yup.string().required(t('trainingsForm.typeMessage')),
    /* type: yup
      .mixed()
      .oneOf(Object.values(typeOptions()))
      .required(t('trainingsForm.typeMessage')), */
    sltAcceptanceRequired: yup.bool().notRequired(),
    competences: yup.array().of(yup.number().notRequired()).notRequired(),
  });

  const handleSave = (dataSubmit: TrainingsValue) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: TrainingsValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  const { handleSubmit, register, errors, setValue } = useForm<TrainingsValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <>
          <LoaderForm active={data === undefined && edit} type="getData">
            <>
              <ExitFromForm changeForm={changeForm} />
              <Form
                className="formTrainings"
                onChange={(e) => {
                  setChangeForm(true);
                }}
              >
                <LoaderForm active={submitForm} type="saving">
                  <>
                    <Box
                      id="formTrainingsBox"
                      name={
                        edit
                          ? t('trainingsForm.boxNameEdit')
                          : t('trainingsForm.boxNameAdd')
                      }
                      svgIcon="addEdit"
                      noMarginBottom
                      noShadow
                    >
                      <>
                        <Row className="justify-content-md-center">
                          <Col>
                            <CustomInput
                              name="name"
                              label={t('trainingsForm.name')}
                              register={register}
                              defaultValue={data?.name}
                              error={errors?.name?.message}
                              required
                              disabled={onlyView}
                              fullWidth
                              placeholder={t('trainingsForm.namePlaceholder')}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <EditorMce
                              id="purpose"
                              name="purpose"
                              register={register}
                              contentGet={data?.purpose}
                              error={errors?.purpose?.message}
                              onlyView={onlyView}
                              setChangeForm={setChangeForm}
                              label={t('trainingsForm.purpose')}
                              placeholder={t(
                                'trainingsForm.purposePlaceholder'
                              )}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <EditorMce
                              id="program"
                              name="program"
                              register={register}
                              contentGet={data?.program}
                              error={errors?.program?.message}
                              onlyView={onlyView}
                              setChangeForm={setChangeForm}
                              label={t('trainingsForm.program')}
                              placeholder={t(
                                'trainingsForm.programPlaceholder'
                              )}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <CustomSelect
                              options={providersOptions()}
                              name="providerId"
                              valueByKey={data?.provider?.id}
                              error={errors?.providerId?.message}
                              onlyView={onlyView}
                              label={t('trainingsForm.providerId')}
                              placeholder={t(
                                'trainingsForm.providerIdPlaceholder'
                              )}
                              setValue={setValue}
                              isSearchable
                              onNewRecord={onNewTrainignsProviders}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <CustomSelect
                              options={typeOptions()}
                              name="type"
                              valueByKey={data?.type}
                              error={errors?.type?.message}
                              onlyView={onlyView}
                              label={t('trainingsForm.type')}
                              placeholder={t('trainingsForm.typePlaceholder')}
                              setValue={setValue}
                              isSearchable
                              required
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <CustomMultiSelect
                              options={competencesOptions()}
                              name="competences"
                              valueById={data?.competences}
                              onlyView={onlyView}
                              label={t('trainingsForm.competences')}
                              placeholder={t(
                                'trainingsForm.competencesPlaceholder'
                              )}
                              setValue={setValue}
                              isSearchable
                              onNewRecord={onNewCompetences}
                            />
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                          <Col>
                            <CustomSwitch
                              label={t('trainingsForm.sltAcceptanceRequired')}
                              name="sltAcceptanceRequired"
                              id="sltAcceptanceRequired"
                              defaultValue={data?.sltAcceptanceRequired}
                              register={register}
                            />
                          </Col>
                        </Row>
                      </>
                    </Box>
                  </>
                </LoaderForm>
                {!onlyView ? (
                  <CustomSubmit
                    save={onSave ? handleSubmit(handleSave) : undefined}
                    saveAndExit={handleSubmit(handleSaveAndExit)}
                    cancel={cancel}
                    submitForm={submitForm}
                  />
                ) : (
                  ''
                )}
              </Form>
            </>
          </LoaderForm>
          {modalCompetencesAdd ? (
            <ModalCompetencesAdd
              changeModalAdd={setModalCompetencesAdd}
              changeUpdateTableData={setUpdateCompetences}
            />
          ) : (
            ''
          )}
          {modalProvidersAdd ? (
            <TrainingsProviderAddModal
              changeModalAdd={setModalProvidersAdd}
              changeUpdateTableData={setUpdateProviders}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  );
};

export default FormTrainings;
