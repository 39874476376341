import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './CustomInput.scss';
import { RenderSvg } from 'components';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface SwitchProps {
  name: string;
  label?: string;
  register?: (Ref, RegisterOptions?) => void;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  error?: string;
  defaultValue?: string;
  placeholder?: string;
  info?: string;
  maxLength?: number;
  fullWidth?: boolean;
  toLeft?: boolean;
  smallRadius?: boolean;
  setValueWhenDisable?: any;
}

const CustomInput = (props: SwitchProps): ReactElement => {
  const {
    name,
    label,
    register,
    required,
    id,
    disabled,
    error,
    defaultValue,
    placeholder,
    info,
    maxLength,
    fullWidth,
    toLeft,
    smallRadius,
    setValueWhenDisable,
  } = props;

  const [data, setData] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (setValueWhenDisable && disabled) {
      if (data !== undefined) {
        setValueWhenDisable(name, data);
      } else {
        setValueWhenDisable(name, '');
      }
    }
  }, [data, disabled, name, setValueWhenDisable]);

  useEffect(() => {
    if (defaultValue !== undefined && data === undefined) {
      setData(defaultValue);
    }
  }, [defaultValue]);

  const idElement = () => {
    let tId = '';
    if (id !== undefined) {
      tId += id;
    } else {
      tId += name;
    }
    return tId;
  };

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let tclass = 'blockInputWithError';
    if (required) {
      tclass += ' required';
    }
    if (typeof error !== 'undefined') {
      tclass += ' error';
    }
    if (typeof info !== 'undefined' && !disabled) {
      tclass += ' withInfo';
    }
    if (disabled) {
      tclass += ' disabled';
    }
    if (fullWidth) {
      tclass += ' fullWidth';
    }
    if (error) {
      tclass += ' error';
    }
    if (toLeft) {
      tclass += ' toLeft';
    }

    if (smallRadius) {
      tclass += ' smallRadius';
    }

    return tclass;
  };

  const infoElement = () => {
    if (typeof info !== 'undefined' && !disabled) {
      return <RenderSvg nameSvg="form-info" />;
    }
    return null;
  };

  return (
    <>
      <div id={`${idElement()}-contentbox`} className={additionalClass()}>
        <div id={`${idElement()}-blockInput`} className="blockInput">
          <Form.Label id={name}>{label}</Form.Label>
          <div className="blockInputWithInfo">
            <Form.Control
              name={name}
              id={name}
              ref={
                setValueWhenDisable !== undefined && disabled === true
                  ? undefined
                  : register
              }
              placeholder={placeholder}
              defaultValue={defaultValue}
              disabled={disabled}
              maxLength={maxLength || 255}
              onChange={(val) => setData(val.target.value)}
            />
            {infoElement()}
          </div>
        </div>
        <div className="additionalInfo">{errorElement()}</div>
      </div>
    </>
  );
};

export default CustomInput;
