import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingsGroupTraining } from 'api/types';
import { ActionInFolder, RenderSvg } from 'components';

import './groupItem.scss';

interface Props {
  id: string;
  data: TrainingsGroupTraining;
  showClick?: (dataId: number) => void;
  editClick?: (dataId: number) => void;
  archiveClick?: (dataId: number) => void;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data, showClick, editClick, archiveClick } = props;
  const { t } = useTranslation('plansTrainings');

  return (
    <div className="TrainingsGroupItem" id={`${id}-${data.id}`}>
      <div className="accent" />
      <div className="name">{data.name}</div>
      <div className="datesPlan">
        <div className="icon">
          <RenderSvg nameSvg="plansIcon" />
        </div>

        {data.startDate !== null ? (
          <div className="dates">
            <div className="startDateBox">
              Od:{' '}
              <span className="startDate">
                {data.startDate.substring(0, 10)}
              </span>
            </div>
            <div className="endDateBox">
              Do:{' '}
              <span className="endDate">{data.endDate.substring(0, 10)}</span>
            </div>
          </div>
        ) : (
          <div className="singleDate">
            <div className="singleDateBox">
              {data.singleDate.substring(0, 10)}
            </div>
          </div>
        )}
      </div>
      <div className="minPeopleBox">
        <div className="minPeopleLabel">Minimalna liczba osób:</div>
        <div className="minPeopleValue">{data.minPeople}</div>
      </div>
      <div className="maxPeopleBox">
        <div className="maxPeopleLabel">Maksymalna liczba osób:</div>
        <div className="maxPeopleValue">{data.maxPeople}</div>
      </div>
      <div className="action">
        <div className="verificationDateBox">
          <div className="freeSpaceLabel">Wolne miejsca:</div>
          <div className="freeSpaceData">
            {data.maxPeople - data.registrationsCount}
          </div>
        </div>
        <ActionInFolder
          id="actionTrainingsGroupItem"
          dataId={data.id}
          showClick={showClick}
          editClick={editClick}
          archiveClick={archiveClick}
        />
      </div>
    </div>
  );
};

export default ContentBox;
