import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { CatalogsTrainingsRepository } from 'api/repository';
import { CatalogsTrainingsAddAndPut } from 'api/types';
import { Modal } from 'components';
import FormCatalogsTrainings from '../FormCatalogsTrainings';

interface CompetencesProps {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const CompetencesAdd = (props: CompetencesProps): ReactElement => {
  const {
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = CatalogsTrainingsRepository.useAddCatalogsTrainings(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
      if (changeModalId) {
        changeModalId(data.id);
      }
      if (changeModalEdit) {
        changeModalEdit(true);
      }
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: CatalogsTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    postDataSave(dataPost);
  };

  const postDataSaveAndExit = CatalogsTrainingsRepository.useAddCatalogsTrainings(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContactAndExit = (
    dataPost: CatalogsTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd} widthBox="medium">
      <div>
        <FormCatalogsTrainings
          onSave={handleAddTrainingsProviderContact}
          onSaveAndExit={handleAddTrainingsProviderContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default CompetencesAdd;
