import React, { ReactElement, SetStateAction, Dispatch } from 'react';
import { PlansTrainingsRepository } from 'api/repository';
import { Modal, ShowBox, ShowShortName, RenderSvg } from 'components';
import { useTranslation } from 'react-i18next';
import TableTrainings from './TableTrainings';

import './plansTrainingsShow.scss';

interface Props {
  dataId: number;
  changeShow: Dispatch<SetStateAction<boolean>>;
}

const PlansTrainingsShow = (props: Props): ReactElement => {
  const { dataId, changeShow } = props;
  const { t } = useTranslation(['plansTrainings', 'trainings']);

  const [plan] = PlansTrainingsRepository.useGetPlanTrainings({
    id: Number(dataId),
  });

  return (
    <>
      <Modal open changeModalShow={changeShow}>
        <ShowBox
          id="showPlansTrainingsBox"
          name={t('showPlans.boxName')}
          noPadding
        >
          <>
            <div id="titleBox" className="titleBox">
              <ShowShortName id="namePlansTrainings" data={plan?.name} />
            </div>
            <div id="dateBox" className="dateBox">
              <div className="left">
                <div id="frameDate" className="dateContent">
                  <div id="frameDateTitle" className="title">
                    {t('showPlans.frameDate')}
                  </div>
                  <div id="frameDateDetail" className="content">
                    <div id="frameDatesIcon" className="icon">
                      <RenderSvg nameSvg="frameDate" />
                    </div>
                    <div id="frameDateBoxDate" className="dateDetail">
                      <div id="frameDateStart">
                        {t('showPlans.from')}
                        <span className="start">{plan?.startDate}</span>
                      </div>
                      <div id="frameDateEnd">
                        {t('showPlans.to')}
                        <span className="end">{plan?.endDate}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="frameRegistrationDate" className="dateContent">
                  <div id="frameRegistrationDateTitle" className="title">
                    {t('showPlans.frameRegisterDate')}
                  </div>
                  <div id="frameRegistrationDateDetail" className="content">
                    <div id="frameRegistrationDateIcon" className="icon">
                      <RenderSvg nameSvg="frameRegistrationDate" />
                    </div>
                    <div
                      id="frameRegistrationDateBoxDate"
                      className="dateDetail"
                    >
                      <div id="frameRegistrationDateStart">
                        {t('showPlans.from')}
                        <span className="start">
                          {plan?.registrationStartDate}
                        </span>
                      </div>
                      <div id="frameRegistrationDateEnd">
                        {t('showPlans.to')}
                        <span className="end">{plan?.registrationEndDate}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div id="validationDate" className="dateContent">
                  <div id="validationDateTitle" className="title">
                    {t('showPlans.validDate')}
                  </div>
                  <div id="validationDateDetail" className="content">
                    <div id="validationDateIcon" className="icon">
                      <RenderSvg nameSvg="dateValidationDate" />
                    </div>
                    <div id="validationDateBoxDate" className="dateDetail">
                      <div id="validationDateEnd">
                        <span className="end">{plan?.verificationDate}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="limitBox">
              <div className="limitTitle">
                {t('plansForm.limitTypesTraining')}
              </div>
              <div className="limitContent">
                <div className="detail">
                  <div className="label">{t('plansForm.internalLimit')}</div>
                  <div className="value">
                    {plan?.limits.INTERNAL
                      ? plan.limits.INTERNAL
                      : 'Bez limitu'}
                  </div>
                </div>
                <div className="detail">
                  <div className="label">{t('plansForm.externalLimit')}</div>
                  <div className="value">
                    {plan?.limits.EXTERNAL
                      ? plan.limits.EXTERNAL
                      : 'Bez limitu'}
                  </div>
                </div>
                <div className="detail">
                  <div className="label">{t('plansForm.languageLimit')}</div>
                  <div className="value">
                    {plan?.limits.LANGUAGE
                      ? plan.limits.LANGUAGE
                      : 'Bez limitu'}
                  </div>
                </div>
                <div className="detail">
                  <div className="label">{t('plansForm.corporateLimit')}</div>
                  <div className="value">
                    {plan?.limits.CORPORATE
                      ? plan.limits.CORPORATE
                      : 'Bez limitu'}
                  </div>
                </div>
                <div className="detail">
                  <div className="label">{t('plansForm.studiesLimit')}</div>
                  <div className="value">
                    {plan?.limits.STUDIES
                      ? plan.limits.STUDIES
                      : t('showPlans.noLimits')}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TableTrainings id={dataId} />
            </div>
          </>
        </ShowBox>
      </Modal>
    </>
  );
};

export default PlansTrainingsShow;
