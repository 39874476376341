import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContentBox,
  CustomFilterData,
  CustomFilterLastFirstName,
  CustomFilterText,
  CustomHeaderFormatter,
  CustomSort,
  CustomSortData,
  LoaderTable,
  ShowTitleTable,
  TableCellLastFirstName,
  TableCellListToCell,
  TablePagination,
  TableRecordAction,
} from 'components';
import { EmployeeAppraisalRepository, IDPRepository } from 'api/repository';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { customFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import { EmployeeModel } from 'api/types';
import AppraisalModalSelect from '../../AppraisalModalSelect';
import AppraisalModalEditSelect from '../../AppraisalModalEditSelect';

const TableTrainingsBlock = (): ReactElement => {
  const [page, setPage] = React.useState(1);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  // variable search
  const [filterReq, setFilterReq] = useState<{ [key: string]: string }>({});
  const mapSearch: { [key: string]: string } = {
    firstName: 'firstName',
    lastName: 'lastName',
    locations: 'location',
    positions: 'position',
    segments: 'segment',
  };
  // End variable search

  // variable order
  const [orderColumn, setOrderColumn] = useState<string>(
    JSON.stringify({
      'row.lastName': 'default',
      order: 'asc',
    })
  );
  const mapOrder: { [key: string]: string } = {
    'row.lastName': 'lastName',
    id: 'ID',
  };
  // end variable order
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [
    getData,
    data,
  ] = EmployeeAppraisalRepository.useGetApprasialAllEmployeeAdmin();

  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const [modalEdit, setModalEdit] = useState<boolean>(false);

  const [employeeName, setEmployeeName] = useState<string | undefined>(
    undefined
  );

  function handleShowClick(id: number) {
    setModalId(id);
    setModalShow(true);
  }

  function handleEditClick(id: number) {
    setModalId(id);
    setModalEdit(true);
  }

  useEffect(() => {
    setLoaderTable(true);
    const paramQuery = {
      pageNumber: page - 1,
      pageSize: rowsPerPage,
    };
    Object.keys(filterReq).forEach((key) => {
      if (filterReq[key] !== '') {
        Object.assign(paramQuery, { [key]: filterReq[key] });
      }
    });
    const getOrderColumn = JSON.parse(orderColumn);
    if (getOrderColumn.name !== 'default') {
      Object.assign(paramQuery, { sort: getOrderColumn.name });
    }
    if (getOrderColumn.order === 'asc') {
      Object.assign(paramQuery, { ascending: true });
    } else {
      Object.assign(paramQuery, { ascending: false });
    }

    getData(paramQuery);
  }, [getData, page, rowsPerPage, filterReq, orderColumn, updateDataTable]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  const dataCustomFilterData = (objSearch: { [key: string]: string }) => {
    CustomFilterData({
      filterReq,
      objSearch,
      mapSearch,
      setFilterReq,
      setUpdateDataTable,
      setPage,
    });
  };

  const onTableChange = (type: any, newState: any) => {
    if (type === 'sort') {
      CustomSortData({
        orderColumn,
        newState,
        mapOrder,
        setOrderColumn,
        setUpdateDataTable,
      });
    }
  };

  const columns = [
    {
      dataField: '',
      text: '',
      headerClasses: 'firstCellAction',
      formatter: (
        cell: any,
        row: EmployeeModel,
        rowIndex: any,
        formatExtraData: any
      ) => {
        return (
          <TableRecordAction
            dataId={row.id}
            showClick={() => {
              setEmployeeName(`${row.lastName} ${row.firstName}`);
              handleShowClick(row.id);
            }}
            editClick={() => {
              setEmployeeName(`${row.lastName} ${row.firstName}`);
              handleEditClick(row.id);
            }}
            rowNumber={rowIndex}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        );
      },
    },
    {
      dataField: 'row.lastName',
      text: 'Nazwisko i imię',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      sortOrder: 'asc',
      sort: true,
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterLastFirstName
          customFilter={dataCustomFilterData}
          column={column}
        />
      ),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return (
          <TableCellLastFirstName
            id={row.id}
            first={row.firstName}
            last={row.lastName}
          />
        );
      },
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
            moveOverAction
          />
        );
      },
    },
    {
      dataField: 'locations',
      text: 'Lokalizacja',
      sortCaret: (order: any, column: any) => <CustomSort order={order} />,
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell noObject data={row?.locations} />;
      },
    },
    {
      dataField: 'positions',
      text: 'Stanowiska',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell noObject data={row?.positions} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
    },
    {
      dataField: 'segments',
      text: 'Działy',
      headerFormatter: (
        column: any,
        index: any,
        { sortElement, filterElement }: { sortElement: any; filterElement: any }
      ) => {
        return (
          <CustomHeaderFormatter
            column={column}
            sortElement={sortElement}
            filterElement={filterElement}
          />
        );
      },
      formatter: (cell: any, row: any, rowIndex: any, formatExtraData: any) => {
        return <TableCellListToCell noObject data={row?.segments} />;
      },
      // @ts-ignore
      filter: customFilter(),
      filterRenderer: (onFilter: any, column: any) => (
        <CustomFilterText customFilter={dataCustomFilterData} column={column} />
      ),
    },
  ];

  return (
    <>
      <ContentBox id="managerUserPlansTrainingsEmployee" noPadding>
        <>
          <LoaderTable active={loaderTable} noRadiusTop>
            <>
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              >
                <ShowTitleTable
                  id="titleTableEmployee"
                  label="LISTA PRACOWNIKÓW:"
                />
              </TablePagination>
              <BootstrapTable
                bootstrap4
                hover
                bordered={false}
                keyField="id"
                data={data?.content || []}
                columns={columns}
                noDataIndication="Brak danych"
                remote={{
                  filter: true,
                  sort: true,
                }}
                onTableChange={onTableChange}
                filter={filterFactory()}
                defaultSorted={[{ dataField: 'name', order: 'asc' }]}
              />
              <TablePagination
                rowInPage={rowsPerPage}
                page={page}
                changeRowInPage={setRowsPerPage}
                data={data}
                changePage={setPage}
              />
            </>
          </LoaderTable>
        </>
      </ContentBox>
      {modalShow ? (
        <AppraisalModalSelect
          employeeId={modalId}
          changeModalEdit={setModalShow}
          employeeName={employeeName}
        />
      ) : (
        ''
      )}

      {modalEdit ? (
        <AppraisalModalEditSelect
          employeeId={modalId}
          changeModalEdit={setModalEdit}
          employeeName={employeeName}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TableTrainingsBlock;
