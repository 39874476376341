import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsCompanyContactRepository } from 'api/repository';
import { TrainingsCompanyContactAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsCompanyContact from '../Form/FormTrainingsCompanyContact';

interface TrainigsCompanyProps {
  idRecord: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const TrainingsCompanyEdit = (props: TrainigsCompanyProps): ReactElement => {
  const { changeModalEdit, idRecord, changeUpdateTableData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [
    trainingCompanyContact,
  ] = TrainingsCompanyContactRepository.useGetTrainingsCompanyContact({
    idRecord: Number(idRecord),
  });

  const putDataSave = TrainingsCompanyContactRepository.usePutTrainingsCompanyContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsCompanyContact = (
    dataPost: TrainingsCompanyContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    putDataSave(data);
  };

  const postDataSaveAndExit = TrainingsCompanyContactRepository.usePutTrainingsCompanyContact(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsCompanyContactAndExit = (
    dataPost: TrainingsCompanyContactAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = idRecord;
    if (data.email === '') {
      delete data.email;
    }
    if (data.phone === '') {
      delete data.phone;
    }
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <Modal open changeModalShow={changeModalEdit}>
      <div>
        <FormTrainingsCompanyContact
          data={trainingCompanyContact}
          edit
          onSave={handleAddTrainingsCompanyContact}
          onSaveAndExit={handleAddTrainingsCompanyContactAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsCompanyEdit;
