import { EmployeeModel, Error } from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: EmployeeModel | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number | undefined;
}

const useGetEmployee = (
  props: PropsRecord
): [
  EmployeeModel | null | undefined,
  (employee: EmployeeModel) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<EmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    if (id !== undefined) {
      try {
        const { data } = await axios.get<EmployeeModel>(
          `${apiEndpoints.employee}/employees/${id}`
        );
        setGetData(data);
        return data;
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onError) onError(error);
        setGetData(null);
      }
    }
    return null;
  }, [axios, id, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeModel) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetUser = (
  props: Props
): [
  EmployeeModel | null | undefined,
  (employee: EmployeeModel) => void,
  (idRecord: number) => void
] => {
  const { onError } = props;
  const axios = useAxios();
  const [gData, setGData] = useState<EmployeeModel | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<EmployeeModel>(
        `${apiEndpoints.employee}/employees/user`
      );
      setGData(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setGData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: EmployeeModel) => {
    setGData(data);
  }, []);

  return [gData, updateData, fetchData];
};

export default {
  useGetEmployee,
  useGetUser,
};
