/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './showUl.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label: string;
  data?: any;
  inLine?: boolean;
}

const ShowUl = (props: Props): ReactElement => {
  const { id, label, data, inLine } = props;

  return (
    <div id={id} className={`showUl ${inLine ? 'inLine' : ''}`}>
      <div id={`${id}-label`} className="label">
        {label}
      </div>
      <div id={`${id}-data`} className="data">
        <ul>
          {data
            ? data.map((item: any) => {
                return <li id={item?.id}>{item?.name}</li>;
              })
            : ''}
        </ul>
      </div>
    </div>
  );
};

export default ShowUl;
