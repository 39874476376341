/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import '../../CustomFilter.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  name: string;
  setValue: (value: string) => void;
  keyAction: (e: any) => void;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  nameLabel?: string;
  value: any;
  options?: any;
}

const SearchBox = (props: Props): ReactElement => {
  const {
    name,
    nameLabel,
    placeholder,
    setValue,
    keyAction,
    setOpenSearch,
    value,
    options,
  } = props;

  return (
    <>
      {nameLabel !== undefined ? <label htmlFor={name}>{nameLabel}</label> : ''}
      <Select
        options={options}
        name={name}
        classNamePrefix="selectSearchCustom"
        className="selectSearchCustom"
        placeholder={placeholder || 'Wybierz...'}
        onChange={(e) => {
          setValue(e);
        }}
        onKeyDown={(e) => {
          keyAction(e);
        }}
        onFocus={() => {
          setOpenSearch(true);
        }}
        onBlur={(e) => {
          setOpenSearch(false);
        }}
        value={value}
        isSearchable
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F1F6F5',
            primary: '#00965E',
          },
        })}
      />
    </>
  );
};

export default SearchBox;
