import { useStoreState } from 'store/userSettings';
import React, { ReactElement } from 'react';
import LogoBox from './LogoBox';
import MenuBox from './MenuBox';
import ConfigMenu from './ConfigMenu';

import './LeftMenu.scss';

const LeftMenuComp = (): ReactElement => {
  // const classes = useStyles();

  const isPin = useStoreState((state) => state.userSettings.isPin);

  function CheckPin() {
    if (isPin) {
      return 'menuFull show hiddden-xs';
    }
    return 'menuFull hidden-xs';
  }

  return (
    <div className={CheckPin()}>
      <>
        <LogoBox />
        <MenuBox />
        <ConfigMenu />
      </>
    </div>
  );
};

export default LeftMenuComp;
