import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PlansTrainingsRepository } from 'api/repository';
import { PlansTrainingsAddAndPut } from 'api/types';
import FormPlansTrainings from '../../FormPlansTrainings';

const CompetencesAdd = (): ReactElement => {
  const { id } = useParams<{ id: string; action: string }>();
  const history = useHistory();

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [plan] = PlansTrainingsRepository.useGetPlanTrainings({
    id: Number(id),
  });

  const postDataSave = PlansTrainingsRepository.usePutPlansTrainings(
    async () => {
      setSubmitForm(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContact = (
    dataPost: PlansTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = Number(id);
    postDataSave(data);
  };

  const postDataSaveAndExit = PlansTrainingsRepository.usePutPlansTrainings(
    async () => {
      setSubmitForm(false);
      history.push('/ustawienia/szkolenia/plan-szkolen');
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderContactAndExit = (
    dataPost: PlansTrainingsAddAndPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.id = Number(id);
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {};

  return (
    <>
      <FormPlansTrainings
        onSave={handleAddTrainingsProviderContact}
        onSaveAndExit={handleAddTrainingsProviderContactAndExit}
        submitForm={submitForm}
        cancel={handleCancel}
        data={plan}
        edit
      />
    </>
  );
};

export default CompetencesAdd;
