import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, LoaderTable, CustomContainer } from 'components';
import { TrainingsGroupRepository } from 'api/repository';
import './trainings.scss';
import TableTrainingsBlock from './TableTrainingsBlock';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [data] = TrainingsGroupRepository.useGetCurrentPlanTest({});

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <>
            {data !== null &&
            data !== undefined &&
            data.status !== 'REGISTRATION' ? (
              <TableTrainingsBlock />
            ) : (
              <Box
                id="trainingsGroupBox"
                name="GRUPY SZKOLENIOWE"
                svgIcon="trainingsGroup"
                noContentStyle
              >
                <div className="userPlansTrainingsNoActivePlans">
                  <div className="noActivePlan">
                    {data === null ? (
                      <div>Brak aktywnego planu szkoleniowego</div>
                    ) : (
                      ''
                    )}

                    {data !== null &&
                    data !== undefined &&
                    data.status === 'REGISTRATION' ? (
                      <div>
                        Proces rejestracji na szkolenia jeszcze się nie
                        zakończył
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Box>
            )}
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default Trainigns;
