import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { RenderSvg } from 'components';

interface Props {
  changeModalShow: Dispatch<SetStateAction<boolean>>;
}

const CloseButton = (props: Props): ReactElement => {
  const { changeModalShow } = props;

  const closeModal = () => {
    changeModalShow(false);
  };

  return (
    <>
      <div
        className="closeButton"
        onClick={() => closeModal()}
        onKeyDown={() => closeModal()}
        role="button"
        tabIndex={0}
      >
        <RenderSvg nameSvg="closeButton" />
      </div>
    </>
  );
};

export default CloseButton;
