/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, {
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import Form from 'react-bootstrap/Form';
// import tinymce from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';
import './EditorMceShort.scss';
import { useStoreState } from 'store/userSettings';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  name: string;
  contentGet?: string;
  label?: string;
  register?: any;
  error?: string | undefined;
  onlyView?: boolean;
  setChangeForm?: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  noInfoPlace?: boolean;
  onChange?: (textMce: string) => void;
  setHeight?: number;
  required?: boolean;
}
const EditorMceShort = (props: Props): ReactElement => {
  const {
    id,
    name,
    contentGet,
    label,
    register,
    error,
    onlyView,
    setChangeForm,
    placeholder,
    noInfoPlace,
    onChange,
    setHeight,
    required,
  } = props;

  const isDarkTheme = useStoreState((state) => state.userSettings.isDarkTheme);

  const [contentEditor, setContentEditor] = useState(contentGet);
  const [contentStartEditor, setContentStartEditor] = useState('');
  const [editorIsInit, setEditorIsInit] = useState(0);

  useEffect(() => {
    if (contentGet) {
      setContentEditor(contentGet);
      setContentStartEditor(contentGet);
      if (tinymce.get(name)) {
        tinymce.get(name).setContent(contentGet);
      }
    }
  }, [contentGet]);

  useEffect(() => {
    if (contentStartEditor) {
      tinymce.get(name).setContent(contentStartEditor);
    }
  }, [editorIsInit]);

  const handleEditorChange = (content: any, editor: any) => {
    setContentEditor(editor.getContent());
    if (onChange) {
      onChange(editor.getContent());
    }
    if (setChangeForm) {
      setChangeForm(true);
    }
  };

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  const additionalClass = () => {
    let aClass = '';
    if (onlyView) {
      if (isDarkTheme) {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#fff; background:none;} .shortEditor p{margin:0}';
      } else {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#555; background:none;} .shortEditor p{margin:0}';
      }
    }

    if (required && (!onlyView || onlyView === undefined)) {
      if (isDarkTheme) {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#C2C2E2; background:#4C4D6D;} .shortEditor p{margin:0} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color:#C2C2E2}';
      } else {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#555; background:#F1F6F5;} .shortEditor p{margin:0}';
      }
    }
    if (
      (!onlyView || onlyView === undefined) &&
      (!required || required === undefined)
    ) {
      if (isDarkTheme) {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#A0A0C2; background:#2C2D44;} .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color:#A0A0C2}';
      } else {
        aClass +=
          '.shortEditor{margin:5px 10px; font-size:0.875rem;color:#555; background:#FFFFFF;} .shortEditor p{margin:0}';
      }
    }

    return aClass;
  };

  return (
    <>
      <div
        className={`editorBlock shortEditorBox ${label ? 'withLabel' : ''} ${
          noInfoPlace ? 'noInfoPlace' : ''
        } ${error ? 'error' : ''} ${required ? 'required' : ''}`}
      >
        {label ? <Form.Label id={name}>{label}</Form.Label> : ''}

        <Form.Control
          as="textarea"
          id={id}
          name={name}
          value={contentEditor}
          ref={register}
          className="hidden"
        />
        <Editor
          textareaName={name}
          tagName={name}
          id={id}
          ref={register}
          onChange={handleEditorChange}
          disabled={onlyView}
          init={{
            init_instance_callback: () => {
              setEditorIsInit(1);
            },
            placeholder: `${placeholder || ''}`,
            indent: false,
            skin: isDarkTheme ? 'oxide-dark' : undefined,
            content_css: isDarkTheme ? 'dark' : undefined,
            menubar: false,
            statusbar: false,
            toolbar: false,
            height: setHeight ? `${setHeight}` : undefined,
            body_class: 'shortEditor',

            content_style: additionalClass(),
          }}
        />
        <div className="additionalInfo">{errorElement()}</div>
      </div>
    </>
  );
};

export default EditorMceShort;
