/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { ModalYesNo, RenderSvg } from 'components';
import React, { ReactElement, useEffect, useState } from 'react';
import './TableCellDeleteRow.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  deleteClick: () => void;
  dataId: string;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellDeleteRow = (props: Props): ReactElement => {
  const { dataId, justify, deleteClick } = props;

  const [modalShow, setModalShow] = useState<boolean>(false);

  return (
    <>
      {dataId ? (
        <div className={`tableCellButtonDeleteBox ${justify || ''}`}>
          <div
            id={`actionDelete-${dataId}`}
            className="buttonAction"
            onClick={() => setModalShow(true)}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <RenderSvg nameSvg="deleteAction" />
          </div>
          {modalShow ? (
            <ModalYesNo
              changeModalShow={setModalShow}
              yesAction={deleteClick}
            />
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellDeleteRow;
