import {
  Error,
  EmployeeAppraisalAddAndPut,
  GroupEmployeeAppraisalAllEmployeeRequest,
  EmployeeAppraisalEmployeeModel,
  PagedData,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';
import { NumberSchema } from 'yup';

interface Props {
  onCompleted?: (data: EmployeeAppraisalAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

const useGetGroupEmployeeAppraisalAllEmployee = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: GroupEmployeeAppraisalAllEmployeeRequest) => void,
  PagedData<EmployeeAppraisalEmployeeModel> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<EmployeeAppraisalEmployeeModel> | null>(null);

  const doGet = useCallback(
    (params: GroupEmployeeAppraisalAllEmployeeRequest) => {
      axios
        .get<PagedData<EmployeeAppraisalEmployeeModel>>(
          `${apiEndpoints.employeeAppraisal}/evaluations/second-level`,
          {
            params,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

export default { useGetGroupEmployeeAppraisalAllEmployee };
