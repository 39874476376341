/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement, useEffect, useState } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  dataObject: any;
}

const SelectedToArray = (props: Props): ReactElement => {
  const { dataObject } = props;

  const getDataObject = () => {
    if (dataObject !== null) {
      return JSON.parse(dataObject);
    }
    return {};
  };

  const objectToArray = () => {
    const obj = getDataObject();

    const arr: any = [];
    Object.keys(obj).forEach((key) => {
      arr.push(key);
    });
    return arr;
  };

  return objectToArray();
};

export default SelectedToArray;
