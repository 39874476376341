import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { EmployeeAppraisalRepository } from 'api/repository';
import {
  EmployeeAppraisalGoal,
  EmployeeAppraisalRatingModel,
  EmployeeAppraisalRatingPut,
} from 'api/types';
import { Modal } from 'components';
import GoalRatingForm from '../../../Form/GoalRatingForm';

interface Props {
  idEmployee: number;
  idEvaluation: number;
  changeModal: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: (data: EmployeeAppraisalGoal) => void;
  edit?: boolean;
  data?: EmployeeAppraisalRatingModel;
}
const EmployeeAppraisalGoalEmployeeRating = (props: Props): ReactElement => {
  const {
    idEmployee,
    idEvaluation,
    changeModal,
    changeUpdateData,
    edit,
    data,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutEmployeeRatingAdmin(
    idEmployee,
    idEvaluation,
    async (dataAfterUpdate) => {
      setSubmitForm(false);
      changeUpdateData(dataAfterUpdate);
      changeModal(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (dataPost: EmployeeAppraisalRatingPut) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModal(false);
  };

  return (
    <Modal open changeModalShow={changeModal} widthBox="low" maxLevel="lvl4">
      <div>
        <GoalRatingForm
          onSaveAndExit={handleSaveAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          titleBox={
            edit ? 'EDYCJA OCENY PRACOWNIKA' : 'DODAWANIE OCENY PRACOWNIKA'
          }
          data={data}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalGoalEmployeeRating;
