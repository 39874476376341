import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingsGroupUserTraining } from 'api/types';
import { ActionInFolder, RenderSvg } from 'components';
import { useHistory } from 'react-router-dom';

import './groupItem.scss';
import { Button } from 'react-bootstrap';

interface Props {
  id: string;
  data: TrainingsGroupUserTraining;
  showClick?: (dataId: number) => void;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data, showClick } = props;
  const { t } = useTranslation(['plansTrainings', 'trainingsGroup']);

  const history = useHistory();

  const actualDate: Date = new Date();

  const startDate: Date | null =
    data?.trainingGroup?.startDate !== null &&
    data?.trainingGroup?.startDate !== undefined
      ? new Date(data?.trainingGroup?.startDate)
      : null;
  const singleDate: Date | null =
    data?.trainingGroup?.singleDate !== null &&
    data?.trainingGroup?.singleDate !== undefined
      ? new Date(data?.trainingGroup?.singleDate)
      : null;

  const startDateSmaller: Date | null = startDate !== null ? startDate : null;

  const singleDateSmaller: Date | null =
    singleDate !== null ? singleDate : null;

  const selectClick = () => {
    history.push(`/panel/szkolenia/termin-szkolenia/${data.training.id}`);
  };

  const button = () => {
    if (data?.trainingGroup === null) {
      return (
        <Button className="green" onClick={() => selectClick()}>
          Wybierz datę
        </Button>
      );
    }
    if (data?.trainingGroup !== null) {
      if (startDate !== null && startDate.getTime() <= actualDate.getTime()) {
        return (
          <Button className="noActive disabled">Nie można zmienić daty</Button>
        );
      }
      if (singleDate !== null && singleDate.getTime() <= actualDate.getTime()) {
        return (
          <Button className="noActive disabled">Nie można zmienić daty</Button>
        );
      }
      if (startDateSmaller !== null) {
        startDateSmaller.setDate(startDateSmaller.getDate() - 28);
        if (startDateSmaller.getTime() <= actualDate.getTime()) {
          return (
            <Button className="noActive disabled">
              Nie można zmienić daty
            </Button>
          );
        }
        if (startDateSmaller.getTime() > actualDate.getTime()) {
          return (
            <Button className="green" onClick={() => selectClick()}>
              Zmień datę
            </Button>
          );
        }
      }
      if (singleDateSmaller !== null) {
        singleDateSmaller.setDate(singleDateSmaller.getDate() - 28);
        if (singleDateSmaller.getTime() <= actualDate.getTime()) {
          return (
            <Button className="noActive disabled">
              Nie można zmienić daty
            </Button>
          );
        }
        if (singleDateSmaller.getTime() > actualDate.getTime()) {
          return (
            <Button className="green" onClick={() => selectClick()}>
              Zmień datę
            </Button>
          );
        }
      }
      return null;
    }

    return null;
  };

  return (
    <div
      className={`trainingGroupsUserSelectTrainingItem ${
        (startDate !== null && startDate.getTime() <= actualDate.getTime()) ||
        (singleDate !== null && singleDate.getTime() <= actualDate.getTime())
          ? 'noActive'
          : ''
      }`}
      id={`${id}-${data.training.id}`}
    >
      <div className="accent" />
      <div className="name">{data.training.name}</div>

      {data.trainingGroup === null ? (
        <div className="datesPlanNoSelectedGroup">
          <div className="label">
            Należy wybrać
            <br /> datę szkolenia
          </div>
        </div>
      ) : (
        <div className="datesPlan">
          <div className="icon">
            <RenderSvg nameSvg="plansIcon" />
          </div>
          {data.trainingGroup.startDate !== null ? (
            <div className="dates">
              <div className="startDateBox">
                Od:{' '}
                <span className="startDate">
                  {data.trainingGroup.startDate.substring(0, 10)}
                </span>
              </div>
              <div className="endDateBox">
                Do:{' '}
                <span className="endDate">
                  {data.trainingGroup.endDate.substring(0, 10)}
                </span>
              </div>
            </div>
          ) : (
            <div className="singleDate">
              <div className="singleDateBox">
                {data.trainingGroup.singleDate.substring(0, 10)}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="statusTrainingsRegisterBox">
        <div className="trainingsRegisterIcon">
          <div
            className={`statusTrainingsRegisterIcon ${
              startDate !== null && startDate?.getTime() > actualDate.getTime()
                ? 'selected'
                : ''
            } ${
              startDate !== null && startDate?.getTime() <= actualDate.getTime()
                ? 'afterTime'
                : ''
            } ${
              singleDate !== null &&
              singleDate?.getTime() > actualDate.getTime()
                ? 'selected'
                : ''
            } ${
              singleDate !== null &&
              singleDate?.getTime() <= actualDate.getTime()
                ? 'afterTime'
                : ''
            }`}
          />
        </div>
        <div className="statusTrainingsRegisterLabel">
          {startDate === null && singleDate === null
            ? 'Możesz się zapisać na to szkolenie'
            : ''}
          {startDate !== null && startDate?.getTime() > actualDate.getTime()
            ? 'Szkolenie z wybrany terminem'
            : ''}{' '}
          {startDate !== null && startDate?.getTime() <= actualDate.getTime()
            ? 'Szkolenie które się odbyło'
            : ''}{' '}
          {singleDate !== null && singleDate?.getTime() > actualDate.getTime()
            ? 'Szkolenie z wybrany terminem'
            : ''}{' '}
          {singleDate !== null && singleDate?.getTime() <= actualDate.getTime()
            ? 'Szkolenie które się odbyło'
            : ''}
        </div>
      </div>

      <div className="typeTrainingsBox">
        <div className="typeTrainingsLabel">Forma szkolenia:</div>
        <div className="typeTrainingsValue">
          {data?.trainingGroup?.trainingForm
            ? t(
                `trainingsGroup:TrainingFormTypeEnum.${data?.trainingGroup?.trainingForm}`
              )
            : ''}
        </div>
      </div>

      <div className="action">
        <div className="buttonBox">{button()}</div>
        {data?.trainingGroup !== null ? (
          <ActionInFolder
            id="actionTrainingsGroupItem"
            dataId={data.trainingGroup.id}
            showClick={
              data.trainingGroup.id !== undefined ? showClick : undefined
            }
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ContentBox;
