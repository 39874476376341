/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './showDate.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  label: string;
  singleDate?: string;
  dateFrom?: string;
  dateTo?: string;
  fullWidth?: boolean;
  toLeft?: boolean;
}

const ShowDate = (props: Props): ReactElement => {
  const { id, label, singleDate, dateFrom, dateTo, fullWidth, toLeft } = props;

  return (
    <div
      id={id}
      className={`showInput ${fullWidth ? 'fullWidth' : ''} ${
        toLeft ? 'toLeft' : ''
      }`}
    >
      <div id={`${id}-label`} className="label">
        {label}
      </div>
      <div id={`${id}-value`} className="data">
        {singleDate ? singleDate.substring(0, 10) : ''}
        {dateFrom ? `Od: ${dateFrom.substring(0, 10)}` : ''}
        {dateFrom && dateTo ? ' ' : ''}
        {dateTo ? `Do: ${dateTo.substring(0, 10)}` : ''}
      </div>
    </div>
  );
};

export default ShowDate;
