import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentBox, AnimatedProgressBarLine } from 'components';
import './limitBlock.scss';

interface Props {
  maxLimits: any;
  limitsData: any;
}

const Trainigns = (props: Props): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const { maxLimits, limitsData } = props;

  return (
    <>
      <ContentBox id="userPlansTrainingsEnrollmentLimits" bottomBorder>
        <>
          <div className="titleLimits">{t('limits.titleLimits')}</div>
          <div className="dataLimits">
            <div className="limitBlock">
              <div className="labelName">
                {maxLimits?.INTERNAL ? (
                  <>
                    {t('plansForm.internalLimit')}
                    <span>
                      {limitsData?.INTERNAL || 0} z {maxLimits?.INTERNAL}
                    </span>
                  </>
                ) : (
                  <>
                    {t('plansForm.internalLimit')}{' '}
                    <span>{t('limits.noLimits')}</span>
                  </>
                )}
              </div>
              <div className="progressBar">
                {maxLimits?.INTERNAL ? (
                  <AnimatedProgressBarLine
                    valueEnd={
                      ((limitsData?.INTERNAL || 0) / maxLimits?.INTERNAL) * 100
                    }
                  />
                ) : (
                  <AnimatedProgressBarLine noLimits />
                )}
              </div>
            </div>
            <div className="limitBlock">
              <div className="labelName">
                {maxLimits?.EXTERNAL ? (
                  <>
                    {t('plansForm.externalLimit')}
                    <span>
                      {limitsData?.EXTERNAL || 0} z {maxLimits?.EXTERNAL}
                    </span>
                  </>
                ) : (
                  <>
                    {t('plansForm.externalLimit')}{' '}
                    <span>{t('limits.noLimits')}</span>
                  </>
                )}
              </div>
              <div className="progressBar">
                {maxLimits?.EXTERNAL ? (
                  <AnimatedProgressBarLine
                    valueEnd={
                      ((limitsData?.EXTERNAL || 0) / maxLimits?.EXTERNAL) * 100
                    }
                  />
                ) : (
                  <AnimatedProgressBarLine noLimits />
                )}
              </div>
            </div>
            <div className="limitBlock">
              <div className="labelName">
                {maxLimits?.LANGUAGE ? (
                  <>
                    {t('plansForm.languageLimit')}
                    <span>
                      {limitsData?.LANGUAGE || 0} z {maxLimits?.LANGUAGE}
                    </span>
                  </>
                ) : (
                  <>
                    {t('plansForm.languageLimit')}{' '}
                    <span>{t('limits.noLimits')}</span>
                  </>
                )}
              </div>
              <div className="progressBar">
                {maxLimits?.LANGUAGE ? (
                  <AnimatedProgressBarLine
                    valueEnd={
                      ((limitsData?.LANGUAGE || 0) / maxLimits?.LANGUAGE) * 100
                    }
                  />
                ) : (
                  <AnimatedProgressBarLine noLimits />
                )}
              </div>
            </div>
            <div className="limitBlock">
              <div className="labelName">
                {maxLimits?.CORPORATE ? (
                  <>
                    {t('plansForm.corporateLimit')}
                    <span>
                      {limitsData?.CORPORATE || 0} z {maxLimits?.CORPORATE}
                    </span>
                  </>
                ) : (
                  <>
                    {t('plansForm.corporateLimit')}{' '}
                    <span>{t('limits.noLimits')}</span>
                  </>
                )}
              </div>
              <div className="progressBar">
                {maxLimits?.CORPORATE ? (
                  <AnimatedProgressBarLine
                    valueEnd={
                      ((limitsData?.CORPORATE || 0) / maxLimits?.CORPORATE) *
                      100
                    }
                  />
                ) : (
                  <AnimatedProgressBarLine noLimits />
                )}
              </div>
            </div>
            <div className="limitBlock">
              <div className="labelName">
                {maxLimits?.STUDIES ? (
                  <>
                    {t('plansForm.studiesLimit')}
                    <span>
                      {limitsData?.STUDIES || 0} z {maxLimits?.STUDIES}
                    </span>
                  </>
                ) : (
                  <>
                    {t('plansForm.studiesLimit')}{' '}
                    <span>{t('limits.noLimits')}</span>
                  </>
                )}
              </div>
              <div className="progressBar">
                {maxLimits?.STUDIES ? (
                  <AnimatedProgressBarLine
                    valueEnd={
                      ((limitsData?.STUDIES || 0) / maxLimits?.STUDIES) * 100
                    }
                  />
                ) : (
                  <AnimatedProgressBarLine noLimits />
                )}
              </div>
            </div>
          </div>
        </>
      </ContentBox>
    </>
  );
};

export default Trainigns;
