/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { ShowAction, EditAction, ArchiveAction } from './Action';

import './actionInFolder.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  dataId: number;
  showClick?: (dataId: number) => void;
  editClick?: (dataId: number) => void;
  archiveClick?: (dataId: number) => void;
}

const ActionInFolder = (props: Props): ReactElement => {
  const { id, dataId, showClick, editClick, archiveClick } = props;

  return (
    <div id={id} className="actionInFolder">
      {showClick ? <ShowAction showClick={() => showClick(dataId)} /> : ''}
      {editClick ? <EditAction editClick={() => editClick(dataId)} /> : ''}
      {archiveClick ? (
        <ArchiveAction archiveClick={() => archiveClick(dataId)} />
      ) : (
        ''
      )}
    </div>
  );
};

export default ActionInFolder;
