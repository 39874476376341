import {
  Error,
  SingleQuestionnaire,
  Questionnaire,
  QuestionnaireAnswers,
  SingleQuestionnaireSummary,
  TrainingsGroupActivePlan,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: Questionnaire | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number;
}

const useGetAllQuestionnaire = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<Questionnaire> | null] => {
  const axios = useAxios();
  const [plans, setPlans] = useState<PagedData<Questionnaire> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<Questionnaire>>(
        `${apiEndpoints.currentPlan}/current-plan/user/questionnaires`
      )
      .then(({ data }) => {
        setPlans(data);
      })
      .catch((error) => {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, plans];
};

const useGetQuestionnaire = (
  props: PropsRecord
): [
  SingleQuestionnaire | null | undefined,
  (singleQuestionnaire: SingleQuestionnaire) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    SingleQuestionnaire | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<SingleQuestionnaire>(
        `${apiEndpoints.questionnaire}/questionnaires/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: SingleQuestionnaire) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const usePostQuestionnaire = (
  id: number,
  onCompletedPost?: (data: QuestionnaireAnswers) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: QuestionnaireAnswers) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (plansTrainings: QuestionnaireAnswers) => {
      try {
        const rData = await axios.post<QuestionnaireAnswers>(
          `${apiEndpoints.plansTrainings}/training-groups/${id}/user/submit-questionnaire`,
          plansTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const useGetQuestionnaireSummary = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [(id: number) => void, SingleQuestionnaireSummary | null | undefined] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    SingleQuestionnaireSummary | null | undefined
  >(undefined);

  const doGet = useCallback(
    async (id: number) => {
      try {
        const { data } = await axios.get<SingleQuestionnaireSummary>(
          `${apiEndpoints.questionnaire}/questionnaires/${id}/summary`
        );
        setGetData(data);
        return data;
      } catch (error) {
        if (onError) onError(error);
        if (error.response) {
          AutoAlertStatus({
            status: error.response.status,
            customMsg: error.response.data,
          });
        }
        setGetData(null);
      }
      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [axios, onError]
  );

  return [doGet, getData];
};

const useGetCurrentPlanTest = (props: {
  onError?: (error: AxiosError<Error>) => void;
}): [
  TrainingsGroupActivePlan | null | undefined,
  (trainingsGroupTrainings: TrainingsGroupActivePlan) => void,
  (idRecord: number) => void
] => {
  const { onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<
    TrainingsGroupActivePlan | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsGroupActivePlan>(
        `${apiEndpoints.currentPlan}/current-plan/status`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (onError) onError(error);
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsGroupActivePlan) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

export default {
  useGetAllQuestionnaire,
  useGetQuestionnaire,
  usePostQuestionnaire,
  useGetQuestionnaireSummary,
  useGetCurrentPlanTest,
};
