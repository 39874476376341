/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  justify?: 'toLeft' | 'center' | 'toRight';
  noObject?: boolean;
}

const TableCellTypeTrainings = (props: Props): ReactElement => {
  const { data, justify, noObject } = props;
  const { t } = useTranslation('trainings');

  return (
    <>
      {data && data.length > 0
        ? data.map((item: any, i: number) => {
            if (data.length === i + 1) {
              if (noObject) {
                return `${item}`;
              }
              return `${item?.name}`;
            }
            if (noObject) {
              return `${item}, `;
            }
            return `${item?.name}, `;
          })
        : ''}
    </>
  );
};

export default TableCellTypeTrainings;
