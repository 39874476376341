/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import './contentBox.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  bottomBorder?: boolean;
  children?: ReactElement;
  noPadding?: boolean;
  title?: string;
  allowGrow?: boolean;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, bottomBorder, children, noPadding, title, allowGrow } = props;

  return (
    <div
      id={id}
      className={`contentBox ${bottomBorder ? 'bottomBorder' : ''} ${
        noPadding ? 'noPadding' : ''
      } ${allowGrow ? 'allowGrow' : ''}`}
    >
      {title ? (
        <div id={`${id}-title`} className="titleBox">
          {title}
        </div>
      ) : (
        ''
      )}
      {children}
    </div>
  );
};

export default ContentBox;
