/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import './TableCellEmployeeTrainingsStatus.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  id: number;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellTrainingsStatus = (props: Props): ReactElement => {
  const { id, data, justify } = props;

  return (
    <>
      <div className={`tableCellTrainingsStatus ${justify || ''}`}>
        <div id="dhr" className="status">
          <div className="labelStatus">DziałHR</div>
          <div className="boxStatus">
            <div className={`${data.hrStatus} circleBox`}>
              <RenderSvg nameSvg={`${data.hrStatus}Status`} />
            </div>
          </div>
        </div>
        <div id="ds" className="status">
          <div className="labelStatus">Szkolenia</div>
          <div className="boxStatus">
            <div className={`${data.dsStatus} circleBox`}>
              <RenderSvg nameSvg={`${data.dsStatus}Status`} />
            </div>
          </div>
        </div>
        <div id="slt" className="status">
          <div className="labelStatus">SLT</div>
          <div className="boxStatus">
            <div className={`${data.sltStatus} circleBox`}>
              <RenderSvg nameSvg={`${data.sltStatus}Status`} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableCellTrainingsStatus;
