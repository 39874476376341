import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ActionBeforeCatalog, ContentBox, LoaderTable } from 'components';
import { Button } from 'react-bootstrap';
import { TrainingsGroupRepository } from 'api/repository';
import { useHistory, useParams } from 'react-router-dom';
import { TrainingsGroupTraining } from 'api/types';
import GroupItem from './GroupItem';

interface Props {
  setModalId?: Dispatch<SetStateAction<number>>;
  changeModalShow?: Dispatch<SetStateAction<boolean>>;
}

const TableTrainingsBlock = (props: Props): ReactElement => {
  const { setModalId, changeModalShow } = props;
  const { t } = useTranslation('trainingsGroup');
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [loaderTable, setLoaderTable] = useState<boolean>(false);

  const [getData, data] = TrainingsGroupRepository.useGetAllTrainingsGroup(
    Number(id)
  );

  useEffect(() => {
    setLoaderTable(true);
    getData();
  }, [getData]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <LoaderTable active={loaderTable} radiusTop>
        <ContentBox id="trainingGroupsBox" noPadding title="Grupy szkoleniowe:">
          <>
            <ActionBeforeCatalog id="actionBeforeTrainingsGroup">
              <Button
                className="green"
                onClick={() => {
                  history.push(
                    `/administracja/szkolenia/grupy-szkoleniowe/${id}/formularz`
                  );
                }}
              >
                Dodaj grupę
              </Button>
            </ActionBeforeCatalog>
            <div className="TrainingsGroupItems" id={id}>
              {data?.content
                ? data.content.map((item: TrainingsGroupTraining) => {
                    return (
                      <GroupItem
                        id={id}
                        data={item}
                        showClick={() => {
                          if (setModalId && changeModalShow) {
                            setModalId(Number(item.id));
                            changeModalShow(true);
                          }
                        }}
                        editClick={() => {
                          history.push(
                            `/administracja/szkolenia/grupy-szkoleniowe/${id}/${item.id}/dane-podstawowe`
                          );
                        }}
                        archiveClick={() => {}}
                      />
                    );
                  })
                : ''}
            </div>
          </>
        </ContentBox>
      </LoaderTable>
    </>
  );
};

export default TableTrainingsBlock;
