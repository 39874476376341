/* eslint-disable no-nested-ternary */
import React, { ReactElement } from 'react';
import { Box, CustomContainer } from 'components';
import { EmployeeAppraisalRepository } from 'api/repository';
import './employee.scss';
import TableEmployeeBlock from './TableEmployeeBlock';

const Employee = (): ReactElement => {
  const [
    dataSettings,
    updateSettings,
  ] = EmployeeAppraisalRepository.useGetEmployeeAppraisalSettings({});

  return (
    <>
      {dataSettings !== null && dataSettings?.interviewType === 'ANNUAL' ? (
        <CustomContainer>
          <>
            <Box
              id="managerGroupEmployeeAppraisalEmployeeBox"
              name="OCENA GRUPOWA PRACOWNIKÓW"
              svgIcon="groupApprisal"
              noPadding
            >
              <>
                <TableEmployeeBlock />
              </>
            </Box>
          </>
        </CustomContainer>
      ) : (
        <CustomContainer allowGrow>
          <Box
            id="managerEmployeeAppraisalEmployeeBoxNoActivePlans"
            name="OCENA PRACOWNICZA"
            svgIcon="employeeAppraisalList"
            noContentStyle
          >
            <div id="managerEmployeeAppraisalEmployeeNoActivePlans">
              <div className="noActivePlan">
                <div>Aktualnie nie ma aktywnego planu ocen pracownika</div>
                <div>Powiadomimy Cię drogą mailową o jego rozpocząciu</div>
              </div>
            </div>
          </Box>
        </CustomContainer>
      )}
    </>
  );
};

export default Employee;
