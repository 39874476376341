/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Switch } from 'components';
import './TableCellSwitch.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  dataId: string;
  id: string;
  justify?: 'toLeft' | 'center' | 'toRight';
  selectedData: any;
  setSelectedData: Dispatch<SetStateAction<any>>;
  setUpdateSelected: Dispatch<SetStateAction<number>>;
  edit?: boolean;
}

const TableCellTypeTrainings = (props: Props): ReactElement => {
  const {
    dataId,
    justify,
    id,
    selectedData,
    setSelectedData,
    setUpdateSelected,
    edit,
  } = props;

  const [checked, setChecked] = useState<boolean | null>(null);

  const getSelectedData = (data: string | null) => {
    if (data !== null) {
      return JSON.parse(data);
    }
    return {};
  };

  const handleChangeSwitch = () => {
    if (checked === null) {
      setChecked(true);
    } else {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    const obj = getSelectedData(selectedData);
    if (checked !== null) {
      if (checked) {
        obj[dataId] = dataId;
      } else {
        delete obj[dataId];
      }
      setSelectedData(JSON.stringify(obj));
      if (setUpdateSelected) {
        setUpdateSelected(Math.random);
      }
    }
  }, [checked, dataId, selectedData, setSelectedData, setUpdateSelected]);

  useEffect(() => {
    if (checked === null && edit && selectedData !== null) {
      const obj = getSelectedData(selectedData);
      if (obj[dataId]) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
    if (checked === null && !edit) {
      setChecked(false);
    }
  }, [checked, dataId, edit, selectedData]);

  return (
    <>
      {dataId ? (
        <div
          className={`tableCellBlockSwitch ${
            justify !== undefined ? justify : 'toLeft'
          }`}
        >
          <Switch
            id={`${id}-${dataId}`}
            onChange={handleChangeSwitch}
            name={`selected-${dataId}`}
            checked={checked}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellTypeTrainings;
