/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { Dispatch, SetStateAction } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  orderColumn: string;
  newState: any;
  mapOrder: { [key: string]: string };
  setOrderColumn: Dispatch<SetStateAction<string>>;
  setUpdateDataTable: Dispatch<SetStateAction<number>>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomSortData = (props: Props) => {
  const {
    orderColumn,
    newState,
    mapOrder,
    setOrderColumn,
    setUpdateDataTable,
  } = props;
  const actualSort = JSON.parse(orderColumn);
  actualSort.order = newState.sortOrder;
  actualSort.name = mapOrder[newState.sortField];
  setOrderColumn(JSON.stringify(actualSort));
  setUpdateDataTable(Math.random);
  return null;
};

export default CustomSortData;
