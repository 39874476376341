import React, { ReactElement, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CatalogsTrainingsRepository } from 'api/repository';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PlansTrainingsAddAndPut, PlansTrainings } from 'api/types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  LoaderForm,
  ExitFromForm,
  ContentBox,
  SelectedToArray,
  CustomDate,
  CustomNumber,
  CustomSelect,
} from 'components';
import './formPlansTrainings.scss';

type PlanTrainingsValue = Omit<PlansTrainingsAddAndPut, 'id'>;

interface FormPlanTrainingsProps {
  onSave?: any;
  onSaveAndExit?: any;
  cancel?: any;
  data?: PlansTrainings | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
}

const FormPlansTrainings = (props: FormPlanTrainingsProps): ReactElement => {
  const { t } = useTranslation(['plansTrainings', 'trainings']);

  const [changeForm, setChangeForm] = useState<boolean>(false);
  const {
    onSave,
    edit,
    data,
    onlyView,
    onSaveAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [selectedTrainings, setSelectedTrainings] = useState<any>(null);
  const [incomingData, setIncomingData] = useState<boolean>(false);
  const [updateSelected, setUpadteSelected] = useState<number>(0);

  const [
    getCatalos,
    dataAllCatalogs,
  ] = CatalogsTrainingsRepository.useGetAllCatalogsTrainingsAll();

  useEffect(() => {
    if (!edit) {
      getCatalos();
    }
  }, [edit, getCatalos]);

  const catalogsOptions = () => {
    const arrayCatalogsOption = [] as any;
    if (dataAllCatalogs !== null && dataAllCatalogs.content.length > 0) {
      Object.keys(dataAllCatalogs.content).forEach((key) => {
        arrayCatalogsOption.push({
          value: dataAllCatalogs.content[key].id,
          label: dataAllCatalogs.content[key].name,
        });
      });
    }
    return arrayCatalogsOption;
  };

  const formSchema: yup.SchemaOf<PlanTrainingsValue> = yup.object().shape(
    {
      name: yup.string().required(t('plansForm.nameMessage')),
      startDate: yup.string().required(t('plansForm.startDate')),
      endDate: yup
        .string()
        .required(t('plansForm.endDate'))
        .test(
          'endDateValidStartDate',
          t('plansForm.endDateValidStartDate'),
          function endDateValidStartDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.endDate) <= new Date(this.parent.startDate)
            ) {
              return false;
            }
            return true;
          }
        ),

      registrationStartDate: yup
        .string()
        .required(t('plansForm.registrationStartDate'))
        .test(
          'registrationStartDateValidStartDate',
          t('plansForm.registrationStartDateValidStartDate'),
          function registrationStartDateValidStartDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.registrationStartDate) <=
              // eslint-disable-next-line
              new Date(this.parent.startDate)
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          'registrationStartDateValidEndDate',
          t('plansForm.registrationStartDateValidEndDate'),
          function registrationStartDateValidEndDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.registrationStartDate) >=
              // eslint-disable-next-line
              new Date(this.parent.endDate)
            ) {
              return false;
            }
            return true;
          }
        ),
      registrationEndDate: yup
        .string()
        .required(t('plansForm.registrationEndDate'))
        .test(
          'registrationEndDateValidRegistrationStartDate',
          t('plansForm.registrationEndDateValidRegistrationStartDate'),
          function registrationStartDateValidRegistrationStartDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.registrationStartDate) >=
              // eslint-disable-next-line
              new Date(this.parent.registrationEndDate)
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          'registrationEndDateValidEndDate',
          t('plansForm.registrationEndDateValidEndDate'),
          function registrationStartDateValidEndDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.registrationEndDate) >=
              // eslint-disable-next-line
              new Date(this.parent.endDate)
            ) {
              return false;
            }
            return true;
          }
        ),
      verificationDate: yup
        .string()
        .required(t('plansForm.verificationDate'))
        .test(
          'verificationDateValidRegistrationEndDate',
          t('plansForm.verificationDateValidRegistrationEndDate'),
          function verificationDateValidRegistrationEndDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.registrationEndDate) >=
              // eslint-disable-next-line
              new Date(this.parent.verificationDate)
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          'verificationDateValidEndDate',
          t('plansForm.verificationDateValidEndDate'),
          function verificationDateValidEndDate() {
            if (
              // eslint-disable-next-line
              new Date(this.parent.verificationDate) >=
              // eslint-disable-next-line
              new Date(this.parent.endDate)
            ) {
              return false;
            }
            return true;
          }
        ),
      catalogId: !edit
        ? yup.number().required(t('plansForm.catalogId'))
        : yup.number().notRequired(),
      corporateLimit: yup.string().nullable().notRequired(),
      externalLimit: yup.string().nullable().notRequired(),
      internalLimit: yup.string().nullable().notRequired(),
      languageLimit: yup.string().nullable().notRequired(),
      studiesLimit: yup.string().nullable().notRequired(),
    },
    [['startDate', 'endDate']]
  );

  const {
    handleSubmit,
    register,
    setValue,
    errors,
  } = useForm<PlanTrainingsValue>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
    shouldUnregister: false,
  });

  const handleSave = (dataSubmit: PlanTrainingsValue) => {
    setChangeForm(false);
    onSave(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: PlanTrainingsValue) => {
    setChangeForm(false);
    onSaveAndExit(dataSubmit);
  };

  return (
    <>
      {data === null ? (
        <ErrorLoadData />
      ) : (
        <LoaderForm active={data === undefined && edit} type="getData">
          <>
            <Box
              id="formPlanTrainingsBox"
              name={
                edit ? t('plansForm.boxNameEdit') : t('plansForm.boxNameAdd')
              }
              svgIcon="addEdit"
              noMarginBottom
              noShadow
            >
              <>
                <ExitFromForm changeForm={changeForm} />
                <Form
                  className="formPlansTrainings"
                  onChange={() => {
                    setChangeForm(true);
                  }}
                >
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <Row className="justify-content-md-center">
                        <ContentBox
                          id="FormPlansTrainingsBasicInformation"
                          bottomBorder
                        >
                          <>
                            <Row>
                              <CustomInput
                                name="name"
                                label={t('plansForm.name')}
                                register={register}
                                defaultValue={data?.name}
                                error={errors?.name?.message}
                                required
                                disabled={onlyView}
                                fullWidth
                              />
                            </Row>
                            <Row>
                              <Col xs="12" sm="12" md="6" lg="5" xl="5">
                                <Row>
                                  <Col style={{ fontWeight: 600 }}>
                                    {t('plansForm.planDates')}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ paddingRight: '0' }}>
                                    <CustomDate
                                      id="startDate"
                                      name="startDate"
                                      label={t('plansForm.from')}
                                      setValue={setValue}
                                      error={errors?.startDate?.message}
                                      defaultValue={data?.startDate}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                  <Col style={{ paddingLeft: '0' }}>
                                    <CustomDate
                                      id="endDate"
                                      name="endDate"
                                      label={t('plansForm.to')}
                                      setValue={setValue}
                                      error={errors?.endDate?.message}
                                      defaultValue={data?.endDate}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" sm="12" md="6" lg="5" xl="5">
                                <Row>
                                  <Col>
                                    {t('plansForm.datesRecruitmentProcess')}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col style={{ paddingRight: '0' }}>
                                    <CustomDate
                                      id="registrationStartDate"
                                      name="registrationStartDate"
                                      label={t('plansForm.from')}
                                      setValue={setValue}
                                      error={
                                        errors?.registrationStartDate?.message
                                      }
                                      defaultValue={data?.registrationStartDate}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                  <Col style={{ paddingLeft: '0' }}>
                                    <CustomDate
                                      id="registrationEndDate"
                                      name="registrationEndDate"
                                      label={t('plansForm.to')}
                                      setValue={setValue}
                                      error={
                                        errors?.registrationEndDate?.message
                                      }
                                      defaultValue={data?.registrationEndDate}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12" sm="12" md="6" lg="2" xl="2">
                                <Row>
                                  <Col>{t('plansForm.approvalDate')}</Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <CustomDate
                                      id="verificationDate"
                                      name="verificationDate"
                                      setValue={setValue}
                                      error={errors?.verificationDate?.message}
                                      defaultValue={data?.verificationDate}
                                      required
                                      growInput
                                      toLeft
                                      smallPaddingBlock
                                      noInfo
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </>
                        </ContentBox>
                        <ContentBox id="limitBox" bottomBorder={!edit}>
                          <div className="limitBoxContainer">
                            <div className="titleLimitBox">
                              {t('plansForm.limitTypesTraining')}
                            </div>
                            <div className="itemLimitBox">
                              <CustomNumber
                                name="internalLimit"
                                label={t('plansForm.internalLimit')}
                                register={register}
                                defaultValue={data?.limits?.INTERNAL}
                                toLeft
                                smallInput
                                smallPaddingBlock
                              />
                              <CustomNumber
                                name="externalLimit"
                                label={t('plansForm.externalLimit')}
                                register={register}
                                defaultValue={data?.limits?.EXTERNAL}
                                toLeft
                                smallInput
                                smallPaddingBlock
                              />
                              <CustomNumber
                                name="languageLimit"
                                label={t('plansForm.languageLimit')}
                                register={register}
                                defaultValue={data?.limits?.LANGUAGE}
                                toLeft
                                smallInput
                                smallPaddingBlock
                              />
                              <CustomNumber
                                name="corporateLimit"
                                label={t('plansForm.corporateLimit')}
                                register={register}
                                defaultValue={data?.limits?.CORPORATE}
                                toLeft
                                smallInput
                                smallPaddingBlock
                              />
                              <CustomNumber
                                name="studiesLimit"
                                label={t('plansForm.studiesLimit')}
                                register={register}
                                defaultValue={data?.limits?.STUDIES}
                                toLeft
                                smallInput
                                smallPaddingBlock
                              />
                            </div>
                          </div>
                        </ContentBox>
                        {!edit ? (
                          <ContentBox id="catalogsTrainingsBox">
                            <CustomSelect
                              name="catalogId"
                              setValue={setValue}
                              options={catalogsOptions()}
                              placeholder={t(
                                'plansForm.selectCatalogTrainings'
                              )}
                              noOptions={t('plansForm.noCatalogsTrainings')}
                              error={errors?.catalogId?.message}
                              required
                            />
                          </ContentBox>
                        ) : (
                          ''
                        )}
                      </Row>
                    </>
                  </LoaderForm>
                </Form>
              </>
            </Box>
            {!onlyView ? (
              <CustomSubmit
                save={handleSubmit(handleSave)}
                saveAndExit={edit ? handleSubmit(handleSaveAndExit) : undefined}
                cancel={cancel}
                submitForm={submitForm}
              />
            ) : (
              ''
            )}
          </>
        </LoaderForm>
      )}
    </>
  );
};

export default FormPlansTrainings;
