import React, { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import {
  IDPEmployeeModel,
  IDPFeedbackForm,
  IDPFeedbackPostAndPut,
  IDPModelAddAndPut,
} from 'api/types';
import IDPEmployeeFeedback from '../../Form/IDPEmployeeFeedback';

interface Props {
  IDPEmployee: IDPEmployeeModel;
  fetchData?: () => void;
  idEmployee: number;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const { IDPEmployee, fetchData, idEmployee, changeModalEdit } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [IDPFeedback, IDPFetchData] = IDPRepository.useGetIDPFeedbackAdmin({
    id: IDPEmployee.id,
    employeeId: idEmployee,
  });

  const handleEditTrainingsProvider = IDPRepository.usePutIDPFeedbackAdmin(
    Number(idEmployee),
    IDPEmployee.id,
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null && fetchData) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleEditTrainingsProviderAndExit = IDPRepository.usePutIDPFeedbackAdmin(
    Number(idEmployee),
    IDPEmployee.id,
    async () => {
      setSubmitForm(false);
      changeModalEdit(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPFeedbackForm) => {
    if (!submitForm) {
      const dataToPost: IDPFeedbackPostAndPut = {
        feedbackValues: {
          HONESTY: {
            consistentBehaviour: data.honestyConsistentBehaviour,
            inconsistentBehaviour: data.honestyInconsistentBehaviour,
          },
          RESPECT: {
            consistentBehaviour: data.respectConsistentBehaviour,
            inconsistentBehaviour: data.respectInconsistentBehaviour,
          },
          RESPONSIBILITY: {
            consistentBehaviour: data.responsibilityConsistentBehaviour,
            inconsistentBehaviour: data.responsibilityInconsistentBehaviour,
          },
          COOPERATION: {
            consistentBehaviour: data.cooperationConsistentBehaviour,
            inconsistentBehaviour: data.cooperationInconsistentBehaviour,
          },
        },
      };
      setSubmitForm(true);

      handleEditTrainingsProvider(dataToPost);
    }
  };

  const handleSubmitSaveAndExit = (data: IDPFeedbackForm) => {
    if (!submitForm) {
      setSubmitForm(true);
      const dataToPost: IDPFeedbackPostAndPut = {
        feedbackValues: {
          HONESTY: {
            consistentBehaviour: data.honestyConsistentBehaviour,
            inconsistentBehaviour: data.honestyInconsistentBehaviour,
          },
          RESPECT: {
            consistentBehaviour: data.respectConsistentBehaviour,
            inconsistentBehaviour: data.respectInconsistentBehaviour,
          },
          RESPONSIBILITY: {
            consistentBehaviour: data.responsibilityConsistentBehaviour,
            inconsistentBehaviour: data.responsibilityInconsistentBehaviour,
          },
          COOPERATION: {
            consistentBehaviour: data.cooperationConsistentBehaviour,
            inconsistentBehaviour: data.cooperationInconsistentBehaviour,
          },
        },
      };

      handleEditTrainingsProviderAndExit(dataToPost);
    }
  };

  const handleCancel = () => {
    changeModalEdit(false);
  };

  return (
    <IDPEmployeeFeedback
      id={Number(idEmployee)}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={
        IDPEmployee !== null ? handleSubmitSaveAndExit : undefined
      }
      submitForm={submitForm}
      data={IDPFeedback}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
