import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import { TrainingsRepository } from 'api/repository';
import { TrainingsAddAndPut } from 'api/types';
import { Modal } from 'components';
import FormTrainings from '../FormTrainings';

interface TrainingsProps {
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit?: Dispatch<SetStateAction<boolean>>;
  changeModalId?: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
  onlySaveAndExit?: boolean;
}
const TrainingsAdd = (props: TrainingsProps): ReactElement => {
  const {
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
    onlySaveAndExit,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = TrainingsRepository.useAddTrainings(
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
      if (changeModalId) {
        changeModalId(data.id);
      }
      if (changeModalEdit) {
        changeModalEdit(true);
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainings = (dataPost: TrainingsAddAndPut) => {
    setSubmitForm(true);
    postDataSave(dataPost);
  };

  const postDataSaveAndExit = TrainingsRepository.useAddTrainings(
    async () => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsAndExit = (dataPost: TrainingsAddAndPut) => {
    setSubmitForm(true);
    postDataSaveAndExit(dataPost);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal
      open
      changeModalShow={changeModalAdd}
      widthBox="medium"
      maxLevel="lvl2"
    >
      <div>
        <FormTrainings
          onSave={onlySaveAndExit ? undefined : handleAddTrainings}
          onSaveAndExit={handleAddTrainingsAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsAdd;
