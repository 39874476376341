import {
  TrainingsRequest,
  Error,
  Training,
  TrainingsAddAndPut,
  TrainingsList,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: TrainingsAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number;
}

const useGetAllTrainings = (
  onError?: (error: AxiosError<Error>) => void
): [(params: TrainingsRequest) => void, PagedData<TrainingsList> | null] => {
  const axios = useAxios();
  const [competence, setCompetence] = useState<PagedData<TrainingsList> | null>(
    null
  );

  const doGet = useCallback(
    (params: TrainingsRequest) => {
      axios
        .get<PagedData<TrainingsList>>(`${apiEndpoints.trainings}/trainings`, {
          params,
        })
        .then(({ data }) => {
          setCompetence(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, competence];
};

const useGetTrainings = (
  props: PropsRecord
): [
  Training | null | undefined,
  (trainings: Training) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [training, setTraining] = useState<Training | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<Training>(
        `${apiEndpoints.trainings}/trainings/${id}`
      );
      setTraining(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setTraining(null);
    }
    return null;
  }, [axios, id, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: Training) => {
    setTraining(data);
  }, []);

  return [training, updateData, fetchData];
};

const useAddTrainings = (
  onCompletedPost?: (data: TrainingsAddAndPut) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsAddAndPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (trainings: Omit<TrainingsAddAndPut, 'id'>) => {
      try {
        const rData = await axios.post<TrainingsAddAndPut>(
          `${apiEndpoints.trainings}/trainings`,
          trainings
        );

        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const usePutTrainings = (
  onCompletedPut?: (data: TrainingsAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putTrainings = useCallback(
    async (trainings: TrainingsAddAndPut) => {
      try {
        const rData = await axios.put<TrainingsAddAndPut>(
          `${apiEndpoints.trainings}/trainings/${trainings.id}`,
          trainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putTrainings;
};

const useDeleteTrainings = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteTrainings = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.trainings}/trainings/${id}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteTrainings;
};

export default {
  useGetAllTrainings,
  useGetTrainings,
  useAddTrainings,
  usePutTrainings,
  useDeleteTrainings,
};
