/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, {
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import Form from 'react-bootstrap/Form';
// import tinymce from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import tinymce from 'tinymce';
import './EditorMce.scss';
import { useStoreState } from 'store/userSettings';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: string;
  name: string;
  contentGet?: string;
  label?: string;
  register?: any;
  error?: string | undefined;
  onlyView?: boolean;
  setChangeForm?: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  noInfoPlace?: boolean;
  onChange?: (textMce: string) => void;
  noToolbar?: boolean;
  noMenubar?: boolean;
  noStatusbar?: boolean;
  setHeight?: number;
}
const EditorMce = (props: Props): ReactElement => {
  const {
    id,
    name,
    contentGet,
    label,
    register,
    error,
    onlyView,
    setChangeForm,
    placeholder,
    noInfoPlace,
    onChange,
    noToolbar,
    noMenubar,
    noStatusbar,
    setHeight,
  } = props;

  const isDarkTheme = useStoreState((state) => state.userSettings.isDarkTheme);

  const [contentEditor, setContentEditor] = useState(contentGet);
  const [contentStartEditor, setContentStartEditor] = useState('');
  const [editorIsInit, setEditorIsInit] = useState(0);

  useEffect(() => {
    if (contentGet) {
      setContentEditor(contentGet);
      setContentStartEditor(contentGet);
      if (tinymce.get(name)) {
        tinymce.get(name).setContent(contentGet);
      }
    }
  }, [contentGet]);

  useEffect(() => {
    if (contentStartEditor) {
      tinymce.get(name).setContent(contentStartEditor);
    }
  }, [editorIsInit]);

  const handleEditorChange = (content: any, editor: any) => {
    setContentEditor(editor.getContent());
    if (onChange) {
      onChange(editor.getContent());
    }
    if (setChangeForm) {
      setChangeForm(true);
    }
  };

  const errorElement = () => {
    if (typeof error === 'string') {
      return <p className="alertInfo">{error}</p>;
    }
    return null;
  };

  return (
    <>
      <div
        id={`${name}EditorBlock`}
        className={`editorBlock ${label ? 'withLabel' : ''} ${
          noInfoPlace ? 'noInfoPlace' : ''
        } ${error ? 'error' : ''}`}
      >
        {label ? <Form.Label id={name}>{label}</Form.Label> : ''}
        <div id={`${name}AdditionalInfo`} className="additionalInfo">
          {errorElement()}
        </div>
        <Form.Control
          as="textarea"
          id={id}
          name={name}
          value={contentEditor}
          ref={register}
          className="hidden"
        />
        <Editor
          textareaName={name}
          tagName={name}
          id={id}
          ref={register}
          onChange={handleEditorChange}
          disabled={onlyView}
          init={{
            init_instance_callback: () => {
              setEditorIsInit(1);
            },
            placeholder: `${placeholder || ''}`,
            indent: false,
            skin: isDarkTheme ? 'oxide-dark' : undefined,
            content_css: isDarkTheme ? 'dark' : undefined,
            menubar: noMenubar ? false : undefined,
            statusbar: noStatusbar ? false : undefined,
            toolbar: noToolbar ? false : undefined,
            height: setHeight ? `${setHeight}` : undefined,
          }}
        />
      </div>
    </>
  );
};

export default EditorMce;
