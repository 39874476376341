import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tabs, CustomContainer } from 'components';
import PlansTrainingsEditForm from './PlansTrainingsEditForm';
import TableTrainings from './TableTrainings';

const PlansTrainingsEdit = (): ReactElement => {
  const { id, action } = useParams<{ id: string; action: string }>();

  return (
    <>
      <CustomContainer>
        <>
          <Tabs
            id="PlansTrainingsTabs"
            items={[
              {
                id: 'dane-podstawowe',
                label: 'Dane podstawowe',
                link: `/ustawienia/szkolenia/plan-szkolen/formularz/${id}/dane-podstawowe`,
              },
              {
                id: 'szkolenia',
                label: 'Szkolenia',
                link: `/ustawienia/szkolenia/plan-szkolen/formularz/${id}/szkolenia`,
              },
            ]}
          />

          {action === 'dane-podstawowe' ? <PlansTrainingsEditForm /> : ''}
          {action === 'szkolenia' ? (
            <Box
              id="plansTrainingsBox"
              name="Szkolenia przypisane do planu"
              lightHeader
              noPadding
            >
              <TableTrainings />
            </Box>
          ) : (
            ''
          )}
        </>
      </CustomContainer>
    </>
  );
};

export default PlansTrainingsEdit;
