/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import { CustomTooltip, RenderSvg } from 'components';
import React, { ReactElement } from 'react';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  id: number;
  last?: string;
  first?: string;
  customString?: string;
  customList?: any;
}

const TableCellLastFirstName = (props: Props): ReactElement => {
  const { id, last, first, customString, customList } = props;

  return (
    <>
      {id ? (
        <div
          id={`tableCellBlockLastFirstNameWithAdditionalInfo-${id}`}
          className="tableCellBlockLastFirstNameWithAdditionalInfo"
        >
          <span>
            {last || ''} {first || ''}{' '}
            <CustomTooltip
              customTitle="DODATKOWE INFORMACJE"
              customList={customList || undefined}
              customString={customString || undefined}
            />
          </span>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellLastFirstName;
