import React, { ReactElement, useState, ElementType, useEffect } from 'react';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import './customSliderBar.scss';
import { RenderSvg } from 'components';
import Slider from 'rc-slider/lib/Slider';

/*
Input Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface CustomSliderBarProps {
  name: string;
  label?: string;
  setValue: any;
  defaultValue?: number;
  disabled?: boolean;
}

const CustomSliderBar = (props: CustomSliderBarProps): ReactElement => {
  const { name, label, setValue, defaultValue, disabled } = props;

  const [valueData, setValueData] = useState<number>(0);

  const [updateDefault, setUpdateDefault] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue !== undefined && updateDefault === false) {
      setValueData(defaultValue);
      setUpdateDefault(true);
    }
  }, [defaultValue, updateDefault]);

  useEffect(() => {
    setValue(name, valueData);
  }, [name, setValue, valueData]);

  return (
    <div
      id={`${name}CustomSliderBlock`}
      className={`customSliderBlock ${label ? 'withLabel' : ''}`}
    >
      {label ? <Form.Label id={name}>{label}</Form.Label> : ''}
      <div className="blockInputWithInfo">
        <Slider
          min={0}
          marks={{
            0: '0%',
            25: '25%',
            50: '50%',
            75: '75%',
            100: '100%',
          }}
          step={null}
          onChange={(e: number) => {
            setValueData(e);
          }}
          value={valueData}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default CustomSliderBar;
