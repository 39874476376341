import React, { ReactElement, Dispatch, SetStateAction, useState } from 'react';
import { TrainingsProviderAddressRepository } from 'api/repository';
import { TrainingsProviderAddressAddPut } from 'api/types';
import { Modal } from 'components';
import FormTrainingsProviderAddress from '../Form/FormTrainingsProviderAddress';

interface TrainigsProviderProps {
  id: number;
  changeModalAdd: Dispatch<SetStateAction<boolean>>;
  changeModalEdit: Dispatch<SetStateAction<boolean>>;
  changeModalId: Dispatch<SetStateAction<number>>;
  changeUpdateTableData: Dispatch<SetStateAction<number>>;
}
const TrainingsProviderShow = (props: TrainigsProviderProps): ReactElement => {
  const {
    id,
    changeModalAdd,
    changeModalEdit,
    changeModalId,
    changeUpdateTableData,
  } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSave = TrainingsProviderAddressRepository.useAddTrainingsProviderAddress(
    Number(id),
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
      changeModalId(data.address.id);
      changeModalEdit(true);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAddress = (
    dataPost: TrainingsProviderAddressAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.providerId = id;
    postDataSave(data);
  };

  const postDataSaveAndExit = TrainingsProviderAddressRepository.useAddTrainingsProviderAddress(
    Number(id),
    async (data) => {
      setSubmitForm(false);
      changeUpdateTableData(Math.random);
      changeModalAdd(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleAddTrainingsProviderAddressAndExit = (
    dataPost: TrainingsProviderAddressAddPut
  ) => {
    setSubmitForm(true);
    const data = dataPost;
    data.providerId = id;
    postDataSaveAndExit(data);
  };

  const handleCancel = () => {
    changeModalAdd(false);
  };

  return (
    <Modal open changeModalShow={changeModalAdd}>
      <div>
        <FormTrainingsProviderAddress
          onSave={handleAddTrainingsProviderAddress}
          onSaveAndExit={handleAddTrainingsProviderAddressAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
        />
      </div>
    </Modal>
  );
};

export default TrainingsProviderShow;
