/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';

import { RenderSvg } from 'components';
import './Tabs.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface PropsElement {
  id: string;
  label: string;
  link: string;
  svgName?: string;
  disabled?: boolean;
}
interface Props {
  id: string;
  items: PropsElement[];
}

const Box = (props: Props): ReactElement => {
  const { id, items } = props;

  return (
    <div className="tabsBox" id={id}>
      {items.map((item: PropsElement) => {
        return item.disabled === true ? (
          <div id={item.id} className="tabsBoxItem disabled">
            {item.svgName ? <RenderSvg nameSvg={item.svgName} /> : ''}
            {item.label}
          </div>
        ) : (
          <NavLink to={item.link} id={item.id} className="tabsBoxItem">
            {item.svgName ? <RenderSvg nameSvg={item.svgName} /> : ''}
            {item.label}
          </NavLink>
        );
      })}
    </div>
  );
};

export default Box;
