import {
  TrainingsCompanyContactRequest,
  Error,
  TrainingsCompanyContact,
  TrainingsCompanyContactAddPut,
  PagedData,
} from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (
    data: TrainingsCompanyContactAddPut | TrainingsCompanyContact | number
  ) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  idRecord: number;
}

const useGetAllTrainingsCompanyContact = (
  props: Props
): [() => void, PagedData<TrainingsCompanyContact> | null] => {
  const axios = useAxios();
  const { onError } = props;
  const [
    trainingsCompanyContact,
    setTrainingsCompanyContact,
  ] = useState<PagedData<TrainingsCompanyContact> | null>(null);

  const doGet = useCallback(
    async () => {
      try {
        const rData = await axios.get<PagedData<TrainingsCompanyContact>>(
          `${apiEndpoints.trainingsCompany}/company-contacts`
        );
        setTrainingsCompanyContact(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onError]
  );

  return [doGet, trainingsCompanyContact];
};

const useGetTrainingsCompanyContact = (
  props: PropsRecord
): [
  TrainingsCompanyContact | null | undefined,
  (trainingsCompanyContact: TrainingsCompanyContact) => void,
  (idRecord: number) => void
] => {
  const { onError, idRecord } = props;
  const axios = useAxios();
  const [trainingsCompanyContact, setTrainingsCompanyContact] = useState<
    TrainingsCompanyContact | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<TrainingsCompanyContact>(
        `${apiEndpoints.trainingsCompany}/company-contacts/${idRecord}`
      );
      setTrainingsCompanyContact(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setTrainingsCompanyContact(null);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [axios, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: TrainingsCompanyContact) => {
    setTrainingsCompanyContact(data);
  }, []);

  return [trainingsCompanyContact, updateData, fetchData];
};

const useAddTrainingsCompanyContact = (
  onCompletedPost?: (data: TrainingsCompanyContact) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<TrainingsCompanyContactAddPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postTrainingsCompanyContact = useCallback(
    async (
      trainingsCompanyContact: Omit<TrainingsCompanyContactAddPut, 'id'>
    ) => {
      try {
        const rData = await axios.post<TrainingsCompanyContact>(
          `${apiEndpoints.trainingsCompany}/company-contacts`,
          trainingsCompanyContact
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postTrainingsCompanyContact;
};

const usePutTrainingsCompanyContact = (
  onCompletedPut?: (data: TrainingsCompanyContactAddPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: TrainingsCompanyContactAddPut) => Promise<void>) => {
  const axios = useAxios();

  const putTrainingsCompanyContact = useCallback(
    async (trainingsCompanyContact: TrainingsCompanyContactAddPut) => {
      try {
        const rData = await axios.put<TrainingsCompanyContactAddPut>(
          `${apiEndpoints.trainingsCompany}/company-contacts/${trainingsCompanyContact.id}`,
          trainingsCompanyContact
        );
        AutoAlertStatus({ status: rData.status });

        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putTrainingsCompanyContact;
};

const useDeleteTrainingsCompanyContact = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteTrainingsCompanyContact = useCallback(
    async (idDelete: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.trainingsCompany}/company-contacts/${idDelete}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(idDelete);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteTrainingsCompanyContact;
};

export default {
  useGetAllTrainingsCompanyContact,
  useGetTrainingsCompanyContact,
  useAddTrainingsCompanyContact,
  usePutTrainingsCompanyContact,
  useDeleteTrainingsCompanyContact,
};
