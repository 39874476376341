/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { ActionInFolder, RenderSvg } from 'components';

import './catalogsPlansItem.scss';

/*
Short Name Component
Note: 
Usage:
*/

interface Props {
  id: string;
  data: any;
  showClick?: (dataId: number) => void;
  editClick?: (dataId: number) => void;
  archiveClick?: (dataId: number) => void;
}

const ContentBox = (props: Props): ReactElement => {
  const { id, data, showClick, editClick, archiveClick } = props;
  const { t } = useTranslation('plansTrainings');
  const actualDate = new Date();

  return (
    <div
      className={`CatalogsPlansItem ${
        actualDate >= new Date(data.startDate) &&
        actualDate <= new Date(data.endDate)
          ? ''
          : 'noActive'
      }`}
      id={`${id}-${data.id}`}
    >
      <div className="accent" />
      <div className="name">{data.name}</div>
      <div className="datesPlan">
        <div className="icon">
          <RenderSvg nameSvg="plansIcon" />
        </div>
        <div className="dates">
          <div className="startDateBox">
            Od: <span className="startDate">{data.startDate}</span>
          </div>
          <div className="endDateBox">
            Do: <span className="endDate">{data.endDate}</span>
          </div>
        </div>
      </div>
      <div className="registrationProcess">
        <div className="registrationProcessLabel">Proces Rejestracji:</div>
        <div className="registrationProcessDates">
          <div className="startRegistrationBox">
            Od: <span className="startDate">{data.registrationStartDate}</span>
          </div>
          <div className="endRegistrationBox">
            Do: <span className="endDate">{data.registrationEndDate}</span>
          </div>
        </div>
      </div>
      <div className="action">
        <div className="verificationDateBox">
          <div className="verificationDateLabel">Zatwierdzenie:</div>
          <div className="verificationDate">{data.verificationDate}</div>
        </div>
        <ActionInFolder
          id="actionCatalogsPlansItem"
          dataId={data.id}
          showClick={showClick}
          editClick={editClick}
          archiveClick={archiveClick}
        />
      </div>
    </div>
  );
};

export default ContentBox;
