/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';

import { RenderSvg } from 'components';
import './TabsHook.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface PropsElement {
  id: string;
  label: string;
  svgName?: string;
}
interface Props {
  id: string;
  items: PropsElement[];
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  marginTop?: boolean;
}

const Box = (props: Props): ReactElement => {
  const { id, items, activeTab, setActiveTab, marginTop } = props;

  return (
    <div className={`tabsHookBox ${marginTop ? 'marginTop' : ''}`} id={id}>
      {items.map((item: PropsElement) => {
        return (
          <div
            role="button"
            onClick={() => setActiveTab(item.id)}
            onKeyDown={() => {}}
            tabIndex={0}
            id={item.id}
            className={`tabsBoxItem ${activeTab === item.id ? 'active' : ''}`}
          >
            {item.svgName ? <RenderSvg nameSvg={item.svgName} /> : ''}
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default Box;
