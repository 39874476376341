import React, { ReactElement, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { CustomInput, ErrorLoadData } from 'components/CustomForm';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TrainingsProvider } from 'api/types';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CustomSubmit,
  EditorMce,
  LoaderForm,
  ExitFromForm,
  CustomContainer,
} from 'components';

type TrainingsProviderValues = Omit<TrainingsProvider, 'id'>;

interface TrainingsProviderProps {
  id?: number;
  onTrainingsProvider?: any;
  onTrainingsProviderAndExit?: any;
  data?: TrainingsProvider | null;
  edit?: boolean;
  onlyView?: boolean;
  onModalShow?: boolean;
  submitForm?: boolean;
  cancel?: () => void;
}

const FormTrainingsProvider = (props: TrainingsProviderProps): ReactElement => {
  const {
    id,
    onTrainingsProvider,
    edit,
    data,
    onlyView,
    onTrainingsProviderAndExit,
    onModalShow,
    submitForm,
    cancel,
  } = props;

  const [changeForm, setChangeForm] = useState<boolean>(false);

  const { t } = useTranslation('trainingsProvider');

  const formSchema: yup.SchemaOf<TrainingsProviderValues> = yup.object().shape({
    name: yup.string().required(t('form.nameMessage')),
    fullName: yup.string().required(t('form.fullNameMessage')),
    cooperationTerms: yup.string().notRequired(),
    nip: yup.string().notRequired(),
    regon: yup.string().notRequired(),
    website: yup.string().notRequired(),
  });

  const handleSave = (dataSubmit: TrainingsProviderValues) => {
    setChangeForm(false);
    onTrainingsProvider(dataSubmit);
  };

  const handleSaveAndExit = (dataSubmit: TrainingsProviderValues) => {
    setChangeForm(false);
    onTrainingsProviderAndExit(dataSubmit);
  };

  const { handleSubmit, register, errors } = useForm<TrainingsProviderValues>({
    resolver: yupResolver(formSchema),
    shouldFocusError: true,
  });

  return (
    <CustomContainer>
      <>
        {data === null ? (
          <ErrorLoadData />
        ) : (
          <>
            <ExitFromForm changeForm={changeForm} />
            <Form
              className="form"
              onChange={(e) => {
                setChangeForm(true);
              }}
            >
              <LoaderForm active={data === undefined && edit} type="getData">
                <>
                  <LoaderForm active={submitForm} type="saving">
                    <>
                      <Box
                        id="trainingProviderBox"
                        name={t('form.basicInfomation')}
                        svgIcon="basicInformation"
                        lightHeader={edit}
                      >
                        <>
                          <Row className="justify-content-md-center">
                            <Col>
                              <div>
                                <CustomInput
                                  name="name"
                                  label={t('form.nameLabel')}
                                  register={register}
                                  defaultValue={data?.name}
                                  error={errors?.name?.message}
                                  required
                                  disabled={onlyView}
                                />
                                <CustomInput
                                  name="fullName"
                                  label={t('form.nameFullName')}
                                  register={register}
                                  defaultValue={data?.fullName}
                                  error={errors?.fullName?.message}
                                  required
                                  disabled={onlyView}
                                />
                              </div>
                            </Col>

                            <Col>
                              <CustomInput
                                name="nip"
                                label={t('form.taxNumber')}
                                register={register}
                                error={errors?.nip?.message}
                                defaultValue={data?.nip}
                                disabled={onlyView}
                              />
                              <CustomInput
                                name="regon"
                                label={t('form.regon')}
                                register={register}
                                error={errors?.regon?.message}
                                defaultValue={data?.regon}
                                disabled={onlyView}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <CustomInput
                                name="website"
                                label={t('form.website')}
                                register={register}
                                error={errors?.website?.message}
                                defaultValue={data?.website}
                                disabled={onlyView}
                              />
                            </Col>
                            <Col />
                          </Row>
                        </>
                      </Box>
                      <Box
                        id="trainingProviderEditorBox"
                        name={t('form.cooperationTerms')}
                        svgIcon="hand-cap"
                        lightHeader
                        allowHideContent
                        noRadius={onlyView}
                        noPadding
                      >
                        <>
                          <EditorMce
                            id="cooperationTerms"
                            name="cooperationTerms"
                            register={register}
                            contentGet={data?.cooperationTerms}
                            error={errors?.cooperationTerms?.message}
                            onlyView={onlyView}
                            setChangeForm={setChangeForm}
                          />
                        </>
                      </Box>
                    </>
                  </LoaderForm>
                  {!onlyView ? (
                    <CustomSubmit
                      save={
                        onTrainingsProvider
                          ? handleSubmit(handleSave)
                          : undefined
                      }
                      saveAndExit={
                        onTrainingsProviderAndExit
                          ? handleSubmit(handleSaveAndExit)
                          : undefined
                      }
                      submitForm={submitForm}
                      cancel={cancel}
                    />
                  ) : (
                    ''
                  )}
                </>
              </LoaderForm>
            </Form>
          </>
        )}
      </>
    </CustomContainer>
  );
};

export default FormTrainingsProvider;
