/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { SummaryOnly } from 'api/types';

import './questionYesNoPartlySummary.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: SummaryOnly;
}

const QuestionYesNoPartlySummary = (props: Props): ReactElement => {
  const { data } = props;

  return (
    <>
      <div
        id={`questionYesNoPartlyBox-${data.id}`}
        className="questionYesNoPartlySummaryBox"
      >
        <div
          id={`questionYesNoPartlySummaryNumber-${data.id}`}
          className="questionYesNoPartlySummaryNumber"
        >
          {data.questionOrder < 10
            ? `0${data.questionOrder}.`
            : `${data.questionOrder}.`}
        </div>
        <div
          id={`questionYesNoPartlySummaryName-${data.id}`}
          className="questionYesNoPartlySummaryName"
        >
          {data.name}
        </div>
        <div
          id={`questionYesNoPartlySummaryAnswerBox-${data.id}`}
          className="questionYesNoPartlySummaryAnswerBox"
        >
          Tak:
          <span>
            {data.answersProjection.tak
              ? `${data.answersProjection.tak}% `
              : '0% '}
          </span>
          / Nie:
          <span>
            {data.answersProjection.nie
              ? `${data.answersProjection.nie}% `
              : '0% '}
          </span>
          / Częściowo:
          <span>
            {data.answersProjection.częściowo
              ? `${data.answersProjection.częściowo}%`
              : '0%'}
          </span>
        </div>
      </div>

      {data?.texts && data.texts !== null ? (
        <div
          id={`questionYesNoPartlySummarySubstantiationBox-${data.id}`}
          className="questionYesNoPartlySummarySubstantiationBox"
        >
          <>
            {Object.entries(data.texts).map((value) => {
              return value[1].map((val) => {
                return (
                  <div className="questionYesNoPartlySummarySubstantiationItem">
                    <span className="summaryLabel">
                      Uzasadnienie - {value[0]}:
                    </span>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={
                        val ? { __html: val } : undefined
                      }
                    />
                  </div>
                );
              });
            })}
          </>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default QuestionYesNoPartlySummary;
