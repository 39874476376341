import React, { ReactElement, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDPRepository } from 'api/repository';
import {
  IDPEmployeeModel,
  IDPFeedbackForm,
  IDPFeedbackPostAndPut,
  IDPModelAddAndPut,
} from 'api/types';
import IDPEmployeeFeedback from '../../Form/IDPEmployeeFeedback';

interface Props {
  IDPEmployee: IDPEmployeeModel | null | undefined;
  fetchData?: any;
}

const TrainingsProviderAddPage = (props: Props): ReactElement => {
  const history = useHistory();
  const { idEmployee } = useParams<{ idEmployee: string; idRecord: string }>();

  const { IDPEmployee, fetchData } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const [IDPFeedback, IDPFetchData] = IDPRepository.useGetIDPFeedback({
    id: Number(idEmployee),
  });

  const handleEditTrainingsProvider = IDPRepository.usePutIDPFeedback(
    Number(idEmployee),
    async () => {
      setSubmitForm(false);
      if (IDPEmployee === null) {
        fetchData();
      }
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleEditTrainingsProviderAndExit = IDPRepository.usePutIDPFeedback(
    Number(idEmployee),
    async () => {
      setSubmitForm(false);
      history.push(`/kierownik/IDP/pracownicy`);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSubmit = (data: IDPFeedbackForm) => {
    if (!submitForm) {
      const dataToPost: IDPFeedbackPostAndPut = {
        feedbackValues: {
          HONESTY: {
            consistentBehaviour: data.honestyConsistentBehaviour,
            inconsistentBehaviour: data.honestyInconsistentBehaviour,
          },
          RESPECT: {
            consistentBehaviour: data.respectConsistentBehaviour,
            inconsistentBehaviour: data.respectInconsistentBehaviour,
          },
          RESPONSIBILITY: {
            consistentBehaviour: data.responsibilityConsistentBehaviour,
            inconsistentBehaviour: data.responsibilityInconsistentBehaviour,
          },
          COOPERATION: {
            consistentBehaviour: data.cooperationConsistentBehaviour,
            inconsistentBehaviour: data.cooperationInconsistentBehaviour,
          },
        },
      };
      setSubmitForm(true);

      handleEditTrainingsProvider(dataToPost);
    }
  };

  const handleSubmitSaveAndExit = (data: IDPFeedbackForm) => {
    if (!submitForm) {
      setSubmitForm(true);
      const dataToPost: IDPFeedbackPostAndPut = {
        feedbackValues: {
          HONESTY: {
            consistentBehaviour: data.honestyConsistentBehaviour,
            inconsistentBehaviour: data.honestyInconsistentBehaviour,
          },
          RESPECT: {
            consistentBehaviour: data.respectConsistentBehaviour,
            inconsistentBehaviour: data.respectInconsistentBehaviour,
          },
          RESPONSIBILITY: {
            consistentBehaviour: data.responsibilityConsistentBehaviour,
            inconsistentBehaviour: data.responsibilityInconsistentBehaviour,
          },
          COOPERATION: {
            consistentBehaviour: data.cooperationConsistentBehaviour,
            inconsistentBehaviour: data.cooperationInconsistentBehaviour,
          },
        },
      };

      handleEditTrainingsProviderAndExit(dataToPost);
    }
  };

  const handleCancel = () => {
    history.push(`/kierownik/IDP/pracownicy`);
  };

  return (
    <IDPEmployeeFeedback
      id={Number(idEmployee)}
      onTrainingsProvider={handleSubmit}
      onTrainingsProviderAndExit={
        IDPEmployee !== null ? handleSubmitSaveAndExit : undefined
      }
      submitForm={submitForm}
      data={IDPFeedback}
      cancel={handleCancel}
      edit
    />
  );
};

export default TrainingsProviderAddPage;
