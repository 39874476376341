import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';

import './elementItem.scss';

interface Props {
  id: 'trainings' | 'idp' | 'appraisal' | 'questionnaire' | 'loyality';
  name: string;
  children?: ReactElement;
  buttonTitle?: string | undefined;
  buttonAction?: () => void | undefined;
  smallButtonName?: boolean;
}

const ElementItem = (props: Props): ReactElement => {
  const {
    id,
    name,
    children,
    buttonTitle,
    buttonAction,
    smallButtonName,
  } = props;

  return (
    <div id={`desktopItem-${id}`} className={`desktopItem ${id}`}>
      <div id={`desktopItemAccent-${id}`} className="accent" />
      <div
        id={`desktopItemName-${id}`}
        className={`name ${smallButtonName ? 'smallButtonName' : ''}`}
      >
        {name}
      </div>
      <div id={`desktopItemContent-${id}`} className="content">
        {children}
      </div>

      <div id={`desktopItemAction-${id}`} className="action">
        {buttonTitle && buttonAction ? (
          <Button
            id={`desktopItemButton-${id}`}
            className="green"
            onClick={buttonAction}
          >
            {buttonTitle}
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ElementItem;
