import React, { ReactElement, useState, Dispatch, SetStateAction } from 'react';
import {
  EmployeeAppraisalRepository,
  EmployeeRepository,
} from 'api/repository';
import {
  EmployeeAppraisalRatingPut,
  EmployeeAppraisalRatingWithIdEmployeePut,
  GroupEmployeeAppraisalEmployeeModel,
} from 'api/types';
import { Modal } from 'components';
import GoalRatingForm from '../../Form/FinalGoalRatingForm';

interface Props {
  changeModal: Dispatch<SetStateAction<boolean>>;
  changeUpdateData: Dispatch<SetStateAction<number>>;
  data: GroupEmployeeAppraisalEmployeeModel;
}
const EmployeeAppraisalFinalRating = (props: Props): ReactElement => {
  const { changeModal, changeUpdateData, data } = props;

  const [submitForm, setSubmitForm] = useState<boolean>(false);

  const postDataSaveAndExit = EmployeeAppraisalRepository.usePutManagerN2Rating(
    async () => {
      setSubmitForm(false);
      changeUpdateData(Math.random);
      changeModal(false);
    },
    async () => {
      setSubmitForm(false);
    }
  );

  const handleSaveAndExit = (dataPost: EmployeeAppraisalRatingPut) => {
    setSubmitForm(true);
    const dataToPost: EmployeeAppraisalRatingWithIdEmployeePut = {
      id: data.id,
      justification: dataPost.justification,
      rating: dataPost.rating,
    };
    postDataSaveAndExit(dataToPost);
  };

  const handleCancel = () => {
    changeModal(false);
  };

  return (
    <Modal open changeModalShow={changeModal} widthBox="medium">
      <div>
        <GoalRatingForm
          onSaveAndExit={handleSaveAndExit}
          submitForm={submitForm}
          cancel={handleCancel}
          titleBox={
            data?.finalAppraisal !== null
              ? 'EDYCJA OCENY FINALNEJ'
              : 'DODAWANIE OCENY FINALNEJ'
          }
          data={data}
        />
      </div>
    </Modal>
  );
};

export default EmployeeAppraisalFinalRating;
