import { createStore, createTypedHooks, persist } from 'easy-peasy';
import userSettingsModel, { UserSettingsModel } from './userSettings';

interface StoreModel {
  userSettings: UserSettingsModel;
}

interface InitialState {
  userSettings: Pick<
    UserSettingsModel,
    'isDarkTheme' | 'isPin' | 'isConfigBox' | 'isHighContrast' | 'fontSize'
  >;
}

const storeModel: StoreModel = {
  userSettings: userSettingsModel,
};

const initialState = {
  initialState: {
    userSettings: {
      isDarkTheme: window.matchMedia('(prefers-color-scheme: dark)').matches,
      isPin: false,
      isConfigBox: false,
      isHighContrast: false,
      fontSize: 1,
    },
  },
};

const store = createStore<StoreModel, InitialState>(
  persist(storeModel, { storage: 'localStorage' }),
  initialState
);

const typedHooks = createTypedHooks<StoreModel>();

export const { useStoreActions } = typedHooks;
export const { useStoreDispatch } = typedHooks;
export const { useStoreState } = typedHooks;

export default store;
