import { Error, TrainingsProviderSimple } from 'api/types';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const useGetAllTrainingsProviderSimple = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, TrainingsProviderSimple | null] => {
  const axios = useAxios();
  const [
    trainingsProvider,
    setTrainingsProvider,
  ] = useState<TrainingsProviderSimple | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<TrainingsProviderSimple>(
        `${apiEndpoints.trainingsProvider}/providers/all`
      )
      .then(({ data }) => {
        setTrainingsProvider(data);
      })
      .catch((error) => {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, trainingsProvider];
};

export default useGetAllTrainingsProviderSimple;
