import {
  UserActivePlansTrainings,
  LimitsTrainings,
  SelectedTrainingsActivePlansRequest,
  SelectedTrainingsActivePlans,
  UnselectedTrainingsActivePlansRequest,
  AddTrainingsMotivation,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

const useGetActivePlansTrainings = (): [
  UserActivePlansTrainings | null | undefined,
  (userActivePlansTrainings: UserActivePlansTrainings) => void,
  (idRecord: number) => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<
    UserActivePlansTrainings | null | undefined
  >(undefined);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<UserActivePlansTrainings>(
        `${apiEndpoints.userPlansTrainings}/current-plan`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: UserActivePlansTrainings) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useGetLimitsActivePlansTrainings = (): [
  () => void,
  LimitsTrainings | null | undefined
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<LimitsTrainings | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<LimitsTrainings>(
        `${apiEndpoints.userPlansTrainings}/current-plan/user/limits`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
  }, [axios]);

  return [fetchData, getData];
};

const useGetAllSelectedTrainingsActivePlans = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: SelectedTrainingsActivePlansRequest) => void,
  PagedData<SelectedTrainingsActivePlans> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<SelectedTrainingsActivePlans> | null>(null);

  const doGet = useCallback(
    (params: SelectedTrainingsActivePlansRequest) => {
      const paramsGet = new URLSearchParams();
      if (params !== undefined) {
        Object.keys(params).forEach((key) => {
          if (Array.isArray(params[key])) {
            Object.keys(params[key]).forEach((keyObject) => {
              if (params[key][keyObject] !== '') {
                paramsGet.append(key, params[key][keyObject]);
              }
            });
          } else if (params[key] !== undefined) {
            paramsGet.append(key, params[key]);
          }
        });
      }
      axios
        .get<PagedData<SelectedTrainingsActivePlans>>(
          `${apiEndpoints.userPlansTrainings}/current-plan/user/registrations`,
          {
            params: paramsGet,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useGetAllUnselectedTrainingsActivePlans = (
  onError?: (error: AxiosError<Error>) => void
): [
  (params: UnselectedTrainingsActivePlansRequest) => void,
  PagedData<SelectedTrainingsActivePlans> | null
] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<SelectedTrainingsActivePlans> | null>(null);

  const doGet = useCallback(
    (params: UnselectedTrainingsActivePlansRequest) => {
      const paramsGet = new URLSearchParams();
      if (params !== undefined) {
        Object.keys(params).forEach((key) => {
          if (Array.isArray(params[key])) {
            Object.keys(params[key]).forEach((keyObject) => {
              if (params[key][keyObject] !== '') {
                paramsGet.append(key, params[key][keyObject]);
              }
            });
          } else if (params[key] !== undefined) {
            paramsGet.append(key, params[key]);
          }
        });
      }
      axios
        .get<PagedData<SelectedTrainingsActivePlans>>(
          `${apiEndpoints.userPlansTrainings}/current-plan/user/available-trainings`,
          {
            params: paramsGet,
          }
        )
        .then(({ data }) => {
          setCatalogs(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, onError]
  );

  return [doGet, catalogs];
};

const useAddTrainingsToSelected = (
  onCompletedPost?: (data: AddTrainingsMotivation) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: AddTrainingsMotivation) => Promise<void>) => {
  const axios = useAxios();

  const post = useCallback(
    async (motivation: AddTrainingsMotivation) => {
      try {
        const rData = await axios.post<AddTrainingsMotivation>(
          `${apiEndpoints.userPlansTrainings}/current-plan/user/registrations`,
          motivation
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return post;
};

const useDeleteSeletedTrainings = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteTrainings = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.userPlansTrainings}/current-plan/user/registrations/${id}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteTrainings;
};

const useApprovalSelectedTrainings = (
  onCompleted?: () => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): (() => void) => {
  const axios = useAxios();

  const approvalSelectedTrainings = useCallback(
    async () => {
      try {
        const rData = await axios.post(
          `${apiEndpoints.userPlansTrainings}/current-plan/user/registrations/accept`
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompleted) onCompleted();
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompleted, onErrorDelete]
  );

  return approvalSelectedTrainings;
};

const useGetApprovePlan = (): [
  boolean | null | undefined,
  (userActivePlansTrainings: boolean) => void,
  (idRecord: number) => void
] => {
  const axios = useAxios();
  const [getData, setGetData] = useState<boolean | null | undefined>(undefined);

  const fetchData = useCallback(async () => {
    try {
      const rData = await axios.get<boolean>(
        `${apiEndpoints.userPlansTrainings}/current-plan/user/registrations/accept`
      );

      setGetData(rData.data);
      return rData.data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      setGetData(null);
    }
    return null;
  }, [axios]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: boolean) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

export default {
  useGetActivePlansTrainings,
  useGetLimitsActivePlansTrainings,
  useGetAllSelectedTrainingsActivePlans,
  useGetAllUnselectedTrainingsActivePlans,
  useAddTrainingsToSelected,
  useDeleteSeletedTrainings,
  useApprovalSelectedTrainings,
  useGetApprovePlan,
};
