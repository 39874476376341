import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Box, LoaderTable, CustomContainer } from 'components';
import { QuestionaireRepository } from 'api/repository';
import QuestionnaireList from './QuestionnaireList';
import './questionnaire.scss';

const Trainigns = (): ReactElement => {
  const { t } = useTranslation('plansTrainings');

  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [updateDataTable, setUpdateDataTable] = useState<number>(0);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalId, setModalId] = useState<number>(0);

  const history = useHistory();

  const [dataPlan] = QuestionaireRepository.useGetCurrentPlanTest({});

  const [getData, data] = QuestionaireRepository.useGetAllQuestionnaire();

  useEffect(() => {
    if (
      dataPlan !== undefined &&
      dataPlan !== null &&
      dataPlan.status !== 'REGISTRATION'
    ) {
      setLoaderTable(true);
      getData();
    }
  }, [getData, updateDataTable, dataPlan]);

  useEffect(() => {
    if (data !== null) {
      setLoaderTable(false);
    }
  }, [data]);

  return (
    <>
      <CustomContainer allowGrow>
        <LoaderTable active={loaderTable} radiusTop allowGrow>
          <>
            <Box
              id="questionnairesBox"
              name="ANKIETY"
              svgIcon="questionnarieIcon"
              noContentStyle
            >
              {dataPlan !== undefined &&
              dataPlan !== null &&
              dataPlan.status !== 'REGISTRATION' &&
              data !== undefined &&
              data !== null &&
              data?.content.length > 0 ? (
                <div id="contentQuestionnaire">
                  <QuestionnaireList id="QuestionnaireList" data={data} />
                </div>
              ) : (
                <div id="questionnairesNoActive">
                  <div className="noActivePlan">
                    <div>
                      Nie masz w tej chwili żadnych ankiet do wypełnienia
                    </div>
                    <div>
                      Poinformujemy cię mailowo o konieczności wypełnienia
                      ankiety
                    </div>
                  </div>
                </div>
              )}
            </Box>
          </>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default Trainigns;
