/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { RenderSvg } from 'components';
import Select from 'react-select';
import './CustomSort.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  order: string;
}

const CustomSort = (props: Props): ReactElement => {
  const { order } = props;

  return (
    <>
      {!order ? <RenderSvg nameSvg="sortCaret" idElement="noSortTable" /> : ''}
      {order === 'asc' ? (
        <RenderSvg nameSvg="sortCaretAsc" idElement="sortTableAsc" />
      ) : (
        ''
      )}
      {order === 'desc' ? (
        <RenderSvg nameSvg="sortCaretDesc" idElement="sortTableDesc" />
      ) : (
        ''
      )}
    </>
  );
};

export default CustomSort;
