import React, { ReactElement } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  changeForm: boolean;
}

const ExitFromForm = (props: Props): ReactElement => {
  const { changeForm } = props;

  const { t } = useTranslation('form');

  return (
    <>
      {changeForm ? (
        <>
          <Beforeunload
            onBeforeunload={() => t('exitAfterChangeForm.message')}
          />
          <Prompt message={t('exitAfterChangeForm.message')} />
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ExitFromForm;
