import { action, Action } from 'easy-peasy';

export interface UserSettingsModel {
  isDarkTheme: boolean;
  changeTheme: Action<UserSettingsModel, boolean>;
  isPin: boolean;
  changePin: Action<UserSettingsModel, boolean>;
  isConfigBox: boolean;
  changeConfigBox: Action<UserSettingsModel, boolean>;
  isHighContrast: boolean;
  changeHighContrast: Action<UserSettingsModel, boolean>;
  fontSize: number;
  changeFontSize: Action<UserSettingsModel, number>;
}

const userSettingsModel: UserSettingsModel = {
  isDarkTheme: false,
  changeTheme: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isDarkTheme = payload;
  }),
  isPin: false,
  changePin: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isPin = payload;
  }),
  isConfigBox: false,
  changeConfigBox: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isConfigBox = payload;
  }),
  isHighContrast: false,
  changeHighContrast: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.isHighContrast = payload;
  }),
  fontSize: 1,
  changeFontSize: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.fontSize = payload;
  }),
};

export default userSettingsModel;
