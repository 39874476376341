/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './TableCellTypeTrainings.scss';

/*
Toggle Switch Component
Note: id, checked and onChange are required for ToggleSwitch component to function.
The props name, small, disabled and optionLabels are optional.
Usage: <ToggleSwitch id={id} checked={value} onChange={checked => setValue(checked)}} />
*/

interface Props {
  data: any;
  justify?: 'toLeft' | 'center' | 'toRight';
}

const TableCellTypeTrainings = (props: Props): ReactElement => {
  const { data, justify } = props;
  const { t } = useTranslation('trainings');

  return (
    <>
      {data ? (
        <div
          className={`tableCellBlockTypeTraining ${
            justify !== undefined ? justify : 'toLeft'
          }`}
        >
          {data ? <div>{t(`trainingsTypeEnum.${data}`)}</div> : ''}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableCellTypeTrainings;
