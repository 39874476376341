import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  CustomContainer,
  LoaderTable,
  ShowTextarea,
  Tabs,
} from 'components';
import {
  EmployeeAppraisalRepository,
  EmployeeRepository,
} from 'api/repository';
import { Button } from 'react-bootstrap';
import { EmployeeAppraisalPutSpecificGoal } from 'api/types';
import { EditAction } from 'components/CustomShow/ActionInFolder/Action';

import EmployeeAppraisalEmployeeGoal from './EmployeeAppraisalEmployeeGoal';
import SpecificGoalEditModal from './SpecificGoalEditModal';
import EmployeeAppraisalEmployeeGoalRating from './EmployeeAppraisalEmployeeGoalRating';

import './employeeAppraisalDetail.scss';

const EmployeeAppraisalDetail = (): ReactElement => {
  const { idEmployee, action } = useParams<{
    idEmployee: string;
    action: string;
  }>();

  const [
    showAddSpecificGoalModal,
    setAddShowSpecificGoalModal,
  ] = useState<boolean>(false);

  const [
    showEditSpecificGoalModal,
    setEditShowSpecificGoalModal,
  ] = useState<boolean>(false);

  const [employeeData] = EmployeeRepository.useGetEmployee({
    id: Number(idEmployee),
  });

  const [
    getGoalUser,
    updateGoalUser,
    refreshGoalUser,
  ] = EmployeeAppraisalRepository.useGetGoalEmployee(Number(idEmployee));

  const [
    getGoalManager,
    updateGoalManager,
    refreshGoalManager,
  ] = EmployeeAppraisalRepository.useGetGoalManager(Number(idEmployee));

  const putSpecificGoal = EmployeeAppraisalRepository.usePutEmployeeAppraisaSpecificGoal(
    Number(idEmployee),
    (data) => {
      setAddShowSpecificGoalModal(false);
      setEditShowSpecificGoalModal(false);
      updateGoalUser(data);
    }
  );

  const handleAddSpecificGoal = (data: EmployeeAppraisalPutSpecificGoal) => {
    const dataPost = data;
    if (data.specificGoal === '') {
      dataPost.specificGoal = null;
    }
    putSpecificGoal(dataPost);
  };

  const handleEditSpecificGoal = (data: EmployeeAppraisalPutSpecificGoal) => {
    const dataPost = data;
    if (data.specificGoal === '') {
      dataPost.specificGoal = null;
    }
    putSpecificGoal(dataPost);
  };

  const nameBox = () => {
    let textName = 'EDYCJA OCENY PRACOWNIKA ';
    if (getGoalUser && getGoalUser.interviewType === 'FIRST_ANNUAL') {
      textName = 'EDYCJA OCENY PRACOWNIKA - WPROWADZANIE DANYCH';
    }
    if (getGoalUser && getGoalUser.interviewType === 'SIX_MONTH') {
      textName = 'EDYCJA OCENY PRACOWNIKA - ROZMOWA PÓŁROCZNA';
    }
    if (getGoalUser && getGoalUser.interviewType === 'LAST_ANNUAL') {
      textName = 'EDYCJA OCENY PRACOWNIKA - ROZMOWA ROCZNA';
    }
    return textName;
  };

  return (
    <>
      <CustomContainer>
        <LoaderTable active={getGoalUser === undefined}>
          <Box
            id="EmployeeAppraisalBox"
            name={getGoalUser === null ? 'DODAWANIE IDP' : nameBox()}
            svgIcon="addEdit"
            noPadding
          >
            <>
              <div id="basicInformation">
                <div id="firstRowBasicInformation">
                  <div id="firstColumn">
                    <div id="labelEmployee">Fromularz wprowadzania dla:</div>
                    <div id="dataEmployee">
                      {employeeData?.lastName}, {employeeData?.firstName}
                    </div>
                  </div>
                  <div id="secondColumn">
                    {getGoalUser?.specificGoal !== undefined &&
                    (getGoalUser?.specificGoal === '' ||
                      getGoalUser?.specificGoal === null) &&
                    getGoalUser.interviewType !== null &&
                    getGoalUser.interviewType !== 'LAST_ANNUAL' &&
                    getGoalUser.interviewType !== 'NONE' ? (
                      <div id="detailPurposeEmplyee">
                        Możesz pracownikowi wprowadzić cel szczegółówy:{' '}
                        <Button
                          className="green"
                          onClick={() => setAddShowSpecificGoalModal(true)}
                        >
                          Wprowadź
                        </Button>
                        {showAddSpecificGoalModal ? (
                          <SpecificGoalEditModal
                            changeShowModal={setAddShowSpecificGoalModal}
                            employeeName={`${employeeData?.lastName},  ${employeeData?.firstName}`}
                            onSaveAndExit={handleAddSpecificGoal}
                            superiorGoal={getGoalManager?.overallGoal}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                {getGoalUser?.specificGoal !== undefined &&
                getGoalUser?.specificGoal !== '' &&
                getGoalUser?.specificGoal !== null ? (
                  <div id="goal">
                    <div id="goalLabel">
                      Cel szczegółowy przypisany do pracownika:
                    </div>
                    <div id="goalData">
                      {getGoalUser.interviewType !== null &&
                      getGoalUser.interviewType !== 'LAST_ANNUAL' ? (
                        <EditAction
                          editClick={() => {
                            setEditShowSpecificGoalModal(true);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <ShowTextarea
                        id="goalText"
                        data={getGoalUser?.specificGoal}
                      />
                      {showEditSpecificGoalModal ? (
                        <SpecificGoalEditModal
                          changeShowModal={setEditShowSpecificGoalModal}
                          employeeName={`${employeeData?.lastName},  ${employeeData?.firstName}`}
                          data={getGoalUser?.specificGoal}
                          onSaveAndExit={handleEditSpecificGoal}
                          superiorGoal={getGoalManager?.overallGoal}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {getGoalManager?.overallGoal !== undefined &&
                    getGoalManager?.overallGoal !== null &&
                    getGoalManager?.overallGoal !== '' ? (
                      <div id="goal">
                        <div id="goalLabel">
                          Cel ogólny przypisany wszystkim pracownikom:
                        </div>
                        <div id="goalData">
                          <ShowTextarea
                            id="goalText"
                            data={getGoalManager?.overallGoal}
                          />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
              {getGoalUser === null ? (
                <div id="EmployeeAppraisalContentBoxEmpty">
                  Nie ma Oceny pracownika
                </div>
              ) : (
                <>
                  <Tabs
                    id="EmployeeAppraisalTabs"
                    items={[
                      {
                        id: 'cele-roczne',
                        label: 'Cele roczne',
                        link: `/kierownik/ocena-pracownika/pracownik/${idEmployee}/cele-roczne`,
                        svgName: 'target',
                      },
                      {
                        id: 'ocena-celow',
                        label: 'Ocena celów',
                        link: `/kierownik/ocena-pracownika/pracownik/${idEmployee}/ocena-celow`,
                        svgName: 'appraisalGoal',
                        disabled:
                          getGoalUser?.interviewType === 'LAST_ANNUAL'
                            ? undefined
                            : true,
                      },
                    ]}
                  />
                  <div id="EmployeeAppraisalContentBox">
                    {action === 'cele-roczne' ? (
                      <EmployeeAppraisalEmployeeGoal
                        employeeAppraisalStatus={getGoalUser}
                        updateGoalUser={updateGoalUser}
                      />
                    ) : (
                      ''
                    )}
                    {action === 'ocena-celow' ? (
                      <EmployeeAppraisalEmployeeGoalRating
                        goalUser={getGoalUser}
                        employee={employeeData}
                        updateGoalUser={updateGoalUser}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </Box>
        </LoaderTable>
      </CustomContainer>
    </>
  );
};

export default EmployeeAppraisalDetail;
