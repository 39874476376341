import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const LogoIconComp = (): ReactElement => {
  return (
    <div className="logoIconBox">
      <Link to="/panel/tablica">
        <svg
          width="57"
          height="43"
          viewBox="0 0 57 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.754 6.59749H41.8033V35.4552V36.2795L56.7967 0H17.6703L14.754 6.59749ZM14.754 6.59749L0 42.0519H39.3448L41.8033 35.4552H14.754V6.59749Z" />
        </svg>
      </Link>
    </div>
  );
};

export default LogoIconComp;
