import {
  Error,
  CatalogsTrainingsList,
  CatalogsTrainingsAddAndPut,
  CatalogTrainings,
  CatalogTrainingsSelected,
  CatalogsTrainingsSelectedRequest,
  CatalogTrainingsAll,
} from 'api/types';
import { PagedData } from 'api/types/BaseResponse';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useAxios, apiEndpoints } from 'api';
import { AutoAlertStatus } from 'components';

interface Props {
  onCompleted?: (data: CatalogsTrainingsAddAndPut | number) => void;
  onError?: (error: AxiosError<Error>) => void;
}

interface PropsRecord extends Props {
  id: number;
}

const useGetAllCatalogsTrainings = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<CatalogsTrainingsList> | null] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<CatalogsTrainingsList> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<CatalogsTrainingsList>>(
        `${apiEndpoints.catalogsTrainings}/catalogs`
      )
      .then(({ data }) => {
        setCatalogs(data);
      })
      .catch((error) => {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onError) onError(error);
      });
  }, [axios, onError]);

  return [doGet, catalogs];
};

const useGetCatalogTrainings = (
  props: PropsRecord
): [
  CatalogTrainings | null | undefined,
  (catalogTrainings: CatalogTrainings) => void,
  (idRecord: number) => void
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [getData, setGetData] = useState<CatalogTrainings | null | undefined>(
    undefined
  );

  const fetchData = useCallback(async () => {
    try {
      const { data } = await axios.get<CatalogTrainings>(
        `${apiEndpoints.catalogsTrainings}/catalogs/${id}`
      );
      setGetData(data);
      return data;
    } catch (error) {
      if (error.response) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
      }
      if (onError) onError(error);
      setGetData(null);
    }
    return null;
  }, [axios, id, onError]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateData = useCallback((data: CatalogTrainings) => {
    setGetData(data);
  }, []);

  return [getData, updateData, fetchData];
};

const useAddCatalogsTrainings = (
  onCompletedPost?: (data: CatalogsTrainingsAddAndPut) => void,
  onErrorPost?: (error: AxiosError<Error>) => void
): ((arg0: Omit<CatalogsTrainingsAddAndPut, 'id'>) => Promise<void>) => {
  const axios = useAxios();

  const postCompetence = useCallback(
    async (catalogsTrainings: Omit<CatalogsTrainingsAddAndPut, 'id'>) => {
      try {
        const rData = await axios.post<CatalogsTrainingsAddAndPut>(
          `${apiEndpoints.catalogsTrainings}/catalogs`,
          catalogsTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPost) onCompletedPost(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPost) onErrorPost(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPost, onErrorPost]
  );

  return postCompetence;
};

const usePutCatalogsTrainings = (
  onCompletedPut?: (data: CatalogsTrainingsAddAndPut) => void,
  onErrorPut?: (error: AxiosError<Error>) => void
): ((arg0: CatalogsTrainingsAddAndPut) => Promise<void>) => {
  const axios = useAxios();

  const putCatalogsTrainings = useCallback(
    async (catalogsTrainings: CatalogsTrainingsAddAndPut) => {
      try {
        const rData = await axios.put<CatalogsTrainingsAddAndPut>(
          `${apiEndpoints.trainings}/catalogs/${catalogsTrainings.id}`,
          catalogsTrainings
        );
        AutoAlertStatus({ status: rData.status });
        if (onCompletedPut) onCompletedPut(rData.data);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorPut) onErrorPut(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedPut, onErrorPut]
  );

  return putCatalogsTrainings;
};

const useDeleteCatalogsTrainings = (
  onCompletedDelete?: (data: number) => void,
  onErrorDelete?: (error: AxiosError<Error>) => void
): ((arg0: number) => Promise<void>) => {
  const axios = useAxios();

  const deleteCatalogsTrainings = useCallback(
    async (id: number) => {
      try {
        const rData = await axios.delete(
          `${apiEndpoints.catalogsTrainings}/catalogs/${id}`
        );
        AutoAlertStatus({ status: rData.status, statusMsg: 'archive' });
        if (onCompletedDelete) onCompletedDelete(id);
      } catch (error) {
        AutoAlertStatus({
          status: error.response.status,
          customMsg: error.response.data,
        });
        if (onErrorDelete) onErrorDelete(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [axios, onCompletedDelete, onErrorDelete]
  );

  return deleteCatalogsTrainings;
};

const useGetCatalogsTrainingsSelected = (
  props: PropsRecord
): [
  (params: CatalogsTrainingsSelectedRequest) => void,
  PagedData<CatalogTrainingsSelected> | null
] => {
  const { id, onError } = props;
  const axios = useAxios();
  const [
    catalogsTrainings,
    setCatalogsTrainings,
  ] = useState<PagedData<CatalogTrainingsSelected> | null>(null);

  const doGet = useCallback(
    (params: CatalogsTrainingsSelectedRequest) => {
      axios
        .get<PagedData<CatalogTrainingsSelected>>(
          `${apiEndpoints.catalogsTrainings}/catalogs/${id}/trainings`,
          { params }
        )
        .then(({ data }) => {
          setCatalogsTrainings(data);
        })
        .catch((error) => {
          if (error.response) {
            AutoAlertStatus({
              status: error.response.status,
              customMsg: error.response.data,
            });
          }
          if (onError) onError(error);
        });
    },
    [axios, id, onError]
  );

  return [doGet, catalogsTrainings];
};

const useGetAllCatalogsTrainingsAll = (
  onError?: (error: AxiosError<Error>) => void
): [() => void, PagedData<CatalogTrainingsAll> | null] => {
  const axios = useAxios();
  const [
    catalogs,
    setCatalogs,
  ] = useState<PagedData<CatalogTrainingsAll> | null>(null);

  const doGet = useCallback(() => {
    axios
      .get<PagedData<CatalogTrainingsAll>>(
        `${apiEndpoints.catalogsTrainings}/catalogs`
      )
      .then(({ data }) => {
        setCatalogs(data);
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  }, [axios, onError]);

  return [doGet, catalogs];
};

export default {
  useGetAllCatalogsTrainings,
  useGetCatalogTrainings,
  useAddCatalogsTrainings,
  usePutCatalogsTrainings,
  useDeleteCatalogsTrainings,
  useGetCatalogsTrainingsSelected,
  useGetAllCatalogsTrainingsAll,
};
